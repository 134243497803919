import { Component, OnInit } from '@angular/core';
//import { loadSlider } from 'common/slick-slider';

@Component({
  selector: 'app-left-part',
  templateUrl: './left-part.component.html',
  styleUrls: ['./left-part.component.css']
})
export class LeftPartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   // loadSlider();
  }

}
