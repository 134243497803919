import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(name: string): string {
    if (!name) return '';
    const names = name.split(' ');
    const initials = names.map(word => word.charAt(0)).join('');
    return initials.toUpperCase();
  }
}
