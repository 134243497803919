import { Injectable } from '@angular/core';
import { ApiClientService } from './common/api-client.service';
import { Reminder } from 'models/reminder';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RemindersService {

  public updateReminder: BehaviorSubject<any> = new BehaviorSubject(false);
  getupdateReminder$ = this.updateReminder.asObservable();

  public deleteReminder: BehaviorSubject<any> = new BehaviorSubject('');
  getDeleteReminder$ = this.deleteReminder.asObservable();

  constructor(private apiClient: ApiClientService) { }

  updateReminderCount(isUpdate: boolean) {
    this.updateReminder.next(isUpdate);
  }

  deleteReminderCount(isDelete: any) {
    this.deleteReminder.next(isDelete);
  }

  getRemindersCount(requestedBy: number){
    return this.apiClient.get(`api/Reminder/GetReminderCount/${requestedBy}`, null, null, false);
  }

  getReminderTypes() {
    return this.apiClient.get(`api/Reminder/GetReminderTypes`, null, null, false);
  }

  getReminders(userId: number, requestedBy: number, isPast?: boolean, reminderTypeId?: number, userAppointmentId?: number, userLicenseClassId?: number, branchId?: number) {
    return this.apiClient.get(`api/Reminder/GetReminders/${requestedBy}/${isPast}/${userId}/${reminderTypeId}/${userAppointmentId}/${userLicenseClassId}/${branchId}`, null, null, false);
  }

  getReminderDetails(reminderId: number, reminderTypeId: number) {
    return this.apiClient.get(`api/Reminder/GetReminderDetails/${reminderId}/${reminderTypeId}`, null, null, false);
  }
  
  DismissReminder(reminderId: number) {
    return this.apiClient.post(`api/Reminder/DismissReminder/${reminderId}`, null, null, false);
  }

  insertReminder(reminder: Reminder) {
    return this.apiClient.post(`api/Reminder/InsertReminder`, reminder, null, true);
  }
}
