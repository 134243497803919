declare var $: any;
// export function loadSlider() {    
//     $(".agenzee-info-slider").slick({
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         dots: false,
//         arrows: false,
//         speed: 300,
//         autoplaySpeed: 5000,
//         autoplay:false,
//     });
//     $(".left-side-content .agenzee-info-slider .slick-dots li button").text("");
// }



export function seterror() {
    $(".form-input").on("keyup", function () {
        var height_val = $(".error-text").outerHeight();
        $(".form-group.error").css("margin-bottom", height_val + 5);
        if ($(".form-group") && $(".form-group").removeattr) {
            $(".form-group").removeattr("style");
        }

    });
}

export function setStickyTheader(){
    setTimeout(function(){
        var t_value = $(".header-content").outerHeight() + $(".common-tab .nav-tabs.fixed").outerHeight() + $(".action-table-header").outerHeight();
        $(".common-table .table thead.sticky-from-top").css("top",t_value);
    },100);    
}
// export function sideCollapse() {
//     $(".collapse-sidebar").click(function () {
//         $(".sidebar-contentdata .sidebar-width").toggleClass("half");
//         $(".sidebar-contentdata .contentdata-width").toggleClass("full");
//     });
// }