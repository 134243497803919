<div class="common-modal set-reminder-modal">
    <div class="modal-header pop-title">
        <h4 class="modal-title">New Reminder</h4>
        <button type="button" class="modal-close-btn close" data-dismiss="modal" (click)="close()">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><rect width="28" height="28" rx="6" fill="none" /><g transform="translate(4 4)"><rect width="20" height="20" fill="none" /><path d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z" transform="translate(-0.005 0.005)" fill="#757575" /></g></svg>
        </button>
    </div>
    <div class="modal-body">
        <div class="user-detail-block" >
            <h3 class="name">{{contactInfo?.displayName}}
            <!-- <p class="data-info mt-2 m-0"><span *ngIf="contactInfo?.ReminderTypeId == 2 || contactInfo?.ReminderTypeId == 3">'s</span>" <span *ngIf="contactInfo?.ReminderTypeId == 3">license</span><span *ngIf="contactInfo?.ReminderTypeId == 2">appointment</span>?</p> -->
            <span class="reminder-type mt-1" *ngIf="contactInfo.ReminderTypeId == 1">{{tenantTypeCap[tenantTypeId]}}</span>
              <span class="reminder-type mt-1" *ngIf="contactInfo.ReminderTypeId == 2">Appointment</span>
              <span class="reminder-type mt-1" *ngIf="contactInfo.ReminderTypeId == 3">License</span>
              <span class="reminder-type mt-1" *ngIf="contactInfo.ReminderTypeId == 4">{{isMGAUser ? 'Agency' : 'Branch'}}
            </span>

            </h3>
        </div>

        <div class="reminder-block px-3" >
            <div class="remind-details reminder-datalist">
              <div class="reminder-info">
                <p class="mr-0" [ngClass]="(contactInfo.ReminderTypeId === 3 || contactInfo.ReminderTypeId === 4) ? 'w-50-ml': 'w-100'">NPN: <span class="semi-bold"> {{contactInfo?.npn ? contactInfo?.npn : 'N/A'}} </span></p>
                <p class="mr-0 w-50 ml-2" *ngIf="contactInfo.ReminderTypeId == 3">License #: <span class="semi-bold"> {{contactInfo?.licenseNumber}}
                  </span></p>
                <p class="mr-0 w-100" *ngIf="contactInfo.ReminderTypeId == 3">License Class: 
                    <span class="semi-bold max-width-tooltip-210" appEllipsifyMe> {{contactInfo?.licenseClass}} </span></p>
               
                <p class="mr-0 w-100" *ngIf="contactInfo.ReminderTypeId == 2">Company: <span class="semi-bold max-width-tooltip-250"
                    appEllipsifyMe> {{contactInfo?.carrierName}} </span></p>
                <p class="mr-0 w-100" *ngIf="contactInfo.ReminderTypeId == 2 || contactInfo.ReminderTypeId == 3">LOA: <span class="semi-bold max-width-tooltip-290"
                    appEllipsifyMe> {{contactInfo?.lineOfAuthorityName}} </span>
                </p>
                <p class="mr-0 w-50 ml-2" *ngIf="contactInfo.ReminderTypeId == 4">FEIN: <span class="semi-bold"> {{contactInfo?.fein}}
                </span></p>
                <p class="mr-0 w-50-ml" *ngIf="contactInfo.ReminderTypeId == 4">Producer Code: <span class="semi-bold max-width-tooltip-145" appEllipsifyMe> {{contactInfo?.producerCode}}
                </span></p>
                <p class="mr-0 w-50 ml-2" *ngIf="contactInfo.ReminderTypeId == 4">Primary: <span class="semi-bold"> {{contactInfo?.isPrimary == true ? 'Yes' : 'No'}}
                </span></p>
              </div>
            </div>
        </div>

        <div class="group-form pt-1">
          <form class="resolveContact-form" [formGroup]="reminderForm">
            <div class="row">
              <div class="col-md-4 pr-2">
                <!-- <div class="user-name-block mb-1 p-0">
                  <h2>Remind me on<span class="validation-field">*</span></h2>
                </div> -->
                <div class="user-name-block p-0">
                  <label class="mb-1">Remind me on<span class="validation-field">*</span></label>
                  <div class="input-block d-flex">
                    <input class="form-control pointer-none w-100" placeholder="mm/dd/yyyy" name="reminderDate"
                             formControlName="reminderDate" ngbDatepicker #d="ngbDatepicker" />
                      <div class="set-reminder-modal-date">
                        <span class="close-icon date-close" (click)="clearDate()" *ngIf="reminderForm.value.reminderDate">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                            <rect _ngcontent-wvn-c189="" width="20" height="20" rx="6" fill="none"></rect>
                            <g _ngcontent-wvn-c189="" transform="translate(4 4)">
                              <rect _ngcontent-wvn-c189="" width="16" height="16" fill="none"></rect>
                              <path _ngcontent-wvn-c189=""
                                    d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z"
                                    transform="translate(-0.005 0.005)" fill="#9e9e9e"></path>
                            </g>
                          </svg>
                        </span>
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                  type="button"></button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8 pl-2">
                <div class="email-due d-flex align-items-center justify-content-between">
                  <div class="user-name-block">
                    <div class="input-block bg">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="emailOnDueDate" name="emailOnDueDate"
                                formControlName="emailOnDueDate">
                        <label class="custom-control-label" for="emailOnDueDate">
                            Email when Due
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>&amp;</div>
                  <div class="user-name-block">
                    <div class="input-block bg d-flex justify-content-between align-items-center">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="autoExpire" name="autoExpire"
                                formControlName="autoExpire">
                        <label class="custom-control-label" for="autoExpire">
                            Auto Expire
                        </label>
                      </div>
                      <span class="tooltip-icon pl-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 16.75C12.2167 16.75 12.396 16.6793 12.538 16.538C12.6793 16.396 12.75 16.2167 12.75 16V11.725C12.75 11.525 12.6793 11.354 12.538 11.212C12.396 11.0707 12.2167 11 12 11C11.7833 11 11.6043 11.0707 11.463 11.212C11.321 11.354 11.25 11.5333 11.25 11.75V16.025C11.25 16.225 11.321 16.396 11.463 16.538C11.6043 16.6793 11.7833 16.75 12 16.75ZM12 9.3C12.2333 9.3 12.425 9.22067 12.575 9.062C12.725 8.904 12.8 8.70833 12.8 8.475C12.8 8.25833 12.725 8.07067 12.575 7.912C12.425 7.754 12.2333 7.675 12 7.675C11.7667 7.675 11.575 7.754 11.425 7.912C11.275 8.07067 11.2 8.25833 11.2 8.475C11.2 8.70833 11.275 8.904 11.425 9.062C11.575 9.22067 11.7667 9.3 12 9.3ZM12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z" fill="#757575"/>
                        </svg>                          
                        <span class="text-tooltip info-list top">
                          If you have email option selected, this will send the email alert and then dismiss the reminder
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <!-- <div class="user-name-block mb-2 p-0">
                  <h2>Description<span class="validation-field">*</span></h2>
                </div> -->
                <div class="user-name-block p-0">
                  <div class="input-block">
                    <label class="mb-1">Description<span class="validation-field">*</span></label>
                    <textarea class="form-control note-field" formControlName="description" name="description" maxlength="100" rows="7"
                              style="width: -webkit-fill-available; resize:none" placeholder="Remind me to…"></textarea>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="user-name-block" *ngIf="!isContactUser">
                  <div class="input-block bg d-flex justify-content-between align-items-center w-100">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="agree" name="isPublic"
                               formControlName="isPublic">
                        <label class="custom-control-label" for="agree">
                            Make it Public
                        </label>
                      </div>
                      <span class="tooltip-icon pl-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 16.75C12.2167 16.75 12.396 16.6793 12.538 16.538C12.6793 16.396 12.75 16.2167 12.75 16V11.725C12.75 11.525 12.6793 11.354 12.538 11.212C12.396 11.0707 12.2167 11 12 11C11.7833 11 11.6043 11.0707 11.463 11.212C11.321 11.354 11.25 11.5333 11.25 11.75V16.025C11.25 16.225 11.321 16.396 11.463 16.538C11.6043 16.6793 11.7833 16.75 12 16.75ZM12 9.3C12.2333 9.3 12.425 9.22067 12.575 9.062C12.725 8.904 12.8 8.70833 12.8 8.475C12.8 8.25833 12.725 8.07067 12.575 7.912C12.425 7.754 12.2333 7.675 12 7.675C11.7667 7.675 11.575 7.754 11.425 7.912C11.275 8.07067 11.2 8.25833 11.2 8.475C11.2 8.70833 11.275 8.904 11.425 9.062C11.575 9.22067 11.7667 9.3 12 9.3ZM12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z" fill="#757575" />
                        </svg>
                        <span class="text-tooltip info-list top" *ngIf="contactInfo.ReminderTypeId != 4">
                          If selected, all Admins and the {{tenantTypeCap[tenantTypeId]}} for whom you are creating the reminder will be able to view it.
                          <!-- If selected, you and the {{tenantTypeCap[tenantTypeId]}} you are creating a reminder for can see the reminder. -->
                        </span>
                        <span class="text-tooltip info-list top" *ngIf="contactInfo.ReminderTypeId == 4">
                          If selected, you and all other Administrators will be able to view this reminder.
                        </span>
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="button-block action-btn text-right d-flex justify-content-end pb-4">
            <button type="submit" class="btn btn-md basic-btn" (click)="activeModal.close(false)">Cancel</button>
            <button type="submit" [class.disabled]="!reminderForm.valid" (click)="onSubmit()" class="btn btn-md primary-btn ml-2">Create</button>
        </div>
    </div>
</div>
