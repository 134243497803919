import { Injectable } from '@angular/core';
import { ApiClientService } from 'api-services';

@Injectable({
  providedIn: 'root',
})
export class BranchService {
  constructor(private apiClient: ApiClientService) {}

  public list(search) {
    return this.apiClient.post(`api/branch/listbytenant`, search, null, true);
  } 

   public addBranch(branch) {
   return this.apiClient.post(`api/Branch/add`, branch, null, true);
  }
 
  public deleteBranch(id: number) {
   return this.apiClient.delete(`api/Branch/${id}`, null, null, true);
  }
  //public get(id: number) {
  //  return this.apiClient.get(`api/branch/${id}`, null, null, false);
  //}

  //public update(id: number, branch: Branch) {
  //  return this.apiClient.put(`api/branch/${id}`, branch, null, false);
  //}

  //public add(branch: Branch) {
  //  return this.apiClient.post(`api/branch`, branch, null, false);
  //}

  //public delete(id: number) {
  //  return this.apiClient.delete(`api/branch/${id}`, null, null, false);
  //}
  public archiveBranch(branch) {
    return this.apiClient.post(`api/Branch/archiveBranch`, branch, null, true);
  }
  public unarchiveBranch(branch) {
    return this.apiClient.post(`api/Branch/unarchiveBranch`, branch, null, true);
  }
  public deleteBranchDeatil(branchDetail: any) {
    return this.apiClient.post(`api/Branch/deleteBranch`, branchDetail, null, true);
  }
}
