import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { SharedService } from './shared.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(localStorage.getItem("token") ? true : false);
  public redirectUrl: string = "";
  errorMessages = [];
  profileStatus =0;
  constructor(private router: Router,private sharedService: SharedService,  public meta: Meta, public title: Title) { }

  public userLoggedIn(tokenresponse: any) {    
    var decoded = JSON.stringify(tokenresponse);
    let obj = JSON.parse(decoded);
    localStorage["token"] = obj.token;
    localStorage["refreshToken"] = obj.refreshToken;
    localStorage["userRole"] = obj.role;
    localStorage["userId"] = obj.id;
    localStorage["tokenGuId"] = obj.guidId;
    //localStorage["userName"] = obj.userName;
    localStorage["tenantId"] = obj.tenantId;
    localStorage["name"] = obj.name;
    localStorage["expiredTime"] = obj.expiredTime;
    localStorage["profileStatus"] = obj.profileStatus;
    localStorage["domain"] = obj.domain;
    localStorage["guidId"]=obj.guidId;
    localStorage["tenantTypeId"] = obj.tenantTypeId;
    localStorage["isNIPRAccountSetup"] = obj.isNIPRAccountSetup;
    localStorage["fileStoreTypeId"] = obj.fileStoreTypeId;
    localStorage["emailId"] = obj.emailId;
    localStorage["userRolePermissionIds"] =  obj.userRolePermissionIds;
    // localStorage["cms"] = obj.cmsToken; 
    this.isUserLoggedIn.next(true);

    // Title and Meta Description
    this.meta.addTag({ name: 'Description', content: 'Discover our revolutionary SaaS product that simplifies producer license requests, appointments, terminations etc. ensuring 100% compliance with insurance regulations for your company.' }); 
    this.title.setTitle('Agenzee');
    //
  }
  public userLoggedIn1(token: string) {
    localStorage["token"] = token;
    this.isUserLoggedIn.next(true);
  }

  public setWinClaims(claims: string) {
    localStorage["claims"] = claims;
    this.isUserLoggedIn.next(true);
  }

  public userLoggedOut() {
    localStorage.clear();
    this.isUserLoggedIn.next(false);
  }

  redirect(): void {
      if (localStorage.getItem("token") != null) {
      if (!window.location.href.includes("/login") && !window.location.href.includes("login")
        && !window.location.href.includes("/setpwd") 
      ) {
        if (localStorage["domain"]) {
          if (environment.production == true && localStorage["domain"] && !window.location.href.includes(localStorage["domain"] + '.agenzee.com')) {
            this.userLoggedOut();
            this.router.navigate(["/login"]);
          }
        }
      }
      //this.accountService.getUserProfile(this.sharedService.getValue("userId")).subscribe(
      //  (response: any) => {
      //    this.profileStatus = response.profilestatus;
      //  },
      //  (error: any) => {
      //    this.errorMessages.push(error);
      //  }
      //);
      if (localStorage.getItem("userRole")?.trim().toLowerCase() == "Contact".toLowerCase()) {
        localStorage.setItem("activeContactUserId", localStorage.getItem("userId").toString());
        //this.router.navigate(['/contact-dashboard']);
      } else if (localStorage.getItem("userRole").trim().toLowerCase() == "guest".toLowerCase()) {
        this.router.navigate(['/packages-carrier']);
      }else if (localStorage.getItem("userRole").trim().toLowerCase() == "Admin".toLowerCase()) {
        // localStorage.setItem("activeContactUserId", localStorage.getItem("userId").toString());
        //this.router.navigate(['/dashboard']);
      } 
      else if (parseInt(localStorage.getItem('profileStatus')) >= 5) {
        localStorage["profileStatus"] = 5;
        if (window.location.href.endsWith("login") || window.location.href.endsWith("login/")
          || window.location.href.endsWith("register") || window.location.href.endsWith("register/")
        || window.location.href.endsWith("scheduleOnboarding") || window.location.href.endsWith("scheduleOnboarding/")
        || window.location.href.endsWith("bookOnboardingSlot") || window.location.href.endsWith("bookOnboardingSlot/")
          || window.location.href.endsWith("forgotpwd") || window.location.href.endsWith("forgotpwd/")
          || window.location.href.endsWith("verifydetail") || window.location.href.endsWith("verifydetail/")
          || window.location.href.endsWith("product-plan") || window.location.href.endsWith("product-plan/")
          || window.location.href.endsWith("domain") || window.location.href.endsWith("domain/")
          || window.location.href.endsWith("niprcred") || window.location.href.endsWith("niprcred/")
          || window.location.href.endsWith("npn") || window.location.href.endsWith("npn/")
          || window.location.href.endsWith("add-contact") || window.location.href.endsWith("add-contact/")
          )
        {
          this.router.navigate(['/dashboard']);
        }
      }
      else if (parseInt(localStorage.getItem('profileStatus')) == 0) {
        localStorage["profileStatus"] = 0;
        this.router.navigate(['/registration/product-plan']);
      }
      else if (parseInt(localStorage.getItem('profileStatus')) == 1) {
        localStorage["profileStatus"] = 1;
        this.router.navigate(['/registration/domain']);
      }
      else if (parseInt(localStorage.getItem('profileStatus')) == 2){
        if (parseInt(localStorage.getItem('tenantTypeId')) == 3) {
          localStorage["profileStatus"] = 3;
          this.router.navigate(['/registration/npn']);
        }
        else {
          localStorage["profileStatus"] = 2;
          this.router.navigate(['/registration/niprcred']);
        }
      }
      else if (parseInt(localStorage.getItem('profileStatus')) == 3) {
        localStorage["profileStatus"] = 3;
        this.router.navigate(['/registration/npn']);
      }
      else if (parseInt(localStorage.getItem('profileStatus')) == 4) {
        localStorage["profileStatus"] = 4;
        this.router.navigate(['/registration/add-contact']);
      }
    }
  }
}
