import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  @Input() modalData: any;
  @Input() buttonText: string = 'Delete';
  @Input() buttonClass: string = 'danger-btn';

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  close(data): void {
    this.activeModal.close(data);
  }

}
