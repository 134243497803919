<div class="left-side-content">
  <div class="slider-block">
    <div class="agenzee-info-slider">
      <div class="agenzee-slider-data">
        <div class="agenzee-slider-image">
          <div class="slider-images">
            <img src="../assets/images/login-agenzee-img.svg" class="res-img" alt="slider image" />
          </div>
        </div>
        <div class="agenzee-slider-details">
          <div class="agenzee-title-text">
            <h1 class="agenzee-title">Agenzee</h1>
            <p class="agenzee-text">Insurtech that will help ease the burden of agent licensing, appointments, and terminations to help your company stay 100% compliant with insurance regulations.</p>
          </div>
        </div>
      </div>
     </div>
  </div>
  <div class="mobile-logo">
    <img src="../assets/images/logo-text.svg" alt="slider image" />
 </div>
</div>

