<div class="common-modal">
    <!-- <div class="modal-header">
        <h4 class="modal-title">Header</h4>
        <button type="button" class="modal-close-btn close" data-dismiss="modal" (click)="activeModal.close(false)">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><rect width="28" height="28" rx="6" fill="none" /><g transform="translate(4 4)"><rect width="20" height="20" fill="none" /><path d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z" transform="translate(-0.005 0.005)" fill="#757575" /></g></svg>
        </button>
      </div> -->
    <div class="modal-body full-width">
        <div class="row">
            <div class="col-8 d-flex">
              <div class="preview-detail">
                  <span class="bold">Preview </span>
                  <span>({{processedCount}}/{{objectData.length}} Processed)</span>
              </div>
              <div class="select-options preview-dropdown">
                <div class="select-block">
                  <ng-select [searchable]="true" [(ngModel)]="selectedFilter" [clearable]="true" name="record" (change)="onChange($event)">
                    <ng-option value="All">All ({{objectData.length}})</ng-option>
                    <ng-option value="Failed">Failed ({{objectData.length - processedCount}})</ng-option>
                    <ng-option value="Processed">Processed ({{processedCount}})</ng-option>
                  </ng-select>
                </div>
              </div>   
            </div>  
              <div class="col-4">
              <div class="preview-detail right">
                 <span><button class="export-btn" [class.disabled]="responseData?.length <= 0" (click)="exportToExcelContactList()">
                   Export to Excel
                   <svg class="action-export-icon" id="ic-export" xmlns="http://www.w3.org/2000/svg" width="20"
                   height="20" viewBox="0 0 20 20">
                <rect id="Rectangle_17995" data-name="Rectangle 17995" width="20" height="20" fill="none" />
                <path id="ios_share_FILL0_wght300_GRAD0_opsz20"
                      d="M10,13.229a.676.676,0,0,1-.667-.667v-8.5l-.979,1A.669.669,0,0,1,7.9,5.25a.636.636,0,0,1-.458-.208.594.594,0,0,1-.209-.459A.657.657,0,0,1,7.438,4.1l2.02-2.021a.856.856,0,0,1,.282-.2.785.785,0,0,1,.562,0,.846.846,0,0,1,.281.2l2.063,2.063a.6.6,0,0,1,.177.416.668.668,0,0,1-.2.48.68.68,0,0,1-.937,0l-1.021-1v8.52a.676.676,0,0,1-.667.667ZM5.333,18.75A1.564,1.564,0,0,1,3.75,17.167v-8.4A1.564,1.564,0,0,1,5.333,7.188H6.6a.653.653,0,0,1,.458.2.631.631,0,0,1,0,.938.651.651,0,0,1-.458.2H5.333a.3.3,0,0,0-.25.25v8.4a.3.3,0,0,0,.25.25h9.334a.3.3,0,0,0,.25-.25v-8.4a.3.3,0,0,0-.25-.25H13.4a.651.651,0,0,1-.458-.2.631.631,0,0,1,0-.938.653.653,0,0,1,.458-.2h1.271A1.564,1.564,0,0,1,16.25,8.771v8.4a1.564,1.564,0,0,1-1.583,1.583Z"
                      transform="translate(0 -0.291)" fill="#0068D8" />
              </svg>
                  </button></span> 
              </div>
            </div> 
          <div class="col-12 col-sm-12">
            <div class="common-table table-border import-response mt-3" style="max-height: calc(100vh - 236px);">
              <table class="table">
                <thead class="sticky-from-top">
                  <tr class="text-nowrap" >
                    <th>NPN</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Producer Code</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>NIPR First Name</th>
                    <th>NIPR Last Name</th>
                  </tr>
                </thead>
                <tbody *ngIf="responseData?.length > 0">                      
                  <tr *ngFor="let npn of responseData" [ngClass]="{'bg-error': npn.errorMessage != null && npn.errorMessage.length > 0}">
                    <td>{{npn.npn}}</td>
                    <td>{{npn.firstName}}</td>
                    <td>{{npn.lastName}}</td>
                    <td>{{npn.producerCode}}</td>
                    <td>{{npn.email}}</td>
                    <td><span class="text-{{npn.status}}">{{npn.status}} </span>
                        <span *ngIf="npn.errorMessage != null && npn.errorMessage.length > 0" title="{{npn.errorMessage}}">
                            <svg xmlns="http://www.w3.org/2000/svg" id="ic-info" width="16" height="16" viewBox="0 0 24 24">
                                <rect id="Rectangle_18062" data-name="Rectangle 18062" width="16" height="16" fill="none"/>
                                <path id="info_FILL0_wght300_GRAD0_opsz24" d="M12,16.75a.725.725,0,0,0,.75-.75V11.725a.7.7,0,0,0-.212-.513.751.751,0,0,0-1.288.538v4.275a.7.7,0,0,0,.213.513A.728.728,0,0,0,12,16.75ZM12,9.3a.757.757,0,0,0,.575-.238.82.82,0,0,0,.225-.587.792.792,0,0,0-.225-.563.816.816,0,0,0-1.15,0,.792.792,0,0,0-.225.563.82.82,0,0,0,.225.587A.757.757,0,0,0,12,9.3Zm0,12.2a9.263,9.263,0,0,1-3.712-.75A9.432,9.432,0,0,1,3.25,15.712a9.563,9.563,0,0,1,0-7.425A9.435,9.435,0,0,1,8.288,3.25a9.563,9.563,0,0,1,7.425,0A9.437,9.437,0,0,1,20.75,8.287a9.563,9.563,0,0,1,0,7.425,9.435,9.435,0,0,1-5.037,5.038A9.27,9.27,0,0,1,12,21.5ZM12,12Zm0,8a7.714,7.714,0,0,0,5.663-2.337A7.714,7.714,0,0,0,20,12a7.714,7.714,0,0,0-2.337-5.663A7.714,7.714,0,0,0,12,4,7.711,7.711,0,0,0,6.338,6.337,7.713,7.713,0,0,0,4,12a7.713,7.713,0,0,0,2.338,5.663A7.711,7.711,0,0,0,12,20Z" fill="#757575"/>
                              </svg>    
                        </span>
                    </td>
                    <td>{{npn.niprFirstName}}</td>
                    <td>{{npn.niprLastName}}</td>
                  </tr>
                </tbody>
                <tr class="no-data-found " *ngIf="activeList && responseData.length <= 0">
                    <td colspan="7"><app-no-data-found [isGraphDashboard]="'false_false_ _ _ '"></app-no-data-found></td> 
                 </tr>
              </table>                              
            </div>
          </div>                            
        </div>
    </div>
    <div class="modal-footer">
        <div class="action-btn">
            <button class="btn commn-btn" (click)="activeModal.close(true)">Done</button>
      </div>
    </div>
  </div>
