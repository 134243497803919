import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, ReplaySubject } from 'rxjs';
import jwt_decode from "jwt-decode";
import { ModulePermission, RoleType, SystemModule, TenantType } from 'shared/constant';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor() {

  }


  get isContactUser(): boolean {
    return this.checkIsRoleExists(RoleType.Contact);
  }

  get isGuestUser(): boolean {
    return this.checkIsRoleExists(RoleType.GuestUser);
  }

  get isTenantOwnerUser(): boolean {
    return this.checkIsRoleExists(RoleType.AccountOwner);
  }

  get isAdminUser(): boolean {
    return this.checkIsRoleExists(RoleType.Admin);
  }

  get isMGAUser(): boolean {
    return Number(localStorage['tenantTypeId']) == TenantType.MGA;
  }

  get isCarrierUser(): boolean {
    return Number(localStorage['tenantTypeId']) == TenantType.Carrier;
  }

  get isAgencyUser(): boolean {
    return Number(localStorage['tenantTypeId']) == TenantType.Agency;
  }

  getObjectFromToken() {
    return jwt_decode(localStorage.getItem('token'))
  }

  getRole(): any[] {
    let loggedInUser = this.getObjectFromToken() as any;
    if (loggedInUser && loggedInUser.role) {
      return JSON.parse(loggedInUser.role)
    }
    return [];
  }

  checkIsRoleExists(roleId: number): boolean {
    let role = this.getRole() as any[];
    let roleExists = role.filter(x => x.roleId == roleId);
    if (roleExists && roleExists.length > 0) {
      return true;
    }
    return false;
  }

  isAccess(moduleId: number): boolean {
    let isAccess = false;
    let isModuleAccess = ModulePermission.find(x => x.moduleId == moduleId);
    if (isModuleAccess) {
      let getLoggedInUserRole = this.getRole();
      for (let role of getLoggedInUserRole) {
        let isPermission = isModuleAccess.allowedRole.find(x => x == role.roleId);
        if (isPermission) {
          isAccess = true;
          break
        }
      }
    }
    return isAccess;
  }

  isFeatureAccessible(permissionId): boolean {
    // currently for admin only   
    if (this.isAdminUser) {
      return localStorage['userRolePermissionIds'].split(',').map(Number).includes(permissionId);
    }
    return true;
  }
}
