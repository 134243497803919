import { Injectable } from '@angular/core';
import { ApiClientService } from './common/api-client.service';
import { CustomGroup } from '../models/custom-loa-group.model';
import { CustomGroupDetail } from '../models/custom-loa-group-detail.model';
import { LinesOfAuthority } from '../models/lines-of-authority.model';
import { SupportedLinesOfAuthority } from '../models/supported-lines-of-authority.model';
import { LicenseClass } from '../models/license-class.model';
import { SearchCustomGroup } from '../models/search-custom-group.model';
import { SearchLinesOfAuthority } from '../models/search-lines-of-authority.model';
import { State } from '../models/state.model';
import { Branch } from '../components/branch/models/branch.model';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { DashboardScorecard } from 'models/dashboard-scorecard.model';


@Injectable({
  providedIn: 'root',
})
export class LinesOfAuthorityService {

  constructor(
    private apiClient: ApiClientService  ) {

  }

  public getSupportedStates(): Observable<State[]> {
    return this.apiClient.get('api/linesofAuthority/getSupportedStates');
  }

  public getSupportedLicenseClassesByStates(stateIds?: string): Observable<LicenseClass[]> {
    let params = new HttpParams();
    params = params.append("stateIds", stateIds);

    return this.apiClient.get('api/linesofAuthority/getSupportedLicenseClassByStates', undefined, params, false);
  }

  public getSupportedLicenseClasses(): Observable<LicenseClass[]> {

    return this.apiClient.get('api/linesofAuthority/getSupportedLicenseClasses');
  }

  public getSupportedLOAs(searchLinesOfAuthority: SearchLinesOfAuthority): Observable<SupportedLinesOfAuthority[]> {
    return this.apiClient.post('api/linesofAuthority/ListSupportedLOAs', searchLinesOfAuthority, false);
  }
  public getManageSupportedLOAs(searchLinesOfAuthority: SearchLinesOfAuthority): Observable<SupportedLinesOfAuthority[]> {
    return this.apiClient.post('api/linesofAuthority/ListManageSupportedLOAs', searchLinesOfAuthority, false);
  }
  public createCustomGroup(customGroup: CustomGroup): Observable<number> {
    return this.apiClient.post('api/linesofAuthority/createCustomGroup', customGroup, undefined, true);
  }
  public createCustomGroupScoreCard(customGroup: CustomGroup): Observable<number> {
    return this.apiClient.post('api/linesofAuthority/createCustomGroupScorecard', customGroup, undefined, true);
  }

  public updateCustomGroup(customGroup: CustomGroup): Observable<number> {
    return this.apiClient.post('api/linesofAuthority/updateCustomGroup', customGroup, undefined, true);
  }

  public updateCustomGroupScoreCard(customGroup: CustomGroup): Observable<number> {
    return this.apiClient.post('api/linesofAuthority/updateCustomGroupScorecard', customGroup, undefined, true);
  }

  public getCustomGroups(search: SearchCustomGroup): Observable<CustomGroup[]> {
    return this.apiClient.post('api/linesofAuthority/getCustomGroups', search, undefined, true);
  }

  public getCustomGroupsScoreCard(search: SearchCustomGroup): Observable<CustomGroup[]> {
    return this.apiClient.post('api/linesofAuthority/getCustomGroupsScorecard', search, undefined, true);
  }

  public getCustomGroupDetail(customGroupId: number): Observable<CustomGroup> {
    let params = new HttpParams();
    params = params.append("customGroupId", customGroupId);

    return this.apiClient.get('api/linesofAuthority/getCustomGroupDetail', undefined, params, true);
  }

  public getCustomGroupDetailScoreCard(customGroupId: number): Observable<CustomGroup> {
    let params = new HttpParams();
    params = params.append("customGroupId", customGroupId);

    return this.apiClient.get('api/linesofAuthority/getCustomGroupDetailScorecard', undefined, params, true);
  }

  public getAllStates(): Observable<State[]> {
    return this.apiClient.get('api/linesofAuthority/getAllStates', undefined, undefined, false);
  }

  public listBranch(tenantId: number) {
    return this.apiClient.get(`api/branch/list/${tenantId}`, null, null, false);
  }

  public getAllLicenseClasses(stateIds?: string): Observable<LicenseClass[]> {
    let params = new HttpParams();
    if (stateIds) {
      params = params.append("stateIds", stateIds);
    }
    return this.apiClient.get('api/linesofAuthority/getAllLicenseClasses', undefined, params, false);
  }

  public getAllLicenseClassLOAs(stateIds?: string, licenseClassIds?: string, searchText?: string): Observable<LinesOfAuthority[]> {
    let params = new HttpParams();
    if (stateIds) {
      params = params.append("stateIds", stateIds);
    }

    if (licenseClassIds) {
      params = params.append("licenseClassIds", licenseClassIds);
    }
    if (searchText) {
      params = params.append("searchText", searchText);
    }
    return this.apiClient.get('api/linesofAuthority/getAllLicenseClassLOAs', undefined, params, false);
  }

  public updateSupportedLOAs(supportedLOAs: SupportedLinesOfAuthority[]) {
    return this.apiClient.post('api/linesofAuthority/updateSupportedLOAs', supportedLOAs, undefined, true);
  }

  public deleteCustomGroup(customGroupId: number): Observable<number> {
    return this.apiClient.post('api/linesofAuthority/deleteCustomGroup', customGroupId, undefined, true);
  }
  public deleteCustomGroupScoreCard(customGroupId: number): Observable<number> {
    return this.apiClient.post('api/linesofAuthority/deleteCustomGroupScorecard', customGroupId, undefined, true);
  }
  public fillScorecardListGroup(scorecard: DashboardScorecard): Observable<number> {
    return this.apiClient.post('api/LinesOfAuthority/fillscorecardgroup', scorecard, undefined, true);
  }
  public fillScorecardListGroupUser(scorecard: DashboardScorecard): Observable<number> {
    return this.apiClient.post('api/LinesOfAuthority/fillscorecardgroupuser', scorecard, undefined, true);
  }
  public getDashboardScorecardList(scorecard: DashboardScorecard): Observable<number> {
    return this.apiClient.post('api/LinesOfAuthority/scorecard', scorecard, undefined, true);
  }

  public getContactScorecard(contactScorecard: DashboardScorecard): Observable<number> {
    return this.apiClient.post('api/LinesOfAuthority/contactScorecard', contactScorecard, undefined, true);
  }

  public getContactScorecardList(contactGroupScorecard: DashboardScorecard): Observable<number> {
    return this.apiClient.post('api/LinesOfAuthority/contactgroupscorecard', contactGroupScorecard, undefined, true);
  }

  //Alert Task API

  public getTaskList(reqstPayload: any) {
    return this.apiClient.post(`api/Alert/list`, reqstPayload, undefined, true);
  }
  
  public getTaskDetailById(tenantId: number, alertId: number): Observable<number> {
    return this.apiClient.get(`api/Alert/get/${tenantId}/${alertId}`, null, null, true);
  }

  public UpdateTaskDetails(taskForm: any): Observable<number> {
    return this.apiClient.put('api/Alert', taskForm, undefined, true);
  }

  public getActiveTaskCount(tenantId: number) {
    return this.apiClient.get(`api/Alert/activealertscount/${tenantId}`, null, null, false);
  }

  public getAgentProducerList(branchId: number) {
    return this.apiClient.get(`api/AdminDashboard/getAgentProducerList/${branchId}`, null, null, true);
  }

  public getNIPRTransactionTypeList() {
    return this.apiClient.get('api/AdminDashboard/getNIPRTransactionTypeList', null, null, true);
  }
}
