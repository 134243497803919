<div class="input-block custom-dropdown-control">
    <label class="input-label">State selection <span class="validation-field">*</span></label>
    <div class="selected-states">
        <span *ngFor="let state of selectedStates">
            {{state.stateCode}}
            <a (click)="onSelectionChange(state.stateId)">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 28 28">
                    <rect width="28" height="28" rx="6" fill="none" />
                    <g transform="translate(4 4)">
                        <rect width="20" height="20" fill="none" />
                        <path
                            d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z"
                            transform="translate(-0.005 0.005)" fill="#757575" />
                    </g>
                </svg>
            </a>
        </span>
        <span class="select-states" *ngIf="selectedStates.length == 0">Select state(s)</span>
    </div>
    <div class="unselect-states">
        <div class="select-all">
            <input [ngClass]="{'clear-all' : selectedStates.length == this.states.length}" type="checkbox" class="mr-2"
                (change)="onSelectionChange(0)">
            <label *ngIf="selectedStates.length != this.states.length">Select All</label>
            <label *ngIf="selectedStates.length == this.states.length">Clear All</label>
        </div>
        <div class="row m-0">
            <span class="col-3 " *ngFor="let states of unSelectedStates; let i = index">
                <input class="mr-2" type="checkbox" name="states" (change)="onSelectionChange(states.stateId)">
                <label for=""> {{ states.stateNameWithStateCode }}</label>
            </span>
        </div>
    </div>
</div>