import { ChangeDetectorRef, Component, Injectable, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateParserFormatter, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { RemindersService } from 'api-services/reminders.service';
import { Reminder } from 'models/reminder';
import { RoleService } from 'services/role.service';
import { SharedService } from 'services/shared.service';
import { tenantTypeCap } from 'shared/constant';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year
      : '';
  }
}
@Component({
  selector: 'app-set-reminders',
  templateUrl: './set-reminders.component.html',
  styleUrls: ['./set-reminders.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class SetRemindersComponent implements OnInit {
  @Input() contactInfo;
  isContactUser = false;
  tenantTypeId = localStorage['tenantTypeId'];
  tenantTypeCap = tenantTypeCap;

  reminderForm: FormGroup;
  reminder: Reminder = {
    reminderId: 0,
    description: '',
    reminderDate: '',
    reminderTypeId: 0,
    userId: 0,
    tenantId: 0,
    userAppointmentId: null,
    userLicenseClassId: null,
    branchId:null,
    name: null,
    npn: null,
    licenseClassName: null,
    licenseNumber: null,
    lineOfAuthorityName: null,
    carrierName: null,
    createdDate: new Date(),
    createdBy: 0,
    isPublic: false,
    CreatedName: '',
    emailOnDueDate: false,
    autoExpire: false
  };
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  isMGAUser = false;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private reminderService: RemindersService,
    private sharedService: SharedService,
    private roleService: RoleService, private config:NgbDatepickerConfig, private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isContactUser = this.roleService.isContactUser;
    this.reminderForm = this.fb.group({      
      reminderDate: ['', Validators.required],
      description: ['', [Validators.required, Validators.maxLength(100)]],
      isPublic: [false],
      emailOnDueDate: [false],
      autoExpire: [false],
    });
    console.log("contactInfo", this.contactInfo);
    const today = new Date();
    this.minDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate() + 1
    }
    this.maxDate = {
      year: today.getFullYear() + 10,
      month: today.getMonth() + 1,
      day: today.getDate()
    };
    this.isMGAUser = this.roleService.isMGAUser;
  }

  ngAfterViewInit() {
    this.config.maxDate = this.maxDate;
    this.config.minDate = this.minDate;
    this.cdr.detectChanges();
  }
  close() {
    this.activeModal.close(true);
  }
  onSubmit() {
    // Check if the form is valid before proceeding
    if (this.reminderForm.valid) {
      const tenantId = this.sharedService.getValue('tenantId');
      const userId = this.sharedService.getValue('userId');
      // Map form values to the reminder object
      this.reminder = {
        ...this.reminder, // Spread existing reminder properties
        reminderDate: this.formatDate(this.reminderForm.value.reminderDate),
        description: this.reminderForm.value.description,
        isPublic: this.reminderForm.value.isPublic,
        reminderTypeId: this.contactInfo.ReminderTypeId,
        userId: this.contactInfo.userId || null,
        tenantId: tenantId,
        userLicenseClassId: this.contactInfo.userLicenseClassId || null,
        userAppointmentId: this.contactInfo.userAppointmentId || null,
        branchId: this.contactInfo.branchId || null,
        createdBy: userId,
        CreatedName: this.sharedService.getValue('name'),
        autoExpire: this.reminderForm.value.autoExpire,
        emailOnDueDate: this.reminderForm.value.emailOnDueDate,
      };
      this.reminderService.insertReminder(this.reminder).subscribe(
        (response) => {
          this.sharedService.showToastMessage("Reminder set successfully");
          this.reminderService.updateReminderCount(true);
          this.activeModal.close(true); // Close modal and pass true to indicate success

        },
        (error) => {
          console.error(error);
        }
      );
      // Log the updated reminder object
      // console.log('Reminder:', this.reminder);

      // Perform any further actions, such as submitting the reminder object to a service or API
    }
  }
  // Utility function to format date to 'YYYY-MM-DD'
  formatDate(date: { year: number; month: number; day: number }): string {
    const year = date.year;
    const month = date.month.toString().padStart(2, '0'); // Ensures month is 2 digits
    const day = date.day.toString().padStart(2, '0'); // Ensures day is 2 digits
    return `${year}-${month}-${day}`;
  }

  clearDate() {
    this.reminderForm.get("reminderDate")!.setValue(null);
  }
}
