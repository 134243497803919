<!--<app-header></app-header>-->
<div class="main-layout-grid">
  <main id="main-container">
    <div class="data-content register-page custom-height">
      <div class="content-width">
        <app-left-part></app-left-part>
      </div>
      <div class="content-width right">
        <div class="right-side-content before-login-page">
          <div class="container">
            <div class="form-details">
              <div class="forms-data">
                <form class="create-account-form" id="testdevendra" [formGroup]="userRegForm" (ngSubmit)="onSubmit()"
                  autocomplete="off">
                  <div class="form-block-title">
                    <h3 class="form-title">Create An Account</h3>
                    <p class="login-note">Already have an account?<a href="javascript:void()" routerLink="/login" class="plain-link"><span
                          class="extra-title">Login</span></a></p>
                  </div>
                  <div class="form-inputs-data">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-6">
                        <div class="form-group" [class.error]="((userRegForm.get('firstName')!.touched ||
                        userRegForm.get('firstName')!.dirty) &&
                        userRegForm.get('firstName')!.errors)">
                          <input type="text" class="form-control form-input text-tra-cap no-icon" name="firstname" id="firstName"
                            formControlName="firstName" placeholder=" ">
                          <label class="form-label">First Name <span class="validation-field">*</span></label>
                          <app-error [controlName]="userRegForm.controls['firstName']">
                          </app-error>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-6">
                        <div class="form-group" [class.error]="((userRegForm.get('lastName')!.touched ||
                        userRegForm.get('lastName')!.dirty) &&
                        userRegForm.get('lastName')!.errors)">
                          <input type="text" class="form-control form-input text-tra-cap no-icon" name="lastname" id="lastName"
                            formControlName="lastName" placeholder=" ">
                          <label class="form-label">Last Name <span class="validation-field">*</span></label>
                          <app-error [controlName]="userRegForm.controls['lastName']">
                          </app-error>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12">
                        <div class="form-group" [class.error]="((userRegForm.get('businessName')!.touched ||
                        userRegForm.get('businessName')!.dirty) &&
                        userRegForm.get('businessName')!.errors)">
                          <input type="text" class="form-control form-input text-tra-cap" name="businessName" id="businessName"
                            formControlName="businessName" placeholder=" ">
                          <label class="form-label">Business Name <span class="validation-field">*</span></label>
                          <div class="form-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                              <g id="ic_business" transform="translate(-1300 -639)">
                                <rect id="Rectangle_454" data-name="Rectangle 454" width="24" height="24"
                                  transform="translate(1300 639)" fill="none" />
                                <path class="fill-color" id="corporate_fare_FILL0_wght300_GRAD0_opsz24"
                                  d="M3.875,20.575a1.494,1.494,0,0,1-1.5-1.5V4.575a1.46,1.46,0,0,1,.437-1.05,1.428,1.428,0,0,1,1.063-.45h6.5a1.439,1.439,0,0,1,1.05.45,1.439,1.439,0,0,1,.45,1.05v2.5h8.25a1.428,1.428,0,0,1,1.063.45,1.46,1.46,0,0,1,.437,1.05v10.5a1.494,1.494,0,0,1-1.5,1.5Zm0-1.5h6.5v-2.5h-6.5Zm0-4h6.5v-2.5h-6.5Zm0-4h6.5v-2.5h-6.5Zm0-4h6.5v-2.5h-6.5Zm8,12h8.25V8.575h-8.25Zm2.175-6.5v-1.5h3.7v1.5Zm0,4v-1.5h3.7v1.5Z"
                                  transform="translate(1300 639.175)" fill="#757575" />
                              </g>
                            </svg>
                          </div>
                          <app-error [controlName]="userRegForm.controls['businessName']">
                          </app-error>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12">
                        <div class="form-group" [class.error]="((userRegForm.get('phoneNumber')!.touched ||
                        userRegForm.get('phoneNumber')!.dirty) &&
                        userRegForm.get('phoneNumber')!.errors)">
                          <input type="text" [textMask]="{mask: mask}" class="form-control form-input" name="phone"
                            id="phone" formControlName="phoneNumber" placeholder=" ">
                          <label class="form-label">Phone <span class="validation-field">*</span></label>
                          <div class="form-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                              <g id="ic_phone" transform="translate(-1300 -639)">
                                <rect id="Rectangle_454" data-name="Rectangle 454" width="24" height="24"
                                  transform="translate(1300 639)" fill="none" />
                                <path class="fill-color" id="call_FILL0_wght300_GRAD0_opsz24"
                                  d="M19.425,20.45a13.093,13.093,0,0,1-5.762-1.388A18.729,18.729,0,0,1,8.55,15.425a18.8,18.8,0,0,1-3.637-5.1A13.067,13.067,0,0,1,3.525,4.55,1.025,1.025,0,0,1,4.575,3.5h3.25a1.044,1.044,0,0,1,1.05.85L9.45,7.3a1.8,1.8,0,0,1-.025.675A1.036,1.036,0,0,1,9.1,8.5L6.8,10.75a18.489,18.489,0,0,0,2.837,3.625A18.127,18.127,0,0,0,13.35,17.2l2.225-2.25a1.368,1.368,0,0,1,.588-.35,1.6,1.6,0,0,1,.687-.05l2.775.575a1.061,1.061,0,0,1,.613.35,1,1,0,0,1,.237.675V19.4a1.025,1.025,0,0,1-1.05,1.05ZM6.1,9.325l1.775-1.7a.221.221,0,0,0,.063-.113A.171.171,0,0,0,7.925,7.4L7.5,5.15a.227.227,0,0,0-.075-.113A.2.2,0,0,0,7.3,5H5.175a.156.156,0,0,0-.113.037.122.122,0,0,0-.037.088,12.459,12.459,0,0,0,.338,2.087A12.214,12.214,0,0,0,6.1,9.325ZM18.85,18.9a.121.121,0,0,0,.088-.038.119.119,0,0,0,.037-.087v-2.1a.2.2,0,0,0-.037-.125.227.227,0,0,0-.113-.075l-2.1-.425a.106.106,0,0,0-.1,0l-.1.05L14.75,17.9a10.216,10.216,0,0,0,2.075.725A10.141,10.141,0,0,0,18.85,18.9Zm-4.075-1ZM6.1,9.325Z"
                                  transform="translate(1300 639.025)" fill="#757575" />
                              </g>
                            </svg>
                          </div>
                          <app-error [controlName]="userRegForm.controls['phoneNumber']">
                          </app-error>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12">
                        <div class="form-group" [class.error]="((userRegForm.get('email')!.touched ||
                        userRegForm.get('email')!.dirty) &&
                        userRegForm.get('email')!.errors)">
                          <input type="text" class="form-control form-input" name="workemail" id="workEmail"
                            formControlName="email" placeholder=" " (keyup)="touchEmail()">
                          <label class="form-label">Work Email <span class="validation-field">*</span></label>
                          <div class="form-icon">
                            <svg id="icons_ic-mail" data-name="icons / ic-mail" xmlns="http://www.w3.org/2000/svg"
                              width="24" height="24" viewBox="0 0 24 24">
                              <rect id="Rectangle_454" data-name="Rectangle 454" width="24" height="24" fill="none" />
                              <path class="fill-color" id="alternate_email_FILL0_wght300_GRAD0_opsz24"
                                d="M12,21.5a9.263,9.263,0,0,1-3.712-.75A9.432,9.432,0,0,1,3.25,15.712,9.263,9.263,0,0,1,2.5,12a9.24,9.24,0,0,1,.75-3.725A9.466,9.466,0,0,1,8.288,3.25,9.263,9.263,0,0,1,12,2.5a9.24,9.24,0,0,1,3.725.75A9.5,9.5,0,0,1,20.75,8.275,9.24,9.24,0,0,1,21.5,12v1.225a3.183,3.183,0,0,1-.938,2.325,3.119,3.119,0,0,1-2.312.95,3.141,3.141,0,0,1-2.825-1.625,5.11,5.11,0,0,1-1.462,1.175A4.089,4.089,0,0,1,12,16.5,4.48,4.48,0,0,1,7.5,12,4.48,4.48,0,0,1,12,7.5,4.48,4.48,0,0,1,16.5,12v1.225a1.75,1.75,0,1,0,3.5,0V12a7.7,7.7,0,0,0-2.363-5.638A7.7,7.7,0,0,0,12,4,7.7,7.7,0,0,0,6.362,6.362,7.7,7.7,0,0,0,4,12a7.7,7.7,0,0,0,2.362,5.637A7.7,7.7,0,0,0,12,20h4.25a.75.75,0,1,1,0,1.5ZM12,15a2.988,2.988,0,0,0,3-3,2.988,2.988,0,0,0-3-3,2.988,2.988,0,0,0-3,3,2.988,2.988,0,0,0,3,3Z"
                                transform="translate(-0.5 -0.5)" fill="#757575" />
                            </svg>
                          </div>
                          <app-error [controlName]="userRegForm.controls['email']">
                          </app-error>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12">
                        <label class="radio-title">Select Business Entity Type</label>
                        <div class="radio-buttons">
                          <div class="form-group">
                            <input [checked]="true" type="radio" id="agency" name="tenantTypeId" class="radio-inputs"
                              [value]="3" formControlName="tenantTypeId" />
                            <label for="agency" class="radio-label"><span>AGENCY</span></label>
                          </div>
                          <div class="form-group">
                            <input type="radio" id="carrier" name="tenantTypeId" class="radio-inputs" [value]="2"
                              formControlName="tenantTypeId" />
                            <label for="carrier" class="radio-label"><span>CARRIER</span></label>
                          </div>
                          <div class="form-group">
                            <input type="radio" id="mga" name="tenantTypeId" class="radio-inputs" [value]="1"
                              formControlName="tenantTypeId" />
                            <label for="mga" class="radio-label"><span>MGA</span></label>
                          </div>
                        </div>
                        <app-error [controlName]="userRegForm.controls['tenantTypeId']">
                        </app-error>
                      </div>
                    </div>
                  </div>
                   <div class="form-notes-link">
                      <p> <span class="custom-control custom-checkbox">
                            <input type="checkbox" id="agree" name="agree" formControlName="agree" class="custom-control-input">
                            <label for="agree" class="custom-control-label"></label>
                        </span>
                        I have read and agree to Agenzee's 
                         <!-- By clicking the "Continue" button, I agree to Agenzee's  -->
                         <a href="https://agenzee.com/terms-conditions/" class="plain-link" target="_blank"> Terms of
                        Service</a> and <a href="https://agenzee.com/privacy-policy/" class="plain-link" target="_blank">Privacy Policy</a>.
                      </p>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <re-captcha formControlName="reCaptcha" (resolved)="resolved($event)" siteKey="6LcDBZYpAAAAAKmR7BkaQVFQkGRAKPQpnuzAuuTQ"></re-captcha>
                      </div>
                    </div>
                    <validation-summary [validationErrors]="errorMessages"></validation-summary>
                  <div class="action-btn mt-3">
                    <button type="submit" class="btn commn-btn width-100" [disabled]="!userRegForm.valid">
                      <span class="btn-text ">Schedule Your Onboarding</span>
                      <span class="loader-btn hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                          <g id="button-loader" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"
                            stroke-dasharray="40">
                            <circle cx="8.5" cy="8.5" r="8.5" stroke="none" />
                            <circle cx="8.5" cy="8.5" r="7.5" fill="none" />
                          </g>
                        </svg>
                      </span>
                    </button>
                    <!-- <button type="button" class="btn commn-btn loader-btn">                      
                      
                    </button> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </main>
</div>
