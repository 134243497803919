<div class="contact-scorecard contact-detail-sc">
    <div class="contact-admin-details">
        <div class="admin-name-actions">
           <div class="name">   
              <div class="back-btn">
                 <a href="javascript:void();"  routerLink="/contact-dashboard/scorecard">                     
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                       <g id="btn_back" transform="translate(-315 -129)">
                       <rect id="Rectangle_18055" data-name="Rectangle 18055" width="24" height="24" transform="translate(315 129)" fill="none"/>
                       <path id="arrow_back_FILL0_wght300_GRAD0_opsz24" d="M11.1,19.1,4.65,12.625a.7.7,0,0,1-.212-.288.936.936,0,0,1,0-.675.7.7,0,0,1,.212-.287L11.1,4.9a.822.822,0,0,1,.525-.213.678.678,0,0,1,.525.213.765.765,0,0,1,.237.525.753.753,0,0,1-.212.55l-5.3,5.275H18.5a.741.741,0,0,1,.525.212.755.755,0,0,1,0,1.075.74.74,0,0,1-.525.213H6.875l5.3,5.3a.736.736,0,0,1,.212.512.691.691,0,0,1-.212.538.755.755,0,0,1-1.075,0Z" transform="translate(315.188 128.994)" fill="#9e9e9e"/>
                       </g>
                    </svg> 
                 </a>
              </div>
              <div class="user-pic">
                 <div class="user-bg">
                    <div class="short-name">{{userProfile?.firstName | slice:0:1}}{{userProfile?.lastName | slice:0:1}}</div>
                 </div>
              </div>
              <div class="user-name-note">
                 <h3 class="user-name">{{userProfile?.name}}</h3>
                 <!-- <span class="user-note after-dash">{{userProfile?.status}}</span> -->
                 <span class="overall-score-note">{{overallScore| number : '1.2-2'}}% - Overall Score</span>
              </div>
           </div>
           <!-- <div class="actions">
              <div class="action-edit-btn">
                 <a class="link delete" href="javascript:void(0);">Terminate</a>
                 <a class="link edit" href="javascript:void(0);">Renew License</a>
                 <a class="link edit" href="javascript:void(0);">New Appointment</a>
              </div>
           </div> -->
        </div>
     </div>
     <div class="overall-score-header">
      <div class="score-block">
        <span class="title after-dash" class="max-width-tooltip-125 center" appEllipsifyMe>{{selectedAgent.customGroupName}}</span>
        <span class="percentage-value after-dash">{{selectedAgent.completion| number : '1.2-2'}}% - COMPLETE</span>
        <span class="info-value">{{selectedAgent.reqLicense}} REQUIRED, {{selectedAgent.missingLicense}} MISSING</span>
      </div>
    </div>
      <div class="table-data contact-list-data">
        <div class="action-table-header">
          <div class="container-fluid">
            <div class="action-pagination-blocks center-div">
              <div class="action-block">
                <div class="left-part">
                  <!-- <div class="new-group-block">
                    <button data-toggle="modal"
                    data-target="#new-appointment" class="btn commn-btn coming-soon-btn" type="button">
                            New Assignment
                      <span class="plus-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <rect data-name="Rectangle 17995" width="20" height="20" fill="none" />
                          <path d="M10,15.625a.676.676,0,0,1-.667-.667V10.667H5.042a.667.667,0,0,1,0-1.334H9.333V5.042a.667.667,0,0,1,1.334,0V9.333h4.291a.667.667,0,0,1,0,1.334H10.667v4.291a.676.676,0,0,1-.667.667Z"
                                transform="translate(0)" fill="#0068D8" />
                        </svg>
                      </span>
                      <h3 class="coming-soon-tooltip" >This feature is coming soon  <span (click)="openComingSoonModal()">See all upcoming features</span> 
                      </h3>
                    </button>
                  </div> -->
                  <div class="search-block">                  
                    <input id="search" type="text" class="search-input"
                           placeholder="Search" role="search" name="search" [(ngModel)]="reqstParam.search" (keyup.enter)="getSearchContactDetails()">
                           <span class="close-icon" (click)="clearSearch()"*ngIf="this.reqstParam.search">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><rect _ngcontent-wvn-c189="" width="20" height="20" rx="6" fill="none"></rect><g _ngcontent-wvn-c189="" transform="translate(4 4)"><rect _ngcontent-wvn-c189="" width="16" height="16" fill="none"></rect><path _ngcontent-wvn-c189="" d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z" transform="translate(-0.005 0.005)" fill="#9e9e9e"></path></g></svg>
                          </span>
                           <button class="search-icon" (click)="getSearchContactDetails();"> </button>
                  </div>
                  <div class="table-selection-block">
                    <div class="select-options">
                      <div class="select-block multi-bor-0 width-auto"> 
                        <ng-multiselect-dropdown name="statesSelected" [placeholder]="'State'"
                            [settings]="dropdownSettingState" [data]="states" [(ngModel)]="selectedStates"
                            (onSelect)="getSearchContactDetails()" (onSelectAll)="getSearchContactDetails()"
                            (onDeSelect)="getSearchContactDetails()" (onDeSelectAll)="getSearchContactDetails()">
                          </ng-multiselect-dropdown>
                      </div>
                    </div>
                    <div class="select-options">
                      <div class="select-block">
                        <ng-select [searchable]="false" [clearable]="false" name="status" [(ngModel)]="reqstParam.status" placeholder="Status"
                        (change)="getSearchContactDetails()">
                        <ng-option value="null" selected>Status</ng-option>
                        <ng-option *ngFor="let AStatus of agentStatusList" value="{{AStatus.status}}">{{
                          AStatus.status }}</ng-option>
                      </ng-select>
                      </div>
                    </div>
                    <div class="select-options">
                      <div class="select-block">
                        <ng-select [searchable]="false" [clearable]="false"  name="expireIn" [(ngModel)]="reqstParam.expireIn" placeholder="Expires In"
                          (change)="getSearchContactDetails()">
                          <ng-option value=0 selected>Expires In</ng-option>
                          <ng-option *ngFor="let days of expiryInData" [value]="days.id">{{days.days}}</ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pagination-block">
                <div class="right-part">
                  <div class="pagination">
                    <div class="pagination-number">
                      <span class="num-range">{{range[0]}} - {{range[1]}}</span> of <span
                          class="total-num">{{overallCount}}</span>
                    </div>
                    <div class="pagination-action-btn">
                      <a class="back" href="javascript:void(0)" (click)="back()" [class.disabled]="range[0] <= 1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <g id="ic-arrow" transform="translate(0)">
                            <rect data-name="Rectangle 17998" width="20" height="20" transform="translate(0)"
                                  fill="none" />
                            <path d="M10,12.458a.867.867,0,0,1-.323-.062.925.925,0,0,1-.3-.208L5.6,8.417a.782.782,0,0,1-.229-.625.891.891,0,0,1,.25-.6.823.823,0,0,1,.625-.261.863.863,0,0,1,.6.261L10,10.354l3.167-3.166a.81.81,0,0,1,.593-.25.849.849,0,0,1,.615.27.881.881,0,0,1,0,1.23l-3.75,3.75a.925.925,0,0,1-.3.208A.867.867,0,0,1,10,12.458Z"
                                  transform="translate(0.308 20) rotate(-90)" fill="#9E9E9E" />
                          </g>
                        </svg>
                      </a>
                      <a class="next" href="javascript:void(0)" (click)="next()" [class.disabled]="range[1] == overallCount">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <g transform="translate(0)">
                            <rect data-name="Rectangle 17998" width="20" height="20" transform="translate(0)"
                                  fill="none" />
                            <path d="M10,12.458a.867.867,0,0,1-.323-.062.925.925,0,0,1-.3-.208L5.6,8.417a.782.782,0,0,1-.229-.625.891.891,0,0,1,.25-.6.823.823,0,0,1,.625-.261.863.863,0,0,1,.6.261L10,10.354l3.167-3.166a.81.81,0,0,1,.593-.25.849.849,0,0,1,.615.27.881.881,0,0,1,0,1.23l-3.75,3.75a.925.925,0,0,1-.3.208A.867.867,0,0,1,10,12.458Z"
                                  transform="translate(0.308 20) rotate(-90)" fill="#9E9E9E" />
                          </g>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="common-table scorcard-table-top">
          <table class="table contactdetail-scorecard-table" [class.all-data-checked]="allChecked">
            <thead class="sticky-from-top">
              <tr>
                <!--<th scope="col">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="statechecked" name="statechecked"  [checked]="allChecked"
        (click)="selectAll(!allChecked)">
        <label class="custom-control-label" for="allChecked">State</label>
        </div>
  </th>-->
                <th scope="col">State
                </th>
                <th scope="col">License Status</th>
                <th scope="col">License Class</th>
                <th scope="col">LOA</th>
                <th scope="col">LOA Status</th>
                <th scope="col">Exp. Date <img class="sorting-icon-asc" (click)="onSortClick($event, 'expirationDate')" src="assets/images/ic_ascending.svg" alt="" /></th>
              </tr>
              </thead>
            <tbody>
              <tr *ngFor="let contactdata of contactDetailScorecardList">
                          <!--<td>
                 <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="name" [checked]="contactdata.isChecked" (click)="selectChkVal(contactdata)">
                  <label class="custom-control-label" for="st1">{{contactdata.stateName}}</label>
                 </div>
              </td>-->
              <td>{{contactdata.stateName}}</td>
                    <td class="status-types">
                      <span class="st-style" [ngClass]="{'active': contactdata.licenseStatus == 'Active', 'missing': contactdata.licenseStatus == 'Missing', 'null': contactdata.licenseStatus == 'null'}">
                        {{contactdata.licenseStatus}}
                      </span>
                    </td>
                    <td>{{contactdata.licenseClassName}}</td>
                    <td>{{contactdata.lineOfAuthorityName}}</td>
                    <td class="status-types">
                      <span class="st-style" [ngClass]="{'active': contactdata.loaStatus == 'Active', 'missing': contactdata.loaStatus == 'Missing', 'null': contactdata.loaStatus == 'null'}">
                        {{contactdata.loaStatus}}
                      </span>
                    </td>
                    <td class="expir-date {{contactdata.formatedexpiryDate.className}}">
                      <span data-toggle="modal" data-target="#licence-expired"
                            class="expiry-tooltip">{{contactdata.formatedexpiryDate.date}}</span>
                      <h3 *ngIf="contactdata.formatedexpiryDate.className != ''" class="expiry-text-tooltip">
                        {{
  contactdata.expirationDate |
                        date : 'MM/dd/yyyy'
                        }}
                      </h3>
                    </td>
                  </tr>
            </tbody>
          </table>
        </div>
      </div>
  </div>
    <!-- Coming soon modal -->
    <app-coming-soon-modal [modalToggle]="openModal" (modalStatus)="modalStatus($event)"></app-coming-soon-modal>
    <!--  -->