import { ChangeDetectorRef, Component, EventEmitter, HostListener, Injectable, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateParserFormatter, NgbDatepickerConfig, NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Filter, FilterCategory, FilterList, FilterRequest, FilterTypeList } from 'models/filter.model';
import { LicenseClass } from 'models/license-class.model';
import { SearchLicense } from 'models/search-license.model';
import { State } from 'models/state.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FilterService } from 'services/filter.service';
import { SharedService } from 'services/shared.service';
import { ValidatorHelper } from '../../custom-validators/validators.helper';
import { SearchAppointment } from 'models/search-appointment.modal';
import { TagList } from 'models/tag.model';
import { TaskService } from 'api-services/task.service';
import { TaskPriority, TaskStatus } from 'shared/constant';
import { RoleService } from 'services/role.service';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year
      : '';
  }
}

@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class CommonFilterComponent implements OnInit {

  tenantTypeId = localStorage['tenantTypeId'];  
  
  tenantId = Number(localStorage.getItem('tenantId'));
  userId = Number(localStorage.getItem('userId'));

  
  @Input() filterObject: any;
  @Input() selectedObj: any;
  @Output() refreshGrid: EventEmitter<any> = new EventEmitter();
  filterFormGroup: FormGroup;
  loggedinUserRole = localStorage["userRole"];
  isLoad:boolean = false;  
  status:string;

  isDisabled: boolean = false;


  taskTypeList = [] 
  priorityTypeList = [];
  statusTypeList = [];
  reasonList = [];
  assignToList = [];
  createdByList = [];

  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  dropdownSettingAssignTo: IDropdownSettings;
  dropdownSettingCreatedBy: IDropdownSettings;
  dropdownSettingStatus: IDropdownSettings;
  isContactUser: boolean = false;

  constructor(private sharedService: SharedService, 
    private fb: FormBuilder, 
    private activeModal: NgbActiveModal,
    private taskService: TaskService,
    private roleService: RoleService,
    private config: NgbDatepickerConfig, private cdr: ChangeDetectorRef,) { }
  
  ngOnInit(): void { 
    this.isContactUser = this.roleService.isContactUser;

    const today = new Date();

    this.minDate = {
      year: today.getFullYear() - 10,
      month: today.getMonth() + 1,
      day: today.getDate()
    };
    this.maxDate = {
      year: today.getFullYear() + 10,
      month: today.getMonth(),
      day: today.getDate()
    }

    this.dropdownSettingAssignTo = {
      singleSelection: false,
      idField: 'assigneeId',
      textField: 'assigneeName',
      selectAllText: 'Select All',
      unSelectAllText: 'Clear All',
      allowSearchFilter: true,
      itemsShowLimit: 3,
    };

    this.dropdownSettingCreatedBy = {
      singleSelection: false,
      idField: 'assigneeId',
      textField: 'assigneeName',
      selectAllText: 'Select All',
      unSelectAllText: 'Clear All',
      allowSearchFilter: true,
      itemsShowLimit: 3,
    };

    this.dropdownSettingStatus = {
      singleSelection: false,
      idField: 'id',
      textField: 'status',
      selectAllText: 'Select All',
      unSelectAllText: 'Clear All',
      allowSearchFilter: false,
      itemsShowLimit: 2,
    };
  
    this.filterFormGroup = this.fb.group({
      taskTypeId: [null, []],
      reasonId: [null, []],
      statusId: [null, []],
      priorityId: [null, []],
      assignByIds: [null, []],
      createdByIds: [null, []],
      // groupByIds: [null, []],
      dueDate: [null, []],
      filterCount: [0, []]
    });

    this.loadFilters();
  }
  

  loadFilters() {
    this.filterObject?.filterList?.forEach(el => {
      if(el.type == FilterTypeList.TaskType){
        this.getTaskTypeList();
      }
      else if(el.type == FilterTypeList.Reason){
        this.selectedObj?.taskTypeId ? this.getReasonCodes(this.selectedObj?.taskTypeId, true) : '';
      }
      else if(el.type == FilterTypeList.AssignBy && !this.isContactUser){
        this.getAssigmentGroups();
      }
      else if(el.type == FilterTypeList.Status){
        this.getTaskStatus();
      }
      else if(el.type == FilterTypeList.DueDate){
        var date = this.selectedObj?.dueDate?.split('-')
        if(date){
          var dateFormat = {
            year: Number(date[0]),
            month: Number(date[1]),
            day: Number(date[2].split('T')[0])
          }
          this.filterFormGroup.get('dueDate')?.setValue(dateFormat);
        }
        
      }
      else if(el.type == FilterTypeList.Priority){
        this.getTaskPriority();
      }
    });
      
  }

  // Filter API call

  getTaskTypeList(){
    this.taskService.getTaskTypes().subscribe(res => {
      this.taskTypeList = res;
      this.filterFormGroup.get('taskTypeId')?.setValue(this.selectedObj?.taskTypeId);
    }, error => {
      console.log(error);
    })
  }

  getTaskPriority() {
    TaskPriority.forEach((label, value) => {
      this.priorityTypeList.push({ id: value, status: label });
      this.filterFormGroup.get('priorityId')?.setValue(this.selectedObj?.taskPriorityId);
    });
  }

  getTaskStatus() {
    TaskStatus.forEach((label, value) => {
      this.statusTypeList.push({ id: value, status: label });
    });
        var selectedStatus = [];
        if (this.selectedObj?.taskStatusIds?.trim().length > 0) {
          this.selectedObj?.taskStatusIds?.split(',').forEach(id => {
            selectedStatus.push(this.statusTypeList.find(el => el.id == id));
          });
          this.filterFormGroup.get('statusId')?.setValue(selectedStatus);
        }
    
  }

  getReasonCodes(id, setValue) {
    if(id){
      this.taskService.getTaskReasonCodesByTaskType(id).subscribe(
        (response: any) => {
          this.reasonList = response;
          setValue ? this.filterFormGroup.get('reasonId')?.setValue(this.selectedObj?.taskReasonCodeId) : this.filterFormGroup.get('reasonId').reset();
        },
        (error: any) => {
          console.log(error);
        }
      );
    }else{
      this.reasonList = [];
      this.filterFormGroup.get('reasonId')?.reset();
    }
  }

  getAssigmentGroups() {
    this.taskService.getUsersForTaskAssignmentGroup().subscribe(
      (response: any) => {
        const assigneeArray = response.map(item => ({
          assigneeName: item.displayName,   // Renaming 'displayName' to 'assigneename'
          assigneeId: item.groupUserId,      // Renaming 'groupUserId' to 'assigneeId'
          roleId: item.roleId
        }));


        this.assignToList = assigneeArray;
        var assigneeList = [];
        if(this.selectedObj?.assignedUserIds?.trim().length > 0){
          this.selectedObj?.assignedUserIds?.split(',').forEach(userId => {
            assigneeList.push(this.assignToList.find(el => el.assigneeId == userId))
          });
          this.filterFormGroup.get('assignByIds')?.setValue(assigneeList);
        }

        this.createdByList = assigneeArray.filter(obj=> obj.roleId != 2);
        var createdByList = [];
        if (this.selectedObj?.createdByIds?.trim().length > 0) {
          this.selectedObj?.createdByIds?.split(',').forEach(userId => {
            createdByList.push(this.createdByList.find(el => el.assigneeId == userId))
          });
          this.filterFormGroup.get('createdByIds')?.setValue(createdByList);
        }
        
        //if(this.selectedObj?.assignedGroupIds?.trim().length > 0){
        //  this.selectedObj?.assignedGroupIds?.split(',').forEach(userId => {
        //    assigneeList.push(this.assignToList.find(el => el.assigneeId == userId))
        //  });
        //}

      },
      (error: any) => {
        console.log(error);
      }
    );
  }

// /////////////////////////

  ngAfterViewInit() {
    this.config.maxDate = this.maxDate;
    this.config.minDate = this.minDate;
    this.cdr.detectChanges();
  }

  clearDate() {
    console.log("clear")
    this.filterFormGroup.get("dueDate")!.setValue("");
  }

  clearFilter(){
    this.filterFormGroup.reset();
    this.filterFormGroup.get("filterCount")!.setValue(0);
  }

  applyFilter() {
    // Set empty values to null
    Object.keys(this.filterFormGroup.value).forEach(key => {
      const value = this.filterFormGroup.value[key];
      if (value === '' || (Array.isArray(value) && value.length === 0)) {
        this.filterFormGroup.get(key)!.setValue(null);
      }
    });

    var filterCount = Object.values(this.filterFormGroup.value).filter(el => (el != undefined && el != null && el != "" && el != []) || el == 0).length - 1;
    this.filterFormGroup.get("filterCount")!.setValue(filterCount);
    // var assignByIds = null;
    //   if (this.filterFormGroup.value && this.filterFormGroup.value['assignBy']?.length > 0) {
    //     assignByIds = this.filterFormGroup?.value['assignBy']?.map(function (val) {
    //       return val.assigneeId
    //     }).join(',');
    // }

 
    // this.close();
    this.refreshGrid.emit(this.filterFormGroup.value)
  }

  close(): void {
    var filterCount = Object.values(this.filterFormGroup.value).filter(el => (el != undefined && el != null && el != "" && el != []) || el == 0).length - 1;
    this.filterFormGroup.get("filterCount")!.setValue(filterCount);
    this.activeModal.close(this.filterFormGroup.value);
  }

  scrolltoBottom(){
    document.getElementById('scrollTo')?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}


