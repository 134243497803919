import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactService } from 'api-services/contact.service';
import { SharedService } from 'services';


@Component({
  selector: 'app-add-contact-preview',
  templateUrl: './add-contact-preview.component.html',
  styleUrls: ['./add-contact-preview.component.css']
})
export class AddContactPreviewComponent implements OnInit {

  searchText: string;
  objectData = [];
  responseData = [];
  processedCount: number = 0;
  selectedFilter = 'All';
  activeList:boolean = false;
  constructor(private contactService:ContactService, public activeModal: NgbActiveModal, public sharedService: SharedService) { }

  ngOnInit(): void {
    this.contactService.contactList.subscribe(obj => {

      this.objectData = obj.map(el => {
        return{
          ...el,
          status: (el.errorMessage != null && el.errorMessage.length > 0) ? "Failed" : "Processed"
        }
      });
      this.responseData = this.objectData;
      this.processedCount = this.responseData.filter(el => el.errorMessage == null || (el.errorMessage != null && el.errorMessage == ''))?.length;
      this.activeList = true;
    });
  }

  onChange(event) {
    if (event != "All") {
      this.responseData = this.objectData.filter(el => el.status.toLowerCase() == event.toLowerCase())
    } else {
      this.responseData = this.objectData;
    }

  }

  propertyMappings = [
    { oldName: 'npn', newName: 'NPN' },
    { oldName: 'firstName', newName: 'First Name' },
    { oldName: 'lastName', newName: 'Last Name' },
    { oldName: 'producerCode', newName: 'Producer Code' },
    { oldName: 'email', newName: 'Email' },
    { oldName: 'status', newName: 'Status' },
    { oldName: 'niprFirstName', newName: 'NIPR First Name' },
    { oldName: 'niprLastName', newName: 'NIPR Last Name' },
    { oldName: 'errorMessage', newName: 'System Warning' }
  ];

  exportToExcelContactList() {

    var result = JSON.parse(JSON.stringify(this.responseData));

    this.renameProperties(result, this.propertyMappings);

    result.forEach(function (obj) {
      delete obj.branchId;
      delete obj.tenantId;
      delete obj.userId;
      delete obj.departmentId;
      delete obj.jobTitleId;
      delete obj.alias;
      delete obj.hireDate;
      delete obj.isError;
      delete obj.index;
    });
    var  columnOrder = null
    this.sharedService.exportToExcel(result, columnOrder, 'BulkImport.xlsx')
  }

  renameProperty(obj: any, oldName: string, newName: string): void {
    if (oldName !== newName) {
      Object.defineProperty(obj, newName,
        Object.getOwnPropertyDescriptor(obj, oldName));
      delete obj[oldName];
    }
  }

  renameProperties(jsonData: any[], propertyMappings: { oldName: string, newName: string }[]): void {
    jsonData.forEach(item => {
      propertyMappings.forEach(mapping => {
        this.renameProperty(item, mapping.oldName, mapping.newName);
      });
    });
  }


}
