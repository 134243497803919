import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { RoleService } from 'services/role.service';
import { RouterDataResolver } from 'services/router.resolver';
import { tenantTypeCap } from 'shared/constant';
import { environment } from '../../../../environments/environment';
import { AccountService } from '../../../api-services';
import { SharedService } from '../../../services';
import { Package } from 'models/packages/package.model';
import { PackageCommonService } from '../../../services/package-common.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemindersComponent } from '../reminders/reminders.component';
import { RemindersService } from 'api-services/reminders.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  name: string
  shortName: string
  pageTitle = ''
  breadcums = ''
  activeIcon = '';
  showIcon = true;
  isContactUser = false;
  isGuestUser = false;
  hideSideBar = false;
  tenantTypeId = localStorage['tenantTypeId'];
  isHelp: boolean = window.location.href.includes("/help");
  totalFileCount: number;
  profileStatus = localStorage["profileStatus"];
  package = this.packageCommonService.getPackageLocalStorage();
  email: string;

  showReminderPannel: boolean = false;
  remiderCount: number = 0;
  reminderDetails = null;
  showUserReminderPannel: boolean = false;
  showHelpAndFeedbackPannel: boolean = false;
  
  private packageEventSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private accountService: AccountService,
    private roleService: RoleService,
    private routerDataResolver: RouterDataResolver,
    private sharedService: SharedService,
    private modalService: NgbModal,
    private packageCommonService: PackageCommonService,
    private remindersService: RemindersService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.pageTitle = routerDataResolver.pageName;
    this.breadcums = routerDataResolver.breadcrumb;
    this.activeIcon = routerDataResolver.pageIcon;
    this.hideSideBar = routerDataResolver.hideSideBar;
    this.sharedService.AllReminderVisibility$.subscribe(isVisible => {
      this.showReminderPannel = isVisible;
    });
    this.sharedService.IssueAndFeedbackVisibility$.subscribe(isVisible => {
      this.showHelpAndFeedbackPannel = isVisible;
    })
    this.remindersService.getupdateReminder$.subscribe(isUpdate => {
      this.getRemindersCount();
    });
  }


  ngOnInit(): void { 
    this.isContactUser = this.roleService.isContactUser;
    this.isGuestUser = this.roleService.isGuestUser;
    if (this.sharedService.getValue('name')) {
      this.name = this.sharedService.getValue('name');
      this.shortName = this.name.split(' ').map(n => n[0]).join('');
      if(this.isGuestUser)
        this.email = this.sharedService.getValue('emailId');
    }

    this.getRemindersCount();

    this.sharedService.getUpdate().subscribe
      (res => { //message contains the data sent from service
        if (this.router.url == "/dashboard/agent-scorecard")
          this.breadcums = ' / Scorecard / ' + JSON.parse(localStorage.getItem('selContact'))?.displayName + '';
        else if (this.router.url == "/contact-dashboard/scorecard")
          this.breadcums = ' / Scorecard ';
        else if (this.router.url == "/contact-dashboard/detail-scorecard")
          this.breadcums = ' / Scorecard / ' + JSON.parse(localStorage.getItem('selContact'))?.customGroupName;
        else if (this.router.url == "/contact-list")
          this.pageTitle = tenantTypeCap[this.tenantTypeId] + "s";
        else if (window.location.href.includes("/notes")) {
          var name = localStorage.getItem('seleNotesEntity');
          this.breadcums = ' / ' + (name == undefined ? '' : name) + ' / Notes';
        } else if (this.router.url == "/contact-list/ce-credits") {
          var name = localStorage.getItem('seleCEUser');
          this.breadcums = ' / ' + (name == undefined ? '' : name + ' / CE ');
        } else if (this.router.url == "/custom-loa-group-list/branch") {
        }
        else if (this.router.url == "/contact-list/healthcare-info") {
          var name = localStorage.getItem('seleCEUser');
          this.breadcums = ' / ' + (name == undefined ? '' : name + ' / Agent and Broker Federally-facilitated Marketplace Information');
        }
        else if (this.router.url == "/custom-loa-group-list/branch") {
          this.breadcums = ' / ' + (this.tenantTypeId == 1 ? 'Custom Groups By Agency' : 'Custom Groups By Branch');
        } else if (window.location.href.includes("/bulk-licenses-renewal-user")) {
          this.breadcums = ' / ' + JSON.parse(localStorage.getItem('licenseActiveContactUser'))?.userName;
        }
        else if (this.router.url == "/packages/new-package/package-request") {
          this.breadcums = ' / ' + JSON.parse(localStorage.getItem('package'))?.name + ' / New Appointment';
        }
        else if (this.router.url == "/packages/new-package/package-termination-request") {
          this.breadcums = ' / ' + JSON.parse(localStorage.getItem('package'))?.name + ' / New Appointment Termination';
        }
        else if (this.router.url == "/packages/package/review-package") {
          this.breadcums = ' / ' + JSON.parse(localStorage.getItem('package'))?.name;
        }
        else if (this.router.url == "/packages/new-package/package-detail" || this.router.url == "/packages-carrier/package-list") {
          this.breadcums = ' / ' + JSON.parse(localStorage.getItem('package'))?.name;
        }
      });

    this.packageEventSubscription = this.packageCommonService.packageStatusUpdateObs.subscribe(() => {
      this.package = this.packageCommonService.getPackageLocalStorage();
      this.breadcums = ' / ' + this.package.name;
    });
  }

  // @ViewChild('reminder') public reminderContainer: ElementRef;

  // @HostListener('document:click', ['$event'])
  // handleOutsideClick(event: Event) {
  //   if ((this.reminderContainer.nativeElement !== event.target && 
  //     !this.reminderContainer.nativeElement.contains(<HTMLInputElement>event.target)) &&
  //     !event.target['classList'].contains('ng-option-label')) {  
  //     this.hideReminder(event);
  //   } 
  // }
  
  toggleSideBar() {
    document.body.classList.toggle('sb-sidenav-toggled');
  }

  logout(): void {

    this.accountService.UserSignOut().subscribe(
      (response: any) => {
        console.log("User logged out successfully");
      },
      (error: any) => {
        console.log(error);
      }
    )

    this.accountService.logout();
    this.router.navigate(['/login']);
  }

  redirectToHomePage() {
    if (this.isContactUser) {
      this.router.navigate(["/contact-dashboard"])
    }
    else if (this.isGuestUser) {
      this.router.navigate(["/package-carrier"])
    }
    else if (localStorage["userRole"] == "admin") {
      this.router.navigate(["/dashboard"])
    }
    else {
      if (JSON.parse(localStorage.getItem('profileStatus')) >= 5) {
        this.router.navigate(["/dashboard"]);
      }
    }

    //if (JSON.parse(localStorage.getItem('profileStatus')) >= 4){
    // if (this.isContactUser) {
    //   this.router.navigate(["/dashboard-tech"])
    // } else {
    //   this.router.navigate(["/dashboard"])
    // }
    //}
    // else if (JSON.parse(localStorage.getItem('profileStatus')) == 0)
    //   this.router.navigate(['/product-plan']);
    // else if (JSON.parse(localStorage.getItem('profileStatus')) == 1)
    //   this.router.navigate(['/domain']);
    // else if (JSON.parse(localStorage.getItem('profileStatus')) == 2)
    //   this.router.navigate(['/niprcred']);
    // else if (JSON.parse(localStorage.getItem('profileStatus')) == 3)
    //   this.router.navigate(['/npn']);
    // else {

    // }

  }

  redirectToHelp() {
    window.open(`/help`, '_blank');
    // this.router.navigateByUrl('/help');
    // this.router.navigateByUrl("/help", { skipLocationChange: true });
  }

  getPackageStatus(_package: Package) {
    if (_package?.statusId == 2)
      return 'New';
    else {
      return _package.status;
    }
  }

isReminder = false;
  showReminder(event) {
    event.stopPropagation();
    this.isReminder = !this.isReminder;
    this.sharedService.setAllReminderVisibility(this.isReminder);
    this.sharedService.setUserReminderVisibility(false);
    this.sharedService.setIssueAndFeedbackVisibility(false);
  }

  hideReminder(event) {
    this.sharedService.setAllReminderVisibility(false);
    this.isReminder = false;
  }

  getRemindersCount(){
    this.remindersService.getRemindersCount(JSON.parse(this.sharedService.getValue('userId'))).subscribe(res => {
      this.remiderCount = res;
    })
  }
 
  showIssueandFeedback(event) {
    event.stopPropagation();
    this.sharedService.setIssueAndFeedbackVisibility(true);
    this.sharedService.setAllReminderVisibility(false);
    this.isReminder = false;
    this.sharedService.setUserReminderVisibility(false);

    const target = event.target as HTMLElement;
    const dropdownMenu = target.closest('.dropdown-menu');
  
    if (dropdownMenu.classList.contains('show')) {
      dropdownMenu.classList.remove('show');
    }
  }
  
  hideIssueAndFeedback(event) {
    this.sharedService.setIssueAndFeedbackVisibility(false);
  }

  ngDestroy() {
    this.packageEventSubscription.unsubscribe();
  }
}
