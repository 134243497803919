import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationSummaryComponent } from 'components/common/validation-summary/validation-summary.component';
import { CustomFormsModule } from 'ng2-validation'
import { ValidatorHelper } from 'components/custom-validators/validators.helper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModelService } from 'services/model.service';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { RoleService } from 'services/role.service';
import { UserProfileSettingService } from 'api-services/user-profile-setting.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { TextMaskModule } from 'angular2-text-mask';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { EllipsifyMeDirective } from './elipsis.directive';
import { ErrorComponent } from 'components/shared-component/error/error.component';
import { AddContactComponent } from 'components/shared-component/add-contact/add-contact.component';
import { ComingSoonModalComponent } from 'components/shared-component/coming-soon-modal/coming-soon-modal.component';
import { AlertDialogComponent } from 'components/shared-component/alert-dialog/alert-dialog.component';
import { NoDataFoundComponent } from 'components/shared-component/no-data-found/no-data-found.component';
import { SearchFilterPipe } from './search-filter.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { AutoFocusDirective } from './auto-focus.directive';
import { DatepickerModule } from 'ng2-datepicker';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { AttachmentsComponent } from 'components/shared-component/attachments/attachments.component';
import { UploadAttachmentModalComponent } from 'components/shared-component/attachments/upload-attachment-modal/upload-attachment-modal.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { AddAdminWithoutNPNComponent } from 'components/shared-component/add-contact/add-admin-without-npn/add-admin-without-npn.component';
import { DateRangePickerComponent } from 'components/shared-component/date-range-picker/date-range-picker.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { PreventCopyDirective } from './prevent-cut-copy-paste';
import { InitialsPipe } from './initials.pipe';
import { CustomTagDropdownComponent } from 'components/shared-component/custom-tag-dropdown/custom-tag-dropdown.component';
import { PhoneformatPipe } from './phoneformat.pipe';
import { CustomCheckboxDropdownComponent } from 'components/shared-component/custom-checkbox-dropdown/custom-checkbox-dropdown.component';
import { RemindersComponent } from 'components/shared-component/reminders/reminders.component';
import { SetRemindersComponent } from 'components/shared-component/reminders/set-reminders/set-reminders.component';
import { NewManualAppointmentComponent } from 'components/after-login/contacts/new-manual-appointment/new-manual-appointment.component';
import { AngularDraggableModule } from 'angular2-draggable';

const CommonModules = [
  CommonModule,
  ReactiveFormsModule,
  CustomFormsModule,
  NgSelectModule,
  NgbModule,
  HttpClientModule,
  TextMaskModule,
  FormsModule,
  NgSelectModule,
  DatepickerModule,
  RecaptchaModule,
  RecaptchaFormsModule,
  VirtualScrollerModule,
  DragDropModule,
  AngularDraggableModule
]

const CommonComponents = [
  ErrorComponent, 
  ValidationSummaryComponent, 
  AddContactComponent,
  ComingSoonModalComponent,
  AlertDialogComponent,
  NoDataFoundComponent,
  SearchFilterPipe,
  EllipsifyMeDirective,
  AutoFocusDirective,
  AttachmentsComponent,
  UploadAttachmentModalComponent,
  AddAdminWithoutNPNComponent,
  DateRangePickerComponent,
  PreventCopyDirective,
  InitialsPipe,
  CustomTagDropdownComponent,
  PhoneformatPipe,
  CustomCheckboxDropdownComponent,
  RemindersComponent,
  SetRemindersComponent,
  NewManualAppointmentComponent
]

@NgModule({
  declarations: [...CommonComponents],
  imports: [
    ...CommonModules, 
    MalihuScrollbarModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  exports:[
    ...CommonModules, 
    MalihuScrollbarModule,
    NgMultiSelectDropDownModule,
    NgxMaskModule,
    ...CommonComponents
  ],

  providers:[ValidatorHelper,ModelService,RoleService,UserProfileSettingService]
})
export class SharedModule { }
