import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'services';

@Component({
  selector: 'app-penalty-info',
  templateUrl: './penalty-info.component.html',
  styleUrls: ['./penalty-info.component.css']
})
export class PenaltyInfoComponent implements OnInit {

  constructor(private sharedService: SharedService, private router: Router) { }

  ngOnInit(): void {
  }
  goToDashboard(){
    if (this.sharedService.getValue('userRole') == 'contact') {
      this.router.navigate(["/contact-dashboard"])
    }
    else{
      this.router.navigate(["/dashboard"])
    }
  }
}
