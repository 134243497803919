import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiClientService } from '../../../api-services';
import { AuthService } from '../../../services';
import { CarrierList } from 'models/manage-mga.model';


@Injectable({
  providedIn: 'root'
})
export class CarrierService {

  public editCarrier: BehaviorSubject<any> = new BehaviorSubject('');

  showEditCarrier(item) {
    this.editCarrier.next(item);
  }

  public editAppointer: BehaviorSubject<any> = new BehaviorSubject('');

  showEditAppointer(item) {
    this.editAppointer.next(item);
  }

  constructor(private apiClient: ApiClientService,
    private authService: AuthService) { }

  public list(tenantId: number) {

    return this.apiClient.get(`api/carrier/list/${tenantId}`, null, null, true);
  }
  public getCarrier(carrierId: number) {

    return this.apiClient.get(`api/carrier/get/${carrierId}`, null, null, true);
  }

  public createCarrier(carrier: any) {

    return this.apiClient.post('api/carrier/manage', carrier, null, true);
  }

  public getCarrierListForSettings(carrierListReq: any) {
    return this.apiClient.post(`api/carrier/listforsettings`, carrierListReq, null, true);
  }

  public changeCarrierStatus(carrierStatusReq: any) {
    return this.apiClient.post(`api/carrier/changeCarrierStatus`, carrierStatusReq, null, true);
  }

  public manageCarrier(carrier: any) {
    return this.apiClient.post('api/carrier/managecarrier', carrier, null, true);
  }

  public getCarrierAppointerList(carrierId: number) {
    return this.apiClient.get(`api/carrier/getappointerlist/${carrierId}`, null, null, true);
  }

  public getAppointer(carrierAttributesId: number) {
    return this.apiClient.get(`api/carrier/getappointer/${carrierAttributesId}`, null, null, false);
  }

  public manageCarrierAttributes(carrierAttributes: any) {
    return this.apiClient.post('api/carrier/managecarrierattributes', carrierAttributes, null, true);
  }

  public getByName(requestParam: any) {
    return this.apiClient.post(`api/carrier/getByCarrierName`, requestParam, null, false);
  }

  public updateCarrier(carrier: any){
    return this.apiClient.put(`api/carrier`, carrier, null, true);
  }

  public insertMGACarrierLicenseAndAppointmentData(requestParam: any) {
    return this.apiClient.post(`api/UserLicense/InsertMGACarrierLicenseAndAppointmentData`, requestParam, null, true);
  }
  public getMGAAppointerDetail(tenantId: number) {
    return this.apiClient.get(`api/UserLicense/getMGAAppointerDetail/${tenantId}`, null, null, true);
  }
  public getCarrierListByTenant(obj: CarrierList) {
    return this.apiClient.post(`api/Carrier/getCarrierListByTenant`, obj, null, true);
  }
  public InsertCarrierForManualAppt(carrier: any) {
    return this.apiClient.post('api/carrier/insertCarrierForManualAppt', carrier, null, true);
  }
  
}
