import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LinesOfAuthorityService } from 'api-services/lines-of-authority.service';
import { DashboardScorecard } from 'models/dashboard-scorecard.model';
import { SearchLinesOfAuthority } from 'models/search-lines-of-authority.model';
import { State } from 'models/state.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-dashboard-scorecard',
  templateUrl: './dashboard-scorecard.component.html',
  styleUrls: ['./dashboard-scorecard.component.css']
})
export class DashboardScorecardComponent implements OnInit {
  scorecardReqtParam: DashboardScorecard = {} as DashboardScorecard
  scorecardList: any = [];
  scorecardGrpList: any = [];
  scorecardUserList: any = [];
  scorecardGrpDetail: any = [];
  //scorecardList: ScoreCard[] = [];
  overallScore:number = 0;
  sortDir = 0;
  overallCount: number = 0;
  totalAgents: number = 0;
  pagCount: number = 1;
  range: number[] = [0, 0] as number[];
  selectedStates: State[];
  states: State[] = [] as State[];
  LOAsList: any;
  selectedLOAsList: any;
  dropdownSettingState: IDropdownSettings;
  dropdownSettingLOA: IDropdownSettings;
  selectedCustomGroupId: number = null;
  searchLOAs: SearchLinesOfAuthority = {} as SearchLinesOfAuthority;
  isClick = true;
  selectedAssignedNonAssigned = "All";
  darkScrollbarOptions: MCustomScrollbar.CustomScrollbarOptions = { axis: 'y', theme: 'light', setHeight: 'calc(100vh - 257px)' };
  constructor(private loaService: LinesOfAuthorityService, private router: Router, private sharedService: SharedService) { }


  ngOnInit(): void {
    this.scorecardReqtParam.tenantId = Number(localStorage.getItem('tenantId'));
    this.scorecardReqtParam.sortBy = 'completion';
    this.scorecardReqtParam.sortOrder = 0;
    this.selectedCustomGroupId = Number(localStorage.getItem('selCustomGrp')? localStorage.getItem('selCustomGrp') : null);
    this.scorecardReqtParam.pageNum = 1;
    this.scorecardReqtParam.pageSize = 10;
    

    this.dropdownSettingState = {
      singleSelection: false,
      idField: 'stateId',
      textField: 'stateName',
      selectAllText: 'Select All',
      unSelectAllText: 'Clear All',
      allowSearchFilter: true,
      itemsShowLimit: 1,
    };

    this.dropdownSettingLOA = {
      singleSelection: false,
      idField: 'lineOfAuthorityCode',
      textField: 'lineOfAuthorityName',
      selectAllText: 'Select All',
      unSelectAllText: 'Clear All',
      allowSearchFilter: true,
      itemsShowLimit: 1,
    };

    this.loadStates();
    this.getSelectedGroupList(this.selectedCustomGroupId);
    this.loadLoas();
    
  }

  // get scorecard data 
  getSelectedGroupList(selGroup) {
    this.totalAgents = 0;
    this.scorecardReqtParam.states = null;
    if (this.selectedStates && this.selectedStates.length > 0) {
      this.scorecardReqtParam.states = this.selectedStates.map(function (val) {
        return val.stateId
      }).join(',');
    }
    this.scorecardReqtParam.loas = null;
    if (this.selectedLOAsList && this.selectedLOAsList.length > 0) {
      this.scorecardReqtParam.loas = this.selectedLOAsList.map(function (val) {
        return val.lineOfAuthorityCode
      }).join(',');
    }
    
    this.scorecardReqtParam.customGroupId = selGroup;
    this.scorecardReqtParam.search = this.scorecardReqtParam.search == ''? null: this.scorecardReqtParam.search; 
    this.isClick = !this.isClick;
    this.loaService.getDashboardScorecardList(this.scorecardReqtParam).subscribe((res:any) => {
      this.scorecardList = res;
      //console.log(this.scorecardList);
      this.totalAgents = this.scorecardList.scoreCardDetail[0]?.overallCount ? this.scorecardList.scoreCardDetail[0]?.overallCount : 0;
      this.overallScore = this.scorecardList.customGroup.reduce((total, val) => { 
        return total + val.totalCompletion
      }, 0) / this.scorecardList.customGroup.length;

      this.scorecardGrpList = [];
      this.scorecardUserList = [];
      this.scorecardGrpDetail = [];
      this.scorecardGrpList = this.scorecardList.customGroup;
      if (this.selectedAssignedNonAssigned == "All") {
        this.scorecardGrpList = this.scorecardList.customGroup.filter(i => i.totalCompletion != 0);
      }
      //console.log(this.scorecardGrpList);
      if (this.scorecardGrpList.length > 0) {
        this.overallScore = this.scorecardGrpList.reduce((total, val) => {
          return total + val.totalCompletion
        }, 0) / this.scorecardGrpList.length;
        //if (this.scorecardList.scoreCardGrpDetail[0]) {
        //  this.scorecardGrpDetail.push(this.scorecardList.scoreCardGrpDetail[1]);
        //  console.log(this.scorecardGrpDetail);
        //}
        //console.log(this.scorecardGrpList);
        if (this.scorecardList.scoreCardGrpDetail[0]) {
          this.scorecardGrpDetail.push(this.scorecardList.scoreCardGrpDetail?.filter(i => i.customGroupId == this.scorecardGrpList[0].customGroupId)[0]);

          //Conver createddate from UTC to local time zone
          if (this.scorecardGrpDetail[0].createdDate != undefined && this.scorecardGrpDetail[0].createdDate != null) {
            this.scorecardGrpDetail[0].createdDate = this.sharedService.convertUTCTimeToLocalTime(this.scorecardGrpDetail[0].createdDate);
          }
        }
        if (this.scorecardList.scoreCardDetail[0]) {
          this.scorecardUserList = this.scorecardList.scoreCardDetail.filter(i => i.customGroupId == this.scorecardGrpDetail[0].customGroupId);
          this.totalAgents = this.scorecardUserList?.length;
        }
      }
    })
  }

  clearSearch(){
    this.scorecardReqtParam.search = '';
    this.getSelectedGroupList(null);
  }

  searchAssignedNonAssigned(event: any): void {
    this.getSelectedGroupList(this.selectedCustomGroupId);
  }
  getLocalSelectedGroupList(selGroup) {
    this.totalAgents = 0;
    if (this.selectedStates && this.selectedStates.length > 0) {
      this.scorecardReqtParam.states = this.selectedStates.map(function (val) {
        return val.stateId
      }).join(',');
    }
    if (this.selectedLOAsList && this.selectedLOAsList.length > 0) {
      this.scorecardReqtParam.loas = this.selectedLOAsList.map(function (val) {
        return val.supportedLineOfAuthorityId
      }).join(',');
    }

    this.scorecardReqtParam.customGroupId = selGroup;
    this.scorecardReqtParam.search = this.scorecardReqtParam.search == '' ? null : this.scorecardReqtParam.search;
    this.isClick = !this.isClick;
    this.scorecardGrpDetail = this.scorecardList.scoreCardGrpDetail.filter(i => i.customGroupId == this.scorecardReqtParam.customGroupId);

    //Convert createddate from UTC to local time zone
    this.scorecardGrpDetail = this.scorecardGrpDetail.map(el => ({
      ...el,
      createdDate: this.sharedService.convertUTCTimeToLocalTime(el.createdDate)
    }))

    this.scorecardUserList = this.scorecardList.scoreCardDetail.filter(i => i.customGroupId == this.scorecardReqtParam.customGroupId);
    this.totalAgents = this.scorecardUserList?.length;

    if (this.selectedAssignedNonAssigned == "All") {
      this.overallScore = this.scorecardList.customGroup.filter(i => i.totalCompletion != 0).reduce((total, val) => {
        return total + val.totalCompletion
      }, 0) / this.scorecardList.customGroup.filter(i => i.totalCompletion != 0).length;
    } else {
      this.overallScore = this.scorecardList.customGroup.reduce((total, val) => {
        return total + val.totalCompletion
      }, 0) / this.scorecardList.customGroup.length;
    }
    
    this.overallCount = this.scorecardList.scoreCardDetail[0]?.overallCount? this.scorecardList.scoreCardDetail[0]?.overallCount : this.scorecardList.scoreCardDetail.length ;
    this.createRange();
  }

  // click on table data next page will be shown
  gotoDetailView(selAgent) {
    localStorage.setItem("selContact", JSON.stringify(selAgent));
    localStorage.setItem("selCustomGrp", selAgent?.customGroupId?.toString());
    this.sharedService.sendUpdate(true);
    //localStorage.setItem("selCustomGrp", this.scorecardReqtParam.customGroupId?.toString());        
    this.router.navigate(['/dashboard/agent-scorecard']);
  }
 
  // ascending descending data
  onSortClick(event, colName) {
    let target = event.currentTarget,
      classList = target.classList;
      
    if (classList.contains('sorting-icon-asc')) {
      classList.remove('sorting-icon-asc');
      classList.add('sorting-icon-des');
      this.sortDir = 1;
      
    } else {
      classList.add('sorting-icon-asc');
      classList.remove('sorting-icon-des');
      this.sortDir = 0;
    }

    if (this.sortDir == 1) {
      if (colName == "displayName") {
        this.scorecardUserList.sort((a, b) => {
          if (b.displayName < a.displayName) return 1;
          if (b.displayName > a.displayName) return -1;
          else {
            return 0
          }
        })
      } else if (colName == "completion") {
        this.scorecardUserList.sort((a, b) => {
          if (b.completion < a.completion) return 1;
          if (b.completion > a.completion) return -1;
          else {
            return 0
          }
        })
      }
    }
    else if (this.sortDir == 0) {
      if (colName == "displayName") {
        this.scorecardUserList.sort((a, b) => {
          if (b.displayName < a.displayName) return -1;
          if (b.displayName > a.displayName) return 1;
          else {
            return 0
          }
        })
      } else if (colName == "completion") {
        this.scorecardUserList.sort((a, b) => {
          if (b.completion < a.completion) return -1;
          if (b.completion > a.completion) return 1;
          else {
            return 0
          }
        })
      }
    }
    
    //this.scorecardReqtParam.sortBy = colName;
    //this.scorecardReqtParam.sortOrder = this.sortDir;
    //this.getSelectedGroupList(this.scorecardReqtParam.customGroupId);
  }
  
  onSortGroupClick(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains('sorting-icon-asc')) {
      classList.remove('sorting-icon-asc');
      classList.add('sorting-icon-des');
      this.sortDir = 1;
    } else {
      classList.add('sorting-icon-asc');
      classList.remove('sorting-icon-des');
      this.sortDir = 0;
    }
    if (this.sortDir == 1) {
      this.scorecardGrpList.sort((a, b) => {
        if (b.customGroupName < a.customGroupName) return 1;
        if (b.customGroupName > a.customGroupName) return -1;
          else {
            return 0
          }
        })
    }
    else if (this.sortDir == 0) {
      this.scorecardGrpList.sort((a, b) => {
        if (b.customGroupName < a.customGroupName) return -1;
        if (b.customGroupName > a.customGroupName) return 1;
          else {
            return 0
          }
        })
    }
    //this.scorecardReqtParam.groupBySortOrder = this.sortDir;
    //this.getSelectedGroupList(this.scorecardReqtParam.customGroupId);
  }
  // pagination
  createRange() {
    var pageSize = this.scorecardReqtParam.pageSize;
    var pageNum = this.scorecardReqtParam.pageNum;
    this.pagCount = this.overallCount < pageSize ? 1 : Math.ceil(this.overallCount / pageSize);

    var offSet = pageNum * pageSize;
    this.range = [this.scorecardList.length > 0 ? ((pageNum - 1) * pageSize +1):0, Math.min(pageNum * pageSize, this.overallCount)]
  }

  // pagination next and back functionality 
  next() {
    if (this.scorecardList.pageNum <= this.pagCount) {
      this.scorecardList.pageNum++;
      this.getSelectedGroupList(null);
    }
  }

  back() {
    if (this.scorecardList.pageNum > 1)
      this.scorecardList.pageNum--;
      this.getSelectedGroupList(null);
  }

  // get data of states
  loadStates() {
    this.loaService.getSupportedStates().subscribe(
      response => {
        this.states = response;
  },
      error => {
        if (error.status == 0) {

        }
        else {

        }
      }
    )
  }

  // get data of LOAs
  loadLoas(){
    this.loaService.getSupportedLOAs(this.searchLOAs).subscribe(
      response => {
        this.LOAsList = response.filter((loaObj, i, rtnObj) => rtnObj.findIndex(t => t.lineOfAuthorityCode === loaObj.lineOfAuthorityCode) === i);
        //this.LOAsList = response;
  },
      error => {
        if (error.status == 0) {

        }
        else {

        }
      }
    )
  }

  openModal= "close";
  openComingSoonModal(){
    this.openModal = "open";
  }
  modalStatus(eve){
    this.openModal= eve;
  }
}
