
<div class="authentication-container">
  <div class="container">
    <div class="auth-content register-auth">
      <div class="logo-block">
        <img src="../../../../assets/images/auth-logo1.svg" />
      </div>
      <div class="auth-title">
        <span>Profile Update</span>
      </div>
      <form id="mainForm" [formGroup]="profileForm" (ngSubmit)="onSubmit()">

        <div class="auth-block">
          <b>User Details</b>
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="First Name" formControlName="firstName" maxlength="50" />
                <div class="help-block" *ngIf="((profileForm.get('firstName')!.touched ||
                               profileForm.get('firstName')!.dirty) &&
                               profileForm.get('firstName')!.errors)">
                  <span *ngIf="profileForm.get('firstName')!.errors!['required']">
                    First Name is required
                  </span>
                </div>
              </div>

            </div>
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName" maxlength="50" />
                <div class="help-block" *ngIf="((profileForm.get('lastName')!.touched ||
                               profileForm.get('lastName')!.dirty) &&
                               profileForm.get('lastName')!.errors)">
                  <span *ngIf="profileForm.get('lastName')!.errors!['required']">
                    Last Name is required
                  </span>
                </div>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block">
                <input type="text" class="form-control" placeholder=" " formControlName="email" readonly maxlength="50" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Phone" formControlName="phoneNumber" maxlength="50" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="input-block next">
                <input type="text" class="form-control" placeholder="NP Number" formControlName="npNumber" maxlength="50" />
                <div class="help-block" *ngIf="((profileForm.get('npNumber')!.touched ||
                               profileForm.get('npNumber')!.dirty) &&
                               profileForm.get('npNumber')!.errors)">
                  <span *ngIf="profileForm.get('npNumber')!.errors!['required']">
                    NP Number is required
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="auth-block" *ngIf="profileForm.get('tenantTypeId')?.value==2">
          <b>Carrier Details</b>
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Carrier Name" formControlName="carrierName" maxlength="50" />
                
              </div>

            </div>
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Address 1" formControlName="carrierAddress1" maxlength="50" />
                
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Address 2" formControlName="carrierAddress2" maxlength="50" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Address 3" formControlName="carrierAddress3" maxlength="50" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="input-block next">
                <input type="text" class="form-control" placeholder="City" formControlName="carrierCity" maxlength="50" />
                


              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="input-block next">
                <input type="text" class="form-control" placeholder="Zip Code" formControlName="carrierZipCode" maxlength="5" (keypress)="sharedService.numberOnly($event)" />


              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block">
                <div class="select">
                  <select formControlName="carrierStateId">
                    <option *ngFor="let s of states" [ngValue]="s.id">{{ s.stateName }}</option>
                  </select>
                </div>
                <input type="hidden" class="form-control" formControlName="carrierId" />
              </div>
            </div>
          </div>



        </div>

        <div class="auth-block" *ngIf="profileForm.get('tenantTypeId')?.value==1 || profileForm.get('tenantTypeId')?.value==3">
          <b>Branch Details</b>
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Branch Name" formControlName="branchName" maxlength="50" />
                
              </div>

            </div>
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Address 1" formControlName="branchAddress1" maxlength="50" />
                
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Address 2" formControlName="branchAddress2" maxlength="50" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Address 3" formControlName="branchAddress3" maxlength="50" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="input-block next">
                <input type="text" class="form-control" placeholder="City" formControlName="branchCity" maxlength="50" />
                


              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="input-block next">
                <input type="text" class="form-control" placeholder="Zip Code" formControlName="branchZipCode" maxlength="5" (keypress)="sharedService.numberOnly($event)" />


              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block">
                <div class="select">
                  <select formControlName="branchStateId">
                    <option *ngFor="let s of states" [ngValue]="s.id">{{ s.stateName }}</option>
                  </select>
                </div>
                <input type="hidden" class="form-control" formControlName="branchId" />
              </div>
            </div>
          </div>



        </div>

        <div class="auth-block">
          <b>Tenant Details</b>
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Business Name" formControlName="businessName" maxlength="50" />
                <div class="help-block" *ngIf="((profileForm.get('businessName')!.touched ||
                               profileForm.get('businessName')!.dirty) &&
                               profileForm.get('businessName')!.errors)">
                  <span *ngIf="profileForm.get('businessName')!.errors!['required']">
                    Business Name is required
                  </span>
                </div>
              </div>

            </div>
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Address 1" formControlName="addressLine1" maxlength="50" />
                <div class="help-block" *ngIf="((profileForm.get('addressLine1')!.touched ||
                               profileForm.get('addressLine1')!.dirty) &&
                               profileForm.get('addressLine1')!.errors)">
                  <span *ngIf="profileForm.get('addressLine1')!.errors!['required']">
                    Address is required
                  </span>
                </div>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Address 2" formControlName="addressLine2" maxlength="50" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Address 3" formControlName="addressLine3" maxlength="50" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="input-block next">
                <input type="text" class="form-control" placeholder="City" formControlName="city" maxlength="50" />
                <div class="help-block" *ngIf="((profileForm.get('city')!.touched ||
                               profileForm.get('city')!.dirty) &&
                               profileForm.get('city')!.errors)">
                  <span *ngIf="profileForm.get('city')!.errors!['required']">
                    City is required
                  </span>

                </div>


              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="input-block next">
                <input type="text" class="form-control" placeholder="Zip Code" formControlName="zipCode" maxlength="5" (keypress)="sharedService.numberOnly($event)" />


              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block">
                <div class="select">
                  <select formControlName="stateId" >
                    <option *ngFor="let s of states" [ngValue]="s.id">{{ s.stateName }}</option>
                  </select>
                </div>
                <input type="hidden" class="form-control" formControlName="tenantId" />
              </div>
            </div>
          </div>
          <div>
            <validation-summary [validationErrors]="errorMessages"></validation-summary>
          </div>
          <div>

          </div>
          <div class="button-block">
            <button class="btn comm-btn" type="submit" [disabled]="!profileForm.valid">Save</button>
          </div>

        </div>


      </form>
    </div>
  </div>
</div>
