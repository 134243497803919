import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'api-services';
import { Subscription } from 'rxjs';
import { SharedService } from 'services';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {
  activationForm!: FormGroup;
  subscription: Subscription[] = [];
  //formErrors: any = {
  //  'email': '',
  //  'activationCode': ''
  //};
  //validationMessages: any = {

  //  'email': {
  //    'required': 'Email is required.',
  //    'pattern': 'Please provide valid Email ID'
  //  },
  //  'activationCode': {
  //    'required': 'Phone Number is required.'
  //  },
  //};
  constructor(private router: Router, private fb: FormBuilder, private route: ActivatedRoute, private accountService: AccountService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.activationForm = this.fb.group({
      activationCode: ['', [Validators.required]],
      //email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
    });
   
  }


  onSubmit(): void {

    this.activationForm.markAllAsTouched();
    if (this.activationForm.valid) {
      this.subscription.push(this.accountService.userActivate(this.activationForm.value).subscribe(
        (response: any) => {
          if (response.id > 0) {
            this.sharedService.setValue('userId', response.id);
            this.router.navigate(['/setpwd']);
          }
        },
        (error: any) => {
          console.log(error);
        }
      ))
    }

  }


}
