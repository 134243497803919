<div class="main-layout-grid">
    <main id="main-container">
        <div class="header-main" [ngClass] = "{'half':collapseSidebar == true}">
            <app-header (sideNvValue)="sideNavValue($event)"></app-header>   
        </div>
    <div class="data-contents-layout">
        <div class="dash-sub-menu">
            <div class="container-fluid">
                <div class="sidebar-contentdata">
                    <div *ngIf="hideSideBar" class="sidebar-width" [ngClass] = "{'half':collapseSidebar == true}">
                        <app-sidebar (sideNvValue)="sideNavValue($event)"></app-sidebar>
                    </div>
                    <div class="contentdata-width" [ngClass] = "{'full-width':hideSideBar == false, 'full':collapseSidebar == true}">                         
                        <router-outlet></router-outlet>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>
</div>