import { formatDate } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { RemindersService } from 'api-services/reminders.service';
import { Subscription } from 'rxjs';
import { SharedService } from 'services';
import { RoleService } from 'services/role.service';
import { tenantTypeCap } from 'shared/constant';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.css']
})
export class RemindersComponent implements OnInit {
  @Input() reminderDetails;
  reminderList: any;
  @Output() hideReminder = new EventEmitter<any>();
  @Output() updateCount = new EventEmitter<any>();
  requestedBy: number;
  isPast: boolean = false;
  status: boolean = false;
  reminderTypeList = [
    { id: false, status: "Active Reminders" },
    { id: true, status: "Dismissed Reminders" }
  ]
  selectedReminderTypeId: number = 0;
  userAppointmentId: number = 0
  userLicenseClassId: number = 0
  branchId: number = 0
  requesteTypeList: any;
  userId: number = 0;
  isMenuOpen: { [key: number]: boolean } = {};
  tenantTypeId = localStorage['tenantTypeId'];
  tenantTypeCap = tenantTypeCap; 
  subscription: Subscription[] = [];
  loginUserId = JSON.parse(localStorage["userId"]);
  isTenantOwnerUser: boolean = false;
  message = 'false_false_ _ _There are no Reminders';
  constructor(private remindersService: RemindersService, private sharedService: SharedService, private roleService: RoleService) {

  }

  ngOnInit(): void {
    this.isTenantOwnerUser = this.roleService.isTenantOwnerUser;
    this.getReminderType();
  }

  @HostListener('document:click', ['$event'])

  handleOutsideClick(event: Event) {
    for (let key in this.isMenuOpen) {
      if (this.isMenuOpen.hasOwnProperty(key)) {
        this.isMenuOpen[key] = false;
      }
    }
  }

  toggleMenu(event: Event, reminderId: number) {
    event.stopPropagation();
    for (let key in this.isMenuOpen) {
      if (this.isMenuOpen.hasOwnProperty(key) && Number(key) != reminderId) {
        this.isMenuOpen[key] = false;
      }
    }
    this.isMenuOpen[reminderId] = !this.isMenuOpen[reminderId];
  }

  getReminderType() {
    this.subscription.push(this.remindersService.getReminderTypes().subscribe(
      (response) => {
        this.requesteTypeList = response;

        let reminderTypeProducerName = this.tenantTypeCap[this.tenantTypeId];
        let reminderTypeBranchName = this.tenantTypeId == 1 ? 'Agency' : 'Branch';

        this.requesteTypeList = this.requesteTypeList.map(x => {
          return {
            ...x,
            reminderTypeName: x.reminderTypeId == 1 ? reminderTypeProducerName : x.reminderTypeId == 4 ? reminderTypeBranchName : x.reminderTypeName
          }
        })
        // if(this.reminderDetails)
        // this.selectedReminderTypeId = this.requesteTypeList.reminderTypeId;
        this.getReminderList();
      },
      (error) => {
        console.log(error);
      }
    ));
  }

  getReminderList() {
    var requestedBy = this.sharedService.getValue("userId");
    if (this.reminderDetails != null) {
      this.userId = this.reminderDetails.userId;
      this.selectedReminderTypeId = this.reminderDetails.reminderTypeId;
      this.userAppointmentId = this.reminderDetails.reminderTypeId == 2 ? this.reminderDetails.userAppointmentId : 0;
      this.userLicenseClassId = this.reminderDetails.reminderTypeId == 3 ? this.reminderDetails.userLicenseClassId : 0;
      this.branchId = this.reminderDetails.reminderTypeId == 4 ? this.reminderDetails.branchId : 0;
    }

    this.subscription.push(this.remindersService.getReminders(this.userId, requestedBy, this.isPast, this.selectedReminderTypeId, this.userAppointmentId, this.userLicenseClassId, this.branchId).subscribe(
      (response) => {
        this.reminderList = response.map(reminder => {
          return {
            ...reminder,
            formattedDate: this.getFormattedDate(reminder.reminderDate),
            remainingDaysClass: this.GetReminderDueDaysColor(reminder.reminderDate),
            reminderDetail: null,
            createdDate: this.sharedService.convertUTCTimeToLocalTime(reminder.createdDate),
            isDismissed: false,
            dismissedDate: this.sharedService.convertUTCTimeToLocalTime(reminder.dismissedDate),
          };
        });
        this.reminderList?.length > 0 ? this.openBox(this.reminderList[0]) : '';
      },
      (error) => {
        console.log(error);
      }
    ));
  }

  onReminderTypeChange(event: any) {
    this.selectedReminderTypeId = event;
    this.getReminderList();
  }

  onChange() {
    this.message = this.isPast ? 'false_false_ _ _There are no Dismissed Reminders' : 'false_false_ _ _There are no Reminders';
    this.getReminderList();

  }

  ngOnChanges(changes: SimpleChanges) {
    changes['reminderDetails'].currentValue != null ? this.getReminderList() : '';
  }

  closeReminder() {
    this.sharedService.setUserReminderVisibility(false);
    this.sharedService.setAllReminderVisibility(false);

    this.hideReminder.emit(false);
  }

  getFormattedDate(dueDate: string): string {
    const today = new Date();
    const due = new Date(dueDate);

    const diffInTime = due.getTime() - today.getTime();
    const diffInDays = Math.ceil(diffInTime / 1000 / 60 / 60 / 24);

    if (diffInDays === 0) {
      return 'Today';
    } else if (diffInDays === 1) {
      return 'Tomorrow';
    } else {
      return formatDate(due, 'MMM dd, yyyy', 'en-US');
    }
  }

  openBox(reminder) {
    if (reminder?.reminderDetail == null) {
      this.subscription.push(this.remindersService.getReminderDetails(reminder.reminderId, reminder.reminderTypeId).subscribe(
        (response) => {
          reminder.reminderDetail = response;
        },
        (error) => {
          console.log(error);
        }
      ))
    } else {
      reminder.reminderDetail = null;
    }
  }

  dismissReminder(reminder) {
    this.subscription.push(this.remindersService.DismissReminder(reminder.reminderId).subscribe(
      (response) => {
        if (response) {
          reminder['isDismissed'] = true;
          const index = this.reminderList.findIndex(em => em.reminderId == reminder.reminderId);
          // this.updateCount.emit(this.reminderList[index].formattedDate);
          this.remindersService.deleteReminderCount(reminder);
          this.remindersService.updateReminderCount(true);
          setTimeout(() => this.reminderList.splice(index, 1), 800)
          
          this.sharedService.showToastMessage("Reminder dismissed successfully");
        }
      }
    ))
  }

  ngOnDestroy() {
    this.subscription.forEach(t => { t.unsubscribe() })
  }

  GetReminderDueDaysColor(dueDate: string): string{
    let currentDate = new Date();
    let due = new Date(dueDate);

    let diffInDays = Math.ceil((due.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);

    if (diffInDays == 0 || (diffInDays >= 0 && diffInDays <= 2)) {
       return "type-1";
    }
    else if (diffInDays >= 3 && diffInDays <= 6) {
      return "type-2";
    }
    else {
      return "type-3"
    }
  }
}
