import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'services';
import { IssueFeedbackService } from '../../../services/IssueFeedback.service';
import { IssueFeedback, IssueFeedbackTopic, IssueFeedbackType } from '../../../models/issue-feedback.model';

@Component({
  selector: 'app-issue-feedback',
  templateUrl: './issue-feedback.component.html',
  styleUrls: ['./issue-feedback.component.css']
})
export class IssueFeedbackComponent implements OnInit {
  @Output() hideIssueAndFeedback = new EventEmitter<boolean>();

  tabActive: string = '#2';
  isFormSubmited: boolean = false;
  activeTabId: number = 2;
  issueFeedbackForm: FormGroup;
  issueFeedbackTypes: IssueFeedbackType[] = [];
  issueFeedbackTopics: IssueFeedbackTopic[] = [];
  isLoader: boolean = false;

  constructor(
    private sharedService: SharedService,
    private fb: FormBuilder,
    private issueFeedbackService: IssueFeedbackService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.loadIssueFeedbackTypes();
  }

  initializeForm(): void {
    this.issueFeedbackForm = this.fb.group({
      selectTopic: [null, Validators.required],
      describe: ['', [Validators.required, Validators.maxLength(1000)]],
      sendEmail: [false, Validators.required]
    });
  }

  loadIssueFeedbackTypes(): void {
    this.issueFeedbackService.getIssueFeedbackTypes().subscribe(
      (types: IssueFeedbackType[]) => {
        this.issueFeedbackTypes = types;
        if (types.length > 0) {
          this.activeTabId = types[1].issueFeedbackTypeID;
          this.tabActive = `#${this.activeTabId}`;
          this.loadFeedbackTopics(this.activeTabId);
        }
      },
      (error) => {
        console.error('Error loading feedback types:', error);
      }
    );
  }

  getSelectedTab(event: any): void {
    this.isFormSubmited = false;
    this.tabActive = event.srcElement.hash || this.tabActive;
    this.activeTabId = Number(this.tabActive.substring(1));
    this.issueFeedbackForm.reset();

    if (this.activeTabId) {
      this.loadFeedbackTopics(this.activeTabId);
    }
  }

  loadFeedbackTopics(issueFeedbackTypeId: number): void {
    this.issueFeedbackService.getIssueFeedbackTopicsByType(issueFeedbackTypeId).subscribe(
      (topics: IssueFeedbackTopic[]) => {
        this.issueFeedbackTopics = topics;
      },
      (error) => {
        console.error('Error loading feedback topics:', error);
      }
    );
  }

  closeIssueFeedback(): void {
    this.hideIssueAndFeedback.emit(false);
  }

  submit(): void {
    if (this.issueFeedbackForm.valid) {
      this.isLoader = true;
      this.isFormSubmited = true;
      const issueFeedback = this.buildIssueFeedbackForm();

      this.issueFeedbackService.insertIssueFeedback(issueFeedback).subscribe(
        (response) => {
          //console.log('Issue feedback submitted successfully:', response);
          this.isLoader = false;
        },
        (error) => {
          console.error('Error submitting issue feedback:', error);
          this.isFormSubmited = false;
          this.isLoader = false;
        }
      );
    }
  }

  buildIssueFeedbackForm(): IssueFeedback {
    const tenantId = this.sharedService.getValue('tenantId');
    const userId = this.sharedService.getValue('userId');
    const sendEmailValue = this.issueFeedbackForm.value.sendEmail;

    return {
      issueFeedbackID: 0,
      issueFeedbackTypeID: this.activeTabId,
      tenantId: tenantId,
      topicID: this.issueFeedbackForm.value.selectTopic,
      description: this.issueFeedbackForm.value.describe,
      sendEmail: sendEmailValue === 'yes' ? true : false,
      ReceiverEmailId: this.sharedService.getValue('emailId'),
      ticketNumber: '',
      createdDate: null,
      createdBy: userId
    };
  }
}
