import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {  RouterDataResolver } from 'services/router.resolver';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  hideSideBar = false;
  collapseSidebar:boolean = false;
  constructor(  private router: Router,
    private  routerDataResolver:  RouterDataResolver,
    ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.hideSideBar =  routerDataResolver.pageName;
   }
   sideNavValue(event){
    this.collapseSidebar = event;
  }
   ngOnInit(): void {
    this.collapseSidebar = JSON.parse(sessionStorage.getItem('collapseValue'));
  }
}