import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap"

export enum RoleType {
  Admin = 1,
  Contact = 2,
  AccountOwner = 3,
  GuestUser = 99
}

export enum TenantType {
  MGA = 1,
  Carrier = 2,
  Agency = 3
}

export enum AdminDashboardStoreProcedure {
  GetDashboardAppointmentSummary = 1,
  GetDashboardExpiringLicenseSummary = 2,
  GetDashboardLicenseSummary = 3,
  GetDashboardNIPRActivityCount = 4,
  GetDashboardScoreCard = 5,
  GetDashboardStateWiseActiveLicenseData = 6,
  GetDashboardUserSummary = 7,
  GetDashboardLicenseAppointmentGrowth = 8
}

export enum ContactDashboardStoreProcedure {
  GetContactDashboardActiveAppointmentList = 1,
  GetContactDashboardActiveLicenseList = 2,
  GetContactDashboardAppointmentSummary = 3,
  GetContactDashboardNIPRActivity = 4,
  GetContactDashboardLicenseData = 5,
  GetContactDashboardScoreCard = 6,
  GetContactDashboardBranchList = 7,
  GetContactPersonalInformationSummary = 8,
  GetContactLicenseAppointmentGrowth = 9,

}

export enum FirmDashboardStoreProcedure {
  GetFirmBranchInformationSummary = 1,
}

export enum SystemModule {
  Dashboard = 1,
  ContactDashboard = 2,
  Requests = 3,
  Contacts = 4,
  Licenses = 5,
  Appointments = 6,
  LineofAuthorities = 7,
  Setting = 8,
  DashboardContactScorecard = 9,
  DashboardContactDetailScorecard = 10,
  Reports = 11,
  TasksModule = 12,
  NotesModule = 13,
  Help = 14
}

export const ModulePermission = [
  {
    moduleId: SystemModule.Dashboard,
    allowedRole: [RoleType.AccountOwner, RoleType.Admin]
  },
  {
    moduleId: SystemModule.NotesModule,
    allowedRole: [RoleType.AccountOwner, RoleType.Admin]
  },
  {
    moduleId: SystemModule.ContactDashboard,
    allowedRole: [RoleType.AccountOwner, RoleType.Admin, RoleType.Contact]
  },
  {
    moduleId: SystemModule.Requests,
    allowedRole: [RoleType.AccountOwner, RoleType.Admin, RoleType.Contact]
  },
  {
    moduleId: SystemModule.Contacts,
    allowedRole: [RoleType.AccountOwner, RoleType.Admin]
  },
  {
    moduleId: SystemModule.DashboardContactScorecard,
    allowedRole: [RoleType.AccountOwner, RoleType.Admin, RoleType.Contact]
  },
  {
    moduleId: SystemModule.Appointments,
    allowedRole: [RoleType.AccountOwner, RoleType.Admin, RoleType.Contact]
  },
  {
    moduleId: SystemModule.LineofAuthorities,
    allowedRole: [RoleType.AccountOwner, RoleType.Admin]
  },
  {
    moduleId: SystemModule.Setting,
    allowedRole: [RoleType.AccountOwner, RoleType.Admin, RoleType.Contact]
  },
  {
    moduleId: SystemModule.DashboardContactScorecard,
    allowedRole: [RoleType.AccountOwner, RoleType.Admin, RoleType.Contact]
  },
  {
    moduleId: SystemModule.DashboardContactDetailScorecard,
    allowedRole: [RoleType.AccountOwner, RoleType.Admin, RoleType.Contact]
  },
]

export const manageCarrier = {
  manage: 'manage',
  carrierList: 'manage-carrier-list',
  addCarrier: 'add-carrier',
  editCarrier: 'edit-carrier',
  appointerList: 'appointer-list',
  addAppointer: 'add-appointer',
  editAppointer: 'edit-appointer',

}
export const tenantType = {
  1: 'agent',
  2: 'agent',
  3: 'producer',
}
export const tenantTypeCap = {
  1: 'Agent',
  2: 'Agent',
  3: 'Producer',
}
export const TenantTypeName = {
  1: 'MGA',
  2: 'Carrier',
  3: 'Agency'
}
export const storageAccount = {
  dropBox: 1
  //, googleDrive: 2, oneDrive: 3, azure: 4
};

export enum LicenseRenewalTransactionType {
  LicenseRenewal = 2
}

export enum ActivityLogType {
  Action = 0,
  Comment = 1,
  NIPRNotification = 2
};

export const ngbModalOptionsSM: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: "sm",
  centered: true
};
export const ngbModalOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true
};
export const ngbModalOptionsClass: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  windowClass: 'right-side-panel',
  centered: true
};
export const ngbModalOptionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: "lg",
  centered: true
};
export const ngbModalOptionsXLClass: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: "xl",
  windowClass: 'top-modal'
};

export const ngbModalOptionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: "xl",
  centered: true
};

export const ngbModalOptionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: "xl",
  centered: true,
  windowClass: 'modal-xxl',
};

export const ngbModalOptionsCenter: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true
};

export enum FileCategory {
  License = 1,
  Appointment = 2,
  Notes = 3,
  CE = 4,
  Carrier = 5,
  Branches = 6,
  ResourceLibrary = 7,
  Producer = 8
}

export enum BatchType {
  LicenseRenewal = 1,
  NewAppointmnt = 2
}

export enum TagType {
  Producer = 1,
  License = 2,
  Appointment = 3,
  Carrier = 4,
  Branch = 5
}

export const TaskStatus = new Map<number, string>([
  [1, 'New'],
  // [2, 'In Progress'],
  [3, 'Resolved']
]);

export const TaskPriority = new Map<number, string>([
  [3, 'High'],
  [2, 'Medium'],
  [1, 'Low']
  
]);

export enum TaskType {
  Producer = 1,
  License = 2,
  Appointment = 3,
  Carrier = 4,
  Branch = 5,
  Other = 6,
  System = 7
}

