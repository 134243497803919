<div class="input-block custom-dropdown-control tag-control" *ngIf="tags?.length > 0" >
    <label class="input-label">Tag selection</label>
    <div class="selected-states">
        <span *ngFor="let Tags of selectedTags">
            {{Tags.description}}
            <a (click)="onSelectionChange(Tags, false)">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 28 28">
                    <rect width="28" height="28" rx="6" fill="none" />
                    <g transform="translate(4 4)">
                        <rect width="20" height="20" fill="none" />
                        <path
                            d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z"
                            transform="translate(-0.005 0.005)" fill="#757575" />
                    </g>
                </svg>
            </a>
        </span>
        <span class="select-states" *ngIf="selectedTags.length == 0">Selected Tag(s)</span>
    </div>
    <div class="unselect-states tag-list">
        <!-- <div class="select-all">
            <input [ngClass]="{'clear-all' : selectedTags.length == this.tags.length}" type="checkbox" class="mr-2"
                (change)="onSelectionChange(0,selectedTags.length != this.tags.length ? true: false)">
            <label *ngIf="selectedTags.length != this.tags.length">Select All</label>
            <label *ngIf="selectedTags.length == this.tags.length">Clear All</label>
        </div> -->
        <div class="row m-0">
            <span class="col-6 " *ngFor="let tags of unSelectedTags; let i = index">
                <input type="checkbox" name="tags" (change)="onSelectionChange(tags, true)">
                <label for=""> {{ tags.description }}</label>
            </span>
        </div>
    </div>
</div>
<!-- <div class="no-tags" *ngIf="(tags?.length <= 0 && activeBlock)">
    <h3>Tag(s) Not Defined</h3>
    <p>You have not defined any Tags yet. Please go to <span><a href="javascript:void(0)" (click)="redirectToManageTags()"> Settings->Manage-> Manage Tags </a></span> to create custom Tags.</p>
</div> -->
<!-- *ngIf="(unSelectedTags == null || (unSelectedTags != null && unSelectedTags.length <= 0))"
 *ngIf="unSelectedTags != null && unSelectedTags.length > 0" -->