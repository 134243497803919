import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Branch } from "components/branch/models/branch.model";
import { ValidatorHelper } from "components/custom-validators/validators.helper";
import { County } from "models/County.model";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { ContactList } from "./models/contact-list.model";

export class ContactListBase {
  errorMessages = [];
  id: number = 0;
  userId: number = 0;
  pageNumber: number = 1;
  selectDeselect: string="checked";
  pageSize: number = parseInt(environment.pageSize);
  totalCount: number = 0;
  fromRecord: number = 0;
  toRecord: number = 0;

  totalActiveAdmins: number = 0;
  subTotalActiveAdmins: number = 1;
  totalActiveContacts: number = 0;
  subTotalActiveContacts: number = 0;

  bulkShowBranchDropDown:string = "hidden";
  bulkShowAddButton:string = "hidden";
  bulkShowEditButton:string = "hidden";
  bulkShowSaveButton:string = "hidden";
  bulkShowBranchtext:string = "hidden";
  bulkShowCancelButton:string = "hidden";

  search: string = "hidden";
  showSaveButton: string = "hidden";
  showEditButton: string = "hidden";
  showAddButton: string = "hidden";
  showBranchDropDown: string = "hidden";
  showBranchtext: string = "hidden";
  showCancelButton: string = "hidden";
  showDeleteActionButton: string = "disabled";
  showAssignActionButton: string = "disabled";
  showInviteActionButton: string = "disabled";
  showBulkAppointmentButton: string = "disabled";
  showNameWithoutEmail: any = [];
  subscription: Subscription[] = [];
  contactList: ContactList[] = [];
  selectedContactList: ContactList[] = [];
  contact: ContactList;
  branchList: Branch[] = [];
  countyList: County[] = [];
  tenantStatusList: any;
  contactForm: FormGroup;
  bulkImportForm: FormGroup;
  emailForm: FormGroup;
  invitationForm: FormGroup;
  archiveContactForm: FormGroup;
  assignForm: FormGroup;
  listForm: FormGroup;
  makeAdminForm: FormGroup;
  makeAdminBindFormGroup(): FormGroup {
    return new FormGroup({
      userId: new FormControl(0, []),
      tenantId: new FormControl(0, []),
      userById: new FormControl(0, []),
    });
  }
  invitBindFormGroup(): FormGroup {
    return new FormGroup({
      userIds: new FormControl('', []),
      tenantId: new FormControl(0, []),
      emails: new FormControl('', []),
      activationCode: new FormControl('', []),
      userById: new FormControl(0, []),
      IsSendActivation: new FormControl(0, [])
    });
  }

  assignBindFormGroup(): FormGroup {
    return new FormGroup({
      tenantId: new FormControl(0, []),
      branchId: new FormControl(null, [Validators.required]),
      userById: new FormControl(0, []),
      userIds: new FormControl('', [])
    });
  }

  emailBindFormGroup(): FormGroup {
    return new FormGroup({
      userId: new FormControl(0, []),
      tenantId: new FormControl(0, []),
      email: new FormControl('', [Validators.required, ValidatorHelper.emailPattern]),
      activationCode: new FormControl('', []),
      userById: new FormControl(0, []),
      statusId: new FormControl(0, []),
      IsSendActivation: new FormControl(0, [])
    });
  }

  bindFormGroup(): FormGroup {
    return new FormGroup({
      tenantId: new FormControl(0, []),
      branchId: new FormControl(null, []),
      branchName: new FormControl('', []),
      userById: new FormControl(0, []),
      npnNumber: new FormControl('', [])
    });
  }

  listBindFormGroup(): FormGroup {
    return new FormGroup({
      tenantId: new FormControl(0, []),
      pageNum: new FormControl('', []),
      pageSize: new FormControl('', []),
      search: new FormControl('', []),
      status: new FormControl(0, []),
      branch: new FormControl(0, []),
      sort: new FormControl('u.[RegistrationDate] DESC', []),
      //contacts: new FormControl('', []),
    });
  }

  bindBulkImportFormGroup(): FormGroup {
    return new FormGroup({
      tenantId: new FormControl(0, []),
      branchId: new FormControl(null, []),
      branchName: new FormControl('', []),
      npnNumber: new FormControl('', []),
      uploadFileName: new FormControl('', []),
      userById: new FormControl(0, [])
    });
  }

  archiveBindFormGroup(): FormGroup {
    return new FormGroup({
      userIds: new FormControl('', []),
      tenantId: new FormControl(0, []),
      userById: new FormControl(0, []),
      archiveAgree: new FormControl(false, Validators.requiredTrue),
      feeAgree: new FormControl(false, Validators.requiredTrue),
      password: new FormControl('',[])
    });
  }
}
