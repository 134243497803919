import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchTaskAssignment } from '../models/tasks/search-task-assignment';
import { TaskAssignmentGroup } from '../models/tasks/task-assignment-group';
import { TaskAssignmentGroupDetail } from '../models/tasks/task-assignment-group-detail';
import { TaskReasonCode } from '../models/tasks/task-reason-code';
import { ArchiveTaskAssignmentGroupRequest } from './../models/tasks/archive-task-assignment-group-request';
import { ApiClientService } from './common/api-client.service';


@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private apiClient: ApiClientService) { }

  insertUpdateTaskReasonCode(taskReason: TaskReasonCode): Observable<number> {
    return this.apiClient.post('api/task/taskReasonCode', taskReason);
  }

  insertUpdateTaskAssignmentGroup(group: TaskAssignmentGroup): Observable<number> {
    return this.apiClient.post('api/task/insertUpdateTaskAssignmentGroup', group);
  }

  insertArchiveTaskAssignmentGroupDetail(details: TaskAssignmentGroupDetail[]): Observable<number> {
    return this.apiClient.post('api/task/insertArchiveTaskAssignmentGroupDetail', details);
  }

  archiveUnarchiveTaskAssignmentGroup(request: ArchiveTaskAssignmentGroupRequest): Observable<number> {
    return this.apiClient.post('api/task/archiveUnarchiveTaskAssignmentGroup', request);
  }

  getTaskAssignmentGroups(archived: boolean = null): Observable<TaskAssignmentGroup[]> {
    let params = new HttpParams();

    if (archived !== null && archived !== undefined) {
      params = params.set('archived', archived);
    }

    return this.apiClient.get(`api/task/getTaskAssignmentGroups`, null, params);
  }

  getUsersForTaskAssignmentGroup(taskAssignmentGroupId: number = null, searchName: string = null): Observable<TaskAssignmentGroupDetail[]> {
    let params = new HttpParams();

    if (taskAssignmentGroupId !== null && taskAssignmentGroupId !== undefined) {
      params = params.set('taskAssignmentGroupId', taskAssignmentGroupId.toString());
    }

    if (searchName !== null && searchName !== undefined) {
      params = params.set('searchName', searchName);
    }
    return this.apiClient.get(`api/task/getTenantUsers`, null, params);
  }

  getTaskAssigneeList(): Observable<any> {
    return this.apiClient.get(`api/task/getTaskAssigneeList`, null, null, false);
  }

  getAllTaskTypes(): Observable<TaskType[]> {
    return this.apiClient.get(`api/task/getTaskTypes`, null, null, false);
  }

  getTaskReasonCodesByTaskType(taskTypeId: number, isArchive: boolean = false, fromTaskCreation: boolean = false): Observable<TaskReasonCode[]> {
    return this.apiClient.get(`api/task/getTaskReasonCodes/${taskTypeId}/${isArchive}/${fromTaskCreation}`, null, null, false);
  }

  createTask(formData: FormData): Observable<number> {
    const headers = new HttpHeaders().append('enctype', 'multipart/form-data');
    return this.apiClient.post('api/task/insertTask', formData, headers);
  }

  getTasks(searchTaskAssignment: SearchTaskAssignment): Observable<Task[]> {
    return this.apiClient.post('api/task/getTasks', searchTaskAssignment);
  }

  getTaskById(taskId: number): Observable<Task> {
    return this.apiClient.get(`api/task/getTaskById/${taskId}`, null, null, true);
  }

  updateTask(task: Task): Observable<number> {
    return this.apiClient.post('api/task/updateTask', task);
  }

  updateTaskResolution(task: Task): Observable<number> {
    return this.apiClient.post('api/task/updateTaskResolution', task, null, true);
  }

  deleteTask(taskId: number): Observable<number> {
    return this.apiClient.post(`api/task/DeleteTask/${taskId}`, null, null, true);
  }

  getTaskTypes(): Observable<any> {
    return this.apiClient.get(`api/task/getTaskTypes`, null, null, false);
  }

  getTaskReasonCodes(taskTypeId: number): Observable<any> {
    return this.apiClient.get(`api/task/getTaskReasonCodes/${taskTypeId}`, null, null, false);
  }

  insertTaskReasonCode(reasonCode: any): Observable<any> {
    return this.apiClient.post(`api/task/taskReasonCode`, reasonCode, null, false);
  }

  archiveUnarchiveReasonCode(reasonCode: number, isArchive: boolean): Observable<any> {
    return this.apiClient.post(`api/task/archiveUnarchiveReasonCode/${reasonCode}/${isArchive}`, null, null, false);
  }

  getTaskResolutionCodes(reasonId: number): Observable<any> {
    return this.apiClient.get(`api/task/getTaskResolutionCodes/${reasonId}`, null, null, false);
  }

  insertTaskResolutionCode(resolutionCode: any): Observable<any> {
    return this.apiClient.post(`api/task/taskResolutionCode`, resolutionCode, null, false);
  }

  copyTaskReasonCode(reasonCode: any): Observable<any> {
    return this.apiClient.post(`api/task/copyTaskReasonCode`, reasonCode, null, false);
  }

  archiveUnarchiveResolutionCode(resolutionCode: number, isArchive: boolean): Observable<any> {
    return this.apiClient.post(`api/task/archiveUnarchiveResolutionCode/${resolutionCode}/${isArchive}`, null, null, false);
  }

  getUnreadTaskCount(): Observable<number> {
    return this.apiClient.get(`api/task/getUnreadTaskCount`, null, null, false);
  }

  deleteAttachment(taskAttachmentId: number): Observable<number> {
    return this.apiClient.post(`api/task/deleteTaskAttachment/${taskAttachmentId}`, null, null, false);
  }

  getTaskAttachments(taskId: number): Observable<any> {
    return this.apiClient.get(`api/task/GetTaskAttachmentsByTaskId/${taskId}`, null, null, false);
  }


  downloadTaskFile(taskAttachmentId: number): Observable<any> {
    return this.apiClient.get(`api/task/downloadTaskFile/${taskAttachmentId}`, null, null, false);
  }

  uploadTaskAttachments(formData: FormData): Observable<number> {
    const headers = new HttpHeaders().append('enctype', 'multipart/form-data');
    return this.apiClient.post('api/task/uploadTaskAttachments', formData, headers);
  }

  resolveSystemGeneratedTask(taskForm: any): Observable<number> {
    return this.apiClient.put('api/task/resolveAlertForMissingFEIN', taskForm, undefined, true);
  }

  saveTaskResolution(task: Task): Observable<number> {
    return this.apiClient.post('api/task/saveTaskResolution', task, null, true);
  }

}
