<div class="outerBound" #myBounds>
<div class="ai-ag-container" ngDraggable [handle]="AIHandle"  [class.full-screen-view]="isFullScreenView"
(edge)="checkEdge($event)" [bounds]="myBounds" [inBounds]="inBounds" [outOfBounds]="myOutOfBounds">
  <div #AIHandle class="d-flex justify-content-between ai-top-header">
    <!-- <h5>Agenzee AI</h5> -->
    <img src="../../../../assets/images/agenzeeAI-logo.svg" width="90px">    
  </div>
  <div class="chatgpt-app">
  <!-- Left Sidebar -->
  <div class="sidebar" [class.syncBar]="!sidebarExpanded">
    <div class="sidebar-header d-flex justify-content-between align-items-center">
      <span (click)="syncExpandSidebar()">
        <svg class="ic-expand" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_16919_7147" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_16919_7147)">
        <path d="M16.048 14.502V9.498C16.048 9.29033 15.956 9.14808 15.772 9.07125C15.588 8.99425 15.4223 9.0295 15.275 9.177L13.0845 11.3672C12.9038 11.5481 12.8135 11.759 12.8135 12C12.8135 12.241 12.9038 12.4519 13.0845 12.6328L15.275 14.823C15.4223 14.9705 15.588 15.0057 15.772 14.9288C15.956 14.8519 16.048 14.7097 16.048 14.502ZM5.30775 20.5C4.80908 20.5 4.38308 20.3234 4.02975 19.9703C3.67658 19.6169 3.5 19.1909 3.5 18.6923V5.30775C3.5 4.80908 3.67658 4.38308 4.02975 4.02975C4.38308 3.67658 4.80908 3.5 5.30775 3.5H18.6923C19.1909 3.5 19.6169 3.67658 19.9703 4.02975C20.3234 4.38308 20.5 4.80908 20.5 5.30775V18.6923C20.5 19.1909 20.3234 19.6169 19.9703 19.9703C19.6169 20.3234 19.1909 20.5 18.6923 20.5H5.30775ZM8 19V5H5.30775C5.23075 5 5.16025 5.03208 5.09625 5.09625C5.03208 5.16025 5 5.23075 5 5.30775V18.6923C5 18.7692 5.03208 18.8398 5.09625 18.9038C5.16025 18.9679 5.23075 19 5.30775 19H8ZM9.5 19H18.6923C18.7692 19 18.8398 18.9679 18.9038 18.9038C18.9679 18.8398 19 18.7692 19 18.6923V5.30775C19 5.23075 18.9679 5.16025 18.9038 5.09625C18.8398 5.03208 18.7692 5 18.6923 5H9.5V19Z" fill="#424242"/>
        </g>
        </svg>
      </span>
      <button type="button" class="btn secondary-btn btn-sm" (click)="createNewChat()">
        <span *ngIf="sidebarExpanded">New Chat</span>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 15.625C9.81944 15.625 9.67028 15.5661 9.5525 15.4483C9.43417 15.33 9.375 15.1806 9.375 15V10.625H5C4.81944 10.625 4.67028 10.5658 4.5525 10.4475C4.43417 10.3297 4.375 10.1806 4.375 10C4.375 9.81944 4.43417 9.67 4.5525 9.55167C4.67028 9.43389 4.81944 9.375 5 9.375H9.375V5C9.375 4.81944 9.43417 4.67028 9.5525 4.5525C9.67028 4.43417 9.81944 4.375 10 4.375C10.1806 4.375 10.33 4.43417 10.4483 4.5525C10.5661 4.67028 10.625 4.81944 10.625 5V9.375H15C15.1806 9.375 15.33 9.43389 15.4483 9.55167C15.5661 9.67 15.625 9.81944 15.625 10C15.625 10.1806 15.5661 10.3297 15.4483 10.4475C15.33 10.5658 15.1806 10.625 15 10.625H10.625V15C10.625 15.1806 10.5661 15.33 10.4483 15.4483C10.33 15.5661 10.1806 15.625 10 15.625Z" fill="#0068D8"/>
          </svg>
          
      </button>
    </div>
    <div class="session-list" *ngIf="sidebarExpanded" (scroll)="onScroll()">
      <div *ngIf="groupedSessions?.today?.length">
        <h4>Today</h4>
        <ul>
          <li *ngFor="let chatSession of groupedSessions.today"
              (click)="loadChatMessages(chatSession)"
              [ngClass]="{ active: selectedSession?.chatSessionId === chatSession.chatSessionId }">
            <div class="session-item">
              <span class="session-name" [title]="chatSession.sessionName" *ngIf="!chatSession.isEditing" >
                {{ chatSession.sessionName }}
              </span>
              <input *ngIf="chatSession.isEditing" autofocus
                     [(ngModel)]="chatSession.sessionName"
                     (blur)="renameSession(chatSession)"
                     (keyup.enter)="renameSession(chatSession)" />

                     <div class="dropdown ellipsis-menu">
                      <button class="dropbtn" (click)="toggleMenu($event, chatSession.chatSessionId)">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 16.0577C9.65625 16.0577 9.36201 15.9352 9.11729 15.6904C8.87243 15.4457 8.75 15.1514 8.75 14.8077C8.75 14.4639 8.87243 14.1696 9.11729 13.9248C9.36201 13.68 9.65625 13.5577 10 13.5577C10.3438 13.5577 10.638 13.68 10.8827 13.9248C11.1276 14.1696 11.25 14.4639 11.25 14.8077C11.25 15.1514 11.1276 15.4457 10.8827 15.6904C10.638 15.9352 10.3438 16.0577 10 16.0577ZM10 11.25C9.65625 11.25 9.36201 11.1275 9.11729 10.8827C8.87243 10.638 8.75 10.3437 8.75 9.99997C8.75 9.65622 8.87243 9.36198 9.11729 9.11726C9.36201 8.8724 9.65625 8.74997 10 8.74997C10.3438 8.74997 10.638 8.8724 10.8827 9.11726C11.1276 9.36198 11.25 9.65622 11.25 9.99997C11.25 10.3437 11.1276 10.638 10.8827 10.8827C10.638 11.1275 10.3438 11.25 10 11.25ZM10 6.44226C9.65625 6.44226 9.36201 6.3199 9.11729 6.07518C8.87243 5.83032 8.75 5.53601 8.75 5.19226C8.75 4.84851 8.87243 4.55427 9.11729 4.30955C9.36201 4.06469 9.65625 3.94226 10 3.94226C10.3438 3.94226 10.638 4.06469 10.8827 4.30955C11.1276 4.55427 11.25 4.84851 11.25 5.19226C11.25 5.53601 11.1276 5.83032 10.8827 6.07518C10.638 6.3199 10.3438 6.44226 10 6.44226Z"
                                fill="#0068D8" />
                        </svg>
                      </button>
                      <div class="dropdown-content" [class.show]="isMenuOpen[chatSession.chatSessionId]">
                        <div class="d-flex align-items-center set-actions" (click)="enableEditSession(chatSession)" data-toggle="modal"
                             data-target="#edit-appointment">
                          <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.1499 19.0002H6.3999L15.6499 9.7502L14.4249 8.5002L5.1499 17.7752V19.0002ZM18.8499 8.6502L15.4749 5.3002L16.7999 4.0002C17.0832 3.71686 17.4376 3.5752 17.8629 3.5752C18.2876 3.5752 18.6416 3.71686 18.9249 4.0002L20.1499 5.2252C20.4332 5.5252 20.5832 5.8792 20.5999 6.2872C20.6166 6.69586 20.4749 7.04186 20.1749 7.3252L18.8499 8.6502ZM4.5499 20.5002C4.2999 20.5002 4.08757 20.4129 3.9129 20.2382C3.73757 20.0629 3.6499 19.8502 3.6499 19.6002V17.5002C3.6499 17.3835 3.6709 17.2712 3.7129 17.1632C3.75424 17.0545 3.8249 16.9502 3.9249 16.8502L14.3999 6.3752L17.7749 9.7502L7.2999 20.2252C7.1999 20.3252 7.0959 20.3962 6.9879 20.4382C6.87924 20.4795 6.76657 20.5002 6.6499 20.5002H4.5499ZM15.0249 9.1252L14.4249 8.5002L15.6499 9.7502L15.0249 9.1252Z" fill="#212121" />
                            </svg>
                          </span>
                          <span class="ml-2 action-name">Rename</span>
                        </div>
                        <div class="d-flex align-items-center set-actions" (click)="deleteSession(chatSession, $event)">
                          <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 20.5C6.8 20.5 6.375 20.325 6.025 19.975C5.675 19.625 5.5 19.2 5.5 18.7V6H5.25C5.03333 6 4.85433 5.929 4.713 5.787C4.571 5.64567 4.5 5.46667 4.5 5.25C4.5 5.03333 4.571 4.85433 4.713 4.713C4.85433 4.571 5.03333 4.5 5.25 4.5H9C9 4.25 9.08733 4.04167 9.262 3.875C9.43733 3.70833 9.65 3.625 9.9 3.625H14.1C14.35 3.625 14.5627 3.70833 14.738 3.875C14.9127 4.04167 15 4.25 15 4.5H18.75C18.9667 4.5 19.146 4.571 19.288 4.713C19.4293 4.85433 19.5 5.03333 19.5 5.25C19.5 5.46667 19.4293 5.64567 19.288 5.787C19.146 5.929 18.9667 6 18.75 6H18.5V18.7C18.5 19.2 18.325 19.625 17.975 19.975C17.625 20.325 17.2 20.5 16.7 20.5H7.3ZM7 6V18.7C7 18.7833 7.02933 18.8543 7.088 18.913C7.146 18.971 7.21667 19 7.3 19H16.7C16.7833 19 16.8543 18.971 16.913 18.913C16.971 18.8543 17 18.7833 17 18.7V6H7ZM9.4 16.25C9.4 16.4667 9.471 16.646 9.613 16.788C9.75433 16.9293 9.93333 17 10.15 17C10.3667 17 10.546 16.9293 10.688 16.788C10.8293 16.646 10.9 16.4667 10.9 16.25V8.75C10.9 8.53333 10.8293 8.354 10.688 8.212C10.546 8.07067 10.3667 8 10.15 8C9.93333 8 9.75433 8.07067 9.613 8.212C9.471 8.354 9.4 8.53333 9.4 8.75V16.25ZM13.1 16.25C13.1 16.4667 13.1707 16.646 13.312 16.788C13.454 16.9293 13.6333 17 13.85 17C14.0667 17 14.246 16.9293 14.388 16.788C14.5293 16.646 14.6 16.4667 14.6 16.25V8.75C14.6 8.53333 14.5293 8.354 14.388 8.212C14.246 8.07067 14.0667 8 13.85 8C13.6333 8 13.454 8.07067 13.312 8.212C13.1707 8.354 13.1 8.53333 13.1 8.75V16.25ZM7 6V18.7C7 18.7833 7.02933 18.8543 7.088 18.913C7.146 18.971 7.21667 19 7.3 19H7V6Z" fill="#212121" />
                            </svg>
                          </span>
                          <span class="ml-2 action-name">Delete</span>
                        </div>
                      </div>
                    </div>
              <!-- <div class="session-actions">
                <button (click)="enableEditSession(chatSession)" *ngIf="!chatSession.isEditing">Rename</button>
                <button (click)="deleteSession(chatSession, $event)">Delete</button>
              </div> -->
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="groupedSessions?.yesterday?.length">
        <h4>Yesterday</h4>
        <ul>
          <li *ngFor="let chatSession of groupedSessions.yesterday"
              (click)="loadChatMessages(chatSession)"
              [ngClass]="{ active: selectedSession?.chatSessionId === chatSession.chatSessionId }">
            <div class="session-item">
              <span class="session-name" [title]="chatSession.sessionName" *ngIf="!chatSession.isEditing" >
                {{ chatSession.sessionName }}
              </span>
              <input *ngIf="chatSession.isEditing" autofocus
                     [(ngModel)]="chatSession.sessionName"
                     (blur)="renameSession(chatSession)"
                     (keyup.enter)="renameSession(chatSession)" />

              
                     <div class="dropdown ellipsis-menu">
                      <button class="dropbtn" (click)="toggleMenu($event, chatSession.chatSessionId)">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 16.0577C9.65625 16.0577 9.36201 15.9352 9.11729 15.6904C8.87243 15.4457 8.75 15.1514 8.75 14.8077C8.75 14.4639 8.87243 14.1696 9.11729 13.9248C9.36201 13.68 9.65625 13.5577 10 13.5577C10.3438 13.5577 10.638 13.68 10.8827 13.9248C11.1276 14.1696 11.25 14.4639 11.25 14.8077C11.25 15.1514 11.1276 15.4457 10.8827 15.6904C10.638 15.9352 10.3438 16.0577 10 16.0577ZM10 11.25C9.65625 11.25 9.36201 11.1275 9.11729 10.8827C8.87243 10.638 8.75 10.3437 8.75 9.99997C8.75 9.65622 8.87243 9.36198 9.11729 9.11726C9.36201 8.8724 9.65625 8.74997 10 8.74997C10.3438 8.74997 10.638 8.8724 10.8827 9.11726C11.1276 9.36198 11.25 9.65622 11.25 9.99997C11.25 10.3437 11.1276 10.638 10.8827 10.8827C10.638 11.1275 10.3438 11.25 10 11.25ZM10 6.44226C9.65625 6.44226 9.36201 6.3199 9.11729 6.07518C8.87243 5.83032 8.75 5.53601 8.75 5.19226C8.75 4.84851 8.87243 4.55427 9.11729 4.30955C9.36201 4.06469 9.65625 3.94226 10 3.94226C10.3438 3.94226 10.638 4.06469 10.8827 4.30955C11.1276 4.55427 11.25 4.84851 11.25 5.19226C11.25 5.53601 11.1276 5.83032 10.8827 6.07518C10.638 6.3199 10.3438 6.44226 10 6.44226Z"
                                fill="#0068D8" />
                        </svg>
                      </button>
                      <div class="dropdown-content" [class.show]="isMenuOpen[chatSession.chatSessionId]">
                        <div class="d-flex align-items-center set-actions" (click)="enableEditSession(chatSession)" data-toggle="modal"
                             data-target="#edit-appointment">
                          <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.1499 19.0002H6.3999L15.6499 9.7502L14.4249 8.5002L5.1499 17.7752V19.0002ZM18.8499 8.6502L15.4749 5.3002L16.7999 4.0002C17.0832 3.71686 17.4376 3.5752 17.8629 3.5752C18.2876 3.5752 18.6416 3.71686 18.9249 4.0002L20.1499 5.2252C20.4332 5.5252 20.5832 5.8792 20.5999 6.2872C20.6166 6.69586 20.4749 7.04186 20.1749 7.3252L18.8499 8.6502ZM4.5499 20.5002C4.2999 20.5002 4.08757 20.4129 3.9129 20.2382C3.73757 20.0629 3.6499 19.8502 3.6499 19.6002V17.5002C3.6499 17.3835 3.6709 17.2712 3.7129 17.1632C3.75424 17.0545 3.8249 16.9502 3.9249 16.8502L14.3999 6.3752L17.7749 9.7502L7.2999 20.2252C7.1999 20.3252 7.0959 20.3962 6.9879 20.4382C6.87924 20.4795 6.76657 20.5002 6.6499 20.5002H4.5499ZM15.0249 9.1252L14.4249 8.5002L15.6499 9.7502L15.0249 9.1252Z" fill="#212121" />
                            </svg>
                          </span>
                          <span class="ml-2 action-name">Rename</span>
                        </div>
                        <div class="d-flex align-items-center set-actions" (click)="deleteSession(chatSession, $event)">
                          <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 20.5C6.8 20.5 6.375 20.325 6.025 19.975C5.675 19.625 5.5 19.2 5.5 18.7V6H5.25C5.03333 6 4.85433 5.929 4.713 5.787C4.571 5.64567 4.5 5.46667 4.5 5.25C4.5 5.03333 4.571 4.85433 4.713 4.713C4.85433 4.571 5.03333 4.5 5.25 4.5H9C9 4.25 9.08733 4.04167 9.262 3.875C9.43733 3.70833 9.65 3.625 9.9 3.625H14.1C14.35 3.625 14.5627 3.70833 14.738 3.875C14.9127 4.04167 15 4.25 15 4.5H18.75C18.9667 4.5 19.146 4.571 19.288 4.713C19.4293 4.85433 19.5 5.03333 19.5 5.25C19.5 5.46667 19.4293 5.64567 19.288 5.787C19.146 5.929 18.9667 6 18.75 6H18.5V18.7C18.5 19.2 18.325 19.625 17.975 19.975C17.625 20.325 17.2 20.5 16.7 20.5H7.3ZM7 6V18.7C7 18.7833 7.02933 18.8543 7.088 18.913C7.146 18.971 7.21667 19 7.3 19H16.7C16.7833 19 16.8543 18.971 16.913 18.913C16.971 18.8543 17 18.7833 17 18.7V6H7ZM9.4 16.25C9.4 16.4667 9.471 16.646 9.613 16.788C9.75433 16.9293 9.93333 17 10.15 17C10.3667 17 10.546 16.9293 10.688 16.788C10.8293 16.646 10.9 16.4667 10.9 16.25V8.75C10.9 8.53333 10.8293 8.354 10.688 8.212C10.546 8.07067 10.3667 8 10.15 8C9.93333 8 9.75433 8.07067 9.613 8.212C9.471 8.354 9.4 8.53333 9.4 8.75V16.25ZM13.1 16.25C13.1 16.4667 13.1707 16.646 13.312 16.788C13.454 16.9293 13.6333 17 13.85 17C14.0667 17 14.246 16.9293 14.388 16.788C14.5293 16.646 14.6 16.4667 14.6 16.25V8.75C14.6 8.53333 14.5293 8.354 14.388 8.212C14.246 8.07067 14.0667 8 13.85 8C13.6333 8 13.454 8.07067 13.312 8.212C13.1707 8.354 13.1 8.53333 13.1 8.75V16.25ZM7 6V18.7C7 18.7833 7.02933 18.8543 7.088 18.913C7.146 18.971 7.21667 19 7.3 19H7V6Z" fill="#212121" />
                            </svg>
                          </span>
                          <span class="ml-2 action-name">Delete</span>
                        </div>
                      </div>
                    </div>

              <!-- <div class="session-actions">
                <button (click)="enableEditSession(chatSession)" *ngIf="!chatSession.isEditing">Rename</button>
                <button (click)="deleteSession(chatSession, $event)">Delete</button>
              </div> -->
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="groupedSessions?.thisMonth?.length">
        <h4>This Month</h4>
        <ul>
          <li *ngFor="let chatSession of groupedSessions.thisMonth"
              (click)="loadChatMessages(chatSession)"
              [ngClass]="{ active: selectedSession?.chatSessionId === chatSession.chatSessionId }">
            <div class="session-item">
              <span class="session-name" [title]="chatSession.sessionName" *ngIf="!chatSession.isEditing">
                {{ chatSession.sessionName }}
              </span>
              <input *ngIf="chatSession.isEditing" autofocus
                     [(ngModel)]="chatSession.sessionName"
                     (blur)="renameSession(chatSession)"
                     (keyup.enter)="renameSession(chatSession)" />


                     <div class="dropdown ellipsis-menu">
                      <button class="dropbtn" (click)="toggleMenu($event, chatSession.chatSessionId)">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 16.0577C9.65625 16.0577 9.36201 15.9352 9.11729 15.6904C8.87243 15.4457 8.75 15.1514 8.75 14.8077C8.75 14.4639 8.87243 14.1696 9.11729 13.9248C9.36201 13.68 9.65625 13.5577 10 13.5577C10.3438 13.5577 10.638 13.68 10.8827 13.9248C11.1276 14.1696 11.25 14.4639 11.25 14.8077C11.25 15.1514 11.1276 15.4457 10.8827 15.6904C10.638 15.9352 10.3438 16.0577 10 16.0577ZM10 11.25C9.65625 11.25 9.36201 11.1275 9.11729 10.8827C8.87243 10.638 8.75 10.3437 8.75 9.99997C8.75 9.65622 8.87243 9.36198 9.11729 9.11726C9.36201 8.8724 9.65625 8.74997 10 8.74997C10.3438 8.74997 10.638 8.8724 10.8827 9.11726C11.1276 9.36198 11.25 9.65622 11.25 9.99997C11.25 10.3437 11.1276 10.638 10.8827 10.8827C10.638 11.1275 10.3438 11.25 10 11.25ZM10 6.44226C9.65625 6.44226 9.36201 6.3199 9.11729 6.07518C8.87243 5.83032 8.75 5.53601 8.75 5.19226C8.75 4.84851 8.87243 4.55427 9.11729 4.30955C9.36201 4.06469 9.65625 3.94226 10 3.94226C10.3438 3.94226 10.638 4.06469 10.8827 4.30955C11.1276 4.55427 11.25 4.84851 11.25 5.19226C11.25 5.53601 11.1276 5.83032 10.8827 6.07518C10.638 6.3199 10.3438 6.44226 10 6.44226Z"
                                fill="#0068D8" />
                        </svg>
                      </button>
                      <div class="dropdown-content" [class.show]="isMenuOpen[chatSession.chatSessionId]">
                        <div class="d-flex align-items-center set-actions" (click)="enableEditSession(chatSession)" data-toggle="modal"
                             data-target="#edit-appointment">
                          <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.1499 19.0002H6.3999L15.6499 9.7502L14.4249 8.5002L5.1499 17.7752V19.0002ZM18.8499 8.6502L15.4749 5.3002L16.7999 4.0002C17.0832 3.71686 17.4376 3.5752 17.8629 3.5752C18.2876 3.5752 18.6416 3.71686 18.9249 4.0002L20.1499 5.2252C20.4332 5.5252 20.5832 5.8792 20.5999 6.2872C20.6166 6.69586 20.4749 7.04186 20.1749 7.3252L18.8499 8.6502ZM4.5499 20.5002C4.2999 20.5002 4.08757 20.4129 3.9129 20.2382C3.73757 20.0629 3.6499 19.8502 3.6499 19.6002V17.5002C3.6499 17.3835 3.6709 17.2712 3.7129 17.1632C3.75424 17.0545 3.8249 16.9502 3.9249 16.8502L14.3999 6.3752L17.7749 9.7502L7.2999 20.2252C7.1999 20.3252 7.0959 20.3962 6.9879 20.4382C6.87924 20.4795 6.76657 20.5002 6.6499 20.5002H4.5499ZM15.0249 9.1252L14.4249 8.5002L15.6499 9.7502L15.0249 9.1252Z" fill="#212121" />
                            </svg>
                          </span>
                          <span class="ml-2 action-name">Rename</span>
                        </div>
                        <div class="d-flex align-items-center set-actions" (click)="deleteSession(chatSession, $event)">
                          <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 20.5C6.8 20.5 6.375 20.325 6.025 19.975C5.675 19.625 5.5 19.2 5.5 18.7V6H5.25C5.03333 6 4.85433 5.929 4.713 5.787C4.571 5.64567 4.5 5.46667 4.5 5.25C4.5 5.03333 4.571 4.85433 4.713 4.713C4.85433 4.571 5.03333 4.5 5.25 4.5H9C9 4.25 9.08733 4.04167 9.262 3.875C9.43733 3.70833 9.65 3.625 9.9 3.625H14.1C14.35 3.625 14.5627 3.70833 14.738 3.875C14.9127 4.04167 15 4.25 15 4.5H18.75C18.9667 4.5 19.146 4.571 19.288 4.713C19.4293 4.85433 19.5 5.03333 19.5 5.25C19.5 5.46667 19.4293 5.64567 19.288 5.787C19.146 5.929 18.9667 6 18.75 6H18.5V18.7C18.5 19.2 18.325 19.625 17.975 19.975C17.625 20.325 17.2 20.5 16.7 20.5H7.3ZM7 6V18.7C7 18.7833 7.02933 18.8543 7.088 18.913C7.146 18.971 7.21667 19 7.3 19H16.7C16.7833 19 16.8543 18.971 16.913 18.913C16.971 18.8543 17 18.7833 17 18.7V6H7ZM9.4 16.25C9.4 16.4667 9.471 16.646 9.613 16.788C9.75433 16.9293 9.93333 17 10.15 17C10.3667 17 10.546 16.9293 10.688 16.788C10.8293 16.646 10.9 16.4667 10.9 16.25V8.75C10.9 8.53333 10.8293 8.354 10.688 8.212C10.546 8.07067 10.3667 8 10.15 8C9.93333 8 9.75433 8.07067 9.613 8.212C9.471 8.354 9.4 8.53333 9.4 8.75V16.25ZM13.1 16.25C13.1 16.4667 13.1707 16.646 13.312 16.788C13.454 16.9293 13.6333 17 13.85 17C14.0667 17 14.246 16.9293 14.388 16.788C14.5293 16.646 14.6 16.4667 14.6 16.25V8.75C14.6 8.53333 14.5293 8.354 14.388 8.212C14.246 8.07067 14.0667 8 13.85 8C13.6333 8 13.454 8.07067 13.312 8.212C13.1707 8.354 13.1 8.53333 13.1 8.75V16.25ZM7 6V18.7C7 18.7833 7.02933 18.8543 7.088 18.913C7.146 18.971 7.21667 19 7.3 19H7V6Z" fill="#212121" />
                            </svg>
                          </span>
                          <span class="ml-2 action-name">Delete</span>
                        </div>
                      </div>
                    </div>
              <!-- <div class="session-actions">
                <button (click)="enableEditSession(chatSession)" *ngIf="!chatSession.isEditing">Rename</button>
                <button (click)="deleteSession(chatSession, $event)">Delete</button>
              </div> -->
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Chat Container -->
  <div class="chat-container" [class.syncBar]="!sidebarExpanded">
    <div class="chat-header header-content d-flex justify-content-between align-items-center ">
      <h3 title="{{selectedSession?.sessionName}}">{{selectedSession?.sessionName}}</h3>
      <nav class="navbar navbar-expand-sm header-user-info-block">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-list-4" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="header-user-dropdown">
          <ul class="navbar-nav">
            <!-- <li class="nav-item info-icon">
            <a href="javascript:void(0)" class="help-url" (click)="syncExpandView()">
              <svg *ngIf="!isFullScreenView" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>Expand</title>
                <path d="M9.83398 18.1663H12.5423C12.7194 18.1663 12.8679 18.2263 12.9877 18.3461C13.1075 18.466 13.1673 18.6145 13.1673 18.7916C13.1673 18.9688 13.1075 19.1172 12.9877 19.2368C12.8679 19.3565 12.7194 19.4163 12.5423 19.4163H9.33732C9.12385 19.4163 8.94496 19.3441 8.80065 19.1997C8.65621 19.0554 8.58398 18.8765 8.58398 18.663V15.458C8.58398 15.2809 8.64392 15.1325 8.76378 15.0126C8.88364 14.8929 9.03211 14.833 9.20919 14.833C9.38641 14.833 9.53482 14.8929 9.6544 15.0126C9.77412 15.1325 9.83398 15.2809 9.83398 15.458V18.1663ZM18.1673 9.83301H15.459C15.2819 9.83301 15.1334 9.77308 15.0136 9.65322C14.8938 9.53335 14.834 9.38488 14.834 9.2078C14.834 9.03058 14.8938 8.88217 15.0136 8.76259C15.1334 8.64287 15.2819 8.58301 15.459 8.58301H18.664C18.8775 8.58301 19.0563 8.65523 19.2007 8.79968C19.3451 8.94398 19.4173 9.12287 19.4173 9.33634V12.5413C19.4173 12.7184 19.3574 12.8669 19.2375 12.9868C19.1177 13.1065 18.9692 13.1663 18.7921 13.1663C18.6149 13.1663 18.4665 13.1065 18.3469 12.9868C18.2272 12.8669 18.1673 12.7184 18.1673 12.5413V9.83301Z" fill="#9E9E9E"/>
                </svg>
                <svg *ngIf="isFullScreenView" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.9173 16.083H9.20898C9.0319 16.083 8.8835 16.0231 8.76378 15.9032C8.64392 15.7834 8.58398 15.6349 8.58398 15.4578C8.58398 15.2806 8.64392 15.1322 8.76378 15.0126C8.8835 14.8929 9.0319 14.833 9.20898 14.833H12.4142C12.6275 14.833 12.8064 14.9052 12.9509 15.0495C13.0952 15.1939 13.1673 15.3728 13.1673 15.5861V18.7913C13.1673 18.9684 13.1074 19.1168 12.9875 19.2366C12.8677 19.3564 12.7192 19.4163 12.5421 19.4163C12.3649 19.4163 12.2165 19.3564 12.0969 19.2366C11.9772 19.1168 11.9173 18.9684 11.9173 18.7913V16.083ZM16.084 11.9163H18.7923C18.9694 11.9163 19.1178 11.9763 19.2375 12.0961C19.3574 12.216 19.4173 12.3645 19.4173 12.5415C19.4173 12.7188 19.3574 12.8672 19.2375 12.9868C19.1178 13.1065 18.9694 13.1663 18.7923 13.1663H15.5871C15.3738 13.1663 15.1949 13.0942 15.0504 12.9499C14.9061 12.8054 14.834 12.6265 14.834 12.4132V9.20801C14.834 9.03092 14.8939 8.88252 15.0138 8.7628C15.1336 8.64294 15.2821 8.58301 15.4592 8.58301C15.6364 8.58301 15.7848 8.64294 15.9044 8.7628C16.0241 8.88252 16.084 9.03092 16.084 9.20801V11.9163Z" fill="#9E9E9E"/>
                </svg>
            </a>
          </li> -->
            <li class="nav-item info-icon dropdown">
              <a (click)="getUserChatToken()" class="nav-link dropdown-toggle user-short-name-link help-url" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.19141 15.25C8.84766 15.25 8.55342 15.1276 8.3087 14.8827C8.06384 14.638 7.94141 14.3438 7.94141 14C7.94141 13.6562 8.06384 13.362 8.3087 13.1173C8.55342 12.8724 8.84766 12.75 9.19141 12.75C9.53516 12.75 9.82946 12.8724 10.0743 13.1173C10.319 13.362 10.4414 13.6562 10.4414 14C10.4414 14.3438 10.319 14.638 10.0743 14.8827C9.82946 15.1276 9.53516 15.25 9.19141 15.25ZM13.9991 15.25C13.6554 15.25 13.3611 15.1276 13.1164 14.8827C12.8715 14.638 12.7491 14.3438 12.7491 14C12.7491 13.6562 12.8715 13.362 13.1164 13.1173C13.3611 12.8724 13.6554 12.75 13.9991 12.75C14.3429 12.75 14.6371 12.8724 14.8818 13.1173C15.1267 13.362 15.2491 13.6562 15.2491 14C15.2491 14.3438 15.1267 14.638 14.8818 14.8827C14.6371 15.1276 14.3429 15.25 13.9991 15.25ZM18.8068 15.25C18.4631 15.25 18.1688 15.1276 17.9239 14.8827C17.6792 14.638 17.5568 14.3438 17.5568 14C17.5568 13.6562 17.6792 13.362 17.9239 13.1173C18.1688 12.8724 18.4631 12.75 18.8068 12.75C19.1506 12.75 19.4448 12.8724 19.6895 13.1173C19.9344 13.362 20.0568 13.6562 20.0568 14C20.0568 14.3438 19.9344 14.638 19.6895 14.8827C19.4448 15.1276 19.1506 15.25 18.8068 15.25Z" fill="#9E9E9E" />
                </svg>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item d-flex" href="javascript:void()">
                  <span class="user-dropdown">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_16918_16994" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                        <rect width="24" height="24" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_16918_16994)">
                        <path d="M10.5497 18.1997L15.7247 11.9997H11.7247L12.4497 6.32471L7.82468 12.9997H11.2997L10.5497 18.1997ZM9.57667 14.4997H6.53443C6.19843 14.4997 5.95226 14.351 5.79593 14.0535C5.63943 13.7561 5.65868 13.4696 5.85368 13.194L12.4054 3.74221C12.5401 3.55371 12.7119 3.42479 12.9209 3.35546C13.1298 3.28629 13.347 3.29212 13.5727 3.37296C13.7983 3.45362 13.9634 3.59171 14.0679 3.78721C14.1724 3.98271 14.2112 4.19329 14.1842 4.41896L13.4324 10.4997H17.1632C17.5195 10.4997 17.7708 10.6594 17.9169 10.9787C18.0631 11.2979 18.0202 11.5953 17.7882 11.871L10.5439 20.5477C10.3991 20.7195 10.2222 20.8308 10.0132 20.8815C9.80418 20.932 9.60034 20.9117 9.40167 20.8207C9.20301 20.7297 9.04884 20.5906 8.93917 20.4035C8.82951 20.2163 8.78817 20.0099 8.81517 19.7842L9.57667 14.4997Z" fill="#1C1B1F" />
                      </g>
                    </svg>
                  </span>
                  <div class="ag-token">
                    <div>
                      Agenzee Tokens <br>
                      <span class="text-small">Next refill {{tokenRefillDiff}}.</span>
                    </div>
                    <span class="token-tag" *ngIf="userChatTokenDetails.tokenLimit">{{userChatTokenDetails.tokenUsage ? userChatTokenDetails.tokenUsage : 0}}<span class="font-light">/{{userChatTokenDetails.tokenLimit}}</span></span>
                  </div>

                </a>
              </div>
            </li>
            <li class="nav-item info-icon">
              <a href="javascript:void(0)" class="help-url" (click)="close()">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <title>Close</title>
                  <path d="M14.0007 14.8747L9.77148 19.1038C9.66037 19.215 9.51815 19.2738 9.34482 19.2805C9.17093 19.2877 9.02148 19.2288 8.89648 19.1038C8.77148 18.9788 8.70898 18.833 8.70898 18.6663C8.70898 18.4997 8.77148 18.3538 8.89648 18.2288L13.1257 13.9997L8.89648 9.77051C8.78537 9.6594 8.72648 9.5169 8.71982 9.34301C8.7126 9.16967 8.77148 9.02051 8.89648 8.89551C9.02148 8.77051 9.16732 8.70801 9.33398 8.70801C9.50065 8.70801 9.64648 8.77051 9.77148 8.89551L14.0007 13.1247L18.2298 8.89551C18.3409 8.7844 18.4834 8.72523 18.6573 8.71801C18.8307 8.71134 18.9798 8.77051 19.1048 8.89551C19.2298 9.02051 19.2923 9.16634 19.2923 9.33301C19.2923 9.49967 19.2298 9.64551 19.1048 9.77051L14.8757 13.9997L19.1048 18.2288C19.2159 18.34 19.2748 18.4822 19.2815 18.6555C19.2887 18.8294 19.2298 18.9788 19.1048 19.1038C18.9798 19.2288 18.834 19.2913 18.6673 19.2913C18.5007 19.2913 18.3548 19.2288 18.2298 19.1038L14.0007 14.8747Z" fill="#9E9E9E" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="chat-history">
      <ng-container *ngFor="let chatHistory of chatMessages">
        <div class="date-separation mb-2">
          <span class="separation-line-left">&nbsp;</span>
          <span class="small-text">{{chatHistory[0]?.displayDate}}</span>
          <span class="separation-line-right">&nbsp;</span>
        </div>
        <div class="agenzee mb-2" *ngFor="let chat of chatHistory" [ngClass]="{'sender': chat.chatTypeId == 1}">
          <svg class="mr-2" *ngIf="chat.chatTypeId == 2" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#F0F6FD" />
            <path d="M16 31.5C24.5604 31.5 31.5 24.5604 31.5 16C31.5 7.43959 24.5604 0.5 16 0.5C7.43959 0.5 0.5 7.43959 0.5 16C0.5 24.5604 7.43959 31.5 16 31.5Z" stroke="#C2DBF6" />
            <path d="M9.31769 14.3008H13.8431L11.0958 21.1009H6.57031L9.31769 14.3008Z" fill="#00D0E8" />
            <path d="M15.8078 12.5996H19.2079L15.7712 21.0996H12.3711L15.8078 12.5996Z" fill="#00A0F4" />
            <path d="M21.1711 10.9004L17.0469 21.1006H18.7467L20.8106 15.9967L22.8714 21.1006H24.5712V11.7503C24.5712 11.2808 24.1908 10.9004 23.7213 10.9004H21.1711Z" fill="#0068D8" />
          </svg>

          <div class="msg-content">
            {{chat.content}}
          </div>

          <div class="small-text" [class.ml-8]="chat.chatTypeId == 2">{{chat?.createdDate | date: 'hh:mm a'}}</div>

        </div>
      </ng-container>
      <div *ngIf="isTyping" class="typing-indicator">Assistant is typing...</div>
      <div id="scrollTo"></div>
    </div>

    <div class="chat-input">
      <input [(ngModel)]="userMessage" (keyup.enter)="sendMessage()" placeholder="Ask Agenzee AI" #chatInput />
      <button (click)="sendMessage()" [disabled]="userMessage?.length <= 0">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.5052 7.74395L1.1227 12.9644C0.855367 13.0713 0.601418 13.0483 0.360851 12.8954C0.120284 12.7424 0 12.5203 0 12.2292V1.77094C0 1.47981 0.120284 1.25772 0.360851 1.10469C0.601418 0.951801 0.855367 0.928809 1.1227 1.03571L13.5052 6.25619C13.8351 6.40183 14 6.64979 14 7.00007C14 7.35035 13.8351 7.59831 13.5052 7.74395ZM1.33074 11.4359L11.8435 7.00007L1.33074 2.56428V5.83989L6.14179 7.00007L1.33074 8.16025V11.4359Z" fill="white" />
        </svg>
      </button>
    </div>
    <div class="chat-info-copy" *ngIf="!isNoteClosed">
      <span>Agenzee AI may occasionally make mistakes. The information provided is intended for reference only and should not be considered a substitute for professional advice or verification.</span>
      <a href="javascript:void(0)" class="help-url" (click)="closeNote()">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <title>Close</title>
          <path d="M14.0007 14.8747L9.77148 19.1038C9.66037 19.215 9.51815 19.2738 9.34482 19.2805C9.17093 19.2877 9.02148 19.2288 8.89648 19.1038C8.77148 18.9788 8.70898 18.833 8.70898 18.6663C8.70898 18.4997 8.77148 18.3538 8.89648 18.2288L13.1257 13.9997L8.89648 9.77051C8.78537 9.6594 8.72648 9.5169 8.71982 9.34301C8.7126 9.16967 8.77148 9.02051 8.89648 8.89551C9.02148 8.77051 9.16732 8.70801 9.33398 8.70801C9.50065 8.70801 9.64648 8.77051 9.77148 8.89551L14.0007 13.1247L18.2298 8.89551C18.3409 8.7844 18.4834 8.72523 18.6573 8.71801C18.8307 8.71134 18.9798 8.77051 19.1048 8.89551C19.2298 9.02051 19.2923 9.16634 19.2923 9.33301C19.2923 9.49967 19.2298 9.64551 19.1048 9.77051L14.8757 13.9997L19.1048 18.2288C19.2159 18.34 19.2748 18.4822 19.2815 18.6555C19.2887 18.8294 19.2298 18.9788 19.1048 19.1038C18.9798 19.2288 18.834 19.2913 18.6673 19.2913C18.5007 19.2913 18.3548 19.2288 18.2298 19.1038L14.0007 14.8747Z" fill="#9E9E9E" />
        </svg>
      </a>
    </div>
  </div>
</div>
</div>
</div>
