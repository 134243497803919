import { Component, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PackageService } from '../../../api-services/package.service';
import { VerifyGuestUserRequest } from '../../../models/packages/verifyGuestUserRequest.model';

@Component({
  selector: 'app-packages-code',
  templateUrl: './packages-code.component.html',
  styleUrls: ['./packages-code.component.css']
})
export class PackagesCodeComponent implements OnInit {

  title = 'otp';
  otpCode = "";
  codeForm: FormGroup;
  formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
  @ViewChildren('formRow') rows: any;
  guestUserId: number;
  errorMessages = [];
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private packageService: PackageService) {
    this.codeForm = this.toFormGroup(this.formInput);
  }

  ngOnInit(): void {
    this.sendVerificationCode();
  }

  sendVerificationCode() {
    var val = this.activatedRoute.snapshot.paramMap.get('id');
    this.packageService.sendVerificationCode(val).subscribe(
      (response: any) => {
        this.guestUserId = response.guestUserId;
        localStorage.setItem("guestUserId", this.guestUserId.toString());
        if (response.statusId == 2) {
          this.router.navigate(['/setpwd']);
        }
        else if (response.statusId == 3) {
          this.router.navigate(['/login']);
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  toFormGroup(elements) {
    const group: any = {};

    elements.forEach(key => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event, index) {
    this.otpCode = ""
    let pos = index;
    
       if(this.codeForm.value[index] == '' || event.ctrlKey){
        this.codeForm.value[index] = "";
        pos = index;
       }else if (event.keyCode === 8 && event.which === 8) {
        pos = index - 1;
      }
        else{
        pos = index + 1 
       }
     
    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }
  
  if (index == 5) {
    this.errorMessages = [];
    Object.values(this.codeForm.value).forEach(el => {
      this.otpCode = this.otpCode + el;
    })
  }
}


  // keyUpEvent(event, index) {
    
  //   this.otpCode = ""
  //   let pos = index;
  //   if (event.keyCode === 8 && event.which === 8) {
  //     pos = index - 1;
  //   } else {
  //     pos = index + 1;
  //   }
  //   if (pos > -1 && pos < this.formInput.length) {
  //     this.rows._results[pos].nativeElement.focus();
  //   }

  //   if (index == 5) {
  //     Object.values(this.codeForm.value).forEach(el => {
  //       this.otpCode = this.otpCode + el;
  //     })
  //   }
  // }

  onSubmit() {
    this.codeForm.markAllAsTouched();
    if (this.codeForm.valid && this.guestUserId > 0) {
      var objRequest = {
        code: parseInt(this.otpCode),
        guestUserId: this.guestUserId
      } as VerifyGuestUserRequest;

      this.packageService.verifyGuestUser(objRequest).subscribe(
        (response: any) => {
          if (response) {            
            this.router.navigate(['/setpwd']);
          }
        },
        (error: any) => {
          // console.log(error);
          this.errorMessages.push(error.message);
        }
      );
    }
  }

}
