<form id="contactForm" [formGroup]="contactAdminForm">
    <div class="group-form">
        <div class="row popup-height mb-4">
            <div class="col-6">
                <div class="input-block">
                    <label for="FirstName" class="input-name">First name <span class="validation-field">*</span></label>
                    <input type="text" class="form-control" maxlength="50" formControlName="FirstName" placeholder="First name">
                </div>
            </div>
            <div class="col-6">
                <div class="input-block">
                    <label for="LastName" class="input-name">Last name <span class="validation-field">*</span></label>
                    <input type="text" class="form-control" maxlength="50" formControlName="LastName" placeholder="Last name">
                </div>
            </div>
            <div class="col-12">
                <div class="input-block">
                    <label for="Email" class="input-name">Email <span class="validation-field">*</span></label>
                    <input type="email" class="form-control" maxlength="500" formControlName="Email" placeholder="Email">
                </div>
            </div>
            <div class="col-6">
                <div class="input-block">
                    <label for="Alias" class="input-name">Alias </label>
                    <input type="text" class="form-control" maxlength="50" formControlName="Alias" placeholder="Alias">
                </div>
            </div>
            <div class="col-6">
                <div class="input-block">
                    <label for="jobTitle" class="input-name">Job title </label>
                    <div class="input-block">
                        <ng-select class="form-control min-height" tabindex="0" formControlName="JobTitleId"
                            [searchable]="true" [clearable]="true" placeholder="Job title">                           
                            <ng-option *ngFor="let jobTitle of jobTitleList" [value]="jobTitle.jobTitleID">{{ jobTitle.description}}</ng-option>
                        </ng-select>
                    </div></div>
            </div>
            <div class="col-6">
                <div class="input-block input-date">
                    <label for="HDate" class="input-name">Hire date </label>
                    <input class="form-control pointer-none" placeholder="mm/dd/yyyy" id="HDate"
                           name="HDate" formControlName="HDate" ngbDatepicker #d="ngbDatepicker" />
                    <div class="model-date-picker">
                      <span class="close-icon date-close" (click)="clearDate()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><rect _ngcontent-wvn-c189="" width="20" height="20" rx="6" fill="none"></rect><g _ngcontent-wvn-c189="" transform="translate(4 4)"><rect _ngcontent-wvn-c189="" width="16" height="16" fill="none"></rect><path _ngcontent-wvn-c189="" d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z" transform="translate(-0.005 0.005)" fill="#9e9e9e"></path></g></svg>
                      </span>
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="input-block">
                    <label for="Department" class="input-name">Department </label>
                    <div class="input-block">
                        <ng-select class="form-control open-top" tabindex="0" formControlName="DepartmentId"
                            [searchable]="true" [clearable]="true" placeholder="Department">                          
                            <ng-option *ngFor="let department of departmentList" [value]="department.departmentID">{{ department.description}}</ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="input-block">
                    <label for="Branch" class="input-name">Select {{tenantTypeId == 1 ? 'agency':'branch'}} <span class="validation-field">*</span></label>
                    <div class="input-block">
                        <ng-select class="form-control open-top"  [searchable]="true" [clearable]="true" formControlName="BranchId" name="BranchId"
                                 placeholder="Select {{tenantTypeId == 1 ? 'agency':'branch'}}">
                        <ng-option *ngFor="let branch of branchList" [value]="branch.branchId">{{ branch.branchName}}</ng-option>
                      </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-12">
            <validation-summary [validationErrors]="errorMessages"></validation-summary>
            </div>
    </div>
    <div class="action-btn text-right">
      <button type="button" (click)="closeAgentPopup(false)" class="btn commn-btn lg-bg">Cancel</button>
      <button type="button" (click)="addAgent(true,0)" class="btn commn-btn dark-bg" [disabled]="!contactAdminForm.valid">Add</button>
      <button type="button" (click)="addAgent(true,1)" class="btn commn-btn dark-bg" [disabled]="!contactAdminForm.valid">Add & Invite</button>
    </div>
</div>
  </form>