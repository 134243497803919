
                <form class="set-password-form" [formGroup]="changePwdForm" (ngSubmit)="onSubmit()">
                  <div class="form-block-title">
                    <h3 class="form-title">Set password to continue</h3>
                    <div class="form-inputs-data">
                      <div class="form-group" [class.error]="((changePwdForm.get('password')!.touched ||
                      changePwdForm.get('password')!.dirty) &&
                      changePwdForm.get('password')!.errors)">
                        <input type="password" formControlName="password" maxlength="50" class="form-control form-input"
                               name="setPassword" id="setPassword" placeholder=" ">
                        <label class="form-label">Set password</label>
                        <div class="form-icon">
                          <svg class="pass-icon" id="icons_ic-visibility" data-name="icons / ic-visibility"
                               xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <rect id="Rectangle_454" data-name="Rectangle 454" width="24" height="24" fill="none" />
                            <path id="visibility_FILL0_wght300_GRAD0_opsz24" class="fill-color"
                                  d="M12,15.575A4.056,4.056,0,0,0,16.075,11.5,4.056,4.056,0,0,0,12,7.425,4.056,4.056,0,0,0,7.925,11.5,4.056,4.056,0,0,0,12,15.575ZM12,14.2a2.69,2.69,0,0,1-2.7-2.7A2.689,2.689,0,0,1,12,8.8a2.687,2.687,0,0,1,2.7,2.7A2.689,2.689,0,0,1,12,14.2Zm0,4.3a11.058,11.058,0,0,1-6.013-1.725A11.311,11.311,0,0,1,1.8,12.1a.9.9,0,0,1-.075-.263,2.291,2.291,0,0,1,0-.675A.9.9,0,0,1,1.8,10.9,11.311,11.311,0,0,1,5.987,6.225,11.058,11.058,0,0,1,12,4.5a11.053,11.053,0,0,1,6.012,1.725A11.308,11.308,0,0,1,22.2,10.9a.9.9,0,0,1,.075.262,2.291,2.291,0,0,1,0,.675.9.9,0,0,1-.075.263,11.308,11.308,0,0,1-4.188,4.675A11.053,11.053,0,0,1,12,18.5Zm0-7ZM12,17a9.545,9.545,0,0,0,5.188-1.488A9.771,9.771,0,0,0,20.8,11.5a9.777,9.777,0,0,0-3.612-4.013A9.549,9.549,0,0,0,12,6,9.549,9.549,0,0,0,6.812,7.487,9.777,9.777,0,0,0,3.2,11.5a9.771,9.771,0,0,0,3.612,4.012A9.545,9.545,0,0,0,12,17Z"
                                  transform="translate(0 0.5)" fill="#757575" />
                          </svg>
                        </div>
                        <app-error [controlName]="changePwdForm.controls['password']">
                        </app-error>
                      </div>
                      <div class="form-group" [class.error]="((changePwdForm.get('confirmPassword')!.touched ||
                      changePwdForm.get('confirmPassword')!.dirty) &&
                      changePwdForm.get('confirmPassword')!.errors)">
                        <input type="password" maxlength="50" formControlName="confirmPassword"
                               class="form-control form-input" name="setConfirmPassword" id="setConfirmPassword"
                               placeholder=" ">
                        <label class="form-label">Confirm password</label>
                        <div class="form-icon">
                          <svg class="pass-icon" id="icons_ic-visibility" data-name="icons / ic-visibility"
                               xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <rect id="Rectangle_454" data-name="Rectangle 454" width="24" height="24" fill="none" />
                            <path id="visibility_FILL0_wght300_GRAD0_opsz24" class="fill-color"
                                  d="M12,15.575A4.056,4.056,0,0,0,16.075,11.5,4.056,4.056,0,0,0,12,7.425,4.056,4.056,0,0,0,7.925,11.5,4.056,4.056,0,0,0,12,15.575ZM12,14.2a2.69,2.69,0,0,1-2.7-2.7A2.689,2.689,0,0,1,12,8.8a2.687,2.687,0,0,1,2.7,2.7A2.689,2.689,0,0,1,12,14.2Zm0,4.3a11.058,11.058,0,0,1-6.013-1.725A11.311,11.311,0,0,1,1.8,12.1a.9.9,0,0,1-.075-.263,2.291,2.291,0,0,1,0-.675A.9.9,0,0,1,1.8,10.9,11.311,11.311,0,0,1,5.987,6.225,11.058,11.058,0,0,1,12,4.5a11.053,11.053,0,0,1,6.012,1.725A11.308,11.308,0,0,1,22.2,10.9a.9.9,0,0,1,.075.262,2.291,2.291,0,0,1,0,.675.9.9,0,0,1-.075.263,11.308,11.308,0,0,1-4.188,4.675A11.053,11.053,0,0,1,12,18.5Zm0-7ZM12,17a9.545,9.545,0,0,0,5.188-1.488A9.771,9.771,0,0,0,20.8,11.5a9.777,9.777,0,0,0-3.612-4.013A9.549,9.549,0,0,0,12,6,9.549,9.549,0,0,0,6.812,7.487,9.777,9.777,0,0,0,3.2,11.5a9.771,9.771,0,0,0,3.612,4.012A9.545,9.545,0,0,0,12,17Z"
                                  transform="translate(0 0.5)" fill="#757575" />
                          </svg>
                        </div>
                        <app-error [controlName]="changePwdForm.controls['confirmPassword']">
                        </app-error>

                      </div>
                    </div>
                    <validation-summary [validationErrors]="errorMessages"></validation-summary>
                    <div class="action-btn">
                      <button type="submit" class="btn commn-btn">Continue</button>
                    </div>
                  </div>
                </form>

