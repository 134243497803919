import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { LinesOfAuthorityService } from 'api-services/lines-of-authority.service';
import { DashboardScorecard } from 'models/dashboard-scorecard.model';
import { State } from 'models/state.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { SharedService } from '../../../../services/shared.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-agent-scorecard',
  templateUrl: './agent-scorecard.component.html',
  styleUrls: ['./agent-scorecard.component.css']
})
export class AgentScorecardComponent implements OnInit {
  
  characterValidate: number = 3;
  agentScorecardList:any = [];
  overallCount: number = 0;
  pagCount: number = 1;
  range: number[] = [0, 0] as number[];
  sortDir: number = 0;
  reqstParam: DashboardScorecard = {} as DashboardScorecard
  selectedAgent = JSON.parse(localStorage.getItem('selContact'))
  selectedCustomGrp: number = parseInt(localStorage.getItem('selCustomGrp'));
  minDate: string = "01/01/0001";

  selectedStates: State[];
  states: State[] = [] as State[];
  dropdownSettingState: IDropdownSettings;
  expiryInData = [
    {id:0, days: "All"},
    {id:15, days: "15 Days"},
    {id:30, days: "30 Days"}
  ];
  agentStatusList = [
    {id:1, status: "Active"},
    {id:0, status: "Missing"}
  ];
  allChecked = false;
  constructor(private router: Router, private datePipe: DatePipe, private loaService: LinesOfAuthorityService, private sharedService: SharedService) {
    // const navigation = this.router.getCurrentNavigation();
    // this.selectedAgent = navigation.extras?.state['selAgent'];
    
   }
   isShow = true;
  ngOnInit(): void {

    this.reqstParam.tenantId = Number(localStorage.getItem('tenantId'));
    this.reqstParam.customGroupId = this.selectedCustomGrp;
    this.reqstParam.userId =  this.selectedAgent['userId']; 
    this.reqstParam.pageNum = 1;
    this.reqstParam.pageSize = parseInt(environment.pageSize);
    this.reqstParam.sortBy = '';
    this.reqstParam.sortOrder = 0;

    this.dropdownSettingState = {
      singleSelection: false,
      idField: 'stateId',
      textField: 'stateName',
      selectAllText: 'Select All',
      unSelectAllText: 'Clear All',
      allowSearchFilter: true,
      itemsShowLimit: 1,
    };

    this.loadStates();
    this.getContactDetails();    
  }

  resetPage() {
    this.reqstParam.pageNum = 1;
  }
  getContactDetails() {
    
  // state list
  var stateIds = null;
  if (this.selectedStates && this.selectedStates.length > 0) {
    stateIds = this.selectedStates.map(function (val) {
      return val.stateId
    }).join(',');
  }
    this.reqstParam.states = stateIds;
    //this.reqstParam.status = stateIds;
    //this.reqstParam.expireIn = stateIds;

  this.loaService.getContactScorecard(this.reqstParam).subscribe((res:any) => {
    this.agentScorecardList = res.map(el => ({
      ...el,
      formatedexpiryDate: this.sharedService.calculateDiff(el.expirationDate)
      
    }))
    this.overallCount = this.agentScorecardList[0]?.overallCount? this.agentScorecardList[0]?.overallCount : this.agentScorecardList.length ;
    this.createRange();
  },
    (error: any) => {
       // this.errorMessages.push(error.message);
    }
  )
}

clearSearch(){
  this.reqstParam.search = '';
  this.getContactDetails();
}


 // select all data from table
 selectAll(value: boolean) {
  this.allChecked = value;
  this.agentScorecardList = this.agentScorecardList.map(m => ({ ...m, isChecked: value }));
  this.isShow = !this.isShow;
}

selectChkVal(list: any): void {
  list.isChecked = !list.isChecked;    
}


// pagination
createRange() {
  var pageSize = this.reqstParam.pageSize;
  var pageNum = this.reqstParam.pageNum;

  this.pagCount = this.overallCount < pageSize ? 1 : Math.ceil(this.overallCount / pageSize);

  var offSet = pageNum * pageSize;
  this.range = [this.agentScorecardList.length > 0 ? ((pageNum - 1) * pageSize +1):0, Math.min(pageNum * pageSize, this.overallCount)]
}

// pagination next and back functionality 
next() {
  if (this.reqstParam.pageNum <= this.pagCount) {
    this.reqstParam.pageNum++;
    this.getContactDetails();
  }
}

back() {
  if (this.reqstParam.pageNum > 1)
    this.reqstParam.pageNum--;
    this.getContactDetails();
}

 // Sorting functionaliy 
 onSortClick(event, colName) {
  let target = event.currentTarget,
    classList = target.classList;
    
  if (classList.contains('sorting-icon-asc')) {
    classList.remove('sorting-icon-asc');
    classList.add('sorting-icon-des');
    this.sortDir = 1;
  } else {
    classList.add('sorting-icon-asc');
    classList.remove('sorting-icon-des');
    this.sortDir = 0;
  }
  this.reqstParam.sortBy = colName;
  this.reqstParam.sortOrder = this.sortDir;
  this.getContactDetails();
}

// get data of states
loadStates() {
  this.loaService.getSupportedStates().subscribe(
    response => {
      this.states = response;
},
    error => {
      if (error.status == 0) {

      }
      else {

      }
    }
  )
}

openModal= "close";
openComingSoonModal(){
  this.openModal = "open";
}
modalStatus(eve){
  this.openModal= eve;
} 

}
