import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CarrierService } from '../../../../carrier/services/carrier.service';
import { CarrierList, Carrier } from 'models/manage-mga.model';
import { SharedService } from 'services';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-select-carrier-modal',
  templateUrl: './select-carrier-modal.component.html',
  styleUrls: ['./select-carrier-modal.component.css']
})

export class SelectCarrierModalComponent implements OnInit {
  @Input() selectedCarrier;
  selectedCarrierId: number = null;
  carrierListModel: CarrierList = {} as CarrierList;
  carrierList = [];
  overallCount: number = 0;
  pagCount: number = 1;
  range: number[] = [0, 0] as number[];
  // selectedCarrier: any;
  Carrier: Carrier = {} as Carrier;
  errorMessages = [];
  addNewCarrierForm: FormGroup;
  sortDir = 0;
  @ViewChild('closeNewCarrierPopup') closeNewCarrierPopupEle: ElementRef;
  feinMask: any[] = [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  constructor(public activeModal: NgbActiveModal, private carrierService: CarrierService
    ,private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.carrierListModel.tenantId = Number(this.sharedService.getValue('tenantId'));
    this.carrierListModel.pageSize = parseInt(environment.pageSize);
    this.carrierListModel.pageNumber = 1;
    this.carrierListModel.sortBy = 'createdDate';
    this.carrierListModel.sortOrder = 1;

    this.selectedCarrier ? this.selectedCarrierId = this.selectedCarrier.carrierId : '';
    this.getCarrierListByTenant();
    this.addNewCarrierForm = this.bindAddNewCarrierForm();
  }

  getCarrierListByTenant() {
    this.carrierService.getCarrierListByTenant(this.carrierListModel).subscribe(data =>{
      this.carrierList = data.map(el => ({
        ...el,
        createdDate: this.sharedService.convertUTCTimeToLocalTime(el.createdDate)
      }));
      this.overallCount = this.carrierList[0]?.overallCount ? this.carrierList[0]?.overallCount : this.carrierList.length;
      this.createRange();

      this.selectedCarrierId ? this.selectedCarrier = this.carrierList.find(el => el.carrierId == this.selectedCarrierId) : '';
    },
      (error: any) => {
        // this.errorMessages.push(error.message);
      }
    )
  }

  createRange() {
    var pageSize = this.carrierListModel.pageSize;
    var pageNum = this.carrierListModel.pageNumber;

    this.pagCount = this.overallCount < pageSize ? 1 : Math.ceil(this.overallCount / pageSize);

    var offSet = pageNum * pageSize;
    this.range = [this.carrierList.length > 0 ? ((pageNum - 1) * pageSize + 1) : 0, Math.min(pageNum * pageSize, this.overallCount)]
  }

  next() {
    if (this.carrierListModel.pageNumber <= this.pagCount) {
      this.carrierListModel.pageNumber++;
      this.getCarrierListByTenant();
    }
  }

  back() {
    if (this.carrierListModel.pageNumber > 1)
      this.carrierListModel.pageNumber--;
    this.getCarrierListByTenant();
  }

  onSortClick(event, colName) {
    let target = event.currentTarget,
      classList = target.classList;
      
    if (classList.contains('sorting-icon-asc')) {
      classList.remove('sorting-icon-asc');
      classList.add('sorting-icon-des');
      this.sortDir = 1;
    } else {
      classList.add('sorting-icon-asc');
      classList.remove('sorting-icon-des');
      this.sortDir = 0;
    }
    this.carrierListModel.sortBy = colName;
    this.carrierListModel.sortOrder = this.sortDir;
    this.getCarrierListByTenant();
  }

  clearSearch() {
    this.carrierListModel.search = '';
    this.getCarrierListByTenant();
  }

// 
// Add Carrier popup
  bindAddNewCarrierForm(): FormGroup {
    return new FormGroup({
      carrierName: new FormControl(null, [Validators.required]),
      aliasName: new FormControl(null, [Validators.required]),
      lob: new FormControl(null, [Validators.required]),
      fein: new FormControl(null,[Validators.required]),
      companyCode: new FormControl(null,[Validators.required])
    })
  }

  addCarrier(isAutoSelect) {
    this.errorMessages = [];
    var reqstPayload = {
      CarrierId: 0,
      TenantId: Number(this.sharedService.getValue('tenantId')),
      CarrierName: this.addNewCarrierForm.value.carrierName,
      DisplayName: this.addNewCarrierForm.value.aliasName,
      FEIN: this.addNewCarrierForm.value.fein,
      CompanyCode: this.addNewCarrierForm.value.companyCode,
      LineofBusiness: this.addNewCarrierForm.value.lob,
      UpdatedBy: Number(this.sharedService.getValue('userId'))
    }
    this.carrierService.InsertCarrierForManualAppt(reqstPayload).subscribe(
      (response: any) => {
        if (response) {
          this.sharedService.showToastMessage('Carrier added successfully.');
          this.closeNewCarrierPopupEle.nativeElement.click();
          this.resetForm();
          if(isAutoSelect){
            this.getCarrierAndSelect(response?.id);
          }else{
            this.getCarrierListByTenant();
            this.selectedCarrierId = response?.id;
          }
          
        }
      },
      (error: any) => {
        this.errorMessages.push(error.message);
      }
    )
  }

  selectedCarrierUser(list){
    this.selectedCarrier = list;
  }

  getCarrierAndSelect(carrierid : number)
  {
    this.carrierService.getCarrier(carrierid).subscribe(
      (response: any) => {
        if (response) {
          this.selectedCarrier = response;
           this.closePopup(this.selectedCarrier);
        }
      },
      (error: any) => {
        this.errorMessages.push(error.message);
      }
    )
  }
  
  closePopup(selectedCarrier) {
    this.activeModal.close(selectedCarrier);
  }
  resetForm(){
    this.addNewCarrierForm.reset();
    this.errorMessages = [];
  }
}
