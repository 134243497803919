<div class="reminder-container issue-feedback-container" #issueFeedback>
    <div class="reminder-header">Issue & Feedback
      <button type="button" class="modal-close-btn close" (click)="closeIssueFeedback()" style="margin-left: 16px;">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
          <rect width="28" height="28" rx="6" fill="none" />
          <g transform="translate(4 4)">
            <rect width="20" height="20" fill="none" />
            <path
              d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z"
              transform="translate(-0.005 0.005)" fill="#757575" />
          </g>
        </svg>
      </button>
    </div>
    <div class="tab common-tab pd-0" role="tabpanel">
        <ul class="nav nav-tabs fixed" role="tablist"  (click)="getSelectedTab($event)">
            <li class="nav-item">
               <a class="nav-link active" href="#2" aria-controls="issueFeedback" role="tab" data-toggle="tab">Report an Issue</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#1" aria-controls="issueFeedback" role="tab" data-toggle="tab">Suggest an Idea</a>
           </li>
         </ul>
    </div>
    <div class="tab-content">
      <div *ngIf="tabActive == '#2'" role="tabpanel" class="tab-pane active in" id="report-an-inssue">
        <form [formGroup]="issueFeedbackForm" (ngSubmit)="submit()">
          <div class="list-content" *ngIf="!isFormSubmited && !isLoader">
            <div class="list-body mt-4">
              <div class="input-block">
                <label class="input-label" for="Status">Select topic<span class="validation-field">*</span></label>
                <div class="select-options mt-2">
                  <div class="">
                    <ng-select [items]="issueFeedbackTopics" bindLabel="topicName" bindValue="topicID" [searchable]="true" [clearable]="true" placeholder="Select topic" name="selectTopic"
                         formControlName="selectTopic"      class="form-control ng-select-bottom ng-select ng-select-single ng-select-searchable ng-select-clearable">
                      
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="input-block mt-3">
                <label class="input-label" for="License">Describe the issue you are facing<span class="validation-field">*</span></label>
                <textarea class="form-control note-field mt-2" formControlName="describe" name="describe" maxlength="1000"
                          rows="7" style="width: -webkit-fill-available; resize:none;height: 180px;" placeholder="Your message"></textarea>
              </div>
              <div class="input-block mt-3">
                <label for="sendEmail" class="input-label">May we email you for more information or updates?<span class="validation-field">*</span></label>
                <div class="personal-info mt-2">
                  <label class="radio-button-container">
                    <input type="radio" tabindex="0" formControlName="sendEmail" name="sendEmail" value="yes">Yes
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-button-container">
                    <input type="radio" tabindex="0" formControlName="sendEmail" name="sendEmail" value="no">No
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="list-footer">
              <div class="action-btn">
                <button type="submit" class="btn primary-btn" [disabled]="!issueFeedbackForm.valid"><span>Send</span></button>
              </div>
            </div>
          </div>
          </form>
          <div *ngIf="isFormSubmited && !isLoader">
            <div class="thanks-page">
              <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width="64" height="64" rx="32" fill="#4CAF50" />
                <mask id="mask0_9599_44546" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="11" width="44" height="43">
                  <rect x="10.6667" y="11.1669" width="42.6667" height="42.6667" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_9599_44546)">
                  <path d="M29.4774 36.3159L25.3474 32.186C25.1013 31.9398 24.7919 31.8139 24.4192 31.8082C24.0466 31.8025 23.7315 31.9284 23.4739 32.186C23.2164 32.4435 23.0876 32.7558 23.0876 33.1227C23.0876 33.4896 23.2164 33.8019 23.4739 34.0594L28.3526 38.938C28.6739 39.2594 29.0488 39.4201 29.4774 39.4201C29.9058 39.4201 30.2807 39.2594 30.6021 38.938L40.4927 29.0475C40.7389 28.8013 40.8648 28.4919 40.8705 28.1193C40.8762 27.7466 40.7503 27.4315 40.4927 27.174C40.2351 26.9165 39.9229 26.7877 39.556 26.7877C39.189 26.7877 38.8768 26.9165 38.6193 27.174L29.4774 36.3159ZM32.0034 49.3893C29.6675 49.3893 27.4719 48.9461 25.4166 48.0595C23.3612 47.173 21.5733 45.9699 20.0529 44.4502C18.5325 42.9305 17.3288 41.1434 16.4419 39.0889C15.555 37.0345 15.1116 34.8393 15.1116 32.5035C15.1116 30.1676 15.5548 27.972 16.4414 25.9166C17.3279 23.8612 18.531 22.0734 20.0507 20.553C21.5704 19.0326 23.3575 17.8289 25.412 16.942C27.4664 16.0551 29.6616 15.6116 31.9974 15.6116C34.3333 15.6116 36.5289 16.0549 38.5843 16.9414C40.6397 17.8279 42.4275 19.031 43.9479 20.5507C45.4683 22.0705 46.672 23.8576 47.5589 25.912C48.4458 27.9665 48.8893 30.1616 48.8893 32.4975C48.8893 34.8334 48.446 37.029 47.5595 39.0843C46.673 41.1397 45.4699 42.9276 43.9502 44.448C42.4304 45.9684 40.6433 47.1721 38.5889 48.059C36.5344 48.9459 34.3393 49.3893 32.0034 49.3893ZM32.0004 46.7227C35.9708 46.7227 39.3338 45.3449 42.0893 42.5894C44.8449 39.8338 46.2226 36.4709 46.2226 32.5005C46.2226 28.5301 44.8449 25.1671 42.0893 22.4116C39.3338 19.656 35.9708 18.2783 32.0004 18.2783C28.03 18.2783 24.6671 19.656 21.9115 22.4116C19.156 25.1671 17.7782 28.5301 17.7782 32.5005C17.7782 36.4709 19.156 39.8338 21.9115 42.5894C24.6671 45.3449 28.03 46.7227 32.0004 46.7227Z" fill="white" />
                </g>
              </svg>
              <h1>Thanks for sharing your feedback!</h1>
              <p>We use feedback like yours to help to fix issues and improve our services and features.</p>
            </div>
          </div>
      </div>
        
      <div *ngIf="tabActive == '#1'" role="tabpanel" class="tab-pane active in" id="suggest-an-idea">
        <form [formGroup]="issueFeedbackForm" (ngSubmit)="submit()">
          <div class="list-content" *ngIf="!isFormSubmited && !isLoader">
            <div class="list-body mt-4">
              <div class="input-block">
                <label class="input-label" for="Status">Select topic<span class="validation-field">*</span></label>
                <div class="select-options mt-2">
                  <div class="">
                    <ng-select [items]="issueFeedbackTopics" bindLabel="topicName" bindValue="topicID" [searchable]="true" [clearable]="true" placeholder="Select topic" name="selectTopic"
                            formControlName="selectTopic"     class="form-control ng-select-bottom ng-select ng-select-single ng-select-searchable ng-select-clearable">

                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="input-block mt-3">
                <label class="input-label" for="License">Describe your suggestion<span class="validation-field">*</span></label>
                <textarea class="form-control note-field mt-2" formControlName="describe" name="describe" maxlength="1000"
                          rows="7" style="width: -webkit-fill-available; resize:none;height: 180px" placeholder="Your message"></textarea>
              </div>
              <div class="input-block mt-3">
                <label for="sendEmail" class="input-label">May we email you for more information or updates?<span class="validation-field">*</span></label>
                <div class="personal-info mt-2">
                  <label class="radio-button-container">
                    <input type="radio" tabindex="0" formControlName="sendEmail" name="sendEmail" value="yes">Yes
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-button-container">
                    <input type="radio" tabindex="0" formControlName="sendEmail" name="sendEmail" value="no">No
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="list-footer">
              <div class="action-btn">
                <button type="submit" class="btn primary-btn" [disabled]="!issueFeedbackForm.valid"><span>Send</span></button>
              </div>
            </div>
          </div>
        </form>
          <div *ngIf="isFormSubmited && !isLoader">
            <div class="thanks-page">
              <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width="64" height="64" rx="32" fill="#4CAF50" />
                <mask id="mask0_9599_44546" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="11" width="44" height="43">
                  <rect x="10.6667" y="11.1669" width="42.6667" height="42.6667" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_9599_44546)">
                  <path d="M29.4774 36.3159L25.3474 32.186C25.1013 31.9398 24.7919 31.8139 24.4192 31.8082C24.0466 31.8025 23.7315 31.9284 23.4739 32.186C23.2164 32.4435 23.0876 32.7558 23.0876 33.1227C23.0876 33.4896 23.2164 33.8019 23.4739 34.0594L28.3526 38.938C28.6739 39.2594 29.0488 39.4201 29.4774 39.4201C29.9058 39.4201 30.2807 39.2594 30.6021 38.938L40.4927 29.0475C40.7389 28.8013 40.8648 28.4919 40.8705 28.1193C40.8762 27.7466 40.7503 27.4315 40.4927 27.174C40.2351 26.9165 39.9229 26.7877 39.556 26.7877C39.189 26.7877 38.8768 26.9165 38.6193 27.174L29.4774 36.3159ZM32.0034 49.3893C29.6675 49.3893 27.4719 48.9461 25.4166 48.0595C23.3612 47.173 21.5733 45.9699 20.0529 44.4502C18.5325 42.9305 17.3288 41.1434 16.4419 39.0889C15.555 37.0345 15.1116 34.8393 15.1116 32.5035C15.1116 30.1676 15.5548 27.972 16.4414 25.9166C17.3279 23.8612 18.531 22.0734 20.0507 20.553C21.5704 19.0326 23.3575 17.8289 25.412 16.942C27.4664 16.0551 29.6616 15.6116 31.9974 15.6116C34.3333 15.6116 36.5289 16.0549 38.5843 16.9414C40.6397 17.8279 42.4275 19.031 43.9479 20.5507C45.4683 22.0705 46.672 23.8576 47.5589 25.912C48.4458 27.9665 48.8893 30.1616 48.8893 32.4975C48.8893 34.8334 48.446 37.029 47.5595 39.0843C46.673 41.1397 45.4699 42.9276 43.9502 44.448C42.4304 45.9684 40.6433 47.1721 38.5889 48.059C36.5344 48.9459 34.3393 49.3893 32.0034 49.3893ZM32.0004 46.7227C35.9708 46.7227 39.3338 45.3449 42.0893 42.5894C44.8449 39.8338 46.2226 36.4709 46.2226 32.5005C46.2226 28.5301 44.8449 25.1671 42.0893 22.4116C39.3338 19.656 35.9708 18.2783 32.0004 18.2783C28.03 18.2783 24.6671 19.656 21.9115 22.4116C19.156 25.1671 17.7782 28.5301 17.7782 32.5005C17.7782 36.4709 19.156 39.8338 21.9115 42.5894C24.6671 45.3449 28.03 46.7227 32.0004 46.7227Z" fill="white" />
                </g>
              </svg>
              <h1>Thanks for sharing your Suggestion!</h1>
              <p>We use suggestions like yours to improve our services and features.</p>
            </div>
          </div>
      </div>
    </div>
  
    
    <!-- <div class="no-data-found" *ngIf="reminderList <= 0">
      <app-no-data-found [isGraphDashboard]="'false_false_ _ _There are no Reminders'"></app-no-data-found>
      </div>-->
    <div class="inside-loader" *ngIf="isLoader">
      <img src="../../../../assets/images/common-loader.gif" alt="" width="50">
    </div> 
  </div>
