import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'api-services';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  isAdmin?: boolean = false;
  constructor(private router: Router, private accountService: AccountService) { }

  ngOnInit(): void {
    if (localStorage["userRole"] == "admin") {
      this.isAdmin = true;
    }
  }
  logout(): void {

     
   
    this.accountService.logout();
    this.router.navigate(['/login']);
    }

  ChangePwd(): void {
    this.router.navigate(['/setpwd']);
  }
}
