import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'api-services';


@Component({
  selector: 'app-request-verification-code',
  templateUrl: './request-verification-code.component.html',
  styleUrls: ['./request-verification-code.component.css']
})
export class RequestVerificationCodeComponent implements OnInit {

  errorMessages: any;
  passwordRequestForm!: FormGroup;
  constructor(private router: Router, private fb: FormBuilder, private accountService: AccountService) { }

  ngOnInit(): void {
    this.passwordRequestForm = this.fb.group({
      userName: ['', [Validators.required]],
      activationCode: ['', []]
    });
  }

  onSubmit(): void {
    this.passwordRequestForm.markAllAsTouched();
    if (this.passwordRequestForm.valid) {
      this.accountService.forgetPwd(this.passwordRequestForm.value).subscribe(
        (response: any) => {
          if (response.userId > 0) {
            alert('Activation link sent in mail to reset Password');
            this.router.navigate(['/login']);
          }
          else
            alert('Something went wrong.');
        },
        (error: any) => {
          alert(error.message);
        }
      )
    }

  }
}
