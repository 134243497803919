import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { seterror } from 'common/slick-slider';
import { Subscription } from 'rxjs/internal/Subscription';
import { SharedService } from 'services/shared.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnInit {
  subscription: Subscription;
  title = 'Licence';
  showLoader = false;
  message = '';
  showNotificaiton = false;
  constructor(private router: Router, private sharedService: SharedService, private authService: AuthService) {

      //this.redirect();
    authService.redirect();
  }
  ngOnInit(): void {         
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.url === '/dashboard') { // Check if route is not 'dashboard'
          this.subscription = this.sharedService.loader.subscribe(obj => {
            setTimeout(() => this.showLoader = false, 0)
          });
        }
        else {
          this.subscription = this.sharedService.loader.subscribe(obj => {
            setTimeout(() => this.showLoader = obj, 0)
          });
        }     
      }
    });   
    this.subscription = this.sharedService.showToast.subscribe(obj => {
      if(obj){
      setTimeout(() => this.message = obj, 0)
      setTimeout(() => this.message = "", 100*100)
      }
    });
  }
  ngAfterViewInit(): void {

    seterror();
  }
}
