import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatMessage } from '../models/chats/chat-messages';
import { ChatSession } from '../models/chats/chat-sessions';
import { SearchChatHistory } from '../models/chats/search-chat-history';
import { UserChatTokenDetail } from '../models/chats/user-chat-token-detail.model';
import { ApiClientService } from './common/api-client.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private apiClient: ApiClientService) { }

  insertChatSession(chatSession: ChatSession): Observable<any> {
    return this.apiClient.post('api/chat/insertChatSession', chatSession, null, true);
  }

  insertChatMessage(chatMessage: ChatMessage): Observable<any> {
    return this.apiClient.post('api/chat/insertChatMessage', chatMessage, null, true);
  }

  getUserChatHistory(searchChatHistory: SearchChatHistory): Observable<ChatSession[]> {
    return this.apiClient.post('api/chat/getUserChatHistory', searchChatHistory, true);
  }

  canMakeRequest(): Observable<boolean> {
    return this.apiClient.get('api/chat/canMakeRequest', null, null, true);
  }

  renameChatSession(chatSession: ChatSession): Observable<any> {
    return this.apiClient.post('api/chat/renameChatSession', chatSession, null, true);
  }

  deleteChatSession(chatSession: ChatSession): Observable<any> {
    return this.apiClient.post(`api/chat/deleteChatSession/${chatSession.chatSessionId}`, null, null, true);
  }

  getUserChatTokenDetail(): Observable<UserChatTokenDetail> {
    return this.apiClient.get('api/chat/getUserChatTokenDetail', null, null, true);
  }
}
