import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneformat'
})
export class PhoneformatPipe implements PipeTransform {

  transform(phoneNumber: string): string {
    // Remove all non-digit characters from the phone number
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Check if the cleaned phone number is empty or not
    if (cleaned.length === 10) {
      // If it's 10 digits long, apply the formatting
      return cleaned.substr(0, 3) + '-' + cleaned.substr(3, 3) + '-' + cleaned.substr(6, 4);
    } else {
      // Otherwise, return the original input
      return phoneNumber;
    }
  }

}
