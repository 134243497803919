export class CommonFunc {

  static showPassword(id) {
    const password = document.querySelector('#' + id);
    if (password != null) {
      const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
      password.setAttribute('type', type);
    }
  }

}
