import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';

declare var window: any;
@Component({
  selector: 'app-coming-soon-modal',
  templateUrl: './coming-soon-modal.component.html',
  styleUrls: ['./coming-soon-modal.component.css']
})
export class ComingSoonModalComponent implements OnInit {
  @Input() modalToggle: string;
  @Output() modalStatus = new EventEmitter();
  comingSoonModal;
  constructor() { }
  darkScrollbarOptions: MCustomScrollbar.CustomScrollbarOptions = { axis: 'y', theme: 'light', setHeight: 'calc(100vh - 190px)' };
  @HostListener("click")
  clicked() {
    this.closeModal();
  }

  ngOnInit(): void {
    this.comingSoonModal = new window.bootstrap.Modal(
      document.getElementById('coming-soon')
      );
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if(this.modalToggle == "open"){
      this.openModal();
    }
  }

  closeModal() {
    this.modalStatus.emit("close");
    this.modalToggle = "close";
    this.comingSoonModal.hide();
  }

  openModal() {
    this.comingSoonModal.show();
  }

}
