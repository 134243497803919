<div class="common-modal comig-soon-model">
  <div id="coming-soon" class="modal fade" arial-hidden="true" role="dialog" data-backdrop="static"
    data-keyboard="false">
    <div class="modal-dialog ">
      <div class="modal-content view-modal">
        <div class="modal-header">
          <h4 class="modal-title">Upcoming Features</h4>
        </div>
        <div class="model-container coming-soon-scroll" light malihu-scrollbar [scrollbarOptions]="darkScrollbarOptions">
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <div class="coming-soon-container">
                <h3 _ngcontent-xnr-c201="" class="plan-owner-name pos-set">Appointments</h3>
                <div class="row">
                  <div class="col-12">
                    <div class="title">Request new appointments</div>
                  </div>
                  <div class="col-12">
                    <div class="title">Terminate appointments</div>
                  </div>
                  <div class="col-12">
                    <div class="title">Reports on the appointed users</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="coming-soon-container">
                <h3 _ngcontent-xnr-c201="" class="plan-owner-name pos-set">Licenses</h3>
                <div class="row">
                  <div class="col-12">
                    <div class="title">Request new licenses</div>
                  </div>
                  <div class="col-12">
                    <div class="title">Renew licenses</div>
                  </div>
                  <div class="col-12">
                    <div class="title">Compliance reports for states</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="coming-soon-container">
                <h3 _ngcontent-xnr-c201="" class="plan-owner-name pos-set">Continuous Education</h3>
                <div class="row">
                  <div class="col-12">
                    <div class="title">Track external CE credit reports</div>
                  </div>
                  <div class="col-12">
                    <div class="title">Track the CE progress of the user</div>
                  </div>
                  <div class="col-12">
                    <div class="title">Integration with WebCE and Limra</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="coming-soon-container">
                <h3 _ngcontent-xnr-c201="" class="plan-owner-name pos-set">PDB Reports</h3>
                <div class="row">
                  <div class="col-12">
                    <div class="title">Demographic information</div>
                  </div>
                  <div class="col-12">
                    <div class="title">Regulatory Action Alerts</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="coming-soon-container coming-logo-container">
            <h3 _ngcontent-xnr-c201="" class="plan-owner-name pos-set">Integrations</h3>
            <div class="logo-container">
              <div class="img-logo">
                <img src="../../../../assets/images/MC_IMG.png">
              </div>
              <div class="img-logo">
                <img src="../../../../assets/images/NIPR_IMG.png">
              </div>
              <div class="img-logo">
                <img src="../../../../assets/images/Salesforce-IMG.png">
              </div>
              <div class="img-logo">
                <img src="../../../../assets/images/WebCE.png">
              </div>
              <div class="img-logo">
                <img src="../../../../assets/images/Workday-IMG.png">
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="modal-footer">
          <div class="text-right coming-soon-model-btn">
            <button type="button" (click)="closeModal()" class="commn-btn">
              Got it!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>