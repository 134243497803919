<div class="main-layout-grid">
  <main id="main-container">
    <div class="data-content custom-height-center">
      <div class="content-width">
        <app-left-part></app-left-part>
      </div>
      <div class="content-width right">
        <div class="right-side-content before-login-page">
          <div class="container">
            <div class="form-details">
              <div class="forms-data">
                <form class="reset-pass-form" [formGroup]="passwordRequestForm" (ngSubmit)="onSubmit()">
                  <div class="form-block-title">
                    <h3 class="form-title">Enter user name to receive activation link in registered email </h3>
                    <div class="form-inputs-data">
                      <div class="form-group" maxlength="50" [class.error]="((passwordRequestForm.get('userName')!.touched ||
                      passwordRequestForm.get('userName')!.dirty) &&
                      passwordRequestForm.get('userName')!.errors)">
                        <input type="text" class="form-control form-input" name="username" id="userName" formControlName="userName" placeholder=" ">
                        <label class="form-label">User Name</label>
                        <!--<div class="form-icon">
                          <svg class="email-icon" id="icons_ic-mail" data-name="icons / ic-mail" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <rect id="Rectangle_454" data-name="Rectangle 454" width="24" height="24" fill="none" />
                            <path id="alternate_email_FILL0_wght300_GRAD0_opsz24" class="fill-color" d="M12,21.5a9.263,9.263,0,0,1-3.712-.75A9.432,9.432,0,0,1,3.25,15.712,9.263,9.263,0,0,1,2.5,12a9.24,9.24,0,0,1,.75-3.725A9.466,9.466,0,0,1,8.288,3.25,9.263,9.263,0,0,1,12,2.5a9.24,9.24,0,0,1,3.725.75A9.5,9.5,0,0,1,20.75,8.275,9.24,9.24,0,0,1,21.5,12v1.225a3.183,3.183,0,0,1-.938,2.325,3.119,3.119,0,0,1-2.312.95,3.141,3.141,0,0,1-2.825-1.625,5.11,5.11,0,0,1-1.462,1.175A4.089,4.089,0,0,1,12,16.5,4.48,4.48,0,0,1,7.5,12,4.48,4.48,0,0,1,12,7.5,4.48,4.48,0,0,1,16.5,12v1.225a1.75,1.75,0,1,0,3.5,0V12a7.7,7.7,0,0,0-2.363-5.638A7.7,7.7,0,0,0,12,4,7.7,7.7,0,0,0,6.362,6.362,7.7,7.7,0,0,0,4,12a7.7,7.7,0,0,0,2.362,5.637A7.7,7.7,0,0,0,12,20h4.25a.75.75,0,1,1,0,1.5ZM12,15a2.988,2.988,0,0,0,3-3,2.988,2.988,0,0,0-3-3,2.988,2.988,0,0,0-3,3,2.988,2.988,0,0,0,3,3Z" transform="translate(-0.5 -0.5)" fill="#757575" />
                          </svg>
                        </div>-->
                        <app-error [controlName]="passwordRequestForm.controls['userName']">
                        </app-error>
                      </div>
                    </div>
                    <div class="action-btn">
                      <!--<button type="submit" class="btn commn-btn disabled">Reset Password</button>-->
                      <button type="submit" class="btn commn-btn">Continue</button>
                      <a href="javascript:void()" routerLink="/login" class="btn commn-btn-plain">Back</a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
        
      </div>
    </div>
  </main>
</div>
