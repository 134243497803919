
 import { Component, OnInit } from '@angular/core';
 import { FormBuilder, FormGroup, Validators } from '@angular/forms';
 import { Router } from '@angular/router';
 import { AccountService } from '../../../api-services/account.service';
 import { ValidatorHelper } from '../../custom-validators/validators.helper';

 @Component({
   selector: 'app-send-activation',
   templateUrl: './send-activation.component.html',
   styleUrls: ['./send-activation.component.css']
 })
 export class SendActivationComponent implements OnInit {
   errorMessages: any;
   passwordRequestForm!: FormGroup;
   isError: boolean = true;
   constructor(private router: Router, private fb: FormBuilder, private accountService: AccountService) { }

   ngOnInit(): void {
     this.passwordRequestForm = this.fb.group({
       email: ['', [Validators.required, ValidatorHelper.emailPattern]],
       activationCode: ['', []]
     });
   }

   onSubmit(): void {
     this.passwordRequestForm.markAllAsTouched();
     if (this.passwordRequestForm.valid) {
       
       this.accountService.sendActivationLink(this.passwordRequestForm.value).subscribe(
         (response: any) => {
           if (response.userId > 0) {
             if (response.statusId == 2) {
               this.errorMessages = [];
               this.isError = true;
               this.errorMessages.push("User already Activated !!");
             } else {
               this.errorMessages = [];
               this.isError = false;
               this.errorMessages.push("Activation link sent in mail to reset Password");
             }
           }
           else {
             this.errorMessages = [];
             this.isError = true;
             this.errorMessages.push("Something went wrong.");
           }
         },
         (error: any) => {
           this.errorMessages = [];
           this.errorMessages.push(error.message);
         }
       )
     }

   }
 }
