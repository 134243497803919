<div class="penalty-info-section">
    <a href="javascript:void(0)" class="penalty-img">
        <!-- (click)="goToDashboard()"  -->
        <img src="../../../../../../assets/images/logo-text.svg" alt="">
    </a>
</div>
<div class="penalty-container">
    <div class="penalty-info-section">
        <div class="data">
            <div class="penalty-img">
                <img src="../../../../../../assets/images/earth-icon.svg" alt="">
            </div>
            <div class="title">
                <h2>State Penalties</h2>
            </div>
        </div>
    </div>
    <table>
        <tr>
            <th class="fisrt">State</th>
            <th class="second">Fee</th>
            <th class="third">Fee disclaimer</th>
        </tr>
        <tr>
            <td class="fisrt">Alabama</td>
            <td class="second">$50.00</td>
            <td class="third">Address Violation Fee NIPR will collect the penalty fee in CCR if the address change is over 30 calendar days. Calculated based upon the effective date of the address change entered by the insurance producer in CCR.</td>
        </tr>
        <tr>
            <td rowspan="3" class="fisrt">Alaska</td>
            <td class="second border-none">$50.00</td>
            <td class="third border-none">Submitted 31-90 days after move date</td>
        </tr>
        <tr>
            <td class="second border-none">$100.00</td>
            <td class="third border-none">Submitted 91-150 days after move date</td>
        </tr>
        <tr>
            <td class="second">$200.00</td>
            <td class="third">Submitted 150 days or more from the move date</td>
        </tr>
        <tr>
            <td class="fisrt">Louisiana</td>
            <td class="second ">$50.00</td>
            <td class="third">State Late Fee (insurance producers and Adjusters) 31 or more days from the address change effective date</td>
        </tr>
        <tr>
            <td class="fisrt">Oklahoma</td>
            <td class="second">$50.00</td>
            <td class="third">Address Violation - Applied if the requested effective date is greater than thirty (30) days or applicant has an address violation.</td>
        </tr>
    </table>
    <div class="info">Visit <a href="https://nipr.com/licensing-center/state-requirements" target="_blank">NIPR</a> to for additional information.</div>
</div>
<app-footer></app-footer>