import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContactService } from 'api-services/contact.service';
import { LinesOfAuthorityService } from 'api-services/lines-of-authority.service';
import { AdminDashboardRequest } from 'models/admin-dashboard/admin-dashboard-request.model';
import { DashboardScorecard } from 'models/dashboard-scorecard.model';
import { State } from 'models/state.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RoleService } from 'services/role.service';
import { ContactDashboardStoreProcedure } from 'shared/constant';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { SharedService } from '../../../services/shared.service';
import { environment } from '../../../../environments/environment';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-contact-scorecard',
  templateUrl: './contact-scorecard.component.html',
  styleUrls: ['./contact-scorecard.component.css']
})
export class ContactScorecardComponent implements OnInit {
 
  isContactUser = false;
  userId: number;
  public totalCount = 0;
  userProfileArray: any;
  userProfile: any;
  request: AdminDashboardRequest = {
    jsonRequest: '',
    code:ContactDashboardStoreProcedure.GetContactPersonalInformationSummary
  };
  overallScore:string;
  contactScorecardReqtParam: DashboardScorecard = {} as DashboardScorecard;
  scorecardList:any = [];
  selectedStates: State[];
  states: State[] = [] as State[];
  LOAsList: any;
  selectedLOAsList: any;
  dropdownSettingState: IDropdownSettings;
  dropdownSettingLOA: IDropdownSettings;
  scorecardReqtParam: DashboardScorecard = {} as DashboardScorecard;
  sortDir = 0;
  overallCount: number = 0;
  pagCount: number = 1;
  range: number[] = [0, 0] as number[];
  allChecked = false;
  isShow = true;
  constructor(private contactService: ContactService,
    private roleService: RoleService, private loaService: LinesOfAuthorityService, private router: Router, private sharedService: SharedService) { }
   
  ngOnInit(): void {

    this.contactScorecardReqtParam.tenantId = Number(localStorage.getItem('tenantId'));
    this.contactScorecardReqtParam.sortOrder = 0;
    this.contactScorecardReqtParam.pageNum = 1;
    this.contactScorecardReqtParam.pageSize = parseInt(environment.pageSize);

    this.dropdownSettingState = {
      singleSelection: false,
      idField: 'stateId',
      textField: 'stateName',
      selectAllText: 'Select All',
      unSelectAllText: 'Clear All',
      allowSearchFilter: true,
      itemsShowLimit: 1,
    };

    // get selected user name 
    this.isContactUser = this.roleService.isContactUser;
    this.userId = Number(localStorage.getItem("activeContactUserId"));
    this.request.contactId = this.userId;
    this.contactService.getContactDashboardDetail(this.request).subscribe(
      (response: any) => {
        this.userProfileArray = JSON.parse(response.jsonResponse);
        if (this.userProfileArray != null && this.userProfileArray.length > 0) {
          this.userProfile = this.userProfileArray[0];
          //console.log(this.userProfile);
        }        
      },
      (error: any) => {
        console.log(error);
      }
    );

    this.loadStates();
    this.getSelectedGroupList();
  }

  getSearchSelectedGroupList() {
    this.contactScorecardReqtParam.pageNum = 1;
    this.getSelectedGroupList();
  }

  clearSearch(){
    this.contactScorecardReqtParam.search = '';
    this.getSearchSelectedGroupList();
  }

  getSearchSelectedstate()
 {
  this.contactScorecardReqtParam.pageNum = 1;
  var stateIds = null;
  if (this.selectedStates && this.selectedStates.length > 0) {
    stateIds = this.selectedStates.map(function (val) {
      return val.stateId
    }).join(',');
  }
  this.contactScorecardReqtParam.userId = this.userId;
  this.contactScorecardReqtParam.states = stateIds;
  this.loaService.getContactScorecardList(this.contactScorecardReqtParam).subscribe((res:any) => {
    this.scorecardList = res;
    //console.log(this.scorecardList);
    this.overallCount = this.scorecardList[0]?.overallCount? this.scorecardList[0]?.overallCount : this.scorecardList.length ;
  this.createRange();
  })

 }

  getSelectedGroupList() {
    var stateIds = null;
    if (this.selectedStates && this.selectedStates.length > 0) {
      stateIds = this.selectedStates.map(function (val) {
        return val.stateId
      }).join(',');
    }
    this.contactScorecardReqtParam.userId = this.userId;
    this.contactScorecardReqtParam.states = stateIds;
    this.loaService.getContactScorecardList(this.contactScorecardReqtParam).subscribe((res:any) => {
      this.scorecardList = res;
      //console.log(this.scorecardList);
      
      this.overallCount = this.scorecardList[0]?.overallCount? this.scorecardList[0]?.overallCount : this.scorecardList.length ;
      //this.overallScore = this.scorecardList.reduce((total, val) => {
      //  return total + val.completion
      //}, 0) / this.overallCount;
      this.overallScore = localStorage.getItem("contactOverallScore");
      this.createRange();
    })
  }
  onSortClick(event, colName) {
    let target = event.currentTarget,
      classList = target.classList;
      
    if (classList.contains('sorting-icon-asc')) {
      classList.remove('sorting-icon-asc');
      classList.add('sorting-icon-des');
      this.sortDir = 1;
    } else {
      classList.add('sorting-icon-asc');
      classList.remove('sorting-icon-des');
      this.sortDir = 0;
    }
    this.contactScorecardReqtParam.sortBy = colName;
    this.contactScorecardReqtParam.sortOrder = this.sortDir;
    this.getSelectedGroupList();
  }



// get data of states
loadStates() {
  this.loaService.getSupportedStates().subscribe(
    response => {
      this.states = response;
},
    error => {
      if (error.status == 0) {

      }
      else {
        
      }
    }
  )
}

// get data of LOAs
loadLoas(){
  
}

  gotoDetailView(selAgent) {
  selAgent["overallScore"] = this.overallScore;
  localStorage.setItem("selContact", JSON.stringify(selAgent))
  this.sharedService.sendUpdate(true);
  this.router.navigate(['/contact-dashboard/detail-scorecard']);
}

// pagination
createRange() {
  var pageSize = this.contactScorecardReqtParam.pageSize;
  var pageNum = this.contactScorecardReqtParam.pageNum;

  this.pagCount = this.overallCount < pageSize ? 1 : Math.ceil(this.overallCount / pageSize);

  var offSet = pageNum * pageSize;
  this.range = [this.scorecardList.length > 0 ? ((pageNum - 1) * pageSize +1):0, Math.min(pageNum * pageSize, this.overallCount)]

}

 // select all data from table
 selectAll(value: boolean) {
  this.allChecked = value;
  this.scorecardList = this.scorecardList.map(m => ({ ...m, isChecked: value }));
  this.isShow = !this.isShow;
}

selectChkVal(list: any): void {
  list.isChecked = !list.isChecked;    
}

// pagination next and back functionality 
next() {
  if (this.contactScorecardReqtParam.pageNum <= this.pagCount) {
    this.contactScorecardReqtParam.pageNum++;
    this.getSelectedGroupList();
  }
}

back() {
  if (this.contactScorecardReqtParam.pageNum > 1)
    this.contactScorecardReqtParam.pageNum--;
    this.getSelectedGroupList();
}

openModal= "close";
openComingSoonModal(){
  this.openModal = "open";
}
modalStatus(eve){
  this.openModal= eve;
}

}
