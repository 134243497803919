import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'components/shared-component/confirm-dialog/confirm-dialog.component';
import { catchError, from, Observable, of, Subject } from 'rxjs';

export enum toastTypes {
  error,
  success,
}

export interface ToastData {
  title?: string;
  content?: string;
  show?: boolean;
  type?: toastTypes;
  progressWidth?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  data: ToastData;
  public open = new Subject<ToastData>();

  constructor(private ngbModal: NgbModal) { }

  confirm(
    prompt = 'Really?', title = 'Confirm'
  ): Observable<boolean> {
    const modal = this.ngbModal.open(
      ConfirmDialogComponent, { backdrop: 'static' });

    modal.componentInstance.prompt = prompt;
    modal.componentInstance.title = title;

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  alert(
    message: string
  ) {
     
    let data: ToastData = {
      content: message
    };
    if (data.type) {
      this.data.type = toastTypes.error;
    }
    this.data = { ...data, show: true, progressWidth: '100%' };
    this.open.next(this.data);
  }




  hide() {
    this.data = { ...this.data, show: false };
    this.open.next(this.data);
  }

  custom<R>(
    content: any,
    config?: { [index: string]: any; },
    options?: NgbModalOptions
  ): Observable<R> {
    // we use a static backdrop by default,
    // but allow the user to set anything in the options
    const modal = this.ngbModal.open(
      content,
      { backdrop: 'static', ...options }
    );

    // copy the config values (if any) into the component
    Object.assign(modal.componentInstance, config);

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }
}
