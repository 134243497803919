import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService, LookupService } from 'api-services';
import { State } from 'models';
import { SharedService } from 'services';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  errorMessages: any;
  profileForm!: FormGroup;
  states: any = [];
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private accountService: AccountService,
    public sharedService: SharedService,
    private lookupService: LookupService
  ) {}

  ngOnInit(): void {
    this.profileForm = this.fb.group({
      id: [],
      tenantId: [],
      tenantTypeId: [],
      businessName: ['', [Validators.required]],
      addressLine1: ['', [Validators.required]],
      addressLine2: ['', []],
      addressLine3: ['', []],
      city: ['', [Validators.required]],
      zipCode: ['', []],
      stateId: ['', []],
      carrierId: [],
      carrierName: ['', []],
      carrierAddress1: ['', []],
      carrierAddress2: ['', []],
      carrierAddress3: ['', []],
      carrierCity: ['', []],
      carrierZipCode: ['', []],
      carrierStateId: ['', []],
      branchId: [],
      branchName: ['', []],
      branchAddress1: ['', []],
      branchAddress2: ['', []],
      branchAddress3: ['', []],
      branchCity: ['', []],
      branchZipCode: ['', []],
      branchStateId: ['', []],
      firstName: ['', []],
      lastName: ['', []],
      email: ['', []],
      phoneNumber: ['', []],
      npNumber: ['', []],
    });
    this.lookupService.getStates().subscribe(
      (response: any) => {

        this.states = <State[]>response;
      },
      (error: any) => {
        console.log(error);
      }
    );
    this.accountService
      .getUserProfile(this.sharedService.getValue('userId'))
      .subscribe(
        (response: any) => {
          this.profileForm.patchValue({
            tenantId: response.tenantId,
            tenantTypeId: response.tenantTypeId,
            businessName: response.businessName,
            addressLine1: response.addressLine1,
            addressLine2: response.addressLine2,
            addressLine3: response.addressLine3,
            city: response.city,
            zipCode: response.zipCode,
            stateId: response.stateId,
            carrierId: response.carrierId,
            carrierName: response.carrierName,
            carrierAddress1: response.carrierAddressLine1,
            carrierAddress2: response.carrierAddressLine2,
            carrierAddress3: response.carrierAddressLine3,
            carrierCity: response.carrierCity,
            carrierZipCode: response.carrierZipCode,
            carrierStateId: response.carrierStateId,
            branchId: response.branchId,
            branchName: response.branchName,
            branchAddress1: response.branchAddressLine1,
            branchAddress2: response.branchAddressLine2,
            branchAddress3: response.branchAddressLine3,
            branchCity: response.branchCity,
            branchZipCode: response.branchZipCode,
            branchStateId: response.branchStateId,
            id: response.id,
            firstName: response.firstName,
            lastName: response.lastName,
            email: response.email,
            phoneNumber: response.phoneNumber,
            npNumber: response.npNumber,
          });
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  onSubmit(): void {
    this.errorMessages = [];
     
    if (this.profileForm.valid) {
      this.accountService.userProfileUpdate(this.profileForm.value).subscribe(
        (response: any) => {
          if (response.id > 0) {
            this.router.navigate(['/list']);
          } else {
            this.errorMessages.push(response.rtnMessage);
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }
}
