import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserStorageService } from '../../../../api-services/user-storage.service';
import { SharedService } from '../../../../services';
import { FileCategory } from '../../../../shared/constant';

@Component({
  selector: 'app-upload-attachment-modal',
  templateUrl: './upload-attachment-modal.component.html',
  styleUrls: ['./upload-attachment-modal.component.css']
})
export class UploadAttachmentModalComponent implements OnInit {

  fileStoreTypeId = localStorage["fileStoreTypeId"];
  contactId = localStorage.getItem("activeContactUserId") ? localStorage.getItem("activeContactUserId") : localStorage.getItem('userId');
  tenantId = localStorage.getItem('tenantId');
  userId = localStorage.getItem('userId');

  @Input() selectedFiles;
  @Input() fileCategory;

  newUploadedFile = [];
  fileTypeList: any = [];
  dropdownSettingFileType: IDropdownSettings;

  errorMessages = [];
  errorMessagesFileFormat = [];
  errorMessagesFileData = [];
  isfileErrorShow = false;

  uploadFile: {
    id: number,
    fileCategoryId: number,
    file: File,
    fileName: string,
    fileTypeId: string,
    description: string,
    errorMessagesFileFormat?: string,
    errorMessagesFileSize?: string,
    errorMessagesFileType?: string
  }

  fileUploadValid = true;

  constructor(public activeModal: NgbActiveModal, private userStorageService: UserStorageService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.getSelectedFiles();

    this.getFileTypeByCategory();
    this.GetValidFiles();
  }

  getSelectedFiles() {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.uploadFile = {
        id: i + 1,
        fileCategoryId: this.fileCategory.fileCategoryId,
        file: this.selectedFiles[i],
        fileName: this.selectedFiles[i].name,
        fileTypeId: null,
        description: "",
      };

      this.newUploadedFile.push(this.uploadFile);
    }
  }

  close(): void {
    this.activeModal.close(true);
  }

  getFileTypeByCategory() {
    this.userStorageService.getFileTypeByCategory(this.fileCategory.fileCategoryId).subscribe(
      (response: any) => {
        this.fileTypeList = response;
        if(this.fileTypeList.length == 1){
          this.newUploadedFile.forEach(el => el.fileTypeId = this.fileTypeList[0].fileTypeId);
        }
      },
      (error: any) => {
        console.log(error);
      });
  }

  private GetValidFiles() {
   // this.newUploadedFile = [];
    this.errorMessagesFileFormat = [];

    let isValid = true;
    this.fileUploadValid = true;
    let invalidFileFormat = [];
    let invalidFileSize = [];
    const supportedExt = "application/pdf, image/jpg, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"

    for (let i = 0; i < this.newUploadedFile.length; i++) {
      let file = this.newUploadedFile[i].file;

      let ext = file.type;
      let size = file.size;

      if (supportedExt.includes(ext)) {
        isValid = true;
        if (size > 10485760) {
          this.fileUploadValid = false;
          this.newUploadedFile[i].errorMessagesFileSize = "Invalid file size! Max file size 10Mb"
        }
      } else {
        this.fileUploadValid = false;
        this.newUploadedFile[i].errorMessagesFileFormat = "Invalid file format! Allowed file format is .pdf, .doc, .docx, .jpg, .jpeg, and .xlsx"
      }

      //  if (isValid == true) {
      //this.isfileErrorShow = this.selectedFiles.length > 0 ? true : false;
      // this.BindValidFilesToGrid(i);
      // }
    }

    //if (invalidFileFormat.length > 0) {
    //  let invalidFileNames = invalidFileFormat.join(", ");
    //  this.errorMessagesFileFormat.push('Invalid file(s) format! Allowed file format is .pdf, .doc, .docx, .jpg, .jpeg, .xlsx (Invalid Files: ' + invalidFileNames + ')');
    //  this.isfileErrorShow = true;
    //}

    //else if (invalidFileSize.length > 0) {
    //  let invalidFileNames = invalidFileSize.join(", ");
    //  this.errorMessagesFileFormat.push('Invalid file(s) size! Max file size 10Mb (Invalid Files: ' + invalidFileNames + ')');
    //  this.isfileErrorShow = true;
    //}

  }

  private BindValidFilesToGrid(i: number) {

    this.uploadFile = {
      id: i + 1,
      fileCategoryId: this.fileCategory.fileCategoryId,
      file: this.selectedFiles[i],
      fileName: this.selectedFiles[i].name,
      fileTypeId: null,
      description: ""
    };

    this.newUploadedFile.push(this.uploadFile);
  }

  uploadFiles() {
    this.errorMessagesFileData = [];
    this.errorMessages = [];
    this.sharedService.showLoader();
    var fileCategoryId = this.fileCategory.fileCategoryId;
    var files = this.newUploadedFile;
    var formData = new FormData();
    let valid = true;
    let unseletedFileType = [];

    for (var i = 0; i < this.newUploadedFile.length; i++) {
      let fileName = files[i].fileName;

      if (files[i].fileTypeId == null || files[i].fileTypeId.length == 0) {
        unseletedFileType.push(fileName);
        valid = false;

        this.newUploadedFile[i].errorMessagesFileType = "File Type is not selected";
      }
      else {
        formData.append('file', files[i].file, files[i].fileName);
        formData.append('fileName', files[i].fileName);
        formData.append('fileTypeId', files[i].fileTypeId);

        if (fileCategoryId == FileCategory.Notes) {
          formData.append('noteId', this.fileCategory.attachmentNoteId);
        }
        else if (fileCategoryId == FileCategory.Carrier) {
          formData.append('carrierId', this.fileCategory.attachmentNoteId);
        }
        else if (fileCategoryId == FileCategory.Branches) {
          formData.append('branchId', this.fileCategory.attachmentNoteId);
        }
        else if (fileCategoryId == FileCategory.ResourceLibrary) {
          formData.append('resourceLibraryNoteId', this.fileCategory.attachmentNoteId);
        }

        formData.append('description', files[i].description == "" ? "//" : files[i].description + '//');
      }
    }

    if (valid == false) {
      //if (unseletedFileType.length > 0) {
      //  this.errorMessagesFileData.push("File Type is not selected for file(s): " + unseletedFileType.join(", "));
      //}
      this.sharedService.hideLoader();
    }
    else {
      this.userStorageService.UploadContactFiles(this.contactId, this.fileStoreTypeId, fileCategoryId, formData).subscribe(
        response => {
          if (response.toLowerCase() == "success") {
            this.sharedService.showToastMessage("File(s) uploaded successfully");
            this.activeModal.close(true);
          }
          else if (response.toLowerCase() == "nostorage") {
            this.errorMessages.push("Not enough storage to upload file(s).");
          }

          this.sharedService.hideLoader();

        },
        error => {
         // console.log(error);
          this.sharedService.hideLoader();
          this.errorMessages.push(error.message);
        });
    }
  }

  removedSelectedFile(id) {
    const index = this.newUploadedFile.findIndex(f => f.id == id);
    if (index > -1) {
      this.newUploadedFile.splice(index, 1);
     // this.selectedFiles.splice(index, 1);
    }

    if (this.newUploadedFile.length == 0) {
      this.isfileErrorShow = false; // !this.isfileErrorShow;
      this.activeModal.close(false);
    }

    this.errorMessagesFileFormat = [];
    this.errorMessagesFileData = [];
    this.GetValidFiles();
  }

  //(change) = "validateFileType($event, file)"
  //validateFileType(evt, file) {
  // // debugger
  //}
}
