import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../../api-services';
import { SharedService } from '../../../services';
import { ValidatorHelper } from '../../custom-validators/validators.helper';

@Component({
  selector: 'app-change-pwd',
  templateUrl: './change-pwd.component.html',
  styleUrls: ['./change-pwd.component.css']
})
export class ChangePwdComponent implements OnInit {
  errorMessages = [];
  changePwdForm!: FormGroup;
  userEmail: string;
  constructor(private router: Router, private fb: FormBuilder, private route: ActivatedRoute, private accountService: AccountService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.changePwdForm = this.fb.group({
      userId: [this.sharedService.getValue("userId")],
      password: ['', [ValidatorHelper.passwordPattern, Validators.required,]],
      confirmPassword: ['', [ValidatorHelper.passwordPattern, Validators.required,]]
    });
    this.changePwdForm.markAsUntouched();
  }

  onSubmit(): void {
    this.errorMessages = [];
    this.changePwdForm.markAllAsTouched();
     ;
    if (this.changePwdForm.get("password")?.value === this.changePwdForm.get("confirmPassword")?.value) {
      this.accountService.chgPwd(this.changePwdForm.value).subscribe(
        (response: any) => {

              this.router.navigate(['/carrier']);
          

        },
        (error: any) => {
          console.log(error);
        }
      )
    } else {
      this.errorMessages.push('Password and Confirm Password are not same !!');
    }

  }
}
