import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ApiClientService } from './common/api-client.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileSettingService {

  constructor(
    private apiClient: ApiClientService,
    private authService: AuthService) { }


  post(requestObject: any) {
    return this.apiClient.post(`api/UserDummy`, requestObject, null, true);
  }

  updateProfile(requestObject: any) {
    return this.apiClient.put(`api/UserProfileSetting/UpdateUserProfile`, requestObject, null, true);
  }

  changePassword(requestObject: any) {
    return this.apiClient.put(`api/UserProfileSetting/changePassword`, requestObject, null, true);
  }

  closeAccount(requestObject: any) {
    return this.apiClient.put(`api/UserProfileSetting/closeAccount`, requestObject, null, true);
  }


  updateNIPRCredential(requestObject: any) {
    return this.apiClient.put(`api/UserProfileSetting/updateNIPRCredential`, requestObject, null, true);
  }

  delete(id: number) {
    return this.apiClient.delete(`api/UserDummy/${id}`, null, null, true);
  }

  getAll() {
    return this.apiClient.get(`api/UserDummy`, null, null, true);
  }

  getUserProfile(userId: number, tenantId: number) {
    return this.apiClient.get(`api/UserProfileSetting/GetUserProfile/${userId}/${tenantId}`, null, null, true);
  }

  getTenantPaymentMethodDetails(tenantId: number) {
    return this.apiClient.get(`api/Payment/tenantPaymentMethodDetails/${tenantId}`, null, null, true);
  }

  getTenantSubscriptionInfo(tenantId: number) {
    return this.apiClient.get(`api/Payment/tenantSubscriptionInfo/${tenantId}`, null, null, true);
  }

  getUpcomingTenantPaymentDetails(tenantId: number) {
    return this.apiClient.get(`api/Payment/getUpcomingTenantPaymentDetails/${tenantId}`, null, null, true);
  }

  getTenantInvoiceList(tenantId: number) {
    return this.apiClient.get(`api/Payment/getTenantInvoiceList/${tenantId}`, null, null, true);
  }

  sendInvoiceToEmail(request: any) {
    return this.apiClient.post(`api/Payment/sendInvoiceToEmail`, request, null, true);
  }
  
  UpdateNIPRandStripeSubscription(requestObject: any) {
    return this.apiClient.put(`api/UserProfileSetting/UpdateNIPRandStripeSubscription`, requestObject, null, true);
  }

  allowAutoUploadCEFiles(state:boolean) {
    return this.apiClient.post(`api/UserProfileSetting/AllowAutoUploadCEFiles/${state}`, null, null, true);
  }
 
}
