import { Injectable } from '@angular/core';
import { ApiClientService } from 'api-services';
import { AuthService } from 'services';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private apiClient: ApiClientService,
    private authService: AuthService
  ) { }

  public loginUser(userLogin: any) {

    return this.apiClient.post('api/user/login', userLogin, null);
  }
  public getUser(userId: number) {

    return this.apiClient.get(`api/user/userlog/${userId}`, null, null);
  }
  //public setToken(token: string) {
  //  this.authService.userLoggedIn(token);
  //  this.apiClient.setHeader("Authorization", `bearer ${token}`);
  //}
  public setToken(tokenresponse: any, token: string) {
    this.authService.userLoggedIn(tokenresponse);
    this.apiClient.setHeader('Authorization', `bearer ${token}`);
  }
  public listUser() {

    return this.apiClient.get('api/user/list');
  }

  public userReg(userReg: any) {

    return this.apiClient.post('api/tenant', userReg, null, true);
  }

  public userActivate(activateUser: any) {

    return this.apiClient.post('api/user/activate', activateUser, null, false);
  }
  public setForgotPwd(password: any) {

    return this.apiClient.post('api/user/setforgotpassword', password, null, false);
  }
  public setPwd(password: any) {

    return this.apiClient.post('api/user/setpassword', password, null, false);
  }
  public resetPwd(password: any) {

    return this.apiClient.post('api/user/resetpassword', password, null, false);
  }
  public chgPwd(password: any) {

    return this.apiClient.post('api/user/changepassword', password, null, false);
  }
  public forgetPwd(userName: any) {

    return this.apiClient.post('api/user/forgetpassword', userName, null, true);
  }

  public sendActivationLink(email: any) {

    return this.apiClient.post('api/user/sendactivation', email, null, true);
  }


  public userProfileUpdate(userProfile: any) {

    return this.apiClient.post('api/tenant/profile', userProfile, null, false);
  }

  public getUserProfile(id: number) {

    return this.apiClient.get(`api/tenant/${id}`, null, null, false);
  }
  public domainupdate(userOtherProf: any) {

    return this.apiClient.post('api/tenant/domainupdate', userOtherProf, null, false);
  }
  public userOtherProf(userOtherProf: any) {

    return this.apiClient.post('api/tenant/otherprofile', userOtherProf, null, false);
  }

  public updateNIPRCredential(userOtherProf: any) {

    return this.apiClient.post('api/tenant/updateNIPRCredential', userOtherProf, null, false);
  }
  public validatePasswordActivation(validateUser: any) {
    return this.apiClient.post(`api/user/validatepasswordactivation`, validateUser, null, false);
  }
  public validateForgotPasswordActivation(validateUser: any) {
    return this.apiClient.post(`api/user/validateForgotPasswordActivation`, validateUser, null, false);
  }
  public logout() {
    this.authService.userLoggedOut();
  }

  public UserSignOut() {
    return this.apiClient.post(`api/user/userSignOut`, 1, null, false);
  }

  public UpdateUserStatus(user: any) {
    return this.apiClient.post(`api/user/updateUserStatus`, user, null, true);
  }
}
