import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, AbstractControlDirective } from '@angular/forms';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  errorMsgList: any = [];

  @Input() controlName: AbstractControl | AbstractControlDirective

  errorMessage = {
    'required': (params) => `This field is required`,
    'email': (params) => `Invalid email address`,
    'maxlength': (params) => `Maximum ${params.requiredLength} characters are allowed`,
    'minlength': (params) => `Minimum ${params.requiredLength} characters are required`,
    'pattern': (params) => `Invalid format`,
    'min': (params) => `Minimum amount should be ₹ ${params.min}`,
    'whitespace': (params) => `White spaces are not allowed`,
    'number': (params) => `Only numeric value allowed`,
    'cannotContainSpace': (params) => `Space not allowed`,
    'mismatch': (params) => `Password and confirm password should be match.`,
    'mismatchNPN': (params) => `NPN and confirm NPN should be match.`,
    'isValidSubDomain': (params) => `Invalid domain name`,
    'passwordPattern': (params) => `Use 8 or more characters with a mix of letters, numbers & symbols`,
    'emailPattern': (params) => `Invalid email address`,
    'oldnNewPassword': (params) => `New password cannot be same as old password`,
    'restrictedDomain': (params) => `Please enter a carrier domain name excluding gmail.com, yahoo.com, aol.com, msn.com, hotmail.com, rediffmail.com, and outlook.com.`,
  };
  constructor() { }

  ngOnInit(): void {
  }



  listErrors() {
    if (!this.controlName) return [];
    if (this.controlName.errors) {
      this.errorMsgList = [];
      Object.keys(this.controlName.errors).map(error => {
        this.controlName.touched || this.controlName.dirty ?
          this.errorMsgList.push(this.errorMessage[error](this.controlName.errors[error])) : '';
      });
      return this.errorMsgList;
    }
    else {
      return [];
    }
  }
}
