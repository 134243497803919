import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ChatService } from '../../../api-services/chat.service';
import { ChatMessage } from '../../../models/chats/chat-messages';
import { ChatSession, ChatToken } from '../../../models/chats/chat-sessions';
import * as _ from 'underscore';
import { SharedService } from 'services';
import { SearchChatHistory } from '../../../models/chats/search-chat-history';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  userMessage: string = '';
  isTyping: boolean = false;
  chatSessions: ChatSession[] = [] as ChatSession[];
  chatMessages: ChatMessage[][] = [] as ChatMessage[][];
  selectedSession: ChatSession = null;
  groupedSessions: any = {}; // To store grouped sessions
  userChatTokenDetails = {} as ChatToken;
  isMenuOpen: { [key: number]: boolean } = {};
  sidebarExpanded: boolean = true;
  isFullScreenView: boolean = false;
  tokenRefillDiff: string = null;
  searchChatHistory: SearchChatHistory = { pageNumber: 1, pageSize: 10, searchName: null } as SearchChatHistory;
  loading = false;
  allDataLoaded = false;
  isNoteClosed: boolean = false
  @ViewChild("chatInput") private chatInputEle: ElementRef;
  @Output() closePopup = new EventEmitter();

  constructor(private chatService: ChatService, private shareService: SharedService) {
    this.loadChatSessions();
  }

  ngOnInit(): void {
    const scrollContainer = document.querySelector('.session-list');
    if (scrollContainer) {
      fromEvent(scrollContainer, 'scroll')
        .pipe(debounceTime(200))
        .subscribe(() => this.onScroll());
    }
  }

  loadChatSessions() {
    if (this.loading || this.allDataLoaded) return;

    this.loading = true; 

    this.chatService.getUserChatHistory(this.searchChatHistory).subscribe((sessions) => {

      this.chatSessions = [...this.chatSessions, ...sessions];
      this.loading = false;

      if (sessions.length < this.searchChatHistory.pageSize) {
        this.allDataLoaded = true;
      } else {
        this.searchChatHistory.pageNumber++;
      }

      this.chatSessions = this.chatSessions.map(el => ({
        ...el,
        createdDate: this.shareService.convertUTCTimeToLocalTime(el.createdDate),
        chatMessages: el.chatMessages.map(em => ({
          ...em,
          createdDate: this.shareService.convertUTCTimeToLocalTime(em.createdDate)
        }))
      }));
      this.groupChatSessions();
    });
  }

  groupChatSessions() {
    const now = new Date();
    this.groupedSessions = {
      today: [] as ChatSession[],
      yesterday: [] as ChatSession[],
      thisMonth: [] as ChatSession[],
    };

    this.chatSessions.forEach((session) => {
      const createdDate = new Date(session.latestMessageDate);

      // Check if session was created today
      if (this.isSameDay(createdDate, now)) {
        this.groupedSessions.today.push(session);
      }
      // Check if session was created yesterday
      else if (this.isSameDay(createdDate, this.getYesterday(now))) {
        this.groupedSessions.yesterday.push(session);
      }
      // Check if session was created in the same month
      else if (
        createdDate.getFullYear() === now.getFullYear() &&
        createdDate.getMonth() === now.getMonth()
      ) {
        this.groupedSessions.thisMonth.push(session);
      }
    });
  }

  onScroll(): void {
    const container = document.querySelector('.session-list');

    if (!container || this.loading || this.allDataLoaded) return;

    const scrollOffset = container.scrollTop + container.clientHeight;
    const scrollThreshold = container.scrollHeight - 5;

    if (scrollOffset >= scrollThreshold) {
      this.loadChatSessions();
    }
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  getYesterday(date: Date): Date {
    const yesterday = new Date(date);
    yesterday.setDate(date.getDate() - 1);
    return yesterday;
  }

  loadChatMessages(chatSession: ChatSession) {
    this.chatMessages = [];
    this.selectedSession = chatSession;

    chatSession.chatMessages = chatSession.chatMessages.map(el => ({
      ...el,
      date: moment(el.createdDate).format('yyyyMMDD'),
      displayDate: this.getDateDiff(el.createdDate)
    }));

    _.mapObject(_.groupBy(chatSession.chatMessages, "date"), (val, keys) => {
      this.chatMessages.push(val)
    });
    this.chatInputEle.nativeElement.focus();
    this.scrolltoBottom();
  }

  getDateDiff(date) {
    let lastMessageDate = moment(date).format('yyyyMMDD');
    let today = moment(new Date).format('yyyyMMDD');

    let diff = Number(today) - Number(lastMessageDate);

    switch (diff) {
      case 0:
        return "Today";
        break;
      case 1:
        return "Yesterday";
        break;
      default:
        return moment(date).format('MM/DD/yyyy');
        break;
    }
  }

  sendMessage() {
    if (!this.userMessage.trim()) return;

    if (!this.selectedSession) {

      const sessionTitle = this.generateSessionName(this.userMessage);
      var newSession: ChatSession = { sessionName: sessionTitle, createdDate: new Date(), latestMessageDate: new Date() } as ChatSession;

      this.chatService.insertChatSession(newSession).subscribe((chatSessionId) => {
        newSession.chatSessionId = chatSessionId;
        this.selectedSession = newSession;
        this.sendMessageToSession(this.userMessage);
      });
    } else {
      this.sendMessageToSession(this.userMessage);
    }
  }

  enableEditSession(chatSession: ChatSession) {
    chatSession.isEditing = true;
  }

  renameSession(chatSession: ChatSession) {
    this.chatService.renameChatSession(chatSession).subscribe((chatSessionId) => {
      chatSession.isEditing = false;
    });
  }

  deleteSession(chatSession: ChatSession, event) {
    this.chatService.deleteChatSession(chatSession).subscribe((chatSessionId) => {
      this.loadChatSessions();
      this.chatMessages = [];
    });
  }

  generateSessionName(message: string): string {
    if (!message || message.trim().length === 0) {
      return "New Chat";
    }

    const sentences = message.split(/[.!?]/).filter(sentence => sentence.trim().length > 0);

    let sessionName = sentences[0].trim();
    sessionName = sessionName.length > 50 ? sessionName.substring(0, 50) : sessionName;

    return sessionName;
  }

  sendMessageToSession(message: string) {
    const userChat: ChatMessage = { chatTypeId: 1, content: this.userMessage, chatSessionId: this.selectedSession.chatSessionId, sessionName: this.selectedSession.sessionName, createdDate: new Date(), displayDate: this.getDateDiff(new Date()) } as ChatMessage;

    if (this.chatMessages != null && this.chatMessages.length > 0 && this.chatMessages[this.chatMessages.length - 1][0]?.displayDate == 'Today') {
      this.chatMessages[this.chatMessages.length - 1].push(userChat);
    } else {
      if (this.chatMessages == null || this.chatMessages == undefined)
        this.chatMessages = [] as ChatMessage[][];
      this.chatMessages.push([userChat]);
    }

    const currentMessage = this.userMessage;

    this.isTyping = true;

    this.chatService.insertChatMessage(userChat).subscribe(response => {
      this.loadChatSessions();
      this.isTyping = false;
      this.displayIncrementalResponse(response.response);
    });

    this.userMessage = '';
  }

  displayIncrementalResponse(message: string) {
    const botChat: ChatMessage = { chatTypeId: 2, content: '', chatSessionId: this.selectedSession.chatSessionId, sessionName: this.selectedSession.sessionName, createdDate: new Date(), displayDate: this.getDateDiff(new Date()) } as ChatMessage;

    if (this.chatMessages[this.chatMessages.length - 1][0]?.displayDate == 'Today') {
      this.chatMessages[this.chatMessages.length - 1].push(botChat);
    } else {
      this.chatMessages.push([botChat]);
    }

    let i = 0;
    const interval = setInterval(() => {
      if (i < message.length) {
        botChat.content += message.charAt(i);
        i++;
      } else {
        clearInterval(interval);
      }
    }, 50);
  }

  createNewChat() {
    this.selectedSession = null;
    this.chatMessages = [];
    this.chatInputEle.nativeElement.focus();
  }

  getUserChatToken() {
    this.chatService.getUserChatTokenDetail().subscribe((res) => {
      this.userChatTokenDetails = res;
      this.getTokenRefillDiff();
    });
  }

  getTokenRefillDiff() {

    let fromDate = moment(this.userChatTokenDetails.validFrom).format('yyyyMMDD');
    let toDate = moment(this.userChatTokenDetails.validTo).format('yyyyMMDD');
    let today = moment(new Date).format('yyyyMMDD');

    let days = Number(fromDate) - Number(toDate);
    let diff = Number(toDate) - Number(today);

    switch (days) {
      case 0:
        this.tokenRefillDiff = "daily";
        break;

      default: {
        diff > 0 ? `in ${{ diff }} day(s)` : 'tomorrow';
      }
        break;
    }

  }

  scrolltoBottom() {
    setTimeout(() => {
      document.getElementById('scrollTo')?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }, 0);

  }

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: Event) {
    for (let key in this.isMenuOpen) {
      if (this.isMenuOpen.hasOwnProperty(key)) {
        this.isMenuOpen[key] = false;
      }
    }
  }

  toggleMenu(event: Event, chatSessionId: number) {
    event.stopPropagation();
    const element = event.target as HTMLElement;
    const dropdownContent = element.closest('.ellipsis-menu')?.querySelector('.dropdown-content') as HTMLElement;

    if (dropdownContent) {
      const dropdownRect = dropdownContent.getBoundingClientRect();
      const targetRect = (event.target as HTMLElement).getBoundingClientRect();

      const spaceAbove = targetRect.top;
      const spaceBelow = window.innerHeight - targetRect.bottom;

      if (spaceBelow < 105 && spaceAbove >= dropdownRect.height) {
        dropdownContent.style.top = '-62px';
      } else {
        dropdownContent.style.top = '0px';
      }
    }

    for (let key in this.isMenuOpen) {
      if (this.isMenuOpen.hasOwnProperty(key) && Number(key) != chatSessionId) {
        this.isMenuOpen[key] = false;
      }
    }
    this.isMenuOpen[chatSessionId] = !this.isMenuOpen[chatSessionId];
  }

  syncExpandSidebar() {
    this.sidebarExpanded = !this.sidebarExpanded;
  }

  syncExpandView() {
    this.isFullScreenView = !this.isFullScreenView
  }

  close() {
    this.closePopup.next(true);
  }

  inBounds = true;
  myOutOfBounds = {
    top: false,
    right: false,
    bottom: false,
    left: false,
  };
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true,
  };

  checkEdge(event) {
    this.edge = event;
    console.log('edge:', event);
  }

  outOfBounds(position) {
    if (this.myOutOfBounds[position]) {
      this.myOutOfBounds[position] = false;
    } else {
      this.myOutOfBounds[position] = true;
    }
  }

  closeNote(){
    this.isNoteClosed = !this.isNoteClosed;
  }

}
