import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/before-login/login/login.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AccountComponent } from './components/before-login/account/account.component';
import { ScheduleOnBoardingComponent } from './components/before-login/scheduleOnboarding/scheduleOnboarding.component';
import { ActivateComponent } from './components/before-login/activate/activate.component';
import { SetPwdComponent } from './components/after-login/set-pwd/set-pwd.component';
import { RequestVerificationCodeComponent } from './components/before-login/request-verification-code/request-verification-code.component';
import { RegistrationThanksComponent } from './components/before-login/registration-thanks/registration-thanks.component';

import { ProfileComponent } from './components/after-login/profile/profile.component';
import { MainComponent } from './components/main/main.component';
import { ChangePwdComponent } from './components/after-login/change-pwd/change-pwd.component';
import { ResetPwdComponent } from './components/after-login/reset-pwd/reset-pwd.component';
import { ContactService } from 'api-services/contact.service';
import { SendActivationComponent } from './components/before-login/send-activation/send-activation.component';
import {  RouterDataResolver } from 'services/router.resolver';
import { ForgotPwdComponent } from './components/before-login/forgot-pwd/forgot-pwd.component';
import { DashboardScorecardComponent } from './components/after-login/dashboard-scorecard/dashboard-scorecard.component';
import { AgentScorecardComponent } from './components/after-login/dashboard-scorecard/agent-scorecard/agent-scorecard.component';
import { DatePipe } from '@angular/common';
import { ContactScorecardComponent } from './components/after-login/contact-scorecard/contact-scorecard.component';
import { ContactDetailScorecardComponent } from './components/after-login/contact-scorecard/contact-detail-scorecard/contact-detail-scorecard.component';
import { AuthInterceptor } from 'auth/auth-interceptor';

import { LeftPartComponent } from 'components/shared-component/left-part/left-part.component';
import { HeaderComponent } from 'components/shared-component/header/header.component';
import { FooterComponent } from 'components/shared-component/footer/footer.component';
import { BodyComponent } from 'components/shared-component/body/body.component';
import { SidebarComponent } from 'components/shared-component/sidebar/sidebar.component';
import { ConfirmDialogComponent } from 'components/shared-component/confirm-dialog/confirm-dialog.component';
import { UnauthorizedComponent } from 'components/shared-component/unauthorized/unauthorized.component';
import { SharedModule } from 'shared/shared.module';
import { AddContactPreviewComponent } from './components/shared-component/add-contact-preview/add-contact-preview.component';
import { AddContactProgressComponent } from './components/shared-component/add-contact-progress/add-contact-progress.component';
import { PenaltyInfoComponent } from 'components/after-login/contact-dashboard/profile-information/penalty-info/penalty-info.component';
import { FilterComponent } from './components/shared-component/filter/filter.component';
import { PackagesCodeComponent } from './components/before-login/packages-code/packages-code.component';
import { ConfirmActivationEmailComponent } from 'components/before-login/confirm-activation-email/confirm-activation-email.component';
import { CommonFilterComponent } from './components/shared-component/common-filter/common-filter.component';
import { IssueFeedbackComponent } from './components/after-login/issue-feedback/issue-feedback.component';
import { BookOnboardingSlotComponent } from './components/before-login/book-onboarding-slot/book-onboarding-slot.component';

@NgModule({ 
  declarations: [
    AppComponent,
    LoginComponent,
    AccountComponent,
    ScheduleOnBoardingComponent,
    ActivateComponent,
    LeftPartComponent,
    SetPwdComponent,
    RequestVerificationCodeComponent,
    RegistrationThanksComponent,
    ProfileComponent,
    SendActivationComponent,
    HeaderComponent,
    FooterComponent,
    BodyComponent,
    MainComponent,
    SidebarComponent,
    ChangePwdComponent,
    ResetPwdComponent,
    ConfirmDialogComponent,
    ForgotPwdComponent,
    UnauthorizedComponent,
    DashboardScorecardComponent,
    AgentScorecardComponent,
    ContactScorecardComponent,
    ContactDetailScorecardComponent,
    AddContactPreviewComponent,
    AddContactProgressComponent,
    PenaltyInfoComponent,
    FilterComponent,
    PackagesCodeComponent,
    ConfirmActivationEmailComponent,
    CommonFilterComponent,
    IssueFeedbackComponent,
    BookOnboardingSlotComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule
  ],
  exports: [SharedModule],

  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },ContactService, RouterDataResolver, DatePipe],
  //providers: [ContactService, RouterDataResolver, DatePipe],
  bootstrap: [AppComponent],
})
//export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
export class AppModule {}
