import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'api-services';
import { seterror } from 'common/slick-slider';
import { ValidatorHelper } from 'components/custom-validators/validators.helper';
import { SharedService } from 'services';
import { CommonFunc } from '../../common/common-func/common-func';
import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { PackageService } from '../../../api-services/package.service';

declare var $: any;
export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mismatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

@Component({
  selector: 'app-set-pwd',
  templateUrl: './set-pwd.component.html',
  styleUrls: ['./set-pwd.component.css']
})
export class SetPwdComponent implements OnInit {
  errorMessages = [];
  setPwdForm!: FormGroup;
  onBoardingDocForm: FormGroup;
  //userEmail: string;
  forgotPwd = 0;
  constructor(private sanitizer: DomSanitizer, private router: Router,
    private fb: FormBuilder, private route: ActivatedRoute,
    private accountService: AccountService,
    private sharedService: SharedService,
    private packageService: PackageService  ) { }

  isShow: boolean = true;
  isValid: boolean = true;
  hide: boolean = false;
  show = false;
  isFirm: boolean = false;
  guestUserId: number = 0;
  
  ngOnInit(): void {
    if(sessionStorage.getItem('hide')){
      this.hide = JSON.parse(sessionStorage.getItem('hide'));
      sessionStorage.removeItem('hide');
    }
    
    this.onBoardingDocForm = this.fb.group({
      orgNpn: [false, Validators.requiredTrue],
      nipr: [false, []],
      creditCard: [false, Validators.requiredTrue],
      npn: [false, Validators.requiredTrue],
    })

    this.setPwdForm = this.fb.group({
      userId: [],
      tenantId : [],
      password: ['', [ValidatorHelper.passwordPattern, Validators.required,]],
      confirmPassword: ['', [ValidatorHelper.passwordPattern, Validators.required,]],
      activationCode: []
    },
      {
        validator: ConfirmedValidator('password', 'confirmPassword')
      }
    );
    
    this.route.queryParams.subscribe(paramsId => {
      if (paramsId['ifu'] != undefined) {
        this.isFirm = JSON.parse(paramsId['ifu']);
        this.setPwdForm.get("userId")!.setValue(paramsId['id']);
        this.setPwdForm.get("activationCode")!.setValue(paramsId['ac']);
        this.setPwdForm.get("tenantId")!.setValue(paramsId['tid']);
      }

      if (paramsId['fg'] != undefined) {
        this.forgotPwd = paramsId['fg'];
      }
      else {
        this.guestUserId = parseInt(localStorage.getItem("guestUserId"));
        if (this.guestUserId > 0) {
          this.setPwdForm.get("userId")!.setValue(this.guestUserId);
          this.forgotPwd = 2
        }
      }
    });

    this.setPwdForm.markAsUntouched();
    if (this.forgotPwd == 0) {
      //this.accountService.validateForgotPasswordActivation(this.setPwdForm.value).subscribe(
      this.accountService.validatePasswordActivation(this.setPwdForm.value).subscribe(
        (response: any) => {
          if (response.id == 2) {
            this.sharedService.showToastMessage('Account activated successfully');
            this.router.navigate(['/login']);
          }
        },
        (error: any) => {
          this.errorMessages.push(error.message);
        }
      )
    } else if (this.forgotPwd == 1) {
      this.accountService.validateForgotPasswordActivation(this.setPwdForm.value).subscribe(
        (response: any) => {
          if (response.id == 2) {//check if user is not active
            this.router.navigate(['/login']);
          }
        },
        (error: any) => {
          this.errorMessages.push(error.message);
        }
      )
    }
    seterror();
  }

  
  @HostListener('window:beforeunload', ['$event'])
    unloadNoifications(event: any){
      sessionStorage.setItem('hide', JSON.stringify(this.hide));
    }

  downloadExcel() {
    const bulkImport = [
      {
          "First Name": "",
          "Last Name": " ",
          "Producer Code": "",
          "NPN": "",
          "Email": ""
      }];
      var  columnOrder = null;
    this.sharedService.exportToExcel(bulkImport, columnOrder, 'BulkImport.xlsx')
  }

  showData(){
   this.hide = !this.hide;   
  }

  showInfo(){
    this.show = !this.show;
    this.show ? window.scrollTo(500, 0) : '';
  }

  onSubmit(): void {
    this.errorMessages = [];
    if (this.forgotPwd == 0) {
      this.setPwdForm.markAllAsTouched();
      if (this.setPwdForm.get("password")?.value === this.setPwdForm.get("confirmPassword")?.value) {
        this.accountService.setPwd(this.setPwdForm.value).subscribe(
          (response: any) => {
            if (response != null && response.token != null) {
              this.accountService.setToken(response, response.token);
              if (response.role.trim().toLowerCase() == "Contact".toLowerCase()) {
                localStorage.setItem("activeContactUserId", response.id.toString());
                localStorage.setItem("isArchived", JSON.stringify(false));
                this.router.navigate(['/contact-dashboard']);

              }
              else if (response.role.trim().toLowerCase() == "Admin".toLowerCase()) {
                localStorage.setItem("activeContactUserId", response.id.toString());
                this.router.navigate(['/dashboard']);
              }
              else {
                this.router.navigate(['/registration/verifydetail']);

              }
            } else {

              if (response.userId == 0) {
                this.errorMessages.push("Activation link might having problem or account already activated.");
              }
            }

          },
          (error: any) => {
            console.log(error);
          }
        )
      } else {
        this.errorMessages.push('Password and Confirm Password are not same !!');
      }
    }
    else if (this.forgotPwd == 1) {

      this.setPwdForm.markAllAsTouched();
      if (this.setPwdForm.get("password")?.value === this.setPwdForm.get("confirmPassword")?.value) {
        this.accountService.setForgotPwd(this.setPwdForm.value).subscribe(
          (response: any) => {
            if (response != null && response.token != null) {
              this.accountService.setToken(response, response.token);
              if (response.role.trim().toLowerCase() == "Contact".toLowerCase()) {
                localStorage.setItem("isArchived", JSON.stringify(false));
                this.router.navigate(['/contact-dashboard']);

              }
              else if (response.role.trim().toLowerCase() == "Admin".toLowerCase()) {
                localStorage.setItem("activeContactUserId", response.id.toString());
                this.router.navigate(['/dashboard']);
              }
              else {

                this.router.navigate(['/dashboard']);
 
              }
            } else {

              if (response.userId == 0) {
                this.errorMessages.push("Activation link might having problem or account already activated.");
              }
            }

          },
          (error: any) => {
            console.log(error);
          }
        )
      } else {
        this.errorMessages.push('Password and Confirm Password are not same !!');
      }
    }
    else if (this.forgotPwd == 2) {

      this.setPwdForm.markAllAsTouched();
      if (this.setPwdForm.get("password")?.value === this.setPwdForm.get("confirmPassword")?.value) {
        this.packageService.setPwd(this.setPwdForm.value).subscribe(
          (response: any) => {
            if (response != null && response.token != null) {
              this.accountService.setToken(response, response.token);
              this.router.navigate(['/packages-carrier']);
            } else {

              if (response.userId == 0) {
                this.errorMessages.push("User does not exists.");
              }
            }

          },
          (error: any) => {
            console.log(error);
          }
        )
      } else {
        this.errorMessages.push('Password and Confirm Password are not same !!');
      }
    }
  }


  showPassword(id) {
    id == 'setPassword' ? this.isShow = !this.isShow : this.isValid = !this.isValid;
    CommonFunc.showPassword(id);

  }

  ngOnDestroy() {
    if(sessionStorage.getItem('hide')){
      sessionStorage.removeItem('hide');
    }
  }
}
