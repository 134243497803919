<!--<app-header></app-header>-->
<div class="main-layout-grid">
  <main id="main-container">
    <div class="data-content custom-height-center">
      <div class="content-width">
        <app-left-part></app-left-part>
      </div>
      <div class="content-width right">
        <div class="right-side-content before-login-page">
          <div class="container">
            <div class="form-details">
              <div class="forms-data">
                <form class="active-account-form" [formGroup]="activationForm" (ngSubmit)="onSubmit()">
                  <div class="form-block-title">
                    <h3 class="form-title">Activate <span class="full-block">your account</span></h3>
                    <p class="login-note">Enter the code we just sent to email address
                      <!--<span class="email-exp full-block">swayde@rdglobalinc.com</span>-->
                    </p>
                    <div class="form-inputs-data">
                      <div class="form-group" [class.error]="!activationForm.controls['activationCode']?.valid">
                        <input type="text" class="form-control form-input otp-num" name="activeAccountotp"
                          id="activeAccountOTP" formControlName="activationCode" placeholder=" " maxlength="10">
                        <label></label>
                        <app-error [controlName]="activationForm.controls['activationCode']">
                        </app-error>
                      </div>
                    
                    </div>
                    <div class="action-btn">
                      <button type="submit" class="btn commn-btn" [disabled]="!activationForm.valid">Activate Your
                        Account</button>
                      <a href="javascript:void()" routerLink="/login" class="btn commn-btn-plain">Back</a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </main>
</div>
<!--<div class="authentication-container">
  <div class="container">
    <div class="auth-content thanks-auth">
      <div class="logo-block">
        <img src="../../../../assets/images/auth-logo1.svg" />
      </div>
      <div class="auth-title">
        <span>-->
<!--Thank you for registering with Agenzee!-->
<!--</span>
      </div>
      <span class="text-line">Check your email for an activation code and enter below</span>
      <div class="img-block">
        <img src="../../../../../assets/images/thanks-img-part.svg" />
      </div>
      <form id="mainForm" [formGroup]="activationForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="input-block extra">
              <input type="text" class="form-control" placeholder="Work email address" formControlName="email" />
              <div class="help-block" *ngIf="((activationForm.get('email')!.touched ||
                               activationForm.get('email')!.dirty) &&
                               activationForm.get('email')!.errors)">
                <span *ngIf="activationForm.get('email')!.errors!['required']">
                  Email is required
                </span>
                <span *ngIf="activationForm.get('email')!.errors!['pattern']">
                  Email is not valid
                </span>
              </div>
            </div>
          </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block extra">
                <input type="text" class="form-control" placeholder="Activation Code" formControlName="activationCode" maxlength="50" />
                <div class="help-block" *ngIf="((activationForm.get('activationCode')!.touched ||
                               activationForm.get('activationCode')!.dirty) &&
                               activationForm.get('activationCode')!.errors)">
                  <span *ngIf="activationForm.get('activationCode')!.errors!['required']">
                    Activation Code is required
                  </span>

                </div>
              </div>
            </div>
          </div>

          <div class="button-block">
            <button class="btn comm-btn" [disabled]="!activationForm.valid">Activate</button>
          </div>

      </form>
    </div>
  </div>
</div>-->