import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ContactService } from 'api-services/contact.service';
import { LinesOfAuthorityService } from 'api-services/lines-of-authority.service';
import { AdminDashboardRequest } from 'models/admin-dashboard/admin-dashboard-request.model';
import { DashboardScorecard } from 'models/dashboard-scorecard.model';
import { State } from 'models/state.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RoleService } from 'services/role.service';
import { ContactDashboardStoreProcedure } from 'shared/constant';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { SharedService } from 'services';

@Component({
  selector: 'app-contact-detail-scorecard',
  templateUrl: './contact-detail-scorecard.component.html',
  styleUrls: ['./contact-detail-scorecard.component.css']
})
export class ContactDetailScorecardComponent implements OnInit {

  characterValidate: number = 3;
  isContactUser = false;
  userId: number;
  activeContactUserId: number;
  request: AdminDashboardRequest = {
    jsonRequest: '',
    code:ContactDashboardStoreProcedure.GetContactPersonalInformationSummary
  };
  userProfileArray: any;
  userProfile: any;
  selectedStates: State[];
  states: State[] = [] as State[];
  dropdownSettingState: IDropdownSettings;
  reqstParam: DashboardScorecard = {} as DashboardScorecard
  selectedAgent = JSON.parse(localStorage.getItem('selContact'));
  overallScore: number = this.selectedAgent['overallScore'];
  contactDetailScorecardList:any = [];
  overallCount: number = 0;
  pagCount: number = 1;
  range: number[] = [0, 0] as number[];
  sortDir: number = 0;
  expiryInData = [
    {id:0, days: "All"},
    {id:15, days: "15 Days"},
    {id:30, days: "30 Days"}
  ];
  agentStatusList = [
    {id:1, status: "Active"},
    {id:0, status: "Missing"}
  ];
  allChecked = false;
  constructor(private contactService: ContactService, private sharedService: SharedService, private datePipe: DatePipe, private roleService:RoleService, private loaService: LinesOfAuthorityService) { }

  isShow = true;
  ngOnInit(): void {
  
    this.reqstParam.tenantId = Number(localStorage.getItem('tenantId'));
    this.reqstParam.customGroupId = this.selectedAgent['customGroupId'];
    this.reqstParam.pageNum = 1;
    this.reqstParam.pageSize = parseInt(environment.pageSize);
    this.reqstParam.sortBy = '';
    this.reqstParam.sortOrder = 0;

    this.dropdownSettingState = {
      singleSelection: false,
      idField: 'stateId',
      textField: 'stateName',
      selectAllText: 'Select All',
      unSelectAllText: 'Clear All',
      allowSearchFilter: true,
      itemsShowLimit: 1,
    };
  
    // get selected user name 
    this.isContactUser = this.roleService.isContactUser;
    this.userId = Number(localStorage.getItem("activeContactUserId"));
    this.request.contactId = this.userId;
    this.contactService.getContactDashboardDetail(this.request).subscribe(
      (response: any) => {
        this.userProfileArray = JSON.parse(response.jsonResponse);
        if (this.userProfileArray != null && this.userProfileArray.length > 0) {
          this.userProfile = this.userProfileArray[0];
        }        
      },
      (error: any) => {
        console.log(error);
      }
    );
    this.loadStates();
    this.getContactDetails();
  }

  getSearchContactDetails() {
    this.reqstParam.status=this.reqstParam.status=="null"? null : this.reqstParam.status;
    this.reqstParam.pageNum = 1;
    this.getContactDetails();
  }

  clearSearch(){
    this.reqstParam.search = '';
    this.getSearchContactDetails();
  }
  getContactDetails() {
    // state list
    var stateIds = null;
    if (this.selectedStates && this.selectedStates.length > 0) {
      stateIds = this.selectedStates.map(function (val) {
        return val.stateId
      }).join(',');
    }
  
    this.reqstParam.userId = this.userId;
    this.reqstParam.states = stateIds;

    this.loaService.getContactScorecard(this.reqstParam).subscribe((res:any) => {
      this.contactDetailScorecardList = res.map(el => ({
        ...el,
        formatedexpiryDate: this.sharedService.calculateDiff(el.expirationDate)
        
      }))
      this.overallCount = this.contactDetailScorecardList[0]?.overallCount? this.contactDetailScorecardList[0]?.overallCount : this.contactDetailScorecardList.length ;
      this.createRange();
    },
      (error: any) => {
         // this.errorMessages.push(error.message);
      }
    )
  }


  // select all data from table
  selectAll(value: boolean) {
    this.allChecked = value;
    this.contactDetailScorecardList = this.contactDetailScorecardList.map(m => ({ ...m, isChecked: value }));
    this.isShow = !this.isShow;
  }

  selectChkVal(list: any): void {
    list.isChecked = !list.isChecked;    
  }

  // pagination
  createRange() {
    var pageSize = this.reqstParam.pageSize;
    var pageNum = this.reqstParam.pageNum;

    this.pagCount = this.overallCount < pageSize ? 1 : Math.ceil(this.overallCount / pageSize);

    var offSet = pageNum * pageSize;
    this.range = [this.contactDetailScorecardList.length > 0 ? ((pageNum - 1) * pageSize +1):0, Math.min(pageNum * pageSize, this.overallCount)]

  }

  // pagination next and back functionality 
  next() {
    if (this.reqstParam.pageNum <= this.pagCount) {
      this.reqstParam.pageNum++;
      this.getContactDetails();
    }
  }

  back() {
    if (this.reqstParam.pageNum > 1)
      this.reqstParam.pageNum--;
      this.getContactDetails();
  }

  // Sorting functionaliy 
  onSortClick(event, colName) {
    let target = event.currentTarget,
      classList = target.classList;
      
    if (classList.contains('sorting-icon-asc')) {
      classList.remove('sorting-icon-asc');
      classList.add('sorting-icon-des');
      this.sortDir = 1;
    } else {
      classList.add('sorting-icon-asc');
      classList.remove('sorting-icon-des');
      this.sortDir = 0;
    }
    this.reqstParam.sortBy = colName;
    this.reqstParam.sortOrder = this.sortDir;
    this.getContactDetails();
  }


  // get data of states
  loadStates() {
    this.loaService.getSupportedStates().subscribe(
      response => {
        this.states = response;
  },
      error => {
        if (error.status == 0) {

        }
        else {

        }
      }
    )
  }

  openModal= "close";
openComingSoonModal(){
  this.openModal = "open";
}
modalStatus(eve){
  this.openModal= eve;
}
}
