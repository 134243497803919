import { Injectable } from "@angular/core";
import { Observable, throwError, empty, of, firstValueFrom } from 'rxjs';
import {
  HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router } from "@angular/router";
import { ApiClientService } from "../api-services/common/api-client.service";
import { AccountService } from "../api-services/account.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public constructor(private route: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler) {

    request = request.clone();
    let token: string | null = localStorage.getItem("token");
    if (token && token !="undefined" && token != undefined) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }
    //return next.handle(request);
    return next.handle(request).pipe(
      catchError(err => {
        
        //if (err.status == '401' || err.status == '500' || err.status == '0') {
        if (err.status == '401' || err.status == '0') {
          localStorage.clear();
          this.route.navigate(['/login']).then(() => {
            window.location.reload();
          });
        }
        return throwError(err);
      }),
    );
  }

  
}
