<p>account works! after login only</p>
<a routerLink="/listlicense">List License</a>
<br />
<a routerLink="/listappointment">List Appointment</a>
<br />
<a routerLink="/carrier" *ngIf="isAdmin">Create Carrier</a>
<br />
<a routerLink="/listcarrier" *ngIf="isAdmin">List Carrier</a>
<br />
<a routerLink="/creatagent" *ngIf="isAdmin">Create Agent</a>
<br />
<a routerLink="/setpwd">Change Password</a>
<br />
<a routerLink="/profile">Profile</a>
<br />
<a routerLink="/verifydetail">Verify Details</a>
<br />
<a routerLink="/branch" *ngIf="isAdmin">Branch List</a>
<br />
<a href="javascript:void(0)" (click)="logout()">Logout</a>
