import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import jwt_decode from "jwt-decode";
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public loader: BehaviorSubject<any> = new BehaviorSubject(false);
  public showToast: BehaviorSubject<any> = new BehaviorSubject('');
  public isRefreshRequired: BehaviorSubject<any> = new BehaviorSubject(false);
  public backURL: BehaviorSubject<any> = new BehaviorSubject('/contact-list');
  public loaderCount: number = 0;
  private UserReminderVisibilitySource = new Subject<boolean>();
  UserReminderVisibility$ = this.UserReminderVisibilitySource.asObservable();

  private AllReminderVisibilitySource = new Subject<boolean>();
  AllReminderVisibility$ = this.AllReminderVisibilitySource.asObservable();


  private unreadTaskCountReload = new Subject<void>();
  unreadTaskCountReloadObs = this.unreadTaskCountReload.asObservable();

  private IssueAndFeedbackVisibilitySource = new Subject<boolean>();
  IssueAndFeedbackVisibility$ = this.IssueAndFeedbackVisibilitySource.asObservable();

  constructor(private datePipe: DatePipe) {}

  setUserReminderVisibility(isVisible: boolean) {
    this.UserReminderVisibilitySource.next(isVisible);
  }

  setAllReminderVisibility(isVisible: boolean) {
    this.AllReminderVisibilitySource.next(isVisible);
  }

  updateUnreadTaskCount() {
    this.unreadTaskCountReload.next();
  }
  
  setIssueAndFeedbackVisibility(isVisible: boolean) {
    this.IssueAndFeedbackVisibilitySource.next(isVisible);
  }

  showLoader() {    
    if (this.loaderCount < 0) {
      this.loaderCount = 0;
    }
    this.loaderCount++;
    this.loader.next(true);
  }

  showToastMessage(message){
    this.showToast.next(message);
  }

  hideLoader() {
    this.loaderCount--;
    if (this.loaderCount <= 0) {
      this.loader.next(false);
    }
  }
  public setValue(Key: string, value: any) {
    localStorage[Key] = value;
  }
  public getValue(Key: string) {
    return localStorage[Key];
  }

  getObjectFromToken() {
    return jwt_decode(localStorage.getItem('token'))
  }

  getKey(keyName): any {
    let loggedInUser = this.getObjectFromToken() as any;
    if (loggedInUser) {
      return loggedInUser[keyName];
      //return Array.isArray(loggedInUser[keyName] == false) ? loggedInUser[keyName] : loggedInUser[keyName][0];
    }
    return null;
  }

  public sendUpdate(isDataUpdated: boolean) { //the component that wants to update something, calls this fn
    this.isRefreshRequired.next(isDataUpdated); //next() will feed the value in Subject
  }

  public getUpdate(): Observable<boolean> { //the receiver component calls this function 
    return this.isRefreshRequired.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }

  convertUTCTimeToLocalTime(UTCDateString) {
    if (UTCDateString != undefined && UTCDateString != null) {
      var utcTime = new Date(UTCDateString);
      return new Date(utcTime.getTime() - (utcTime.getTimezoneOffset() * 60000));
    }
    else {
      return UTCDateString;
    }
  }

    //Calculate Date difference in days
    calculateDiff(expiryDate) {
      let currentDate = new Date();
      let eDate = new Date(expiryDate);
  
      let days = Math.ceil((eDate.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
      
      return {
        date: days == 0 ? "Today" : days > 0 && days <= 30 ? "In " +  days + " Days" : this.datePipe.transform(expiryDate, 'MM/dd/yyyy'),
        className: days < 0 ? "": days < 16 && days >=0 ? "red" : days < 31 && days > 15 ? "orange" : ""
      }
    }

    getBackURL(url){
      this.backURL.next(url);
    }

    exportToExcel(data, columnOrder, sheetName) {
      const workBook = XLSX.utils.book_new();
      const workSheet = XLSX.utils.json_to_sheet(data, columnOrder);
  
      XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
      XLSX.writeFile(workBook, sheetName);
    }

    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }

    alphaOnly(event):boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
        return false;
      }
      return true;
    }

    getMinDate(){
      return {day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear() - 100}
    }

    getMaxDate(){
      return { day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear()}
    }
}
