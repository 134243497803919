<div class="ce-info">
  <h2>Attachments  ({{totalFileCount}})</h2>
  <div class="new-group-block" *ngIf="isArchivedPage==false">
    <input type="file" hidden #fileInput (click)="fileInput.value = null" placeholder="Add attachments" id="attachment" name="Attachment"
           accept=".pdf, .doc, .docx, .jpg, .jpeg, .xlsx, .xls" size="1048576" multiple (change)="selectFiles($event)" />
    <button class="ce-btn">
      <a href="javascript:void(0)" [class.disabled]="(isContactUser && fileCategory.fileCategoryId == FileCategoryConstatnt.ResourceLibrary)" (click)="fileInput.click()">
        Add Attachment
        <span class="plus-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
               viewBox="0 0 20 20">
            <rect data-name="Rectangle 17995" width="20" height="20" fill="none" />
            <path d="M10,15.625a.676.676,0,0,1-.667-.667V10.667H5.042a.667.667,0,0,1,0-1.334H9.333V5.042a.667.667,0,0,1,1.334,0V9.333h4.291a.667.667,0,0,1,0,1.334H10.667v4.291a.676.676,0,0,1-.667.667Z"
                  transform="translate(0)" fill="#0068D8" />
          </svg>
        </span>
      </a>
    </button>
    <span class="tooltip-icon attachment-tooltip">
      <img src="assets/images/ic-info.svg" alt="">
      <span class="text-tooltip info-list top">
        Supports .pdf, .doc, .docx, .jpg, .jpeg, .xlsx and .xls
      </span>
    </span>
  </div>
</div>
<div class="common-table ce-upload">
  <table class="table contact-list-table">
    <thead class="sticky-from-top">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Type</th>
        <th scope="col">Note</th>
        <th scope="col">Uploaded By</th>
        <th scope="col">Uploaded On</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let file of uploadedFile">
        <td><span class="max-width-tooltip-200 center" appEllipsifyMe>{{file.fIleName}}</span></td>
        <td><span class="max-width-tooltip-100 center" appEllipsifyMe>{{file.fileType}}</span></td>
        <td><span class="max-width-tooltip-100 center" appEllipsifyMe>{{file.description}}</span></td>
        <td><span class="max-width-tooltip-100 center" appEllipsifyMe>{{file.createdByUserName}}</span></td>
        <td>{{file.localCreatedDate | date : 'MM/dd/yyyy hh:mm a'}}</td>
        <td class="btn-box">
          <div class="icon upload">
            <a href="javascript:void(0);" title="Download" (click)="DownloadFile(file.fileId, file.storageTypeId)" style="margin-right: 5px;">
              <svg width="18" height="18" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M12 15.2531C11.8833 15.2531 11.771 15.2325 11.663 15.1911C11.5543 15.1491 11.4583 15.0781 11.375 14.9781L8.25 11.8781C8.11667 11.7448 8.05 11.5698 8.05 11.3531C8.05 11.1365 8.11667 10.9615 8.25 10.8281C8.4 10.6781 8.579 10.5988 8.787 10.5901C8.99567 10.5821 9.175 10.6531 9.325 10.8031L11.25 12.7281V5.07812C11.25 4.86146 11.321 4.68213 11.463 4.54013C11.6043 4.39879 11.7833 4.32812 12 4.32812C12.2167 4.32812 12.396 4.39879 12.538 4.54013C12.6793 4.68213 12.75 4.86146 12.75 5.07812V12.7281L14.675 10.8031C14.8083 10.6698 14.9833 10.6031 15.2 10.6031C15.4167 10.6031 15.6 10.6781 15.75 10.8281C15.8833 10.9615 15.95 11.1321 15.95 11.3401C15.95 11.5488 15.8833 11.7281 15.75 11.8781L12.625 14.9781C12.5417 15.0781 12.446 15.1491 12.338 15.1911C12.2293 15.2325 12.1167 15.2531 12 15.2531ZM6.3 19.5031C5.8 19.5031 5.375 19.3281 5.025 18.9781C4.675 18.6281 4.5 18.2031 4.5 17.7031V15.7531C4.5 15.5365 4.571 15.3571 4.713 15.2151C4.85433 15.0738 5.03333 15.0031 5.25 15.0031C5.46667 15.0031 5.64567 15.0738 5.787 15.2151C5.929 15.3571 6 15.5365 6 15.7531V17.7031C6 17.7698 6.03333 17.8365 6.1 17.9031C6.16667 17.9698 6.23333 18.0031 6.3 18.0031H17.7C17.7667 18.0031 17.8333 17.9698 17.9 17.9031C17.9667 17.8365 18 17.7698 18 17.7031V15.7531C18 15.5365 18.0707 15.3571 18.212 15.2151C18.354 15.0738 18.5333 15.0031 18.75 15.0031C18.9667 15.0031 19.146 15.0738 19.288 15.2151C19.4293 15.3571 19.5 15.5365 19.5 15.7531V17.7031C19.5 18.2031 19.325 18.6281 18.975 18.9781C18.625 19.3281 18.2 19.5031 17.7 19.5031H6.3Z"
                      fill="#424242" />
              </svg>
            </a>
          </div>
          <div class="icon delete" *ngIf="isContactUser == false && isArchivedPage == false">
            <!--<a class="link default {{showDeleteActionButton}}" href="javascript:void();" data-toggle="modal"
             data-target="#archiveContact">
            Archive-->
            <!-- selected {{tenantTypeCap[tenantTypeId]}}(s) -->
            <!--</a>-->
            <!-- (click)="DeleteFile(file.fileId)" -->
            <a href="javascript:void(0);" title="Delete" data-toggle="modal" data-target="#deleteFileForm" (click)="openDeleteConfirmationPopup(file.fileId, file.fIleName, file.storageTypeId)">
              <svg class="icon" id="ic-delete-20px" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                <rect id="Rectangle_17995" data-name="Rectangle 17995" width="20" height="20" fill="none"></rect>
                <path id="delete_FILL0_wght300_GRAD0_opsz20" d="M6.146,17.083A1.566,1.566,0,0,1,4.562,15.5V5.125H4.354A.663.663,0,0,1,3.885,3.99a.641.641,0,0,1,.469-.2H7.521A.726.726,0,0,1,7.76,3.25a.776.776,0,0,1,.552-.229h3.355a.776.776,0,0,1,.552.229.726.726,0,0,1,.239.542h3.167a.663.663,0,0,1,.469,1.135.641.641,0,0,1-.469.2h-.208V15.5a1.566,1.566,0,0,1-1.584,1.583ZM5.9,5.125V15.5a.249.249,0,0,0,.25.25h7.687a.249.249,0,0,0,.25-.25V5.125Zm1.9,8.208a.667.667,0,0,0,1.333,0V7.542a.667.667,0,0,0-1.333,0Zm3.062,0a.667.667,0,0,0,1.334,0V7.542a.667.667,0,0,0-1.334,0ZM5.9,5.125V15.5a.249.249,0,0,0,.25.25H5.9V5.125Z" transform="translate(0.009 -0.052)" fill="#424242">
                </path>
              </svg>
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="p-2">
    <validation-summary [validationErrors]="errorMessages"></validation-summary>
  </div>
</div>

<div id="deleteFileForm" class="common-modal modal fade" arial-hidden="true" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content delete-modal">
      <div class="modal-header pop-title">
        <h4 class="modal-title">Delete Selected File</h4>
        <button type="button" class="modal-close-btn close" data-dismiss="modal" (click)="closeDeleteFile()">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><rect width="28" height="28" rx="6" fill="none" /><g transform="translate(4 4)"><rect width="20" height="20" fill="none" /><path d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z" transform="translate(-0.005 0.005)" fill="#757575" /></g></svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="group-form">
          <form class="archiveContact-form" id="deleteFileForm" [formGroup]="deleteFileForm">
            <div class="user-name-block">
              <p class="name-note">
                <span class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="agree" name="agree" formControlName="deleteFileAgree">
                  <label class="custom-control-label" for="agree">
                    <p>
                      Are you sure you want to delete the selected file "<strong>{{deleteFileName}}"</strong>?
                  </label>
                </span>
              </p>
            </div>
          </form>
        </div>
        <div class="button-block action-btn text-right">
          <button type="submit" #closeDeleteFilePopup class="btn commn-btn lg-bg" data-dismiss="modal" (click)="closeDeleteFile()">Cancel</button>
          <button type="submit" class="btn commn-btn dark-bg" [disabled]="!deleteFileForm.valid" (click)="DeleteFile()">Delete</button>
        </div>
      </div>
    </div>
  </div>
</div>
