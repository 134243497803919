import { Component, ElementRef, Input, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserStorageService } from 'api-services/user-storage.service';
import { UploadAttachmentModalComponent } from 'components/shared-component/attachments/upload-attachment-modal/upload-attachment-modal.component';
import { RoleService } from 'services/role.service';
import { SharedService } from 'services/shared.service';
import { FileCategory, ngbModalOptionsXL } from 'shared/constant';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.css']
})
export class AttachmentsComponent implements OnInit {
  @Input() fileCategory;

  errorMessages = [];
  uploadedFile = [];
  isContactUser = this.roleService.isContactUser;
  fileStoreTypeId = localStorage["fileStoreTypeId"];
  //contactId = localStorage.getItem("activeContactUserId");
  contactId = localStorage.getItem("activeContactUserId") ? localStorage.getItem("activeContactUserId") : localStorage.getItem('userId');
  deleteFileForm: FormGroup;
  deleteFileId: number;
  deleteFileName: string;
  totalFileCount: number;
  selectedFiles: FileList;
  @ViewChild('closeDeleteFilePopup') closeDeletePopupEle: ElementRef;
  @ViewChild('deleteFileForm') openDeletePopupEle: ElementRef;

  @Input() reloadAttachment = false;
  @Input() isArchivedPage = false;
  FileCategoryConstatnt = FileCategory
  constructor(private modalService: NgbModal, private sharedService: SharedService
    , private userStorageService: UserStorageService, private roleService: RoleService) { }

  ngOnInit(): void {
    this.getContactUploadedFiles();
    this.deleteFileForm = this.deleteFileBindFormGroup();
  }

  deleteFileBindFormGroup(): FormGroup {
    return new FormGroup({
      fileId: new FormControl(0, []),
      deleteFileAgree: new FormControl(false, Validators.requiredTrue)
    });
  }

  getContactUploadedFiles() {
    var obj = {
      contactId: this.contactId,
      fileStoreTypeId: this.fileStoreTypeId,
      fileCategoryId: this.fileCategory.fileCategoryId,
      attachmentNoteId: this.fileCategory.attachmentNoteId
    }

    this.userStorageService.getContactUploadedFiles(obj).subscribe(
      (response: any) => {
        this.uploadedFile = response.map(el => ({
          ...el,
          localCreatedDate: this.sharedService.convertUTCTimeToLocalTime(el.createdDate)
        }));

        this.totalFileCount = this.uploadedFile.length;
      },
      (error: any) => {
        console.log(error);
      });
  }

  DownloadFile(fileId, fileStorageTypeId): void {
    this.errorMessages = [];
    this.sharedService.showLoader();
    this.contactId = this.contactId;
    this.userStorageService.DownloadContactFile(this.contactId, fileId, fileStorageTypeId, this.fileCategory.fileCategoryId).subscribe(
      response => {
        if (response.response.toLowerCase() == "filedeleted") {
          this.sharedService.hideLoader();
          this.errorMessages.push("This file does not exist.");
        }
        else {
          this.sharedService.hideLoader();
          let byteCharacters = atob(response.response);
          let byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++)
            byteNumbers[i] = byteCharacters.charCodeAt(i);

          let byteArray = new Uint8Array(byteNumbers);
          let blob = new Blob([byteArray], { type: response.contentType });

          if (response.contentType === "application/pdf") {
            var blobURL = URL.createObjectURL(blob);
            window.open(blobURL);
          }
          else {
            var blobURL = window.URL.createObjectURL(blob);
            var anchor = document.createElement("a");
            anchor.download = response.fileName;
            anchor.href = blobURL;
            anchor.click();
          }
        }
      }),
      (error: any) => {
        console.log(error);
        this.sharedService.hideLoader();
      };
  }

  DeleteFile(): void {
    this.sharedService.showLoader();
    this.contactId = this.contactId;
    this.userStorageService.deleteFileById(this.contactId, this.deleteFileId, this.fileStoreTypeId, this.fileCategory.fileCategoryId).subscribe(
      response => {
      this.sharedService.hideLoader();
      this.getContactUploadedFiles();
      this.sharedService.showToastMessage("File deleted successfully");
      this.closeDeleteFile();
    }),
      (error: any) => {
        console.log(error);
        this.sharedService.hideLoader();
      };
  }

  openDeleteConfirmationPopup(fileId, fileName, fileStoreTypeId) {
    this.deleteFileId = fileId;
    this.deleteFileName = fileName;
    this.fileStoreTypeId = fileStoreTypeId;
    this.openDeletePopupEle.nativeElement.click();
  }

  closeDeleteFile() {
    this.deleteFileForm.controls["deleteFileAgree"].reset();
    this.deleteFileId = null;
    this.closeDeletePopupEle.nativeElement.click();
  }

  openUploadPopup() {
    const modalRef = this.modalService.open(UploadAttachmentModalComponent, ngbModalOptionsXL);
    modalRef.componentInstance.selectedFiles = this.selectedFiles;
    modalRef.componentInstance.fileCategory = this.fileCategory;
    modalRef.result.then(data => {
      if (data) {
        this.getContactUploadedFiles();
      }
    })
  }

  selectFiles(event) {
    this.selectedFiles = event.target.files;
    this.openUploadPopup();
  }

  ngOnChanges(changes: SimpleChange) {
    if (changes["reloadAttachment"] && changes["reloadAttachment"].previousValue != undefined) {
      this.getContactUploadedFiles();
    }
  }
}



