import { formatDate } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactService } from 'api-services/contact.service';
import { ContactListBase } from 'components/after-login/contacts/contact-list/contact-list-base';
import { BranchService } from 'components/branch/services/branch.service';
/*import { debug } from 'console';*/
import { RoleService } from 'services/role.service';
import { SharedService } from 'services/shared.service';
import { ngbModalOptions, ngbModalOptionsCenter, ngbModalOptionsXL, tenantType, tenantTypeCap } from 'shared/constant';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { AddContactProgressComponent } from '../add-contact-progress/add-contact-progress.component';
import { AddContactPreviewComponent } from '../add-contact-preview/add-contact-preview.component';
import { ContactList } from '../../after-login/contacts/contact-list/models/contact-list.model';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.css']
})
export class AddContactComponent extends ContactListBase implements OnInit {
  selectionErrorMessages = []
  errorMessagesBranchName = [];
  disableButton: boolean = false;
  ShowUploadWizard = true;
  bulkImportErrorMessages = [];
  bulkImportErrorMessagesBranchName = [];
  bulkSelectionErrorMessages = [];
  selectedFile: any = null;
  fileUploadError: boolean = false
  showToast = false;
  isAddBranch: boolean = false;
  productId: number = 0;
  minNPN: number = 0;
  maxNPN: number = 0;
  addedNPN: number = 0;
  addingNPN: number = 0;
  remainingMinNPN: number = 0;
  remainingMaxNPN: number = 0;
  tenantTypeId = localStorage['tenantTypeId'];
  tenantType = tenantType;
  tenantTypeCap = tenantTypeCap;
  csvData = [];
  tableTitle = [];
  invalidNPN = [];
  @Input() isRegistration;
  @Input() isContactDeleted;
  @Input() reloadPage;
  @Output() contactAdded: any = new EventEmitter();
  @Output() modalSM: any = new EventEmitter();
  @ViewChild('fileInput') fileInputEle: ElementRef;
  // @ViewChild('closeContactAddPopup') closeContactAddPopup: ElementRef;
  @ViewChild('addContactTab') addContactTab: any;
  @ViewChild('addContactTabPane') addContactTabPane: any;
  searchText: string;
  errorNpn: number;
  @ViewChild(VirtualScrollerComponent) viewPort: VirtualScrollerComponent;
  isAgentNPN: boolean = true;
  constructor(private branchService: BranchService,
    private sharedService: SharedService,
    private contactService: ContactService,
    private roleService: RoleService, private modalService: NgbModal) {

    super();
  }
  darkScrollbarOptions: MCustomScrollbar.CustomScrollbarOptions = { axis: 'y', theme: 'light', setHeight: 115 };

  ngOnInit(): void {
    this.contactAdded.emit(false);
    this.initializeData();
    this.contactForm = this.bindFormGroup();
    this.bulkImportForm = this.bindBulkImportFormGroup();
    this.invitationForm = this.invitBindFormGroup();
    this.emailForm = this.emailBindFormGroup();
    this.showBranchDropDown = "";
    this.showAddButton = "";
    this.showEditButton = "";
    this.showSaveButton = "hidden";
    this.showBranchtext = "hidden";
    this.showCancelButton = "hidden";
    this.bulkShowBranchDropDown = "";
    this.bulkShowAddButton = "";
    this.bulkShowEditButton = "";
    this.bulkShowSaveButton = "hidden";
    this.bulkShowBranchtext = "hidden";
    this.bulkShowCancelButton = "hidden";
    this.showNameWithoutEmail = "";
    this.contactForm.get("tenantId")!.setValue(this.sharedService.getValue('tenantId'));
    this.bulkImportForm.get("tenantId")!.setValue(this.sharedService.getValue('tenantId'));
    this.contactForm.get("branchName").clearValidators();
    this.bulkImportForm.get("branchName").clearValidators();
    this.getTenantContactCount();
    this.getLatestBranchList(1, null);
    this.contactForm.get("branchId").setValidators(Validators.required);
    this.bulkImportForm.get("branchId").setValidators(Validators.required);
    this.bulkImportForm.get("uploadFileName").setValidators(Validators.required);
    this.contactForm.get("npnNumber").setValidators([Validators.required, Validators.pattern("[0-9]{0,10}(,[0-9]{0,10})*")]);
    if (!this.roleService.isTenantOwnerUser && !this.roleService.isAdminUser) {
      this.showBranchtext = "hidden";
      this.showEditButton = "hidden";
      this.showAddButton = "hidden";
      this.bulkShowBranchtext = "hidden";
      this.bulkShowEditButton = "hidden";
      this.bulkShowAddButton = "hidden";
    }
  }

  selectBranch(event: any, formType): void {
    if (formType == 'contactForm') {
      this.contactForm.get("branchId")!.setValue(event);
      this.selectionErrorMessages = [];
    } else {
      this.bulkImportForm.get("branchId")!.setValue(event);
      this.bulkSelectionErrorMessages = [];
    }
  }

  getLatestBranchList(tabId: number, branchId: number) {
    this.subscription.push(this.branchService.list(this.sharedService.getValue('tenantId')).subscribe(response => {
      this.branchList = response;
      var addedBranch = branchId == null ? this.branchList[0] : this.branchList.find(ele => ele.branchId == branchId);
      this.bulkImportForm.get("branchId")!.setValue(this.branchList[0].branchId);
      if (tabId == 1) {
        this.contactForm.get("branchId")!.setValue(addedBranch.branchId);
        // this.contactForm.get("branchName")!.setValue(addedBranch.branchName);

        this.showBranchDropDown = "";
        this.showAddButton = "";
        this.showEditButton = "";
        this.showSaveButton = "hidden";
        this.showBranchtext = "hidden";
        this.showCancelButton = "hidden";
      } else if (tabId == 2) {

        this.bulkImportForm.get("branchId")!.setValue(addedBranch.branchId);
        //  this.bulkImportForm.get("branchName")!.setValue(addedBranch.branchName);

        this.bulkShowBranchDropDown = "";
        this.bulkShowAddButton = "";
        this.bulkShowEditButton = "";
        this.bulkShowSaveButton = "hidden";
        this.bulkShowBranchtext = "hidden";
        this.bulkShowCancelButton = "hidden";

      }

    }));
  }

  branchNameValidation(tabId): void {
    if (tabId == 1) {
      this.errorMessagesBranchName = [];
      if (this.contactForm.value.branchName == null || this.contactForm.value.branchName == "") {
        this.errorMessagesBranchName.push("This field is required");
      }
    } else if (tabId == 2) {
      this.bulkImportErrorMessagesBranchName = [];
      if (this.bulkImportForm.value.branchName == null || this.bulkImportForm.value.branchName == "") {
        this.bulkImportErrorMessagesBranchName.push("This field is required");
      }
    }

  }

  editBranch(tabId): void {
    if (tabId == 1) {
      this.errorMessages = [];
      this.errorMessagesBranchName = [];
      this.selectionErrorMessages = [];
      if (this.contactForm.get("branchId")?.value > 0) {
        this.subscription.push(this.branchService
          .get(this.contactForm.get("branchId")?.value)
          .subscribe(
            (response: any) => {
              this.contactForm.patchValue(response);
            },
            (error: any) => {
              console.log(error);
            }
          ));
        this.showBranchDropDown = "hidden";
        this.showAddButton = "hidden";
        this.showEditButton = "hidden";
        this.showSaveButton = "";
        this.showBranchtext = "";
        this.showCancelButton = "";
        this.contactForm.get("branchName").setValidators(Validators.required);
        this.contactForm.get("branchId").clearValidators();
        this.contactForm.get("npnNumber").clearValidators();
      } else {
        this.selectionErrorMessages.push("Please select " + (this.tenantTypeId == 1 ? 'agency' : 'branch') + " from drop down to edit!!");
      }
    } else if (tabId == 2) {
      this.bulkImportErrorMessages = [];
      this.bulkImportErrorMessagesBranchName = [];
      this.bulkSelectionErrorMessages = [];
      if (this.bulkImportForm.get("branchId")?.value > 0) {
        this.subscription.push(this.branchService
          .get(this.bulkImportForm.get("branchId")?.value)
          .subscribe(
            (response: any) => {
              this.bulkImportForm.patchValue(response);
            },
            (error: any) => {
              console.log(error);
            }
          ));
        this.bulkShowBranchDropDown = "hidden";
        this.bulkShowAddButton = "hidden";
        this.bulkShowEditButton = "hidden";
        this.bulkShowSaveButton = "";
        this.bulkShowBranchtext = "";
        this.bulkShowCancelButton = "";
        this.bulkImportForm.get("branchName").setValidators(Validators.required);
        this.bulkImportForm.get("branchId").clearValidators();
        this.bulkImportForm.get("npnNumber").clearValidators();
      } else {
        this.bulkSelectionErrorMessages.push("Please select " + (this.tenantTypeId == 1 ? 'agency' : 'branch') + " from drop down to edit!!");
      }
    }

  }

  addBranch(tabId): void {
    this.isAddBranch = true;
    if (tabId == 1) {
      this.contactForm.get("branchName").setValue(null);
      this.contactForm.get("branchName").setValidators(Validators.required);
      this.contactForm.get("branchId").clearValidators();
      this.contactForm.get("npnNumber").clearValidators();
      // this.contactForm.get("branchId")!.setValue(0);
      this.showBranchDropDown = "hidden";
      this.showAddButton = "hidden";
      this.showEditButton = "hidden";
      this.showSaveButton = "";
      this.showBranchtext = "";
      this.showCancelButton = "";
      this.errorMessagesBranchName = [];
      this.selectionErrorMessages = [];
    } else if (tabId == 2) {
      this.bulkImportForm.get("branchName").setValue(null);
      this.bulkImportForm.get("branchName").setValidators(Validators.required);
      this.bulkImportForm.get("branchId").clearValidators();
      this.bulkImportForm.get("npnNumber").clearValidators();
      // this.bulkImportForm.get("branchId")!.setValue(0);
      this.bulkShowBranchDropDown = "hidden";
      this.bulkShowAddButton = "hidden";
      this.bulkShowEditButton = "hidden";
      this.bulkShowSaveButton = "";
      this.bulkShowBranchtext = "";
      this.bulkShowCancelButton = "";
      this.bulkImportErrorMessagesBranchName = [];
      this.bulkSelectionErrorMessages = [];
    }

  }

  saveBranch(tabId): void {
    if (tabId == 1) {
      if (this.contactForm.value.branchName == null || this.contactForm.value.branchName == "") {
        this.errorMessagesBranchName = [];
        this.errorMessagesBranchName.push("This field is required");
      }
      if (this.contactForm.value.branchName && this.contactForm.value.branchName.trim()) {
        this.disableButton = true;
        this.contactForm.get("userById")!.setValue(this.sharedService.getValue('userId'));
        if (this.isAddBranch) {
          this.contactForm.get("branchId")!.setValue(0);
          this.branchService.add(this.contactForm.value).subscribe(
            (response: any) => {
              this.sharedService.showToastMessage('' + (this.tenantTypeId == 1 ? 'Agency' : 'Branch') + ' added successfully.');
              /*  this.ngOnInit();*/
              this.getLatestBranchList(tabId, response.id);
              this.disableButton = false;
            },
            (error: any) => {
              console.log(error);
              this.errorMessages = [];
              this.errorMessages.push("Error communicating with the server.  If this error persists, contact support.");
              this.disableButton = false;
            }
          );
          this.isAddBranch = false;
        } else {
          this.branchService.update(this.contactForm.value).subscribe(
            (response: any) => {
              this.sharedService.showToastMessage('' + (this.tenantTypeId == 1 ? 'Agency' : 'Branch') + ' updated successfully.')
              /* this.ngOnInit();*/
              this.getLatestBranchList(tabId, response.id);
              this.disableButton = false;
            },
            (error: any) => {
              console.log(error);
              this.errorMessages = [];
              this.errorMessages.push("Error communicating with the server.  If this error persists, contact support.");
              this.disableButton = false;
            }
          );
        }
      }
    } else if (tabId == 2) {
      if (this.bulkImportForm.value.branchName == null || this.bulkImportForm.value.branchName == "") {
        this.bulkImportErrorMessagesBranchName = [];
        this.bulkImportErrorMessagesBranchName.push("This field is required");
      }
      if (this.bulkImportForm.value.branchName && this.bulkImportForm.value.branchName.trim()) {
        this.disableButton = true;
        this.bulkImportForm.get("userById")!.setValue(this.sharedService.getValue('userId'));
        if (this.isAddBranch) {
          this.bulkImportForm.get("branchId")!.setValue(0);
          this.branchService.add(this.bulkImportForm.value).subscribe(
            (response: any) => {
              this.sharedService.showToastMessage('' + (this.tenantTypeId == 1 ? 'Agency' : 'Branch') + ' added successfully.');
              /*this.RemoveUploadedFile();*/
              /*this.ngOnInit();*/
              this.getLatestBranchList(tabId, response.id);
              /*this.ShowUploadWizard = true;*/
              this.disableButton = false;
            },
            (error: any) => {
              this.bulkImportErrorMessages = [];
              this.bulkImportErrorMessages.push("Error communicating with the server.  If this error persists, contact support.");
              this.disableButton = false;
            }
          );
          this.isAddBranch = false;
        } else {
          this.branchService.update(this.bulkImportForm.value).subscribe(
            (response: any) => {
              this.sharedService.showToastMessage('' + (this.tenantTypeId == 1 ? 'Agency' : 'Branch') + ' updated successfully.')
              /*this.ngOnInit();*/
              this.getLatestBranchList(tabId, response.id);
              /*this.RemoveUploadedFile();*/
              /*this.ShowUploadWizard = true;*/
              this.disableButton = false;
            },
            (error: any) => {
              this.bulkImportErrorMessages = [];
              this.bulkImportErrorMessages.push("Error communicating with the server.  If this error persists, contact support.");
              this.disableButton = false;
            }
          );
        }
      }
    }

  }

  cancel(tabId): void {
    this.isAddBranch = false;
    if (tabId == 1) {
      this.disableButton = false;
      this.contactForm.get("branchName").clearValidators();
      this.contactForm.get("branchName").updateValueAndValidity();
      /*  this.contactForm.get("branchId")!.setValue(this.branchList[0].branchId);*/
      //this.contactForm.get("branchId").setValue(this.contactForm.value.branchId);
      this.contactForm.get("branchId").setValidators(Validators.required);
      //this.contactForm.get("npnNumber").setValidators([Validators.required, Validators.pattern("[0-9]+(,[0-9]+)*")]);
      this.contactForm.get("npnNumber").setValidators([Validators.required, Validators.pattern("[0-9]{0,10}(,[0-9]{0,10})*")]);
      this.showBranchDropDown = "";
      this.showAddButton = "";
      this.showEditButton = "";
      this.showSaveButton = "hidden";
      this.showBranchtext = "hidden";
      this.showCancelButton = "hidden";
      this.selectionErrorMessages = [];
    } else if (tabId == 2) {
      this.disableButton = false;
      this.bulkImportForm.get("branchName").clearValidators();
      this.bulkImportForm.get("branchName").updateValueAndValidity();
      /*this.bulkImportForm.get("branchId")!.setValue(this.branchList[0].branchId);*/
      //this.bulkImportForm.get("branchId").setValue(this.bulkImportForm.value.branchId);
      this.bulkImportForm.get("branchId").setValidators(Validators.required);
      //this.bulkImportForm.get("npnNumber").setValidators([Validators.required, Validators.pattern("[0-9]+(,[0-9]+)*")]);
      this.bulkImportForm.get("npnNumber").setValidators([Validators.required, Validators.pattern("[0-9]{0,10}(,[0-9]{0,10})*")]);
      this.bulkShowBranchDropDown = "";
      this.bulkShowAddButton = "";
      this.bulkShowEditButton = "";
      this.bulkShowSaveButton = "hidden";
      this.bulkShowBranchtext = "hidden";
      this.bulkShowCancelButton = "hidden";
      this.bulkSelectionErrorMessages = [];
    }
  }

  addContact() {

    this.errorMessages = [];
    this.selectionErrorMessages = [];
    var errorList = [];
    if (this.contactForm.valid) {
      const npnNumbers = this.contactForm.value.npnNumber.trim().split(",");

      /*if (this.productId != 3) {*/
      if (this.remainingMaxNPN == 0) {
        this.errorMessages.push("Your plan is exceeded to import more NPN. Please contact administrator."); // You cannot add more NPN as per you plan
        //Your current plan is exceeded to add Contact(s). Please contact administration.
        return;
      }

      if (npnNumbers && npnNumbers.length && npnNumbers.length > this.remainingMaxNPN) {
        this.errorMessages.push("Your plan allows you to add a maximum of " + this.remainingMaxNPN + " NPNs. Please upgrade your plan if you’d like to add more.");
        // You can only add maximum " + this.remainingMaxNPN + " NPNs as per your plan.
        return;
      }
      /*}*/

      if (this.contactForm.value.branchId <= 0) {
        this.errorMessages.push("Please select " + (this.tenantTypeId == 1 ? 'agency' : 'branch') + " to proceed further.");
        return;
      }

      const duplicateNpnNumber = npnNumbers.filter((item, index) => index !== npnNumbers.indexOf(item));
      if (duplicateNpnNumber.length > 0) {
        duplicateNpnNumber.forEach(duplicate => {
          this.errorMessages.push("Duplicate NPN Number: " + duplicate);
        })
        return;
      }

      let contactFormPayload = this.contactForm.value.npnNumber?.split(',').map(value => {
        return {
          ...this.contactForm.value,
          npn: value
        }

      });
      this.processData(contactFormPayload, false);

    }
  }

  addBulkContact() {
    this.bulkImportErrorMessages = [];
    this.bulkSelectionErrorMessages = [];
    if (this.bulkImportForm.valid) {
      // const npnNumbers = this.bulkImportForm.value.npnNumber.split(",");
      const npnNumbers = this.bulkImportForm.value.npnNumber;

      /*if (this.productId != 3) {*/
      if (this.remainingMaxNPN == 0) {
        this.bulkImportErrorMessages.push("Your plan is exceeded to import more NPN. Please contact administrator."); // You cannot add more NPN as per you plan
        return;
      }

      if (npnNumbers && npnNumbers.length && npnNumbers.length > this.remainingMaxNPN) {
        this.bulkImportErrorMessages.push("Your plan allows you to add a maximum of " + this.remainingMaxNPN + " NPNs. Please upgrade your plan if you’d like to add more.");
        return;
      }
      /* }*/

      if (this.bulkImportForm.value.branchId <= 0) {
        this.bulkImportErrorMessages.push("Please select " + (this.tenantTypeId == 1 ? 'agency' : 'branch') + " to proceed further.");
        return;
      }

      const duplicateNpnNumber = npnNumbers.filter((item, index) => index !== npnNumbers.indexOf(item));
      if (duplicateNpnNumber.length > 0) {
        duplicateNpnNumber.forEach(duplicate => {
          this.bulkImportErrorMessages.push("Duplicate NPN Number: " + duplicate);
        })
        return;
      }

      this.bulkImportForm.value.npnNumber.forEach(value => {
        delete value.isError;
        value.branchId = this.bulkImportForm.value.branchId;
      });
      this.processData(this.bulkImportForm.value.npnNumber, true);
    }
  }

  processData(payload, isBulk) {
    var errorList = [];
    const modalRef = this.modalService.open(AddContactProgressComponent, ngbModalOptionsCenter);
    modalRef.componentInstance.requestData = payload;
    this.contactAdded.emit(false);
    modalRef.result.then(data => {
      // this.getTenantContactCount();
      if (data && data.length && data.length > 0) {
        data.forEach(err => { err.errorMessage != null && err.errorMessage != "" ? errorList.push(err.errorMessage) : ''; })
        if (errorList.length < data.length) {
          localStorage["profileStatus"] = 5;
        }
        this.contactService.getContactList(data);
        const modalRef2 = this.modalService.open(AddContactPreviewComponent, ngbModalOptionsXL);
        modalRef2.result.then(data => {
          this.contactAdded.emit(true);
          this.contactForm.get("npnNumber").reset();

        });
        isBulk ? this.RemoveUploadedFile() : '';
        // modalRef.componentInstance.modalData = data;
        
      } else {
        if (isBulk) {
          this.bulkImportErrorMessages = [];
          this.bulkImportErrorMessages.push(data.message);
        } else {
          this.errorMessages = [];
          this.errorMessages.push(data.message);
        }

      }
    });
  }

  initializeData() {
    this.errorMessages = [];
    this.bulkImportErrorMessages = [];
    this.contactForm = this.bindFormGroup();
    this.bulkImportForm = this.bindBulkImportFormGroup();
    this.showBranchDropDown = "";
    this.showAddButton = "";
    this.showEditButton = "";
    this.showSaveButton = "hidden";
    this.showBranchtext = "hidden";
    this.showCancelButton = "hidden";
    this.bulkShowBranchDropDown = "";
    this.bulkShowAddButton = "";
    this.bulkShowEditButton = "";
    this.bulkShowSaveButton = "hidden";
    this.bulkShowBranchtext = "hidden";
    this.bulkShowCancelButton = "hidden";
    this.contactForm.get("tenantId")!.setValue(this.sharedService.getValue('tenantId'));
    this.contactForm.get("branchName").clearValidators();
    this.bulkImportForm.get("tenantId")!.setValue(this.sharedService.getValue('tenantId'));
    this.bulkImportForm.get("branchName").clearValidators();
    this.subscription.push(this.branchService.list(this.sharedService.getValue('tenantId')).subscribe(response => {
      this.branchList = response;
    }));
    this.contactForm.get("branchId").setValidators(Validators.required);
    this.bulkImportForm.get("branchId").setValidators(Validators.required);
    //this.contactForm.get("npnNumber").setValidators([Validators.required, Validators.pattern("[0-9]+(,[0-9]+)*")]);
    this.contactForm.get("npnNumber").setValidators([Validators.required, Validators.pattern("[0-9]{0,10}(,[0-9]{0,10})*")]);
    this.getTenantContactCount();
  }

  onFileSelected(event) {
    this.sharedService.showLoader();
    this.selectedFile = event.target.files[0] ?? null;
    this.GetValidFiles();

  }

  GetValidFiles(): void {
    this.bulkImportErrorMessages = [];
    // const commaSeperatedRegex = new RegExp("^\\\d+(,\\\d+)*$");
    // const emailValidRegex = new RegExp("/^[_a-zA-Z0-9+-]+(\.[_a-zA-Z0-9+-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/");
    const npnValidRegex = new RegExp("[0-9]{0,10}(,[0-9]{0,10})*");
    // this.selectedFile = event.target.files[0] ?? null;
    this.bulkImportForm.get("uploadFileName")!.setValue(this.selectedFile.name);

    var formData = new FormData();

    let fileName = this.selectedFile.name;

    formData.append('file', this.selectedFile, this.selectedFile.name);
    formData.append('fileName', this.selectedFile.name);

    this.contactService.uploadBulkImportFile(formData).subscribe(
      response => {

        this.tableTitle = ["First Name", "Last Name", "Producer Code", "NPN", "Email"];
        setTimeout(() => {
          this.csvData = [...response];
          this.bulkImportForm.get("npnNumber")!.setValue(this.csvData);
          var errorData = this.csvData.filter(obj => obj.isError == true);
          this.fileUploadError = errorData.length > 0;
          if (this.fileUploadError) {
            this.errorNpn = errorData[0].index;
            this.bulkImportErrorMessages.push("Errors were identified in your import. Please review and verify the accuracy of all data before attempting the import again for those items.");
          }
          this.sharedService.hideLoader();
        }, 100);

        this.ShowUploadWizard = false;
        this.modalSM.emit(this.ShowUploadWizard);

      },
      errorObj => {
        console.log(errorObj.error);
        this.sharedService.hideLoader();
        this.fileUploadError = true;
        this.bulkImportErrorMessages.push(errorObj.error);
      });

  }

  scrollToElement() {
    this.viewPort.scrollToIndex(this.errorNpn,true, -33);
  }

  RemoveUploadedFile() {
    this.bulkImportErrorMessages = [];
    this.bulkSelectionErrorMessages = [];
    this.selectedFile = null;
    this.bulkImportForm.value.uploadFileName ? this.viewPort.scrollToIndex(0,true, 0) : '';
    this.bulkImportForm.get("uploadFileName")!.setValue('');
    this.fileInputEle.nativeElement.value = '';
    /*this.contactForm.get("branchId")!.setValue(this.branchList[0].branchId);
    this.bulkImportForm.get("branchId")!.setValue(this.branchList[0].branchId);
    this.bulkImportForm.get("npnNumber")!.setValue('');*/
    this.ShowUploadWizard = true;
    this.fileUploadError = false;
    this.modalSM.emit(this.ShowUploadWizard);
    
  }

  removeRecordfromCSVData(npn) {
    const i = this.csvData.findIndex(el => el == npn);
    if (i > -1) {
      npn['isError'] = false;
      npn['errorMessage'] = '';
      this.csvData.splice(i, 1);      
      this.csvData.length > 0 ? this.getValidateDataList() : this.RemoveUploadedFile();
    }
    // const modalRef = this.modalService.open(ConfirmDialogComponent, ngbModalOptionsCenter);
    // modalRef.componentInstance.modalData = {
    //   title:"Delete NPN", 
    //   prompt:"Are you sure you want to delete NPN number: ",
    //   data: `${npn.npnNumber} - ${npn.firstName} ${npn.lastName}`,
    //   isCheck: false
    // };
    // modalRef.result.then(data => {
    //   if (data) {
    //     const i = this.csvData.findIndex(el => el == npn);
    //     if(i > -1) {
    //          this.csvData.splice(i, 1);
    //          this.getValidateDataList();
    //     }
    //   }
    // })
  }

  getValidateDataList() {
    return new Promise((resolve) => {
      this.bulkImportErrorMessages = [];
      this.fileUploadError = false;
      const emailValidRegex = /^[_a-zA-Z0-9+-]+(\.[_a-zA-Z0-9+-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,9})$/;
      this.csvData.forEach(npn => {
        var duplicateNpn = this.csvData.filter(el => el['npn'] == npn['npn'])
        var duplicateEmail = this.csvData.filter(el => el['email'] == npn['email'] && el['email'] != null && el['email'] != "")
        var duplicateProducerCode = this.csvData.filter(el => el['producerCode'] == npn['producerCode'] && el['producerCode'] != null && el['producerCode'] != "")

        if (duplicateNpn.length > 1 || duplicateEmail.length > 1 || (npn['email'] != null && npn['email'].length > 0 && !emailValidRegex.test(npn['email'])) || duplicateProducerCode.length > 1 || npn['producerCode'].length > 20 || npn['npn'] == "" || npn['npn'] == null) {
          this.fileUploadError = true;
          this.bulkImportErrorMessages.length == 0 ? this.bulkImportErrorMessages.push("Errors were identified in your import. Please review and verify the accuracy of all data before attempting the import again for those items.") : '';
          this.errorNpn = npn['index'];
          npn['isError'] = true;
          if (duplicateNpn.length > 1 || duplicateEmail.length > 1 || duplicateProducerCode.length > 1) {
            npn['errorMessage'] = "Duplicate NPN, Email or Producer Code found.";
          }
          else if (npn['email'] != null && npn['email'].length > 0 && !emailValidRegex.test(npn['email'])) {
            npn['errorMessage'] = "Invalid Email.";
          }
          else if (npn['producerCode'].length > 20) {
            npn['errorMessage'] = "Producer code can not be greater than 20.";
          }
          else if (npn['npn'] == "" || npn['npn'] == null) {
            npn['errorMessage'] = "NPN is required.";
          }
        }
        else {
          npn['isError'] = false;
          npn['errorMessage'] = '';
        }
      });
      resolve(null);
      this.bulkImportForm.get("npnNumber")!.setValue(this.csvData);
    });
  }

  removeTabValues() {
    this.bulkImportForm.value.uploadFileName.length > 0 ? this.RemoveUploadedFile() : ''; 
    
    // this.closeContactAddPopup.nativeElement.click();
    this.addContactTab.nativeElement.querySelectorAll('.nav-item .nav-link').forEach(
      tab => {
        tab.classList.remove('active');
        this.addContactTab.nativeElement.querySelectorAll('.nav-item .nav-link')[0].classList.add('active');
      }
    );
    this.addContactTabPane.nativeElement.querySelectorAll('.tab-content .tab-pane').forEach(
      tab => {
        tab.classList.remove('active');
        this.addContactTabPane.nativeElement.querySelectorAll('.tab-content .tab-pane')[0].classList.add('active');
      })
  }

  ngOnChanges(changes: SimpleChange) {
    if (changes["reloadPage"] && changes["reloadPage"].previousValue != undefined) {
      this.initializeData();
      this.removeTabValues();
      this.getLatestBranchList(1, null);
      this.isAgentNPN = true;
      this.RemoveUploadedFile();
    } else if (changes["isContactDeleted"] && changes["isContactDeleted"].previousValue != undefined) {
      this.getTenantContactCount();
    }
  }

  getTenantContactCount() {
    this.contactService.getTenantContactCount(this.sharedService.getValue('tenantId')).subscribe((response: any) => {

      this.productId = response.productId;
      this.minNPN = response.minNPN;
      this.maxNPN = response.maxNPN;
      this.addedNPN = response.totalNPN;

      // Enterprise plan have the NPN limit of 10000
      /*if (response.productId != 3) {*/
      if (this.addedNPN <= this.minNPN) {
        this.remainingMinNPN = this.minNPN - this.addedNPN;
      }

      if (this.addedNPN <= this.maxNPN) {
        this.remainingMaxNPN = this.maxNPN - this.addedNPN;
      }
      //}
    })
  }

  getCSVTemplate() {
    this.contactService.downloadBulkImportTemplate().subscribe(blob => {
      saveAs(blob, "BulkImport");
    })
  }

  displayError(contact) {
    if (contact.isError) {
      this.bulkImportErrorMessages = [];
      this.bulkImportErrorMessages.push(contact.errorMessage);
      this.errorNpn = contact.index;
    }
  }

  tabChange(isNPN){
    this.isAgentNPN = isNPN
  }

  closePopup(event){
    this.isAgentNPN = true;
    this.contactAdded.emit(event);
  }

  // Drag and drop
  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    if (event.dataTransfer.files && event.target.offsetParent.classList.contains('drag-drop')) {
      event.preventDefault();
      event.stopPropagation();
      this.sharedService.showLoader();
      this.selectedFile = event.dataTransfer.files[0] ?? null;
      this.GetValidFiles();
    }
  }

  // ///////
}
