
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Injectable()
export class ValidatorHelper {
    ///This is the guts of Angulars minLength, added a trim for the validation
    // static minLength(minLength: number): ValidatorFn {
    //     return (control: AbstractControl): { [key: string]: any } => {
    //         if (ValidatorHelper.isPresent(Validators.required(control))) {
    //             return null;
    //         }
    //          const v: string = control.value ? control.value : '';
    //         return v.trim().length < minLength ?
    //             { 'minlength': { 'requiredLength': minLength, 'actualLength': v.trim().length } } :
    //             null;
    //     };
    // }

    // static isPresent(obj: any): boolean {
    //     return obj !== undefined && obj !== null;
    // }

    static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {  
        if(((control.value !=null) && (control.value as string).indexOf(' ') >= 0)){  
            return {cannotContainSpace: true}  
        }  
    
        return null;  
    } 
    
    static isValidSubDomain(control: AbstractControl) : ValidationErrors | null {  
        let pattern = /^[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$/;
        if(!pattern.test(control.value as string)){  
            return {isValidSubDomain: true}  
        }  
        
        // if((control.value as string).indexOf(' ') >= 0 || (control.value as string).indexOf('.') >= 0){  
        //     return {isValidSubDomain: true}  
        // }  
    
        return null;  
    } 

    static passwordPattern(control: AbstractControl) : ValidationErrors | null {  
        let pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        if(!pattern.test(control.value as string)){  
            return {passwordPattern: true}  
        }  
    
        return null;  
    } 

    static confirmPassword(control: FormGroup) {
        if(  control && control.value && control.value.password
            && control.value.confirmPassword &&
            control.value.password === control.value.confirmPassword
            ){
                return null;
            }
        
        return { 'mismatch': true }
    }

    static emailPattern(control: FormGroup): ValidationErrors | null {
      let pattern = /^[_a-zA-Z0-9+-]+(\.[_a-zA-Z0-9+-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,9})$/;
      if (!pattern.test(control.value as string)) {
        return { emailPattern: true }
      }

      return null;
    } 

    static phonePattern(control: FormGroup) : ValidationErrors | null {
      let pattern = /^\d{3}-\d{3}-\d{4}$/;
      if (!pattern.test(control.value as string)) {
        return { phonePattern: true}
      }
      return null;
    }

    static requireCheckboxes(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
          const checked = Object.values((control as FormGroup).controls).some(control => control.value === true);
          return checked ? null : { requireCheckboxes: true };
        };
      }

      static domainRestrictionValidator(domainList: string[]): ValidatorFn {       
        return (control: AbstractControl): {[key: string]: any} | null => {
          if (control.value && control.value !== '') {
            const email = control.value as string;
            const domain = email.substring(email.lastIndexOf('@') + 1).toLowerCase();
            
            const lowerCaseDomainList = domainList.map(domain => domain.toLowerCase());

            if (lowerCaseDomainList.includes(domain)) {
              return { restrictedDomain: true };
            }
          }
          return null;
        };
      }
}
