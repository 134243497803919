import { Component, OnInit } from '@angular/core';
import { ModelService } from 'services/model.service';
import { AlertService, NotificationType,NotificationModel } from './alert.service';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {
  
  notifications: NotificationModel[] = [];  
  
    constructor(public _notificationService: AlertService) { }  
  
    ngOnInit() {  
        this._notificationService.getAlert().subscribe((alert: NotificationModel) => {  
           
            this.notifications = [];  
            if (!alert) {  
                this.notifications = [];  
                return;  
            }  
            this.notifications.push(alert); 
            setTimeout(() => {  
                this.notifications = this.notifications.filter(x => x !== alert);  
            }, 5000);  
        });  
    }  
  
    removeNotification(notification: NotificationModel) {  
        this.notifications = this.notifications.filter(x => x !== notification);  
    }  
  
    /**Set css class for Alert -- Called from alert component**/      
    cssClass(notification: NotificationModel) {  
         
        switch (notification.type) {  
            case NotificationType.Success:  
                return 'toast-success';  
            case NotificationType.Error:  
                return 'toast-error';  
            case NotificationType.Info:  
                return 'toast-info';  
            case NotificationType.Warning:  
                return 'toast-warning';  
        }  
    }  

}
