<div class="delete-modal common-modal">
    <div class="modal-header pop-title">
      <h4 class="modal-title">{{modalData?.title}}</h4>
      <button type="button" class="modal-close-btn close" data-dismiss="modal" (click)="close(false)">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><rect width="28" height="28" rx="6" fill="none" /><g transform="translate(4 4)"><rect width="20" height="20" fill="none" /><path d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z" transform="translate(-0.005 0.005)" fill="#757575" /></g></svg>
    </button>
    </div>
    <div class="modal-body">
      <div class="group-form">
        <form class="makeadmin-form">
          <div class="user-name-block">
            <p class="name-note">{{modalData?.prompt}}</p>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer pd-4">
      <button type="button" (click)="close(false)" class="btn btn-md basic-btn">Cancel</button>
      <button type="button" (click)="close(true)" class="btn btn-md ml-2" [ngClass]="[buttonClass]">{{buttonText}}</button>
    </div>
  </div>

