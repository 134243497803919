import { Injectable } from '@angular/core';
import { ApiClientService } from '../../../api-services';
import { Branch } from '../models/branch.model';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(private apiClient: ApiClientService) { }

  public list(tenantId: number) {
    return this.apiClient.get(`api/branch/list/${tenantId}`, null, null, false);
  }

  public get(id: number) {
    return this.apiClient.get(`api/branch/${id}`, null, null, false);
  }

  public update(branch: Branch) {
    return this.apiClient.put(`api/branch`, branch, null, false);
  }

  public add(branch: any) {
    return this.apiClient.post(`api/branch`, branch, null, false);
  }

  public delete(id: number) {
    return this.apiClient.delete(`api/branch/${id}`, null, null, false);
  }
}
