import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IssueFeedbackType, IssueFeedbackTopic, IssueFeedback } from '../models/issue-feedback.model';
import { ApiClientService } from '../api-services/common/api-client.service';

@Injectable({
  providedIn: 'root'
})
export class IssueFeedbackService {

  constructor(private apiClient: ApiClientService) { }

  /**
   * Get all IssueFeedbackTypes.
   * @returns An Observable of IssueFeedbackType array.
   */
  getIssueFeedbackTypes(): Observable<IssueFeedbackType[]> {
    return this.apiClient.get(`api/IssueFeedback/GetIssueFeedbackType`, null, null, false);
  }

  /**
   * Get IssueFeedbackTopics by IssueFeedbackTypeId.
   * @param issueFeedbackTypeId The ID of the IssueFeedbackType to filter topics.
   * @returns An Observable of IssueFeedbackTopic array.
   */
  getIssueFeedbackTopicsByType(issueFeedbackTypeId: number): Observable<IssueFeedbackTopic[]> {
    return this.apiClient.get(`api/IssueFeedback/GetIssueFeedbackTopicsByType/${issueFeedbackTypeId}`, null, null, false);
  }

  /**
   * Insert a new IssueFeedback.
   * @param issueFeedback The IssueFeedback object to insert.
   * @returns An Observable of string (Ticket Number).
   */
  insertIssueFeedback(issueFeedback: IssueFeedback): Observable<string> {
    return this.apiClient.post(`api/IssueFeedback/InsertIssueFeedback`, issueFeedback, null, false);
  }
}
