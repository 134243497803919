<div class="form-group">
    <div class="input-group" id="dateRangePicker">
      <i class="material-icons" (click)="dp.toggle(); isOpen = !isOpen">
        <img src="../../assets/images/ic-calendar.svg" title="calender" width="20">
      </i>
      <input
        class="form-control"
        id="dateRange"
        [placeholder]="placeholder"
        name="dp"
        [value]="formattedDateRange"
        [maxDate]="endDate"
        ngbDatepicker
        #dp="ngbDatepicker"
        (dateSelect)="onDateSelection($event)"
        [displayMonths]="2"
        [dayTemplate]="t"
        [autoClose]="false"
        (click)="dp.toggle(); isOpen = !isOpen"
        readonly
      />
    </div>
  </div>

  <ng-template #t let-date="date" let-focused="focused">
    <span
      class="custom-day"
      [class.focused]="focused"
      [class.range]="
        isFrom(date) || isTo(date) || isInside(date) || isHovered(date)
      "
      [class.faded]="isHovered(date) || isInside(date)"
      (mouseenter)="hoveredDate = toDate(date)"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </span>
  </ng-template>