import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

export {};
declare global {
   interface Window {
      Calendly: any;
   }
}

@Component({
  selector: 'app-book-onboarding-slot',
  templateUrl: './book-onboarding-slot.component.html',
  styleUrls: ['./book-onboarding-slot.component.css']
})
export class BookOnboardingSlotComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {
    var userReg = JSON.parse(sessionStorage.getItem('user'));
    var url = `${environment.calendlyURL}?email=${userReg.email}&name=${userReg.firstName} ${userReg.lastName}`;
      
      window.Calendly.initInlineWidget({
         url: url,
         parentElement: document.querySelector('.calendly-inline-widget'),
         prefill: {}
      });
   }

}
