import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-custom-checkbox-dropdown',
  templateUrl: './custom-checkbox-dropdown.component.html',
  styleUrls: ['./custom-checkbox-dropdown.component.css']
})
export class CustomCheckboxDropdownComponent implements OnInit {

  @Input() states: any; 
  @Input() selectedStateIds: string;
  @Output() satesChanges = new EventEmitter<string[]>();
  selectedStates = [];
  unSelectedStates = [];

  constructor() { }

  ngOnInit(): void {
    this.states?.length > 0 ? this.setValue() : '';
  }

  ngOnChanges(changes: SimpleChange){
    this.setValue();
  }

  setValue(){
    this.states = this.states?.map(m => ({ ...m, isChecked: false }));
    if(this.selectedStateIds?.length > 0){
      const stateIdsArray: number[] = this.selectedStateIds?.split(',').map(id => parseInt(id.trim(), 10));

      stateIdsArray.forEach(id => {
        const foundState = this.states.find(state => state.stateId === id);
        if (foundState) {
          foundState['isChecked'] = true;
          this.selectedStates.push(foundState);
        }
      });
    }
    this.getSelectedData();
  }

  onSelectionChange(id) { 
    this.states.forEach(x =>{
      if( x.stateId == id){
        x.isChecked = !x.isChecked;
      }else if(id == 0){
        x.isChecked = this.selectedStates?.length == this.states?.length ? false : true;
      }
    })
    this.getSelectedData();
    this.satesChanges.emit(this.selectedStates);
  }

  getSelectedData() {
    this.unSelectedStates = this.states.filter(x =>x.isChecked === false)
    this.selectedStates = this.states.filter(x =>x.isChecked === true)
  }
}
