import { Component, OnInit } from '@angular/core';
import { SharedService } from 'services';

@Component({
  selector: 'app-registration-thanks',
  templateUrl: './registration-thanks.component.html',
  styleUrls: ['./registration-thanks.component.css']
})
export class RegistrationThanksComponent implements OnInit {

  constructor(private sharedService: SharedService) {
    document.body.classList.add('white-bg');
   }

  ngOnInit(): void {
  }

  downloadExcel() {
    const bulkImport = [
      {
          "First Name": "",
          "Last Name": " ",
          "Producer Code": "",
          "NPN": "",
          "Email": ""
      }];
      var  columnOrder = null;
    this.sharedService.exportToExcel(bulkImport, columnOrder, 'BulkImport.xlsx')
  }
}
