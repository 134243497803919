import { Injectable } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { SharedService } from '../../services/shared.service';
import { catchError, finalize, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services';


@Injectable({
  providedIn: 'root',
})
export class ApiClientService {

  private headerOptions: any = {} as HttpHeaders;
  apiBaseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService
  ) {
    var token = localStorage.getItem('token');
    if (token) {
      this.setHeader('Authorization', `bearer ${token}`);
    }
    this.setHeader('Content-Type', 'application/json');
  }

  public setHeader(key: any, value: any) {
    this.headerOptions[key] = value;
    //this.headerOptions.set(key, value);
  }

  private fullUrl(url: string): string {
    //return 'https://localhost:7081/' + url;
    return this.apiBaseUrl + url;
  }

  private hideLoader() {
    this.sharedService.hideLoader();
  }

  private handleError(response: any) {
    var error = {
      status: response.status,
      message: '',
    };
    if (response.status === 401 || response.status === 0) {
      this.headerOptions = {} as HttpHeaders;
      //localStorage.clear();
      this.authService.userLoggedOut();
      this.router.navigate(['login']);
    } else if (response.error) {
      if (response.error.status) {
        error.status = response.error.status;
      }

      if (typeof '' == typeof response.error) {
        error.message = response.error;
      } else {
        error.message = response.error.message
          ? response.error.message
          : response.error.error_description;
      }
    } else {
      error.message = response.message;
    }
    return throwError(error);
  }

  public get(
    url: string,
    header: any = undefined,
    params: any = undefined,
    showLoading: boolean = true
  ): any {

    if (showLoading) {
      this.sharedService.showLoader();
    }
    return this.http
      .get(this.fullUrl(url), {
        headers: header ? header : this.headerOptions,
        params: params ? params : null,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => {
          return this.handleError(err);
        }),
        finalize(() => {
          if (showLoading) {
            this.hideLoader();
          }
        })
      );
  }

  public exportGet(
    url: string,
    data: any,
    header: any = undefined,
    showLoading: boolean = true
  ): any {
    if (showLoading) {
      this.sharedService.showLoader();
    }
    return this.http
      .get(this.fullUrl(url), {
        headers: header ? header : this.headerOptions, responseType: 'blob',
        observe: 'response'
      })
      .pipe(
        map((res: any) => {
          return new Blob([res.body], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });//application/vnd.ms-excel
        }),
        catchError((err) => {
          return this.handleError(err);
        }),
        finalize(() => {
          if (showLoading) {
            this.hideLoader();
          }
        })
      );
  }

  public put(
    url: string,
    data: any,
    header: any = undefined,
    showLoading: boolean = true
  ): any {
    if (showLoading) {
      this.sharedService.showLoader();
    }
    return this.http
      .put(this.fullUrl(url), data, {
        headers: header ? header : this.headerOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => {
          if (showLoading) {
            this.hideLoader();
          }
          return this.handleError(err);
        }),
        finalize(() => {
          if (showLoading) {
            this.hideLoader();
          }
        })
      );
  }

  public post(
    url: string,
    data: any,
    header: any = undefined,
    showLoading: boolean = true
  ): any {
    if (showLoading) {
      this.sharedService.showLoader();
    }
    return this.http
      .post(this.fullUrl(url), data, {
        headers: header ? header : this.headerOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => {
          return this.handleError(err);
        }),
        finalize(() => {
          if (showLoading) {
            this.hideLoader();
          }
        })
      );
  }

  public export(
    url: string,
    data: any,
    header: any = undefined,
    showLoading: boolean = true
  ): any {
    if (showLoading) {
      this.sharedService.showLoader();
    }
    return this.http
      .post(this.fullUrl(url), data, {
        headers: header ? header : this.headerOptions, responseType: 'blob',
        observe: 'response'
      })
      .pipe(
        map((res: any) => {
          return new Blob([res.body], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });//application/vnd.ms-excel
        }),
        catchError((err) => {
          return this.handleError(err);
        }),
        finalize(() => {
          if (showLoading) {
            this.hideLoader();
          }
        })
      );
  }

  public delete(
    url: string,
    data: any,
    header: any = undefined,
    showLoading: boolean = true
  ): any {
    if (showLoading) {
      this.sharedService.showLoader();
    }
    return this.http
      .delete(this.fullUrl(url), {
        headers: header ? header : this.headerOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => {
          return this.handleError(err);
        }),
        finalize(() => {
          if (showLoading) {
            this.hideLoader();
          }
        })
      );
  }

  public uploadFile(url: string,
    formData: FormData,
    header: any = undefined,showLoading: boolean = true)
  {
    if (showLoading) {
      this.sharedService.showLoader();
    }
    return this.http.post(environment.baseUrl + url, formData, {
      headers: header,
      reportProgress: true
    })
    .pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        return this.handleError(err);
      }),
      finalize(() => {
        if (showLoading) {
          this.hideLoader();
        }
      })
    );
  }

  //public getBlob(url: string, header: any = undefined, params: any = undefined){
  //  return this.downloadFile("application/octet-stream", url, header, params);
  //}
  //private downloadFile(fileType: string, url: string, header: any = undefined, params: any = undefined) {
  //  this.sharedService.showLoader();
  //  return this.http.get(this.fullUrl(url), { headers: header ? header : this.headerOptions, params: params ? params : null, responseType: 'arraybuffer' })
  //    .pipe(
  //      map(response => {
  //        this.sharedService.hideLoader();
  //        var array = response;
  //        try {
  //          return new Blob([array], { type: fileType });
  //        }
  //        catch (e) {
  //          return response;
  //        }
  //      }),
  //      catchError((err) => { this.hideLoader(); return this.handleError(err); })
  //    );
  //}
}
