import { Component, ElementRef, EventEmitter, Injectable, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectCarrierModalComponent } from './select-carrier-modal/select-carrier-modal.component';
import { ngbModalOptionsXL } from 'shared/constant';
import { NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ContactService } from 'api-services/contact.service'
import { SharedService } from 'services';
import { State } from '../../../../models/state.model';
import { Carrier } from 'models/manage-mga.model';
import { LinesOfAuthorityService } from 'api-services/lines-of-authority.service';
import { UserAppointmentService } from 'api-services/user-appointment.service';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year
      : '';
  }
}

@Component({
  selector: 'app-new-manual-appointment',
  templateUrl: './new-manual-appointment.component.html',
  styleUrls: ['./new-manual-appointment.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class NewManualAppointmentComponent implements OnInit {

  isAppointmentCreatedSuccessfully: boolean = false;
  @Input() isSelectedCarrier: boolean = false;
  isCustomDateSelected: boolean;
  selectedCustomDate: Date;
  newManualAppoitmentForm: FormGroup;
  @ViewChild('customDatePicker') customDatePickerEle: ElementRef;
  date = new Date();
  autoDeleteDate = []
  @Input() isEditable;
  @Input() selectedAppId;
  @Output() onCancel = new EventEmitter<void>();
  userId: number;
  userDetail: any;
  residentState: State;
  Carrier: Carrier = {} as Carrier;
  masterStates: State[] = [] as State[];
  appointmentTypeList: any;
  errorMessages = [];
  customAutoDeleteDate: any;
  autoDeletedays: number = 0;
  // maxDate: NgbDateStruct;
  minDateAutoDelete: NgbDateStruct;
  feinMask: any[] = [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  constructor(private router: Router
    ,private modalService: NgbModal
    ,private contactService: ContactService
    ,private sharedService: SharedService
    ,private loaService: LinesOfAuthorityService
    ,private userAppointmentService: UserAppointmentService
  ) 
  { 
    if (localStorage.getItem('activeAppointmentContactUserId') != null && localStorage.getItem('activeAppointmentContactUserId').toString() != "") {
      this.userId = Number(localStorage.getItem("activeAppointmentContactUserId"));
    }
  }

  ngOnInit(): void {
    // const today = new Date();
    // this.maxDate = {
    //   year: today.getFullYear(),
    //   month: today.getMonth() + 1,
    //   day: today.getDate()
    // }

    // this.minDateAutoDelete = {
    //   year: today.getFullYear(),
    //   month: today.getMonth() + 1,
    //   day: today.getDate() + 1
    // }

    this.loadMasterStates();
    this.loadAppoinmentType();
    
    this.autoDeleteDate = [
      {
      key: moment(this.date.setDate(this.date.getDate() + 7)).format('yyyy/MM/DD'),
      value: "In 7 Days " + "(" + moment(this.date.setDate(this.date.getDate())).format('MM/DD/yyyy') + ")"
      },
      {
        key: moment(this.date.setDate(this.date.getDate() + 7)).format('yyyy/MM/DD'),
        value: "In 14 Days " + "(" + moment(this.date.setDate(this.date.getDate())).format('MM/DD/yyyy') + ")"
      }
    ]

    this.newManualAppoitmentForm = this.bindnewManualAppoitmentForm();

    if(this.selectedAppId){
      this.getAppointmentDetails();
    }else{
      this.getUser();
      this.getResidenceState();
    }
  }

  getUser()
  {
    this.contactService.getUser(this.userId).subscribe((response: any) => {
      this.userDetail = response;
    },
    (error: any) => {
      console.log(error);
    })
  }

  getResidenceState(){
    return new Promise((resolve) => {
      this.contactService.getResidenceStateOfUser(this.userId, this.sharedService.getValue("tenantId")).subscribe(
        (response: any) => {
          this.residentState = response;
        },
        (error: any) => {
          console.log(error);
        }
      );
    });
  }

  loadMasterStates() {
    this.loaService.getAllStates().subscribe(
      response => {
        this.masterStates = response;
      },
      error => {
        
      }
    )
  }

  loadAppoinmentType() {
    this.userAppointmentService.appointmentTypeList().subscribe(
      response => {
        this.appointmentTypeList = response;
      },
      error => {
        
      }
    )
  }

  bindnewManualAppoitmentForm(): FormGroup{
    return new FormGroup({
      userAppointmentId: new FormControl(0,[Validators.required]),
      stateId: new FormControl(null,[Validators.required]),
      // licenseClass: new FormControl(null,[Validators.required]),
      tenantId: new FormControl(JSON.parse(localStorage['tenantId'])),
      userById: new FormControl(JSON.parse(localStorage['userId'])),
      appointmentTypeId: new FormControl(null,[Validators.required]),
      status: new FormControl(null,[Validators.required]),
      appointmentDate: new FormControl(null,[Validators.required]),
      terminationDate: new FormControl(null,[]),
      issueDate: new FormControl(null,[Validators.required]),
      expirationDate: new FormControl(null,[Validators.required]),
      isAutoDelete: new FormControl(),
      autoDeleteDate: new FormControl()
    })
  }

  setValidators(event){
    if(event == 'Terminated'){
      this.newManualAppoitmentForm.get("terminationDate").setValidators([Validators.required]);
      this.clearDate("terminationDate");
    }else{
      this.newManualAppoitmentForm.get("terminationDate").clearValidators();
      this.clearDate("terminationDate");
    }
  }

  clearDate(clearControl) {
    this.newManualAppoitmentForm.get(clearControl)!.setValue(null);
    this.newManualAppoitmentForm.markAsDirty();
  }

  openSelectCarrierModal() {
    const modalRef = this.modalService.open(SelectCarrierModalComponent, ngbModalOptionsXL);
    modalRef.componentInstance.selectedCarrier = this.isSelectedCarrier ? this.Carrier : null;
    modalRef.result.then(data => {      
      if(data) {
        this.isSelectedCarrier = true;
        this.Carrier = data;      
      }
    })
  }

  onDateSelectionChange(event) {
    if(event === 'customDate') {
      this.customDatePickerEle.nativeElement.click();
    }else{
      this.getAutoDeleteDays(event);
    }
  }

  setCustomDate(event){
    const { year, month, day } = event;
    this.selectedCustomDate = new Date(year, month - 1, day);
    this.date = new Date();

    this.autoDeleteDate = [
      {
        key: moment(this.date.setDate(this.date.getDate() + 7)).format('yyyy/MM/DD'),
        value: "In 7 Days " + "(" + moment(this.date.setDate(this.date.getDate())).format('MM/DD/yyyy') + ")"
      },
      {
        key: moment(this.date.setDate(this.date.getDate() + 7)).format('yyyy/MM/DD'),
        value: "In 14 Days " + "(" + moment(this.date.setDate(this.date.getDate())).format('MM/DD/yyyy') + ")"
      },
      {
        key: moment(this.selectedCustomDate).format('YYYY/MM/DD'),
        value: moment(this.selectedCustomDate).format('MM/DD/YYYY')
      }
    ]
    this.newManualAppoitmentForm.get('autoDeleteDate')!.setValue(this.autoDeleteDate[2].value);
    this.getAutoDeleteDays(this.newManualAppoitmentForm.value.autoDeleteDate);    
  }

  getAutoDeleteDays(event){
    let currentDate = new Date();
    let deletedDate = new Date(event);

    this.autoDeletedays = Math.ceil((deletedDate.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
  }

  addAppointment() {
    this.errorMessages = [];

    var ManualAppointmentRequest = {
      UserAppointmentId : this.newManualAppoitmentForm.value.userAppointmentId,
      TenantId : this.newManualAppoitmentForm.value.tenantId,
      UserId : this.userId,
      AppointmentTypeId : this.newManualAppoitmentForm.value.appointmentTypeId,
      StateId: this.newManualAppoitmentForm.value.stateId,
      CarrierId: this.Carrier.carrierId,
      Status: this.newManualAppoitmentForm.value.status,
      AppointmentDate: this.newManualAppoitmentForm.value.appointmentDate ?  this.formatToDate(this.newManualAppoitmentForm.value.appointmentDate) : null,
      IssueDate: this.newManualAppoitmentForm.value.issueDate ? this.formatToDate(this.newManualAppoitmentForm.value.issueDate) : null,
      ExpirationDate: this.newManualAppoitmentForm.value.expirationDate ? this.formatToDate(this.newManualAppoitmentForm.value.expirationDate) : null,
      TerminationDate: this.newManualAppoitmentForm.value.terminationDate ? this.formatToDate(this.newManualAppoitmentForm.value.terminationDate) : null,
      CreatedBy: this.newManualAppoitmentForm.value.userById,
      IsAutoDelete: this.newManualAppoitmentForm.value.isAutoDelete,
      AutoDeleteDate: this.newManualAppoitmentForm.value.autoDeleteDate ? this.newManualAppoitmentForm.value.autoDeleteDate : null,
    }

    this.userAppointmentService.insertUpdateManualAppointment(ManualAppointmentRequest).subscribe(
      (response: any) => {
        if (response) {
          if(this.isEditable){
            this.sharedService.showToastMessage('Manual appointment updated successfully.');
            this.close(true);
            
          }else{
            this.isAppointmentCreatedSuccessfully = true;
            this.sharedService.showToastMessage('Manual appointment created successfully.');
          }
        }
      },
      (error: any) => {
        this.errorMessages.push(error.message);
      }
    )
    
  }

  backToAgentsPage() {
    this.router.navigate(['contact-list'])
  }

  backToAppointmentPage() {
    this.router.navigate(['main-appointments'])
  }

  close(event) {
    this.onCancel.emit(event);
  }

  formatToDate(date)
  {
    return date.year.toString() + '/' + date.month.toString() + '/' + date.day.toString()
  }

  getAppointmentDetails(){
    this.userAppointmentService.getAppointmentDetails(this.selectedAppId).subscribe(
      (response: any) => {
        if (response) {
          response.appointmentDate = this.convertDateFormat(response.appointmentDate);
          response.issueDate = this.convertDateFormat(response.issueDate);
          response.expirationDate = this.convertDateFormat(response.expirationDate);
          response.terminationDate = this.convertDateFormat(response.terminationDate);
          response.autoDeleteDate = this.convertDateFormat(response.autoDeleteDate);
          
          this.Carrier = response;
          this.newManualAppoitmentForm.patchValue(this.Carrier);
          this.userId = response.userId;
          response.autoDeleteDate ? this.setCustomDate(response.autoDeleteDate) : '';
          
        }
      },
      (error: any) => {
        this.errorMessages.push(error.message);
      }
    )
  }

  convertDateFormat(date){
    if(date){
      var fetchedDate = new Date(date);
      return {
        year: fetchedDate.getFullYear(),
        month: fetchedDate.getMonth() + 1,
        day: fetchedDate.getDate()
      };
    }else{
      return null;
    }
  }

  resetautoDeleteDate(event){
    if(this.newManualAppoitmentForm.value.isAutoDelete){
      this.newManualAppoitmentForm.get("autoDeleteDate").setValidators([Validators.required]);
      this.clearDate('autoDeleteDate');
      this.getAutoDeleteDays(this.newManualAppoitmentForm.value.autoDeleteDate);
    }else{
      this.newManualAppoitmentForm.get("autoDeleteDate").clearValidators();
      this.clearDate('autoDeleteDate');
    }
    
  }
}
