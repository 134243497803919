<div class="main-layout-grid">
  <main id="main-container">
    <div class="data-content custom-height-center">
      <div class="content-width">
        <app-left-part></app-left-part>
      </div>
      <div class="content-width right">
        <div class="right-side-content before-login-page">
          <div class="container">
            <div class="form-details">
              <div class="forms-data">
                <form class="login-form" autocomplete="off" [formGroup]="codeForm" (ngSubmit)="onSubmit()">
                  <div class="form-block-title">
                    <h3 class="form-title">Enter Code</h3>
                    <p class="login-note">
                      Please enter the six-digit code that you received via
                      email.
                    </p>
                    <div class="form-inputs-data otp-field">
                      <div class="form-group" *ngFor="let input of formInput;  index as i">
                        <input preventCutCopyPaste autocomplete="off" class="form-control form-input" #formRow type="text" formControlName="{{input}}" maxlength="1" (keyup)="keyUpEvent($event, i)">
                      </div>
                    </div>
                    <p>
                      <validation-summary [validationErrors]="errorMessages"></validation-summary>
                    </p>
                    <div class="action-btn">
                      <button type="submit" class="btn commn-btn login-btn width-100" [disabled]="!(codeForm.valid && guestUserId > 0)">
                        <span class="login-btn-text">Continue</span><span class="login-icon"></span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </main>
</div>
