import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Package } from '../models/packages/package.model';
import { TemplateCustomField } from '../models/packages/templateCustomField.model';

@Injectable({
  providedIn: 'root'
})
export class PackageCommonService {

  packageLocalStorageKey: string = 'package';
  packageIdLocalStorageKey: string = 'packageId';

  private archiveTabReload = new Subject<void>();
  archiveTabReloadReloadObs = this.archiveTabReload.asObservable();

  notifyArchiveTabToReload() {
    this.archiveTabReload.next();
  }

  private activeTabReload = new Subject<void>();
  activeTabReloadReloadObs = this.activeTabReload.asObservable();

  notifyActiveTabToReload() {
    this.activeTabReload.next();
  }

  private packageStatusUpdate = new Subject<void>();
  packageStatusUpdateObs = this.packageStatusUpdate.asObservable();

  notifyPackageStatusUpdate() {
    this.packageStatusUpdate.next();
  }

  private packageCompleteUpdate = new Subject<void>();
  packageCompleteUpdateObs = this.packageCompleteUpdate.asObservable();

  completePackageStatusUpdate() {
    this.packageCompleteUpdate.next();
  }

  constructor() {

  }

  getPackageLocalStorage(): Package {
    var _package = JSON.parse(localStorage.getItem(this.packageLocalStorageKey));
    if (_package != null) {
      if (_package.packageDetails != null && _package.packageDetails.length > 0) {
        _package.packageDetails.forEach(obj => {
          obj.customFields = [] as any[];
          if (obj.templateCustomFields) { // Check if templateCustomFields exists
            obj.templateCustomFields.forEach(_obj => {
              obj.customFields[_obj.customFieldName] = _obj.fieldValue;
            });
          }
        });
      }
    }
    return _package;
  }

  setPackageLocalStorage(packageObj: Package) {
    if (packageObj.packageDetails != null && packageObj.packageDetails.length > 0) {
      packageObj.packageDetails.forEach(obj => {
        if (obj.customFields != null) {
          for (var _obj in obj.customFields) {
            var customfield = packageObj.packageDetailHeader.find(pd => pd.fieldName == _obj && pd.templateCustomFieldId > 0);
            if (customfield != null) {
              var templateCustomField = obj.templateCustomFields.find(pd => pd.customFieldName == customfield.fieldName && customfield.templateCustomFieldId == pd.templateCustomFieldId);
              if (templateCustomField != null) {
                templateCustomField.fieldValue = obj.customFields[customfield.fieldName];
              }
              else {
                obj.templateCustomFields.push({
                  customFieldDataType: customfield.dataType,
                  customFieldName: customfield.fieldName,
                  templateCustomFieldId: customfield.templateCustomFieldId,
                  packageDetailId: obj.packageDetailId,
                  fieldValue: obj.customFields[customfield.fieldName]
                } as TemplateCustomField);
              }
            }
          }
        }
        else {
          obj.customFields = [] as any[];
          if (obj.templateCustomFields) { // Check if templateCustomFields exists
            obj.templateCustomFields.forEach(_obj => {
              obj.customFields[_obj.customFieldName] = _obj.fieldValue;
            });
          }
        }
      });
    }
    localStorage.setItem(this.packageLocalStorageKey, JSON.stringify(packageObj));
  }

  removePackageLocalStorage() {
    localStorage.removeItem(this.packageLocalStorageKey);
  }

  getPackageIdLocalStorage(): number {
    return parseInt(localStorage.getItem(this.packageIdLocalStorageKey));
  }

  setPackageIdLocalStorage(packageId: number) {
    localStorage.setItem(this.packageIdLocalStorageKey, packageId.toString());
  }

  removePackageIdLocalStorage() {
    localStorage.removeItem(this.packageIdLocalStorageKey);
  }
}
