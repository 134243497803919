import { Injectable } from '@angular/core';
import { AuthService } from 'services';
import { ApiClientService } from './common/api-client.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NewAppointment } from 'components/appointment/models/appointment.model';
import { appointmentTerminationRequest } from 'models/appointmentTerminationRequest.model';

@Injectable({
  providedIn: 'root'
})
export class UserAppointmentService {

  constructor(private apiClient: ApiClientService,
    private authService: AuthService
  ) { }

  public bulkAppointmentRequest: BehaviorSubject<any> = new BehaviorSubject(true);

  public appointmentUserList(request: any) {
    return this.apiClient.post('api/UserAppointment/list', request, null, true);
  }
  public appointmentList(request: any) {
    return this.apiClient.post('api/UserAppointment/listappointment', request, null, true);
  }
  public appointmentTypeList() {
    return this.apiClient.get('api/UserAppointment/listappointmenttype', null, null, true);
  }

  // new appointmentRequest
  public getNewAppointmentSupportedStates(): Observable<any> {
    return this.apiClient.get('api/AppointmentRequest/NewAppointmentSupportedStates');
  }
  public getSupportedStates(): Observable<any> {
    return this.apiClient.get('api/AppointmentRequest/getappointmentsupportedstates');
  }
  public getEntityList(tenantId: number): Observable<any> {
    return this.apiClient.get('api/Carrier/list/' + tenantId, undefined, undefined, false);
  }
  public getAppointerCarriersByState(stateId: number): Observable<any> {
    return this.apiClient.get('api/mga/Carrier/getAppointerCarriersByState/' + stateId, undefined, undefined, false);
  }
  public getAdminList(tenantId: number): Observable<any> {
    return this.apiClient.get('api/AppointmentRequest/getadminusersforspecifictenant/' + tenantId, undefined, undefined, false);
  }
  public getLclassLOA(stateId?: string): Observable<any> {
    return this.apiClient.get('api/AppointmentRequest/getlicenseclassesandLoafornewappointment/' + stateId, undefined, undefined, false);
  }
  public createNewAppointment(newAppointmentRqst: NewAppointment): Observable<number> {
    return this.apiClient.post('api/AppointmentRequest', newAppointmentRqst, undefined, true);
  }
  public createNewAppointmentAndAutoApprove(newAppointmentRqst: NewAppointment) {
    return this.apiClient.post('api/AppointmentRequest/createandautoapprove', newAppointmentRqst, undefined, true);
  }
  public getNewAppointmentSupportedStatesForUser(userId : number): Observable<any> {
    return this.apiClient.get('api/AppointmentRequest/newAppointmentSupportedStatesForUser/' + userId);
  }

  // Get all appointments
  public getAllContactRequests(request: any) {
    return this.apiClient.post('api/AppointmentRequest/getallcontactrequests', request, null, false);
  }

  public getMyRequests(request: any) {
    return this.apiClient.post('api/AppointmentRequest/getmyrequests', request, null, true);
  }

  public getTransactionStatuses(transactionTypeId: number) {
    return this.apiClient.get('api/AppointmentRequest/gettransactionstatuses/' + transactionTypeId, undefined, undefined, false);
  }

  //Requested Appointment Action by Admin
  public approveAppointmentRequestByAdmin(appointmentObj: any) {
    return this.apiClient.post('api/AppointmentRequest/approve', appointmentObj, null, true);
  }

  public denyAppointmentRequestByAdmin(appointmentObj: any) {
    return this.apiClient.post('api/AppointmentRequest/deny', appointmentObj, null, true);
  }


  //Appointment termination 
  public getTerminateRequests(request: any) {
    return this.apiClient.post('api/AppointmentTermination/getAppointmentTerminationRequests', request, null, true);
  }

  public appointmentTerminationRequest(appointmentObj: any) {
    return this.apiClient.post('api/AppointmentTermination/CreateAppointmentTerminationRequest', appointmentObj, null, true);
  }

  public getAppointmentTerminationReasons(stateId: number) {
    return this.apiClient.get('api/AppointmentTermination/getAppointmentTerminationReasons/' + stateId, undefined, undefined, false);
  }

  public rejectAppointmentTerminationRequest(appointmentTerminationRequestId: any) {
    return this.apiClient.post('api/AppointmentTermination/rejectAppointmentTerminationRequest?appointmentTerminationRequestId=' + appointmentTerminationRequestId, null, null, true);
  }

  public appointmentTerminationRequestToNIPR(terminationRqst: appointmentTerminationRequest): Observable<number> {
    return this.apiClient.post('api/AppointmentTermination/submitAppointmentTerminationRequestToNIPR', terminationRqst, undefined, true);
  }

  public createAppointmentTerminationRequestAndAutoSubmit(terminationRqst: appointmentTerminationRequest): Observable<number> {
    return this.apiClient.post('api/AppointmentTermination/createAppointmentTerminationRequestAndAutoSubmit', terminationRqst, undefined, true);
  }

  public exportAppointment(request: any) {
    return this.apiClient.export('api/UserAppointment/appointment/export', request, null, true);
  }

  public myAppointments(request: any) {
    return this.apiClient.post('api/UserAppointment/list/agency', request, null, true);
  }

  public getAppointmentLicenseClassDetail(userAppointmentId: number): Observable<any> {
    return this.apiClient.get('api/UserAppointment/getAppointmentLicenseClassDetail?userAppointmentId=' + userAppointmentId, null, null, false);
  }

   public getLclassForUser(stateId: number,userId : number): Observable<any> {
    return this.apiClient.get(`api/AppointmentRequest/licenseClassesNewAppointmentRequest/${stateId}/${userId}`, undefined, undefined, false);
  }
  public getLOAForUser(stateId: number,userId : number,licenseClassCode : number): Observable<any> {
    return this.apiClient.get(`api/AppointmentRequest/loaNewAppointmentRequest/${stateId}/${userId}/${licenseClassCode}`, undefined, undefined, false);
  }

  /// Bulk Appointment
  // shared data comopnent to component
  public bulkAppointmentRequestObj(data: any) { //the component that wants to update something, calls this fn
    this.bulkAppointmentRequest.next(data); //next() will feed the value in Subject
  }

  public getBulkAppointmentCarrierList(searchObj: any) {
    return this.apiClient.post(`api/AppointmentRequest/bulkAppointmentCarrierList`, searchObj, null, true);
  }

  public getBulkAppointmentStateListByCarrierId(tenantId: number, carrierId: number, carrierUserId: number) {
    return this.apiClient.get(`api/AppointmentRequest/bulkAppointmentCarrierStateList/${tenantId}/${carrierId}/${carrierUserId}`, null, null, true);
  }

  public getBulkAppointmentCarrierAndProducerLOAs(searchObj: any) {
    return this.apiClient.post(`api/AppointmentRequest/bulkAppointmentCarrierAndProducerLOAs`, searchObj, null, true);
  }

  public validateBulkAppointment(reqstParam) {
    return this.apiClient.post(`api/AppointmentRequest/validateBulkAppointment`, reqstParam, null, false);
  }

  public submitBulkAppointmentRequest(reqstParam) {
    return this.apiClient.post(`api/AppointmentRequest/createBulkAppointmentRequest`, reqstParam, null, false);
  }

  // For Pakages appointment process
  public validatePackageBulkAppointment(reqstParam) {
    return this.apiClient.post(`api/AppointmentRequest/validatePackageBulkAppointment`, reqstParam, null, false);
  }
  public createPackageBulkAppointmentRequest(reqstParam) {
    return this.apiClient.post(`api/AppointmentRequest/createPackageBulkAppointmentRequest`, reqstParam, null, false);
  }

  public validatePackageBulkAppointmentTermination(reqstParam) {
    return this.apiClient.post(`api/AppointmentTermination/validatePackageBulkAppointmentTermination`, reqstParam, null, false);
  }
  public createPackageBulkAppointmentTerminationRequest(reqstParam) {
    return this.apiClient.post(`api/AppointmentTermination/createPackageBulkAppointmentTerminationRequest`, reqstParam, null, false);
  }
  // 
  public insertUpdateManualAppointment(appointmentObj: any) {
    return this.apiClient.post('api/UserAppointment/insertUpdateManualAppointment', appointmentObj, null, true);
  }

  public deleteManualAppointment(id: number) {
    return this.apiClient.delete(`api/UserAppointment/${id}`, null, null, true);
   }

  public getAppointmentDetails(userAppointmentId: number) {
    return this.apiClient.get(`api/UserAppointment/getManualAppointment/${userAppointmentId}`, null, null, true);
  }
   
}
