import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PackageService } from 'api-services/package.service';
import { ValidatorHelper } from 'components/custom-validators/validators.helper';
import { Subscription } from 'rxjs';
import { SharedService } from 'services/shared.service';

@Component({
  selector: 'app-confirm-activation-email',
  templateUrl: './confirm-activation-email.component.html',
  styleUrls: ['./confirm-activation-email.component.css']
})
export class ConfirmActivationEmailComponent implements OnInit {

  errorMessages = [];
  activationForm!: FormGroup;
  subscription: Subscription[] = [];
  
  constructor(private route: Router, private fb: FormBuilder, private packageService: PackageService, private sharedService: SharedService, private router: Router) {
   
  }
  
  ngOnInit(): void {
    this.activationForm = this.fb.group({
      email: ['', [Validators.required, ValidatorHelper.emailPattern]],
    });
  }

  
  
  onSubmit(): void {    
    this.errorMessages = [];
    this.activationForm.markAllAsTouched();
    
    if(this.activationForm.valid)
    {
      this.packageService.getContactByVerifyEmail(this.activationForm.value.email).subscribe(
        (response) => {
          // Handle successful response
          if(response != '0') 
          {           
            this.router.navigate(["/package/activate/" + response]);            
          }
          else{            
            this.errorMessages.push('Please check your email and try again.');
          }    
        },
        (error) => {
          // Handle error        
          if(error && error.message != undefined)
          {           
            this.errorMessages.push(error.message);
          }
          else {            
            this.errorMessages.push("Oops! Something went wrong. Please try again.");
          }
        }
      );
    }
  }
}
