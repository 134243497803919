<div class="filter-container">
  <div class="block-title">
    <div class="h2 m-0">Filter</div>
    <button type="button" class="close" (click)="closePanel()">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <rect width="28" height="28" rx="6" fill="none" />
        <g transform="translate(4 4)">
          <rect width="20" height="20" fill="none" />
          <path d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z"
                transform="translate(-0.005 0.005)" fill="#757575" />
        </g>
      </svg>
    </button>
  </div>
  <div class="filter-detail">
    <div class="select-options custom-dropdown">
      <label for="">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg" style="vertical-align: bottom;">
          <mask id="mask0_2518_18222" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0"
                y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2518_18222)">
            <path d="M11.3848 19.5C11.1335 19.5 10.9232 19.4153 10.754 19.2461C10.5847 19.0769 10.5001 18.8666 10.5001 18.6153V12.8269L4.90208 5.71538C4.70978 5.45896 4.6819 5.19229 4.81843 4.91538C4.95497 4.63846 5.18541 4.5 5.50976 4.5H18.4905C18.8148 4.5 19.0452 4.63846 19.1818 4.91538C19.3183 5.19229 19.2904 5.45896 19.0981 5.71538L13.5001 12.8269V18.6153C13.5001 18.8666 13.4155 19.0769 13.2462 19.2461C13.077 19.4153 12.8667 19.5 12.6155 19.5H11.3848ZM12.0001 12.3L16.9501 5.99998H7.05011L12.0001 12.3Z"
                  fill="#0068D8" />
          </g>
        </svg>Saved Filters
      </label>
      <div class="select-block">
        <ng-select [searchable]="true" [clearable]="false" [(ngModel)]="selectedFilter" placeholder="Saved Filters" (change)="onSaveFilterChange($event)" class="form-control">
          <ng-option *ngFor="let item of filterList" [value]="item.filterId">
            <span class="overflow-list-item">{{item.name}} </span>
            <span class="right-data">
              <span *ngIf="item.default"> <span class="filter-type"> (</span>Default<span class="filter-type">)</span></span>
              <span *ngIf="item.public"> <span class="filter-type"> (</span>Public<span class="filter-type">)</span></span>
              <a href="javascript:void(0)" (click)="deleteFilter(item.filterId)">
                <svg class="delete-item {{item.filterId}}" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path class="delete-item {{item.filterId}}"
                        d="M7.3 20.5C6.8 20.5 6.375 20.325 6.025 19.975C5.675 19.625 5.5 19.2 5.5 18.7V6H5.25C5.03333 6 4.85433 5.929 4.713 5.787C4.571 5.64567 4.5 5.46667 4.5 5.25C4.5 5.03333 4.571 4.85433 4.713 4.713C4.85433 4.571 5.03333 4.5 5.25 4.5H9C9 4.25 9.08733 4.04167 9.262 3.875C9.43733 3.70833 9.65 3.625 9.9 3.625H14.1C14.35 3.625 14.5627 3.70833 14.738 3.875C14.9127 4.04167 15 4.25 15 4.5H18.75C18.9667 4.5 19.146 4.571 19.288 4.713C19.4293 4.85433 19.5 5.03333 19.5 5.25C19.5 5.46667 19.4293 5.64567 19.288 5.787C19.146 5.929 18.9667 6 18.75 6H18.5V18.7C18.5 19.2 18.325 19.625 17.975 19.975C17.625 20.325 17.2 20.5 16.7 20.5H7.3ZM7 6V18.7C7 18.7833 7.02933 18.8543 7.088 18.913C7.146 18.971 7.21667 19 7.3 19H16.7C16.7833 19 16.8543 18.971 16.913 18.913C16.971 18.8543 17 18.7833 17 18.7V6H7ZM9.4 16.25C9.4 16.4667 9.471 16.646 9.613 16.788C9.75433 16.9293 9.93333 17 10.15 17C10.3667 17 10.546 16.9293 10.688 16.788C10.8293 16.646 10.9 16.4667 10.9 16.25V8.75C10.9 8.53333 10.8293 8.354 10.688 8.212C10.546 8.07067 10.3667 8 10.15 8C9.93333 8 9.75433 8.07067 9.613 8.212C9.471 8.354 9.4 8.53333 9.4 8.75V16.25ZM13.1 16.25C13.1 16.4667 13.1707 16.646 13.312 16.788C13.454 16.9293 13.6333 17 13.85 17C14.0667 17 14.246 16.9293 14.388 16.788C14.5293 16.646 14.6 16.4667 14.6 16.25V8.75C14.6 8.53333 14.5293 8.354 14.388 8.212C14.246 8.07067 14.0667 8 13.85 8C13.6333 8 13.454 8.07067 13.312 8.212C13.1707 8.354 13.1 8.53333 13.1 8.75V16.25ZM7 6V18.7C7 18.7833 7.02933 18.8543 7.088 18.913C7.146 18.971 7.21667 19 7.3 19H7V6Z"
                        fill="#D80000" />
                </svg>
              </a>
            </span>
          </ng-option>
        </ng-select>

        <!-- <ng-select name="savedFilterList" [items]="filterList"
                       bindLabel="name"
                       bindValue="filterId"
                       [(ngModel)]="selectedFilter" placeholder="Saved Filters" class="form-control" (change)="onSaveFilterChange($event)">
        </ng-select> -->
        <!-- <a href="javascript:void(0)" (click)="deleteFilter()">Delete Filter</a> -->
      </div>
    </div>
    <div class="table-selection-block" *ngIf="filterCategory != filterCategoryProducer">
      <div class="row">
        <div class="col-md-8">
          <div class="select-options">
            <label for="">State</label>
            <div class="select-block multi-bor-0 width-auto">
              <ng-multiselect-dropdown name="statesSelected" [placeholder]="'State'" class="form-control" [settings]="dropdownSettingState" [data]="states" [(ngModel)]="selectedStates">
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
        <div class="col-md-4" *ngIf="filterCategory != 4">
          <div class="select-options">
            <label for="">Status</label>
            <div class="select-block">
              <ng-select [searchable]="true" [clearable]="true" placeholder="Status" name="status" class="form-control" [(ngModel)]="selectedStatus">
                <ng-option value="null">All</ng-option>
                <ng-option *ngFor="let LStatus of licenseStatusList" [value]="LStatus.status">
                  {{ LStatus.status }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="filterCategory == 2 || filterCategory == 4">
          <div class="select-options">
            <label for="">License class</label>
            <div class="select-block multi-bor-0 width-auto">
              <ng-multiselect-dropdown name="licenseClassSelected" class="form-control"
                                       [placeholder]="'License Class'" [settings]="dropdownSettingLicenseClass" [data]="licenseClasses" [(ngModel)]="selectedLicenseClasses">
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
        
        <div class="col-md-12" *ngIf="filterCategory == 3">
          <div class="select-options">
            <label for="">LOA</label>
            <div class="select-block multi-bor-0 width-auto">
              <ng-multiselect-dropdown class="form-control" name="appointmentTypeListSelected"
                                       [placeholder]="'LOA'" [settings]="dropdownSettingAppointType" [data]="appointmentTypeList"
                                       [(ngModel)]="selectedAppointmentTypeList">
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="select-options">
            <label for="">{{tenantTypeId == 1 ? 'Agency':'Branch'}}</label>
            <div class="select-block">
              <ng-select [searchable]="true" [clearable]="true" placeholder="{{tenantTypeId == 1 ? 'Agency':'Branch'}}"
                         name="branch" class="form-control min-height" [(ngModel)]="selectedBranchId">
                <ng-option [value]=0>All</ng-option>
                <ng-option *ngFor="let branch of branchList" [value]="branch.branchId">{{branch.branchName}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
       
        <div class="col-md-4">
          <div class="select-options right">
            <label for="">Expires In</label>
            <div class="select-block">
              <ng-select [searchable]="true" [clearable]="true" placeholder="Expires In" name="expireIn" class="form-control" [(ngModel)]="selectedExpireIn">
                <ng-option *ngFor="let days of expiryInData" [value]="days.id">{{days.days}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-md-4" *ngIf="filterCategory == 2">
          <div class="select-options right">
            <label for="">Resident Type</label>
            <div class="select-block">
              <ng-select [searchable]="true" [clearable]="true" placeholder="Resident Type" name="residentTypeCode" class="form-control" [(ngModel)]="selectedResidentTypeCode">
                <ng-option value="null">All</ng-option>
                <ng-option *ngFor="let type of residentTypeList" [value]="type.id">{{type.type}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-md-4" *ngIf="filterCategory == 2 || filterCategory == 3">
          <div class="select-options right">
            <label for="">User Type</label>
            <div class="select-block">
              <ng-select [searchable]="true" [clearable]="true" placeholder="User Type" name="npnType" class="form-control" [(ngModel)]="selectedNPNType">
                <ng-option value="null">All</ng-option>
                <ng-option *ngFor="let type of userTypeList" [value]="type.id">{{type.type}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="filterCategory == 3">
          <div class="select-options">
            <label for="">{{tenantTypeId == 1 ? 'Agent': 'Producer'}}</label>
            <div class="select-block tag-block multi-bor-0 width-auto" (click)="scrolltoBottom('contact')">
              <ng-multiselect-dropdown id="contact" class="form-control" name="selectedContactList"
                                       [placeholder]="" [settings]="dropdownSettingActiveContactList"
                                       [data]="activeContactList"
                                       [(ngModel)]="selectedContactList">
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
        <!--<div class="col-md-6">
    <div class="select-options">
      <label for="">{{tenantTypeId == 1 ? 'Producer':'Agent'}}</label>
      <div class="select-block">
        <ng-select [searchable]="true" [clearable]="true" placeholder="{{tenantTypeId == 1 ? 'Producer':'Agent'}}"
                   name="contact" class="form-control min-height" [(ngModel)]="selectedContactId">
          <ng-option [value]=0>All</ng-option>
          <ng-option *ngFor="let contact of activeContactList" [value]="contact.contactId">{{contact.contactName}}</ng-option>
        </ng-select>
      </div>
    </div>
  </div>-->
        <div class="col-md-12" *ngIf="filterCategory == 3">
          <div class="select-options">
            <label for="">Carrier</label>
            <div class="select-block">
              <ng-select [searchable]="true" [clearable]="true" placeholder="Carrier"
                         name="carrier" class="form-control" [(ngModel)]="selectedCarrierId">
                <ng-option [value]=0>All</ng-option>
                <ng-option *ngFor="let carrier of carrierList" [value]="carrier.carrierId">{{carrier.carrierName}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="filterCategory != 4">
          <div class="select-options">
            <label for="">Tag</label>
            <div class="select-block tag-block multi-bor-0 width-auto" (click)="scrolltoBottom('tag')">
              <ng-multiselect-dropdown id="tag" name="tagsSelected" [placeholder]="'Tag'" [hidden]="false" class="form-control" [settings]="dropdownSettingTag" [data]="tags" [(ngModel)]="selectedTags">
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="filterCategory == 3">
          <div class="select-options">
            <label for="">Line of Business</label>
            <div class="select-block">
              <ng-select [searchable]="true" [clearable]="true" placeholder="Line of Business"
                         name="lineOfBusiness" class="form-control" [(ngModel)]="selectedLineOfBusinessCarrierId">
                <ng-option selected [value]=0>All</ng-option>
                <ng-option *ngFor="let LOB of lineOfBusinessList" [value]="LOB.carrierId">{{LOB.lineofBusiness}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-selection-block" *ngIf="filterCategory == filterCategoryProducer">
      <div class="row">
        <div class="col-md-12">
          <div class="select-options">
            <label for="">{{tenantTypeId == 1 ? 'Agency':'Branch'}}</label>
            <div class="select-block">
              <ng-select [searchable]="true" [clearable]="true" class="form-control" placeholder="{{tenantTypeId == 1 ? 'Agency':'Branch'}}" name="branchId" [(ngModel)]="branchId">
                <ng-option [value]="0" selected>All</ng-option>
                <ng-option *ngFor="let branch of branchList" [value]="branch.branchId">{{ branch.branchName }}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="select-options">
            <label for="">Department</label>
            <div class="select-block">
              <ng-select [searchable]="true" [clearable]="true" placeholder="Department"
                         class="form-control" [(ngModel)]="departmentId" name="departmentId">
                <ng-option [value]="0" selected>All</ng-option>
                <ng-option *ngFor="let department of departmentList" [value]="department.departmentID">{{ department.description }}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="select-options">
            <label for="">Status</label>
            <div class="select-block">
              <ng-select [searchable]="true" [clearable]="true" class="form-control" name="statusId" [(ngModel)]="tenantStatusId" placeholder="Status">
                <ng-option [value]="0" selected>All</ng-option>
                <ng-option *ngFor="let tenantStatus of tenantStatusList" [value]="tenantStatus.tenantStatusId">{{ tenantStatus.status }}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="select-options">
            <label for="">Tag</label>
            <div class="select-block tag-block multi-bor-0 width-auto" (click)="scrolltoBottom('tag')">
              <ng-multiselect-dropdown id="scrollTo" name="tagsSelected" [placeholder]="'Tag'" [hidden]="false" class="form-control" [settings]="dropdownSettingTag" [data]="tags" [(ngModel)]="selectedTags">
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-button-group">
      <div class="left-btn">
        <button type="button" class="btn" style="margin-right: 10px;" (click)="openSaveFilterPopup()">
          Save Filter As
        </button>
        <button type="button" class="btn" *ngIf="this.selectedFilter != null" (click)="updateFilter()" [disabled]="isDisabled">Update Filter</button>
      </div>
      <div class="right-btn">
        <button class="border-btn" style="margin-right: 10px;" (click)="clearFilter()">Clear All</button>
        <button class="btn dark-btn" (click)="applyFilter()">Apply</button>
      </div>
    </div>
  </div>
</div>

<div class="modal-content new-appointment-model save-filter common-modal" id="savefilter" *ngIf="isSavePopup"
     style="z-index: 111;">
  <div class="modal-header pop-title">
    <h4 class="modal-title">Save Filter</h4>
    <button type="button" class="modal-close-btn close" data-dismiss="modal" (click)="closeSavePopup()">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <rect width="28" height="28" rx="6" fill="none" />
        <g transform="translate(4 4)">
          <rect width="20" height="20" fill="none" />
          <path d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z"
                transform="translate(-0.005 0.005)" fill="#757575" />
        </g>
      </svg>
    </button>
  </div>
  <div class="modal-body">
    <div class="group-form">
      <form class="contactupdate-form" [formGroup]="saveFilterForm" id="saveFilterForm">
        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="input-block">
              <label class="input-label" for="groupName">Save Filter Conditions As <span class="validation-field">*</span></label>
              <input type="text" class="form-control" maxlength="200" placeholder="Enter filter name" formControlName="filterName" name="filterName" />
            </div>
            <div class="check-group" *ngIf="loggedinUserRole != 'contact'">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" formControlName="default" name="default" (change)="onCheckboxChange($event)">
                <label class="custom-control-label" for="default">Default</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" formControlName="public" name="public" (change)="onCheckboxChange($event)">
                <label class="custom-control-label" for="public">Make Public</label>
              </div>
            </div>
            <!-- <div *ngIf="saveFilterForm.errors?.['requireCheckboxes'] && saveFilterForm.touched">
                <span class="validation-field">Please select defualt or public checkbox</span>
            </div> -->
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="text-right cancel-btn">
        <button type="submit" class=" cancel-sub" (click)="closeSavePopup()">Cancel</button>
      </div>
      <div class="action-btn footer text-right">
        <button type="submit" class="btn commn-btn dark-bg" (click)="saveFilterChanges()">Save</button>
      </div>
    </div>
  </div>
</div>

<div class="modal-content new-appointment-model save-filter common-modal" id="deletefilter" *ngIf="isDeletePopup"
     style="z-index: 111;">
  <!-- tePopup()"> -->
  <div class="modal-header pop-title">
    <h4 class="modal-title">Delete Filter</h4>
    <button type="button" class="modal-close-btn close" data-dismiss="modal" (click)="closeDeletePopup()">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <rect width="28" height="28" rx="6" fill="none" />
        <g transform="translate(4 4)">
          <rect width="20" height="20" fill="none" />
          <path d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z"
                transform="translate(-0.005 0.005)" fill="#757575" />
        </g>
      </svg>
    </button>
  </div>

  <!-- </button> -->

  <div class="modal-body">
    <div class="block-desc">
      <p>Are you sure you want to delete this filter?</p>
    </div>
    <div class="modal-footer">
      <div class="text-right cancel-btn">
        <button type="submit" class=" cancel-sub" (click)="closeDeletePopup()">No</button>
      </div>
      <div class="action-btn footer text-right">
        <button type="submit" class="btn commn-btn delete-btn">Yes, Delete</button>
      </div>
    </div>
  </div>
</div>
