<div class="main-layout-grid">
  <main id="main-container">
    <div class="data-content custom-height height-center" [ngClass]="{'custom-height-center' : hide || !isFirm, 'custom-height' : hide || isFirm}">
      <div class="content-width">
        <app-left-part></app-left-part>
      </div>
      <div class="content-width right">
        <div class="set-pwd-container before-login-page" *ngIf="!hide && isFirm">
          <form action="" [formGroup]="onBoardingDocForm">
            <div class="pwd-detail">
              <div class="block-title">
                <h2>
                  <span class="hightlight">Welcome to Agenzee!</span> <br>
                  Here's the Checklist of essential items you'll need for onboarding.
                </h2>
              </div>
              <div class="check-group">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="npn" name="npn" formControlName="orgNpn">
                  <label class="custom-control-label" for="npn">Organization NPN</label>
                </div>
                <ul>
                  <li>The NPN that belongs to your organization/business entity</li>
                </ul>
              </div>
              <div class="check-group">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="nipr" name="test" formControlName="nipr">
                  <label class="custom-control-label" for="test">NIPR Web Enabled Account Credentials</label>
                </div>
                <ul>
                  <li>Customer ID</li>
                  <li>User name</li>
                  <li>Password</li>
                  <em><strong>Note: </strong> You can still use Agenzee without these credentials, but you may have limited access to some
                    features.</em>
                </ul>
              </div>
              <div class="check-group">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="cc" name="cc" formControlName="creditCard">
                  <label class="custom-control-label" for="cc">A Valid Credit Card</label>
                </div>
              </div>

              <!-- <div class="block-desc">
                <p>If you find that you are lacking the information referenced above, please do not hesitate to contact
                  the National Insurance Producer Registry (NIPR). They will be able to provide you with the necessary
                  details.</p>
              </div> -->

              <div class="check-group">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="agentnpn" name="agentnpn" formControlName="npn">
                  <label class="custom-control-label" for="agentnpn">Producer / Agent NPNs</label>
                </div>
                <ul>
                  <li>Format the NPNs you plan to manage in our recommended <a href="javascript:void(0)" (click)="downloadExcel()" >excel template</a>. You must have at least one
                    producer/agent NPN to complete the onboarding process.</li>
                </ul>
              </div>
              <div class="btn-box">
                <div class="action-btn">
                  <button type="button" (click)="showData()" class="btn commn-btn" [disabled]="!onBoardingDocForm.valid"> Let's Go!</button>
                </div>
              </div>
              <a href="javascript:void(0)" (click)="showInfo()" class="passwordlink">I Need More Time</a>
              <div class="block-desc" *ngIf="show">
                <p>Don't worry - when you have all the necessary details, you can click the "Continue Onboarding" button in your welcome email again.<br>You'll return to this screen. You may close this page now.</p>
              </div>
            </div>
          </form>
          <app-footer></app-footer>
        </div>
        <div class="right-side-content before-login-page" *ngIf="hide || !isFirm">
          <div class="container">
            <div class="form-details">
              <div class="forms-data">
                <form class="set-password-form" [formGroup]="setPwdForm" (ngSubmit)="onSubmit()">
                  <div class="form-block-title">
                    <h3 class="form-title">Choose a New Password</h3>
                    <!-- <h3 class="form-title">Create a password to continue</h3> -->
                    <div class="form-inputs-data">

                      <!--<div class="form-group" [class.error]="((setPwdForm.get('userName')!.touched ||
                      setPwdForm.get('userName')!.dirty) &&
                      setPwdForm.get('userName')!.errors)">
                        <input type="text" maxlength="50" formControlName="userName"
                               class="form-control form-input" name="setUserName" id="setUserName"
                               placeholder=" ">
                        <label class="form-label">User Name</label>

                        <app-error [controlName]="setPwdForm.controls['userName']">
                        </app-error>

                      </div>-->



                      <div class="form-group" [class.error]="((setPwdForm.get('password')!.touched ||
                      setPwdForm.get('password')!.dirty) &&
                      setPwdForm.get('password')!.errors)">
                        <input type="password" formControlName="password" maxlength="50" class="form-control form-input"
                          name="setPassword" id="setPassword" placeholder=" ">
                        <label class="form-label">Password <span class="validation-field">*</span></label>
                        <div class="form-icon" (click)="showPassword('setPassword')">
                          <svg class="pass-icon open-eye" [class.hidden]="!isShow" id="icons_ic-visibility"
                            data-name="icons / ic-visibility" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24">
                            <rect id="Rectangle_454" data-name="Rectangle 454" width="24" height="24" fill="none" />
                            <path id="visibility_FILL0_wght300_GRAD0_opsz24" class="fill-color"
                              d="M12,15.575A4.056,4.056,0,0,0,16.075,11.5,4.056,4.056,0,0,0,12,7.425,4.056,4.056,0,0,0,7.925,11.5,4.056,4.056,0,0,0,12,15.575ZM12,14.2a2.69,2.69,0,0,1-2.7-2.7A2.689,2.689,0,0,1,12,8.8a2.687,2.687,0,0,1,2.7,2.7A2.689,2.689,0,0,1,12,14.2Zm0,4.3a11.058,11.058,0,0,1-6.013-1.725A11.311,11.311,0,0,1,1.8,12.1a.9.9,0,0,1-.075-.263,2.291,2.291,0,0,1,0-.675A.9.9,0,0,1,1.8,10.9,11.311,11.311,0,0,1,5.987,6.225,11.058,11.058,0,0,1,12,4.5a11.053,11.053,0,0,1,6.012,1.725A11.308,11.308,0,0,1,22.2,10.9a.9.9,0,0,1,.075.262,2.291,2.291,0,0,1,0,.675.9.9,0,0,1-.075.263,11.308,11.308,0,0,1-4.188,4.675A11.053,11.053,0,0,1,12,18.5Zm0-7ZM12,17a9.545,9.545,0,0,0,5.188-1.488A9.771,9.771,0,0,0,20.8,11.5a9.777,9.777,0,0,0-3.612-4.013A9.549,9.549,0,0,0,12,6,9.549,9.549,0,0,0,6.812,7.487,9.777,9.777,0,0,0,3.2,11.5a9.771,9.771,0,0,0,3.612,4.012A9.545,9.545,0,0,0,12,17Z"
                              transform="translate(0 0.5)" fill="#757575" />
                          </svg>
                          <svg id="icons_ic-visibility_none" [class.hidden]="isShow" class="pass-icon eye-close hidden"
                            data-name="icons / ic-visibility" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24">
                            <rect id="Rectangle_454" data-name="Rectangle 454" width="24" height="24" fill="none" />
                            <path id="visibility_off_FILL0_wght300_GRAD0_opsz24"
                              d="M15.775,12.975,14.65,11.85a2.469,2.469,0,0,0-.712-2.237A2.431,2.431,0,0,0,11.65,8.85L10.525,7.725a3.794,3.794,0,0,1,.7-.225A3.706,3.706,0,0,1,12,7.425,4.056,4.056,0,0,1,16.075,11.5a3.881,3.881,0,0,1-.075.787A3.163,3.163,0,0,1,15.775,12.975Zm3.175,3.1-1.1-1.025a10.956,10.956,0,0,0,1.688-1.588A8.9,8.9,0,0,0,20.8,11.5a9.832,9.832,0,0,0-3.588-4.013A9.507,9.507,0,0,0,12,6a10.064,10.064,0,0,0-1.425.1A9.625,9.625,0,0,0,9.2,6.4L8.025,5.225a10.032,10.032,0,0,1,1.938-.55A11.648,11.648,0,0,1,12,4.5a10.967,10.967,0,0,1,6.062,1.762A11.327,11.327,0,0,1,22.2,10.9a.9.9,0,0,1,.075.262,2.291,2.291,0,0,1,0,.675.9.9,0,0,1-.075.263,11.329,11.329,0,0,1-1.388,2.175A10.884,10.884,0,0,1,18.95,16.075Zm.275,5.275-3.5-3.5a9.647,9.647,0,0,1-1.712.475A10.976,10.976,0,0,1,12,18.5a10.951,10.951,0,0,1-6.075-1.762A11.357,11.357,0,0,1,1.8,12.1a.9.9,0,0,1-.075-.263A2.208,2.208,0,0,1,1.7,11.5a1.98,1.98,0,0,1,.025-.325A1.326,1.326,0,0,1,1.8,10.9,11.315,11.315,0,0,1,3.175,8.775,12.052,12.052,0,0,1,4.9,7.05L2.65,4.775a.72.72,0,0,1-.225-.525A.8.8,0,0,1,2.65,3.7a.782.782,0,0,1,.538-.2.693.693,0,0,1,.512.2L20.3,20.3a.725.725,0,0,1,.213.5.757.757,0,0,1-.213.55.822.822,0,0,1-1.075,0ZM5.95,8.1A10.06,10.06,0,0,0,4.413,9.613,8.946,8.946,0,0,0,3.2,11.5a9.828,9.828,0,0,0,3.587,4.012A9.507,9.507,0,0,0,12,17a8.163,8.163,0,0,0,1.35-.113q.675-.112,1.15-.237l-1.25-1.3a3.887,3.887,0,0,1-.6.162,3.408,3.408,0,0,1-.65.063A4.056,4.056,0,0,1,7.925,11.5a3.489,3.489,0,0,1,.063-.638,3.81,3.81,0,0,1,.162-.612Zm7.575,2.625Zm-3.3,1.65Z"
                              transform="translate(0 0.525)" fill="#757575" />
                          </svg>
                        </div>
                        <app-error [controlName]="setPwdForm.controls['password']">
                        </app-error>
                      </div>
                      <div class="form-group" [class.error]="((setPwdForm.get('confirmPassword')!.touched ||
                      setPwdForm.get('confirmPassword')!.dirty) &&
                      setPwdForm.get('confirmPassword')!.errors)">
                        <input type="password" maxlength="50" formControlName="confirmPassword"
                          class="form-control form-input" name="setConfirmPassword" id="setConfirmPassword"
                          placeholder=" ">
                        <label class="form-label">Confirm password <span class="validation-field">*</span></label>
                        <div class="form-icon" (click)="showPassword('setConfirmPassword')">
                          <svg class="open-eye pass-icon" [class.hidden]="!isValid" id="icons_ic-visibility"
                            data-name="icons / ic-visibility" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24">
                            <rect id="Rectangle_454" data-name="Rectangle 454" width="24" height="24" fill="none" />
                            <path id="visibility_FILL0_wght300_GRAD0_opsz24" class="fill-color"
                              d="M12,15.575A4.056,4.056,0,0,0,16.075,11.5,4.056,4.056,0,0,0,12,7.425,4.056,4.056,0,0,0,7.925,11.5,4.056,4.056,0,0,0,12,15.575ZM12,14.2a2.69,2.69,0,0,1-2.7-2.7A2.689,2.689,0,0,1,12,8.8a2.687,2.687,0,0,1,2.7,2.7A2.689,2.689,0,0,1,12,14.2Zm0,4.3a11.058,11.058,0,0,1-6.013-1.725A11.311,11.311,0,0,1,1.8,12.1a.9.9,0,0,1-.075-.263,2.291,2.291,0,0,1,0-.675A.9.9,0,0,1,1.8,10.9,11.311,11.311,0,0,1,5.987,6.225,11.058,11.058,0,0,1,12,4.5a11.053,11.053,0,0,1,6.012,1.725A11.308,11.308,0,0,1,22.2,10.9a.9.9,0,0,1,.075.262,2.291,2.291,0,0,1,0,.675.9.9,0,0,1-.075.263,11.308,11.308,0,0,1-4.188,4.675A11.053,11.053,0,0,1,12,18.5Zm0-7ZM12,17a9.545,9.545,0,0,0,5.188-1.488A9.771,9.771,0,0,0,20.8,11.5a9.777,9.777,0,0,0-3.612-4.013A9.549,9.549,0,0,0,12,6,9.549,9.549,0,0,0,6.812,7.487,9.777,9.777,0,0,0,3.2,11.5a9.771,9.771,0,0,0,3.612,4.012A9.545,9.545,0,0,0,12,17Z"
                              transform="translate(0 0.5)" fill="#757575" />
                          </svg>
                          <svg class="eye-close hidden pass-icon" [class.hidden]="isValid"
                            data-name="icons / ic-visibility" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24">
                            <rect id="Rectangle_454" data-name="Rectangle 454" width="24" height="24" fill="none" />
                            <path id="visibility_off_FILL0_wght300_GRAD0_opsz24"
                              d="M15.775,12.975,14.65,11.85a2.469,2.469,0,0,0-.712-2.237A2.431,2.431,0,0,0,11.65,8.85L10.525,7.725a3.794,3.794,0,0,1,.7-.225A3.706,3.706,0,0,1,12,7.425,4.056,4.056,0,0,1,16.075,11.5a3.881,3.881,0,0,1-.075.787A3.163,3.163,0,0,1,15.775,12.975Zm3.175,3.1-1.1-1.025a10.956,10.956,0,0,0,1.688-1.588A8.9,8.9,0,0,0,20.8,11.5a9.832,9.832,0,0,0-3.588-4.013A9.507,9.507,0,0,0,12,6a10.064,10.064,0,0,0-1.425.1A9.625,9.625,0,0,0,9.2,6.4L8.025,5.225a10.032,10.032,0,0,1,1.938-.55A11.648,11.648,0,0,1,12,4.5a10.967,10.967,0,0,1,6.062,1.762A11.327,11.327,0,0,1,22.2,10.9a.9.9,0,0,1,.075.262,2.291,2.291,0,0,1,0,.675.9.9,0,0,1-.075.263,11.329,11.329,0,0,1-1.388,2.175A10.884,10.884,0,0,1,18.95,16.075Zm.275,5.275-3.5-3.5a9.647,9.647,0,0,1-1.712.475A10.976,10.976,0,0,1,12,18.5a10.951,10.951,0,0,1-6.075-1.762A11.357,11.357,0,0,1,1.8,12.1a.9.9,0,0,1-.075-.263A2.208,2.208,0,0,1,1.7,11.5a1.98,1.98,0,0,1,.025-.325A1.326,1.326,0,0,1,1.8,10.9,11.315,11.315,0,0,1,3.175,8.775,12.052,12.052,0,0,1,4.9,7.05L2.65,4.775a.72.72,0,0,1-.225-.525A.8.8,0,0,1,2.65,3.7a.782.782,0,0,1,.538-.2.693.693,0,0,1,.512.2L20.3,20.3a.725.725,0,0,1,.213.5.757.757,0,0,1-.213.55.822.822,0,0,1-1.075,0ZM5.95,8.1A10.06,10.06,0,0,0,4.413,9.613,8.946,8.946,0,0,0,3.2,11.5a9.828,9.828,0,0,0,3.587,4.012A9.507,9.507,0,0,0,12,17a8.163,8.163,0,0,0,1.35-.113q.675-.112,1.15-.237l-1.25-1.3a3.887,3.887,0,0,1-.6.162,3.408,3.408,0,0,1-.65.063A4.056,4.056,0,0,1,7.925,11.5a3.489,3.489,0,0,1,.063-.638,3.81,3.81,0,0,1,.162-.612Zm7.575,2.625Zm-3.3,1.65Z"
                              transform="translate(0 0.525)" fill="#757575" />
                          </svg>
                        </div>
                        <app-error [controlName]="setPwdForm.controls['confirmPassword']">
                        </app-error>

                      </div>
                    </div>
                    <validation-summary [validationErrors]="errorMessages"></validation-summary>
                    <div class="action-btn" [class.disabled]="!setPwdForm.valid">
                      <button type="submit" class="btn commn-btn" [disabled]="!setPwdForm.valid">Continue</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </main>
</div>