import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/before-login/login/login.component';
import { ScheduleOnBoardingComponent } from './components/before-login/scheduleOnboarding/scheduleOnboarding.component';
import { ActivateComponent } from './components/before-login/activate/activate.component';
import { SetPwdComponent } from './components/after-login/set-pwd/set-pwd.component';
import { RequestVerificationCodeComponent } from './components/before-login/request-verification-code/request-verification-code.component';
import { RegistrationThanksComponent } from './components/before-login/registration-thanks/registration-thanks.component';

import { MainComponent } from 'components/main/main.component';
import { ChangePwdComponent } from './components/after-login/change-pwd/change-pwd.component';
import { ResetPwdComponent } from './components/after-login/reset-pwd/reset-pwd.component';
import { SendActivationComponent } from './components/before-login/send-activation/send-activation.component';
import { RouterDataResolver } from 'services/router.resolver';
import { ForgotPwdComponent } from './components/before-login/forgot-pwd/forgot-pwd.component';
import { UnauthorizedComponent } from 'components/shared-component/unauthorized/unauthorized.component';
import { PenaltyInfoComponent } from 'components/after-login/contact-dashboard/profile-information/penalty-info/penalty-info.component';
import { AuthGuard } from 'auth/auth.guard';
import { CustomerResourcesComponent } from 'components/after-login/customer-resources/customer-resources.component';

import { PackagesCodeComponent } from 'components/before-login/packages-code/packages-code.component';
import { ConfirmActivationEmailComponent } from 'components/before-login/confirm-activation-email/confirm-activation-email.component';
import { IssueFeedbackComponent } from 'components/after-login/issue-feedback/issue-feedback.component';
import { BookOnboardingSlotComponent } from 'components/before-login/book-onboarding-slot/book-onboarding-slot.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'scheduleOnboarding',
    component: ScheduleOnBoardingComponent
  },
  {
    path: 'thanksreg',
    component: RegistrationThanksComponent
  },
  {
    path: 'bookOnboardingSlot',
    component: BookOnboardingSlotComponent
  },
  {
    path: 'activate/:id',
    component: ActivateComponent
  },
  {
    path: 'setpwd',
    component: SetPwdComponent
  },
  {
    path: 'forgotpwd',
    component: ForgotPwdComponent
  },
  {
    path: 'resetpwd',
    component: ResetPwdComponent
  },
  {
    path: 'send-activation',
    component: SendActivationComponent
  },
  {
    path: 'reqpwd',
    component: RequestVerificationCodeComponent
  },
  {
    path: 'changepwd',
    component: ChangePwdComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: {
      title: 'Unauthorized'
    },
    resolve: { data: RouterDataResolver }
  },
  {
    path: 'address-change-penalty-information',
    component: PenaltyInfoComponent,
    // canActivate: [AuthGuard],
    // resolve: { data: RouterDataResolver }
  },
  {
    path: 'package/activate/:id',
    component: PackagesCodeComponent
  },
  {
    path: 'confirm-activation-email',
    component: ConfirmActivationEmailComponent
  },
  {
    path: 'issue-feedback',
    component: IssueFeedbackComponent
  },
  {
    path: '',
    component: MainComponent,
    //canActivate: [AuthGuard],
    data: {
      title: 'Dashboard',
      icon: 'ic-dashboard.svg'
    },
    resolve: { data: RouterDataResolver },
    children: [
      {
       path: "registration",
       loadChildren: () => import("./components/after-login/registration-flow/registration.module").then(t => t.RegistrationModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import("./components/after-login/dashboard/dashboard.module").then(t => t.DashboardModule),
      },
      {
        path: 'contact-list',
        loadChildren: () => import("./components/after-login/contacts/contacts.module").then(t => t.ContactsModule),
      },
      {
        path: 'contact-dashboard',
        loadChildren: () => import("./components/after-login/contact-dashboard/contact-dashboard.module").then(t => t.ContactDashboardModule),
      },
      {
        path: 'firm-dashboard',
        loadChildren: () => import("./components/after-login/firm-dashboard/firm-dashboard.module").then(t => t.FirmDashboardModule),
      },
      {
        path: 'requests',
        loadChildren: () => import("./components/after-login/request/request.module").then(t => t.RequestModule),
      },
      {
        path: 'main-licenses',
        loadChildren: () => import("./components/after-login/main-licenses/licenses.module").then(t => t.LicensesModule),
      },
      {
        path: 'main-appointments',
        loadChildren: () => import("./components/after-login/main-appointments/appointments.module").then(t => t.AppointmentsModule),
      },
      // Old Task/Alert
      // {
      //   path: 'task-list',
      //   loadChildren: () => import("./components/after-login/tasks_old/tasks.module").then(t => t.TasksModule),
      // },
      {
        path: 'tasks',
        loadChildren: () => import("./components/after-login/tasks/tasks.module").then(t => t.TasksModule),
      },
      {
        path: 'reports',
        loadChildren: () => import("./components/after-login/reports/reports.module").then(t => t.ReportsModule),
      },
      {
        path: 'manage',
        loadChildren: () => import("./components/after-login/manage/manage.module").then(t => t.ManageModule),
      },
      // {
      //   path: 'lines-of-authorities',
      //   loadChildren: () => import("./components/after-login/lines-of-authority/loa.module").then(t => t.LOAModule),
      // },
      
      {
        path: 'custom-loa-group-list',
        loadChildren: () => import("./components/after-login/custom-loa-group/custom-loa-group.module").then(t => t.CustomLoaGroupModule),
      },
      {
        path: 'settings',
        loadChildren: () => import("./components/after-login/settings/settings.module").then(t => t.SettingsModule),
      },
      
      {
        path: 'resource-library',
        loadChildren: () => import("./components/after-login/resource-library/resource-library.module").then(t => t.ResourceLibraryModule),
      },
      {
        path: 'help',
        loadChildren: () => import("./components/after-login/customer-resources/customer-resources.module").then(t => t.CustomerResoucesModule),
      },
      {
        path: 'packages',
        loadChildren: () => import("./components/after-login/packages/packages.module").then(t => t.PackagesModule),
      },
      {
        path: 'packages-carrier',
        loadChildren: () => import("./components/after-login/packages-carrier/packages-carrier.module").then(t => t.PackagesCarrierModule),
      },
      {
        path: 'downlining',
        loadChildren: () => import("./components/after-login/downlining/downlining.module").then(t => t.DownliningModule),
      }
    ],
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
