import { Injectable } from '@angular/core';
import { ApiClientService } from 'api-services';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  constructor(private apiClient: ApiClientService) {}

  public getStates() {
    return this.apiClient.get('api/Lookup/state', null, null, false);
  }

  public getState(id: number) {
    return this.apiClient.get(`lookup/state/${id}`, null, null, false);
  }

  public getCountries() {
    return this.apiClient.get('lookup/country', null, null, false);
  }

  public getCountry(id: number) {
    return this.apiClient.get(`lookup/country/${id}`, null, null, false);
  }

  public getTenants() {
    return this.apiClient.get('api/lookup/tenant', null, null, false);
  }

  public getTenant(id: number) {
    return this.apiClient.get(`lookup/tenant/${id}`, null, null, false);
  }

  public getTenantStatuses() {
    return this.apiClient.get('api/lookup/tenantstatus', null, null, false);
  }

  public getTenantStatus(id: number) {
    return this.apiClient.get(`lookup/tenantstatus/${id}`, null, null, false);
  }
}
