import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AccountService } from 'api-services';
import { CustomValidators } from 'ng2-validation';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { ValidatorHelper } from '../../custom-validators/validators.helper';
declare var $:any;
@Component({
  selector: 'app-scheduleOnboarding',
  templateUrl: './scheduleOnboarding.component.html',
  styleUrls: ['./scheduleOnboarding.component.css']
})
export class ScheduleOnBoardingComponent implements OnInit {
  errorMessages = [];
  userRegForm!: FormGroup;
  subscription: Subscription[] = [];
  mask: any[] = [/[2-9]{1}/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  constructor(private router: Router, private fb: FormBuilder, private accountService: AccountService, private authService: AuthService, public meta: Meta, public title: Title) {
    
  }

  ngOnInit(): void {
    // Title and Meta Description
    this.meta.addTag({ name: 'Description', content: 'Streamline producer licensing, appointments, and compliance effortlessly with Agenzee. Register for our app to manage compliance on the go!' }); 
    this.title.setTitle('Register For the Agenzee App');
    //
    this.authService.redirect();
    this.userRegForm = this.fb.group({
      firstName: ['', [Validators.required,Validators.maxLength(50)]],
      lastName: ['', [Validators.required,Validators.maxLength(50)]],
      businessName: ['', [Validators.required,Validators.maxLength(50)]],
      phoneNumber: ['', [Validators.required, ValidatorHelper.phonePattern]],
      tenantTypeId: ['', [Validators.required]],
      email: ['', [Validators.required, ValidatorHelper.emailPattern,,Validators.maxLength(255)]],
      agree: ['', [Validators.requiredTrue]],
      reCaptcha: ['', [Validators.required]]
    });
  }

  touchEmail()
  {   
    this.errorMessages = [];
  }
  onSubmit(): void {
    
    this.errorMessages = [];
    this.userRegForm.markAllAsTouched();
    if (this.userRegForm.valid) {
      delete this.userRegForm.value['reCaptcha'];
      this.subscription.push(this.accountService.userReg(this.userRegForm.value).subscribe(
        (response: any) => {
          if (response.id > 0) {
            sessionStorage.setItem('user', JSON.stringify(this.userRegForm.value));
            this.router.navigate(['/bookOnboardingSlot']);
          }
          else {
            this.errorMessages.push(response.rtnMessage);
          }
        },
        (error: any) => {
          this.errorMessages.push(error.message);
        }
      ))
    }

  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha: ${captchaResponse}`);
  }
}


