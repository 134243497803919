import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { RoleService } from 'services/role.service';
import { RouterDataResolver } from 'services/router.resolver';
import { LinesOfAuthorityService } from '../../../api-services/lines-of-authority.service';
import { SharedService } from '../../../services';
import { tenantType, tenantTypeCap } from 'shared/constant'
import { TaskService } from '../../../api-services/task.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @ViewChild('sideBar') sideBarEle: ElementRef<HTMLElement>;
  @Output() sideNvValue = new EventEmitter<any>();
  manageCarriersUrl = "manage/carriers"
  isAdmin?: boolean = false;
  darkScrollbarOptions: MCustomScrollbar.CustomScrollbarOptions = { axis: 'y', theme: 'light', setHeight: 'calc(100vh - 92px)' };

  hideSideNav: boolean = JSON.parse(sessionStorage.getItem('collapseValue')) ? JSON.parse(sessionStorage.getItem('collapseValue')) : false;
  isContactUser = false;
  isMGAUser = false;
  isGuestUser = false;
  isCarrierUser = false;
  isAgencyUser = false;
  hideSideBar = false;
  tenantTypeId = localStorage['tenantTypeId'];
  tenantType = tenantType;
  tenantTypeCap = tenantTypeCap;
  activeAlertsCount = localStorage.getItem("ActiveTaskCount");
  activeAlertsCountOld = localStorage.getItem("ActiveTaskCountOld");

  private taskUpdateSubscription: Subscription;

  constructor(private roleService: RoleService,
    private routerDataResolver: RouterDataResolver, private LinesOfAuthorityService: LinesOfAuthorityService
    , private sharedService: SharedService
    , private taskService: TaskService
  ) {
    this.hideSideBar = routerDataResolver.hideSideBar;

  }

  ngOnInit(): void {
    this.isContactUser = this.roleService.isContactUser;
    this.isMGAUser = this.roleService.isMGAUser;
    this.isGuestUser = this.roleService.isGuestUser;
    this.isCarrierUser = this.roleService.isCarrierUser;
    this.isAgencyUser = this.roleService.isAgencyUser;
    if (localStorage["userRole"] == "admin") {
      this.isAdmin = true;
    }
    if (this.roleService.isMGAUser) {
      this.manageCarriersUrl = "manage/mga-carriers"
    }

    this.getUnreadTaskCount();

    this.getOldTaskCount();
  }

  getOldTaskCount(){
    this.LinesOfAuthorityService.getActiveTaskCount(Number(localStorage.getItem('tenantId'))).subscribe(
      (response: any) => {
        this.activeAlertsCountOld = response;
        localStorage.setItem("ActiveTaskCountOld", this.activeAlertsCount.toString());
      },
      (error: any) => {
        console.log(error);
      }
    );

    this.taskUpdateSubscription = this.sharedService.getUpdate().subscribe
     (res => {
       if (res != null && res) {

         this.LinesOfAuthorityService.getActiveTaskCount(Number(localStorage.getItem('tenantId'))).subscribe(
           (response: any) => {
             this.activeAlertsCountOld = response;
             localStorage.setItem("ActiveTaskCountOld", this.activeAlertsCountOld.toString());
           },
           (error: any) => {
             console.log(error);
           }
         );
       }
     });
  }

  ngOnDestroy() {
    this.taskUpdateSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.taskUpdateSubscription = this.sharedService.unreadTaskCountReloadObs.subscribe(() => {
      this.getUnreadTaskCount();
    });

  }

  getUnreadTaskCount() {
    this.taskService.getUnreadTaskCount().subscribe(
      (response: any) => {
        this.activeAlertsCount = response;
        localStorage.setItem("ActiveTaskCount", this.activeAlertsCount.toString());
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  toggleSideNav() {
    this.hideSideNav = !this.hideSideNav;
    this.sideNvValue.emit(this.hideSideNav);
    sessionStorage.setItem('collapseValue', JSON.stringify(this.hideSideNav));
  }

  onResize(event) {
    if (event.target.innerWidth <= 1366 && this.hideSideNav == false) {
      this.toggleSideNav();
    }
    else if (event.target.innerWidth > 1366 && this.hideSideNav == true) {
      this.toggleSideNav();
    }
  }
}
