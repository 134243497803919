import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactService } from 'api-services/contact.service';
import { SharedService } from 'services';

@Component({
  selector: 'app-add-contact-progress',
  templateUrl: './add-contact-progress.component.html',
  styleUrls: ['./add-contact-progress.component.css']
})
export class AddContactProgressComponent implements OnInit {
@Input() requestData;
processedCount:number = 0;
  constructor(private activeModal: NgbActiveModal, private contactService: ContactService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.processData();
    // for(var i=1; i<this.requestData.length - 1; i++){
    //   setTimeout(() => this.processedCount = i, 1000);
    // }

    for(let i=1; i<this.requestData.length; i++){
      setTimeout(() => {this.processedCount = i}, (i * 100));
    }
   
  }

  processData(){
    this.contactService.post(this.requestData).subscribe(data => {
      this.processedCount = this.requestData.length;
      setTimeout(() => this.activeModal.close(data), 1000);
      this.sharedService.sendUpdate(true);
    }, err => {
      this.activeModal.close(err);
    }
  )}

}
