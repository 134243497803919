import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateParserFormatter, NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { BranchService } from 'api-services';
import { ContactService } from 'api-services/contact.service';
import { ValidatorHelper } from 'components/custom-validators/validators.helper';
import { SharedService } from 'services';
import { AdminwithoutnpnService } from 'services/adminwithoutnpn.service';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year
      : '';
  }
}

@Component({
  selector: 'app-add-admin-without-npn',
  templateUrl: './add-admin-without-npn.component.html',
  styleUrls: ['./add-admin-without-npn.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class AddAdminWithoutNPNComponent implements OnInit {
  tenantTypeId = localStorage['tenantTypeId'];
  tenantId: number = localStorage['tenantId'];
  contactAdminForm: FormGroup;
  jobTitleList: any;
  departmentList: any;
  @Input() branchList;
  @Output() closePopup: any = new EventEmitter();
  errorMessages = [];
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  constructor(private fb: FormBuilder, private branchService: BranchService, private sharedService: SharedService, private adminwithoutnpnService: AdminwithoutnpnService, private contactService: ContactService, private config:NgbDatepickerConfig, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.contactAdminForm = this.fb.group({
      FirstName: ['', [Validators.required, Validators.maxLength(100)]],
      LastName: ['', [Validators.required, Validators.maxLength(100)]],
      Email: ['', [Validators.required, ValidatorHelper.emailPattern, Validators.maxLength(1000)]],
      Alias: ['', [Validators.maxLength(100)]],
      JobTitleId: [null],
      HDate: [null],
      DepartmentId: [null],
      BranchId: [null, [Validators.required]],
      IsSendActivation: [0],
      StrHireDate: [null],
    });

    this.contactAdminForm.get("BranchId")!.setValue(this.branchList[0].branchId);    
    this.loadJobTitle(this.tenantId);
    this.loadDepartment(this.tenantId);
    const today = new Date();
    this.minDate = {
      year: today.getFullYear() -125,
      month: today.getMonth(),
      day: today.getDate()
    }
    this.maxDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate()
    }
  }
 
  ngAfterViewInit() {
    this.config.maxDate = this.maxDate;
    this.config.minDate = this.minDate;
    this.cdr.detectChanges();
  }

  clearDate() {
    this.contactAdminForm.get("HDate")!.setValue(null);
  }

  loadJobTitle(tenantId: number){
    this.contactService.getTenantJobTitles(tenantId).subscribe(
      response => {
        this.jobTitleList = response;
      },
      error => {
        if (error.status == 0) {
        }
        else {
        }
      }
    )
  }
  
  loadDepartment(tenantId: number){
    this.contactService.getTenantDepartment(tenantId).subscribe(
      response => {
        this.departmentList = response;
      },
      error => {
        if (error.status == 0) {
        }
        else {
        }
      }
    )
  }

  closeAgentPopup(event){
    this.closePopup.emit(event);
  }

  addAgent(event,IsSendActivation:number){
    this.errorMessages = [];
    if(this.contactAdminForm.valid){      
      this.contactAdminForm.get("IsSendActivation")!.setValue(IsSendActivation);
      if(this.contactAdminForm.value.HDate != undefined)
      {
        //const date: Date = new Date(this.contactAdminForm.value.HDate.year, this.contactAdminForm.value.HDate.month - 1, this.contactAdminForm.value.HDate.day);
        const date: string =  (this.contactAdminForm.value.HDate.year + '/' + this.contactAdminForm.value.HDate.month + '/' + this.contactAdminForm.value.HDate.day);
        this.contactAdminForm.get("StrHireDate")!.setValue(date);
      }
      this.adminwithoutnpnService.insertNonProducerAdmin(this.contactAdminForm.value).subscribe(
        (response) => {
          // Handle successful response
          if(response && response > 0)
          {
            if(IsSendActivation == 0)
              this.sharedService.showToastMessage("User added successfully.");      
            else 
              this.sharedService.showToastMessage("User added and the invite has been sent successfully.");
          }
          else{
            this.sharedService.showToastMessage("User added successfully, but there appears to be an error while attempting to email the invite. Please try sending the invite again.");
          }               
          this.closeAgentPopup(event);
        },
        (error) => {
          // Handle error           
          if(error && error.message != undefined)
          {
            // this.sharedService.showToastMessage(error.message);
            this.errorMessages.push(error.message)
          }
          else{
            // this.sharedService.showToastMessage("Unable to add the user. Please try again.");
            this.errorMessages.push("Unable to add the user. Please try again.")
          }
        }
      );      
    }    
  }   
}
