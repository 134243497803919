import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpParamsOptions, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { debug } from 'console';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { ApiClientService } from './common/api-client.service';


@Injectable({
  providedIn: 'root'
})
export class UserStorageService {

  constructor(
    private apiClient: ApiClientService,
    private authService: AuthService, private http: HttpClient) { }

  public getTenantFileStoreDetails() {
    return this.apiClient.get(`api/StorageIntegration/tenantFileStoreDetails`, null, null, true);
  }

  public getStorageRedirectURL(storageType: number): Observable<string> {
    //let params = new HttpParams();
    //params = params.append("storageType", storageType.toString());

    return this.apiClient.get(`api/StorageIntegration/redirectToStorageURL/${storageType}`, null, null, true);
  }

  public updateFileStoreAccountStatus(fileStoreTypeId: number, accountStatus: boolean) {
    return this.apiClient.post(`api/StorageIntegration/updateFileStoreAccountStatus/${fileStoreTypeId}/${accountStatus}`, null, null, true);
  }

  //Upload contact license files
  public UploadContactFiles(contactId, fileStoreTypeId, fileCategoryId, formData: FormData): Observable<any> {
    const headers = new HttpHeaders().append(
      "Content-Disposition",
      "multipart/form-data"
    );
    return this.http.post(environment.baseUrl + `api/StorageIntegration/UploadContactFile/${contactId}/${fileStoreTypeId}/${fileCategoryId}`, formData, {
      headers: headers ,
      reportProgress: true,
      //observe: 'events'
    });
  }

  public DownloadContactFile(contactId, fileId, fileStoreTypeId, fileCategoryId): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
      })
    };

    return this.http.get(environment.baseUrl + `api/StorageIntegration/DownloadContactFile/${contactId}/${fileId}/${fileStoreTypeId}/${fileCategoryId}`, httpOptions)
      .pipe(map((response: any) => {
        let data = response;
        return data;
      }
    ));
  }

  public getContactUploadedFiles(obj) {
    return this.apiClient.post(`api/StorageIntegration/GetContactFiles`, obj, null, true);
  }

  public getContactUploadedFilesCount(contactId, fileStoreTypeId) {
    return this.apiClient.get(`api/StorageIntegration/UserLicenseFileCount/${contactId}/${fileStoreTypeId}`, null, null, true);
  }

  public getTenantAPIKey() {
    return this.apiClient.get(`api/UserProfileSetting/getTenantAPIKey`, null, null, true);
  }
 
  public generateAPIKey() {
    return this.apiClient.get(`api/UserProfileSetting/generateAPIKey`, null, null, true);
  }

  public getFileTypeByCategory(fileCategoryId) {
    return this.apiClient.get(`api/StorageIntegration/fileTypeList/${fileCategoryId}`, null, null, true);
  }

  public deleteFileById(fileId) {
    return this.apiClient.delete(`api/StorageIntegration/deleteFile/${fileId}`, null, null, true);
  }
}
