import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from 'services/role.service';


@Component({
  selector: 'app-custom-tag-dropdown',
  templateUrl: './custom-tag-dropdown.component.html',
  styleUrls: ['./custom-tag-dropdown.component.css']
})
export class CustomTagDropdownComponent implements OnInit {
  @Input() tags: any; 
  @Input() selectedTagIds;
  @Output() tagChanges = new EventEmitter<string[]>();
  selectedTags = [];
  unSelectedTags = [];
  isMGAUser: boolean = false;  

  constructor(  private roleService: RoleService, private router: Router,private activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.isMGAUser = this.roleService.isMGAUser;
    this.tags?.length > 0 ? this.setValue() : '';
   
  }

  ngOnChanges(changes: SimpleChange){
    this.tags?.length > 0 ? this.setValue() : '';
  }

  setValue(){
    this.tags = this.tags?.map(m => ({ ...m, isChecked: false }));
    if(this.selectedTagIds?.length > 0){
      // const tagIdsArray: number[] = this.selectedTagIds?.split(',').map(id => parseInt(id.trim(), 10));

      this.selectedTagIds.forEach(sel => {
        const foundTag = this.tags.find(tags => tags.tagId === sel.tagId);
        if (foundTag) {
          foundTag['isChecked'] = true;
          this.selectedTags.push(foundTag);
        }
      });
    }
    this.getSelectedData();
  }

  onSelectionChange(tag, isAdd) { 
    this.tags.forEach(x =>{
      if( x.tagId == tag.tagId){
        x.isChecked = !x.isChecked;
      }else if(tag == 0){
        x.isChecked = this.selectedTags?.length == this.tags?.length ? false : true;
      }
    })
    this.getSelectedData();

    this.tagChanges.emit([tag, isAdd]);
  }

  getSelectedData() {
    this.unSelectedTags = this.tags.filter(x =>x.isChecked === false)
    this.selectedTags = this.tags.filter(x =>x.isChecked === true)
  }

  redirectToManageTags(){
    this.activeModal.close(false);
  // const navigationExtras: NavigationExtras = {state: {active: 'manage-mga', activeTab: 'appointer-information-mga'}}
  if(this.isMGAUser){
    this.router.navigate(['/settings'],  { queryParams: { query: 'manage-mga' }} );
  }else{
    this.router.navigate(['/settings'],  { queryParams: { query: 'manage-carriers' }} );
  }

}

}
