<div class="new-contact-popup common-modal">
  <div class="tab common-tab" role="tabpanel">
    <ul class="nav nav-tabs" role="tablist" #addContactTab>
      <li class="nav-item">
        <a class="nav-link active" href="#manually" aria-controls="loa" role="tab"
           data-toggle="tab">Manually</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#bulk-import" aria-controls="loa" role="tab" data-toggle="tab">
          Bulk Import
        </a>
      </li>
      <!--<li class="nav-item" type="button">
        <a class="nav-link" href="#integrations" aria-controls="loa" role="tab"
           data-toggle="tab">Integrations</a>
      </li>-->
    </ul>
    <div class="tab-content" #addContactTabPane>
      <div role="tabpanel" class="tab-pane active in" id="manually">
        <div class="tab-btn" *ngIf="!isRegistration">
          <button type="button" class="tab-btn-dark" (click)="tabChange(true)" [ngClass]="{'active': isAgentNPN}">New {{tenantTypeCap[tenantTypeId]}}</button>
          <button type="button" class="tab-btn-dark" (click)="tabChange(false)" [ngClass]="{'active': !isAgentNPN}">New Admin (Non {{tenantTypeCap[tenantTypeId]}})</button>
        </div>
        <form id="contactForm" [formGroup]="contactForm" *ngIf="isAgentNPN || isRegistration">
          <div class="group-form">
            <div class="row">
              <div class="col-12 col-sm-12">
                <div class="label" id="manually">
                  <label class="input-label">Select {{tenantTypeId == 1 ? 'agency':'branch'}} <span class="validation-field">*</span></label>
                </div>
                <div class="branch-block">
                  <div class="select-options {{showBranchDropDown}}">
                    <div class="select-block pd-0">
                      <ng-select class="fix-width"  [searchable]="true" [clearable]="true" formControlName="branchId" name="branchId"
                                 (change)="selectBranch($event, 'contactForm')" placeholder="Select {{tenantTypeId == 1 ? 'agency':'branch'}}">
                        <ng-option *ngFor="let branch of branchList" [value]="branch.branchId">{{ branch.branchName}}</ng-option>
                      </ng-select>
                    </div>
                  </div>
                  <div class="input-block {{showBranchtext}}">
                    <input type="text" maxlength="100" class="form-control" placeholder="{{tenantTypeId == 1 ? 'Agency':'Branch'}} Name"
                           formControlName="branchName" name="branchName" id="branchName" />
                    <!-- (blur)="branchNameValidation(1)" -->
                    <validation-summary [validationErrors]="errorMessagesBranchName"></validation-summary>
                  </div>
                  <div class="action-data-btn">
                    <div class="action-btn">
                      <div class="edit-icon icons {{showEditButton}}" (click)="editBranch(1)" title="Edit {{tenantTypeId == 1 ? 'agency':'branch'}} name">
                        <span>
                          <svg id="ic-edit-20px" xmlns="http://www.w3.org/2000/svg" width="20"
                               height="20" viewBox="0 0 20 20">
                            <rect id="Rectangle_17995" data-name="Rectangle 17995" width="20"
                                  height="20" fill="none" />
                            <path id="edit_FILL0_wght300_GRAD0_opsz20"
                                  d="M4.375,15.75h1.1L13,8.229l-1.083-1.1L4.375,14.667ZM15.812,7.312l-3-3.02.959-.938a1.333,1.333,0,0,1,1.9,0l1.1,1.1a1.357,1.357,0,0,1,.375.969,1.25,1.25,0,0,1-.4.948ZM3.833,17.083a.817.817,0,0,1-.791-.791V14.4a.775.775,0,0,1,.052-.281.753.753,0,0,1,.177-.261L11.9,5.229l3,3L6.271,16.854a.753.753,0,0,1-.261.177.775.775,0,0,1-.281.052Zm8.625-9.395-.541-.563L13,8.229Z"
                                  transform="translate(-0.094 -0.021)" fill="#616161" />
                          </svg>
                        </span>
                      </div>
                      <div class="add-icon icons {{showAddButton}}" (click)="addBranch(1)" title="Add {{tenantTypeId == 1 ? 'agency':'branch'}}">
                        <span>
                          <svg id="ic-add-20px" xmlns="http://www.w3.org/2000/svg" width="20"
                               height="20" viewBox="0 0 20 20">
                            <rect id="Rectangle_17995" data-name="Rectangle 17995" width="20"
                                  height="20" fill="none" />
                            <path id="add_FILL0_wght300_GRAD0_opsz20"
                                  d="M10,15.625a.676.676,0,0,1-.667-.667V10.667H5.042a.667.667,0,0,1,0-1.334H9.333V5.042a.667.667,0,0,1,1.334,0V9.333h4.291a.667.667,0,0,1,0,1.334H10.667v4.291a.676.676,0,0,1-.667.667Z"
                                  transform="translate(0)" fill="#616161" />
                          </svg>
                        </span>
                      </div>
                      <!--hidden-->
                      <!-- <div class="save-icon icons {{showSaveButton}}" (click)="saveBranch()">
                        <span>
                          Save
                          <svg id="ic-done-20px" xmlns="http://www.w3.org/2000/svg" width="20"
                               height="20" viewBox="0 0 20 20">
                            <rect id="Rectangle_18127" data-name="Rectangle 18127" width="20"
                                  height="20" fill="none" />
                            <path id="check_circle_FILL0_wght300_GRAD0_opsz20"
                                  d="M8.854,11.646l-1.9-1.9a.678.678,0,0,0-.937,0,.687.687,0,0,0-.229.49.621.621,0,0,0,.229.468l2.25,2.25a.722.722,0,0,0,.552.25.859.859,0,0,0,.594-.25l4.562-4.583a.653.653,0,0,0,0-.917.632.632,0,0,0-.937,0ZM10,17.917a7.723,7.723,0,0,1-3.094-.625,7.871,7.871,0,0,1-4.2-4.2A7.723,7.723,0,0,1,2.083,10a7.723,7.723,0,0,1,.625-3.094,7.871,7.871,0,0,1,4.2-4.2A7.723,7.723,0,0,1,10,2.083a7.723,7.723,0,0,1,3.094.625,7.871,7.871,0,0,1,4.2,4.2A7.723,7.723,0,0,1,17.917,10a7.723,7.723,0,0,1-.625,3.094,7.871,7.871,0,0,1-4.2,4.2A7.723,7.723,0,0,1,10,17.917ZM10,10Zm0,6.583a6.344,6.344,0,0,0,4.656-1.927A6.344,6.344,0,0,0,16.583,10a6.344,6.344,0,0,0-1.927-4.656A6.344,6.344,0,0,0,10,3.417,6.344,6.344,0,0,0,5.344,5.344,6.344,6.344,0,0,0,3.417,10a6.344,6.344,0,0,0,1.927,4.656A6.344,6.344,0,0,0,10,16.583Z"
                                  fill="#0068d8" />
                          </svg>
                        </span>
                      </div> [disabled]="!contactForm.value.branchName"  -->

                      <button class="save-icon icons {{showSaveButton}}" (click)="saveBranch(1)">
                        <span>
                          Save
                          <svg id="ic-done-20px" xmlns="http://www.w3.org/2000/svg" width="20"
                               height="20" viewBox="0 0 20 20">
                            <rect id="Rectangle_18127" data-name="Rectangle 18127" width="20"
                                  height="20" fill="none" />
                            <path id="check_circle_FILL0_wght300_GRAD0_opsz20"
                                  d="M8.854,11.646l-1.9-1.9a.678.678,0,0,0-.937,0,.687.687,0,0,0-.229.49.621.621,0,0,0,.229.468l2.25,2.25a.722.722,0,0,0,.552.25.859.859,0,0,0,.594-.25l4.562-4.583a.653.653,0,0,0,0-.917.632.632,0,0,0-.937,0ZM10,17.917a7.723,7.723,0,0,1-3.094-.625,7.871,7.871,0,0,1-4.2-4.2A7.723,7.723,0,0,1,2.083,10a7.723,7.723,0,0,1,.625-3.094,7.871,7.871,0,0,1,4.2-4.2A7.723,7.723,0,0,1,10,2.083a7.723,7.723,0,0,1,3.094.625,7.871,7.871,0,0,1,4.2,4.2A7.723,7.723,0,0,1,17.917,10a7.723,7.723,0,0,1-.625,3.094,7.871,7.871,0,0,1-4.2,4.2A7.723,7.723,0,0,1,10,17.917ZM10,10Zm0,6.583a6.344,6.344,0,0,0,4.656-1.927A6.344,6.344,0,0,0,16.583,10a6.344,6.344,0,0,0-1.927-4.656A6.344,6.344,0,0,0,10,3.417,6.344,6.344,0,0,0,5.344,5.344,6.344,6.344,0,0,0,3.417,10a6.344,6.344,0,0,0,1.927,4.656A6.344,6.344,0,0,0,10,16.583Z"
                                  fill="#0068d8" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="cancel-btn  {{showCancelButton}}" (click)="cancel(1)">
                    <span>Cancel</span>
                  </div>
                </div>
                <div class="select-edit"><validation-summary [validationErrors]="selectionErrorMessages"></validation-summary></div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12">
                <div class="input-block">
                  <label class="input-label">National producer number (NPN) <span class="validation-field">*</span></label>
                  <input type="text" class="form-control" placeholder="NPN" formControlName="npnNumber" name="npn" />
                  <app-error [controlName]="contactForm.controls['npnNumber']">
                  </app-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12">
                <div class="notes">
                  <!-- <p>Multiple NPNs should be separated by commas.</p> -->
                  <p>Please separate multiple NPNs by commas without spaces.</p>
                </div>
              </div>
              <!-- <div class="col-12 col-sm-12 col-md-5">
                <div class="max-limit text-right">
                  <p>Max. NPN limit is 50</p>
                </div>
              </div> -->
            </div>
            <div>
              <validation-summary [validationErrors]="errorMessages" light malihu-scrollbar [scrollbarOptions]="darkScrollbarOptions" class="scroll-bar"></validation-summary>
            </div>
          </div>
          <div class="action-btn footer text-right">
            <button type="button" (click)="addContact()" class="btn commn-btn"
                    [disabled]="!contactForm.valid">
              Add
            </button>
          </div>
        </form>
        <div *ngIf="!isAgentNPN && !isRegistration">
          <app-add-admin-without-npn [branchList]="branchList" (closePopup)="closePopup($event)"></app-add-admin-without-npn>
        </div>

      </div>
      <div role="tabpanel" class="tab-pane" id="bulk-import">
        <form id="bulkImportForm" [formGroup]="bulkImportForm">
          <div class="group-form">
            <div class="row">
              <div class="col-12 col-sm-12">
                <div class="label">
                  <label class="input-label">Select {{tenantTypeId == 1 ? 'agency':'branch'}} <span class="validation-field">*</span></label>
                </div>
                <div class="branch-block">
                  <div class="select-options {{bulkShowBranchDropDown}}">
                    <div class="select-block pd-0">
                      <ng-select class="fix-width" [searchable]="true" [clearable]="true" formControlName="branchId" name="branchId"
                                 (change)="selectBranch($event, 'bulkImportForm')" placeholder="Select {{tenantTypeId == 1 ? 'agency':'branch'}}">
                        <ng-option *ngFor="let branch of branchList" [value]="branch.branchId">{{ branch.branchName }}</ng-option>
                      </ng-select>
                    </div>
                  </div>
                  <div class="input-block {{bulkShowBranchtext}}">
                    <input type="text" maxlength="100" class="form-control" placeholder="{{tenantTypeId == 1 ? 'Agency':'Branch'}} Name"
                           formControlName="branchName" name="branchName" id="branchName" />
                    <!-- (blur)="branchNameValidation(2)" -->
                    <validation-summary [validationErrors]="errorMessagesBranchName"></validation-summary>
                  </div>
                  <div class="action-data-btn">
                    <div class="action-btn">
                      <div class="edit-icon icons {{bulkShowEditButton}}" (click)="editBranch(2)" title="Edit {{tenantTypeId == 1 ? 'agency':'branch'}} name">
                        <span>
                          <svg id="ic-edit-20px" xmlns="http://www.w3.org/2000/svg" width="20"
                               height="20" viewBox="0 0 20 20">
                            <rect id="Rectangle_17995" data-name="Rectangle 17995" width="20"
                                  height="20" fill="none" />
                            <path id="edit_FILL0_wght300_GRAD0_opsz20"
                                  d="M4.375,15.75h1.1L13,8.229l-1.083-1.1L4.375,14.667ZM15.812,7.312l-3-3.02.959-.938a1.333,1.333,0,0,1,1.9,0l1.1,1.1a1.357,1.357,0,0,1,.375.969,1.25,1.25,0,0,1-.4.948ZM3.833,17.083a.817.817,0,0,1-.791-.791V14.4a.775.775,0,0,1,.052-.281.753.753,0,0,1,.177-.261L11.9,5.229l3,3L6.271,16.854a.753.753,0,0,1-.261.177.775.775,0,0,1-.281.052Zm8.625-9.395-.541-.563L13,8.229Z"
                                  transform="translate(-0.094 -0.021)" fill="#616161" />
                          </svg>
                        </span>
                      </div>
                      <div class="add-icon icons {{bulkShowAddButton}}" (click)="addBranch(2)" title="Add {{tenantTypeId == 1 ? 'agency':'branch'}}">
                        <span>
                          <svg id="ic-add-20px" xmlns="http://www.w3.org/2000/svg" width="20"
                               height="20" viewBox="0 0 20 20">
                            <rect id="Rectangle_17995" data-name="Rectangle 17995" width="20"
                                  height="20" fill="none" />
                            <path id="add_FILL0_wght300_GRAD0_opsz20"
                                  d="M10,15.625a.676.676,0,0,1-.667-.667V10.667H5.042a.667.667,0,0,1,0-1.334H9.333V5.042a.667.667,0,0,1,1.334,0V9.333h4.291a.667.667,0,0,1,0,1.334H10.667v4.291a.676.676,0,0,1-.667.667Z"
                                  transform="translate(0)" fill="#616161" />
                          </svg>
                        </span>
                      </div>
                      <button class="save-icon icons {{bulkShowSaveButton}}" (click)="saveBranch(2)">
                        <span>
                          Save
                          <svg id="ic-done-20px" xmlns="http://www.w3.org/2000/svg" width="20"
                               height="20" viewBox="0 0 20 20">
                            <rect id="Rectangle_18127" data-name="Rectangle 18127" width="20"
                                  height="20" fill="none" />
                            <path id="check_circle_FILL0_wght300_GRAD0_opsz20"
                                  d="M8.854,11.646l-1.9-1.9a.678.678,0,0,0-.937,0,.687.687,0,0,0-.229.49.621.621,0,0,0,.229.468l2.25,2.25a.722.722,0,0,0,.552.25.859.859,0,0,0,.594-.25l4.562-4.583a.653.653,0,0,0,0-.917.632.632,0,0,0-.937,0ZM10,17.917a7.723,7.723,0,0,1-3.094-.625,7.871,7.871,0,0,1-4.2-4.2A7.723,7.723,0,0,1,2.083,10a7.723,7.723,0,0,1,.625-3.094,7.871,7.871,0,0,1,4.2-4.2A7.723,7.723,0,0,1,10,2.083a7.723,7.723,0,0,1,3.094.625,7.871,7.871,0,0,1,4.2,4.2A7.723,7.723,0,0,1,17.917,10a7.723,7.723,0,0,1-.625,3.094,7.871,7.871,0,0,1-4.2,4.2A7.723,7.723,0,0,1,10,17.917ZM10,10Zm0,6.583a6.344,6.344,0,0,0,4.656-1.927A6.344,6.344,0,0,0,16.583,10a6.344,6.344,0,0,0-1.927-4.656A6.344,6.344,0,0,0,10,3.417,6.344,6.344,0,0,0,5.344,5.344,6.344,6.344,0,0,0,3.417,10a6.344,6.344,0,0,0,1.927,4.656A6.344,6.344,0,0,0,10,16.583Z"
                                  fill="#0068d8" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="cancel-btn  {{bulkShowCancelButton}}" (click)="cancel(2)">
                    <span>Cancel</span>
                  </div>
                </div>
                <div class="select-edit"><validation-summary [validationErrors]="bulkSelectionErrorMessages"></validation-summary></div>
              </div>
            </div>
            <div [ngClass]="{'d-none': !ShowUploadWizard}">
              <div class="row">
                <div class="col-12 col-sm-12 drag-drop">
                  <div class="input-container ">
                    <button class="" type="button" mat-raised-button (click)="fileInput.click()">
                      <svg class="" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                        <g id="Group_13129" data-name="Group 13129" transform="translate(-853 -349)">
                          <rect id="Rectangle_18444" data-name="Rectangle 18444" width="40" height="40" transform="translate(853 349)" fill="none" />
                          <path id="upload_FILL0_wght300_GRAD0_opsz24" d="M17,22.908a1.177,1.177,0,0,1-.9-.375,1.233,1.233,0,0,1-.355-.875V8.908l-3.208,3.208a1.37,1.37,0,0,1-.875.355,1.2,1.2,0,0,1-.917-.4,1.151,1.151,0,0,1-.353-.9,1.226,1.226,0,0,1,.353-.853l5.208-5.208a1.371,1.371,0,0,1,.48-.313,1.583,1.583,0,0,1,1.125,0,1.375,1.375,0,0,1,.478.313l5.208,5.208a1.131,1.131,0,0,1,.355.875,1.37,1.37,0,0,1-.355.875,1.439,1.439,0,0,1-.917.4,1.131,1.131,0,0,1-.875-.355L18.25,8.908v12.75a1.236,1.236,0,0,1-.353.875A1.182,1.182,0,0,1,17,22.908ZM7.5,29.367a2.988,2.988,0,0,1-3-3v-3.25a1.211,1.211,0,0,1,1.25-1.25A1.211,1.211,0,0,1,7,23.117v3.25a.589.589,0,0,0,.5.5h19a.589.589,0,0,0,.5-.5v-3.25a1.25,1.25,0,1,1,2.5,0v3.25a2.988,2.988,0,0,1-3,3Z" transform="translate(856 351.966)" fill="#0068d8" />
                        </g>
                      </svg>
                    </button>
                    <input class="" hidden (change)="onFileSelected($event)" #fileInput type="file" accept=".xlsx">
                    <div class="block-desc ">
                      <p>Drag &amp; Drop or <a href="javascript:void(0)" (click)="fileInput.click()">Browse</a> <br><small class="text-blue">(Support: .xlsx only)</small></p>
                    </div>
                  </div>
                  <div class="download-link" (click)="getCSVTemplate()">Download template</div>
                </div>
              </div>
            </div>
            <!-- <div class="row"  [ngClass]="{'d-none': ShowUploadWizard}">
              <div class="col-8 col-sm-8 col-md-9">
                <div class="input-block">
                  <label class="input-label">Uploaded file <span class="validation-field">*</span></label>
                  <input type="text" class="form-control" placeholder="" formControlName="uploadFileName" name="uploadFileName" readonly />
                </div>
              </div>
                <div class="col-4 col-sm-4 col-md-3 action-data-btn">
                  <div class="action-btn">
                    <label class="input-label"> &nbsp;</label><br>
                    <button class="save-icon icons remove-doc-btn" (click)="RemoveUploadedFile()">
                      <span class="pd-rt-10"> Remove </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g id="Group_13132" data-name="Group 13132" transform="translate(-1197.834 -268)"><rect id="Rectangle_18445" data-name="Rectangle 18445" width="20" height="20" transform="translate(1197.834 268)" fill="none"/><path id="cancel_FILL0_wght300_GRAD0_opsz20" d="M6.562,13.438a.609.609,0,0,0,.917,0L10,10.917l2.521,2.521a.68.68,0,0,0,.937,0,.6.6,0,0,0,.2-.459.754.754,0,0,0-.218-.479l-2.5-2.5,2.52-2.521a.678.678,0,0,0,0-.937.63.63,0,0,0-.937,0L10,9.062,7.458,6.521a.573.573,0,0,0-.448-.177.649.649,0,0,0-.448.2.608.608,0,0,0,0,.916L9.083,10,6.542,12.542a.573.573,0,0,0-.177.448.651.651,0,0,0,.2.448ZM10,17.917a7.723,7.723,0,0,1-3.094-.625,7.871,7.871,0,0,1-4.2-4.2A7.723,7.723,0,0,1,2.083,10a7.723,7.723,0,0,1,.625-3.094,7.871,7.871,0,0,1,4.2-4.2A7.723,7.723,0,0,1,10,2.083a7.723,7.723,0,0,1,3.094.625,7.871,7.871,0,0,1,4.2,4.2A7.723,7.723,0,0,1,17.917,10a7.723,7.723,0,0,1-.625,3.094,7.871,7.871,0,0,1-4.2,4.2A7.723,7.723,0,0,1,10,17.917ZM10,10Zm0,6.583a6.344,6.344,0,0,0,4.656-1.927A6.344,6.344,0,0,0,16.583,10a6.344,6.344,0,0,0-1.927-4.656A6.344,6.344,0,0,0,10,3.417,6.344,6.344,0,0,0,5.344,5.344,6.344,6.344,0,0,0,3.417,10a6.344,6.344,0,0,0,1.927,4.656A6.344,6.344,0,0,0,10,16.583Z" transform="translate(1197.834 268)" fill="#0068d8"/></g></svg>

                    </button>
                  </div>
                </div>
            </div> -->
            <form>
              <div class="row" [ngClass]="{'d-none': ShowUploadWizard}">
                <div class="col-6 col-sm-6">
                  <div class="preview-detail">
                    <span class="bold">Preview </span>
                    <span>({{csvData.length}} Records)</span>
                  </div>
                </div>
                <!-- <div class="col-6 col-sm-6">
                  <div class="search-block">
                    <input id="search" type="text" class="search-input" placeholder="Type to search"
                      role="search" name="search" [(ngModel)]="searchText">
                    <button class="search-icon"> </button>
                  </div>
                </div> -->
                <div class="col-6 col-sm-6">
                  <div class="preview-detail right">
                    <span>{{selectedFile?.name}}</span>
                    <span>
                      <button type="button" class="save-icon icons remove-btn" (click)="RemoveUploadedFile()">
                        Remove
                      </button>
                    </span>
                  </div>
                </div>

                <div class="col-12 col-sm-12">
                  <div class="common-table table-border bulk-import" *ngIf="csvData?.length > 0">
                    <table class="table">
                      <thead class="sticky-from-top">
                        <tr class="text-nowrap">
                          <th *ngFor="let header of tableTitle">{{header}}</th>
                          <!-- <th scope="col" *ngFor="let theader of headers">{{theader | titlecase}} </th> -->
                          <th scope="col" [width]="70">Action</th>
                        </tr>
                      </thead>
                    </table>
                    <virtual-scroller #scroll [items]="csvData" [childHeight]="15">
                      <table class="table">

                        <tbody>
                          <tr *ngFor="let npn of scroll.viewPortItems" [attr.id]="npn.index" [ngClass]="{'bg-error': npn.isError}" (click)="displayError(npn)">
                            <td><input class="inline-input inline-edit" type="text" maxlength="50" name="firstName-{{npn.index}}" [(ngModel)]="npn.firstName"></td>
                            <td><input class="inline-input inline-edit" type="text" maxlength="100" name="lastName-{{npn.index}}" [(ngModel)]="npn.lastName"></td>
                            <td><input class="inline-input inline-edit" type="text" maxlength="20" name="producerCode-{{npn.index}}" [(ngModel)]="npn.producerCode" (change)="getValidateDataList()"></td>
                            <td><input class="inline-input inline-edit" type="text" maxlength="10" name="npn-{{npn.index}}" [(ngModel)]="npn.npn" (change)="getValidateDataList()"></td>
                            <td><input class="inline-input inline-edit" type="text" maxlength="200" name="email-{{npn.index}}" [(ngModel)]="npn.email" (change)="getValidateDataList()"></td>
                            <td>
                              <span (click)="removeRecordfromCSVData(npn)">
                                <svg _ngcontent-tvk-c193="" id="ic-delete-20px" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                     viewBox="0 0 20 20">
                                  <rect _ngcontent-tvk-c193="" id="Rectangle_17995" data-name="Rectangle 17995" width="20" height="20" fill="none">
                                  </rect>
                                  <path _ngcontent-tvk-c193="" id="delete_FILL0_wght300_GRAD0_opsz20"
                                        d="M6.146,17.083A1.566,1.566,0,0,1,4.562,15.5V5.125H4.354A.663.663,0,0,1,3.885,3.99a.641.641,0,0,1,.469-.2H7.521A.726.726,0,0,1,7.76,3.25a.776.776,0,0,1,.552-.229h3.355a.776.776,0,0,1,.552.229.726.726,0,0,1,.239.542h3.167a.663.663,0,0,1,.469,1.135.641.641,0,0,1-.469.2h-.208V15.5a1.566,1.566,0,0,1-1.584,1.583ZM5.9,5.125V15.5a.249.249,0,0,0,.25.25h7.687a.249.249,0,0,0,.25-.25V5.125Zm1.9,8.208a.667.667,0,0,0,1.333,0V7.542a.667.667,0,0,0-1.333,0Zm3.062,0a.667.667,0,0,0,1.334,0V7.542a.667.667,0,0,0-1.334,0ZM5.9,5.125V15.5a.249.249,0,0,0,.25.25H5.9V5.125Z"
                                        transform="translate(0.009 -0.052)" fill="#0068d8"></path>
                                </svg>
                              </span>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </virtual-scroller>
                  </div>
                </div>
              </div>
            </form>
            <div class="pt-2" (click)="scrollToElement()" style="cursor: pointer;">
              <validation-summary [validationErrors]="bulkImportErrorMessages" light malihu-scrollbar [scrollbarOptions]="darkScrollbarOptions" class="scroll-bar"></validation-summary>
            </div>
          </div>
          <div class="action-btn footer text-right" [ngClass]="{'d-none': ShowUploadWizard}" #target>
            <button type="button" (click)="addBulkContact()" class="btn commn-btn"
                    [disabled]="fileUploadError || !bulkImportForm.valid">
              Process
            </button>
          </div>
        </form>
      </div>
      <div role="tabpanel" class="tab-pane" id="integrations">
        <form id="" [formGroup]="">
          <div class="group-form">
            <div class="coming-soon-container coming-logo-container">              
              <div class="logo-container">
                <!-- <div class="img-logo">
                  <img src="../../../../assets/images/MC_IMG.png" title="Microsoft Office">
                </div> -->
                <!-- <div class="img-logo">
                  <img src="../../../../assets/images/NIPR_IMG.png">
                </div> -->
                <div class="img-logo">
                  <img src="../../../../assets/images/Salesforce-IMG.png" title="Salesforce">
                </div>
                <div class="img-logo">
                  <!-- <img src="../../../../assets/images/rest-api-icon.png" title="restapi"> -->
                </div>
                <!-- <div class="img-logo">
                  <img src="../../../../assets/images/WebCE.png">
                </div> -->
                <!-- <div class="img-logo">
                  <img src="../../../../assets/images/Workday-IMG.png" title="Workday">
                </div> -->
              </div>
              <p class="coming-soon">Coming soon!</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

