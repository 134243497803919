import { Injectable } from '@angular/core';
import { ApiClientService } from 'api-services/common/api-client.service';
import { NonProducerAdmin } from 'models/adminwithoutnpn.model';

@Injectable({
  providedIn: 'root'
})
export class AdminwithoutnpnService {

  constructor(private apiClient: ApiClientService) { }

  getNonProducerAdminById(id: number, tenantId: number) {
    return this.apiClient.get(`api/NonProducerAdmin/${id}/${tenantId}`, null, null, false);
  }

  insertNonProducerAdmin(nonProducerAdmin: NonProducerAdmin) {
    return this.apiClient.post('api/NonProducerAdmin/insertNonProducerAdmin', nonProducerAdmin, undefined, true);    
  }  

  updateNonProducerAdmin(nonProducerAdmin: NonProducerAdmin) {
    return this.apiClient.post('api/NonProducerAdmin/updateNonProducerAdmin', nonProducerAdmin, null, true);    
  }

  deleteNonProducerAdmin(userId: number, tenantId: number){
    return this.apiClient.post(`api/NonProducerAdmin/deleteNonProducerAdmin/${userId}/${tenantId}`, null, null, true);
  }
}
