<div id="toast-container" class="toast-bottom-right" *ngFor="let item of notifications">  
  <div class="toast {{cssClass(item) }}" aria-live="polite" style="display: block;">  
      <button type="button" class="toast-close-button" role="button" (click)="removeNotification(item)">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <rect data-name="Rectangle 18059" width="20" height="20" fill="none" />
          <path
              d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z"
              transform="translate(-0.005 0.005)" fill="#ffffff" />
      </svg>
      </button>  
      <div class="toast-message">  
          {{item.message}}  
      </div>  
  </div>  
</div>  
