import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../../api-services';
import { SharedService } from '../../../services';
import { ValidatorHelper } from '../../custom-validators/validators.helper';
import { seterror } from 'common/slick-slider';
import { CommonFunc } from '../../common/common-func/common-func';

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.css']
})
export class ResetPwdComponent implements OnInit {

  isShow: boolean = true;
  isValid: boolean = true;
  errorMessages = [];
  setPwdForm!: FormGroup;
  constructor(private router: Router, private fb: FormBuilder, private route: ActivatedRoute, private accountService: AccountService, private sharedService: SharedService) { }

  ngOnInit(): void {
   //  ;
    this.setPwdForm = this.fb.group({
      userId: [],
      password: ['', [ValidatorHelper.passwordPattern, Validators.required,]],
      confirmPassword: ['', [ValidatorHelper.passwordPattern, Validators.required,]],
      activationCode: []
    });
    this.route.queryParams.subscribe(paramsId => {
      this.setPwdForm.get("userId")!.setValue(paramsId['id']);
      this.setPwdForm.get("activationCode")!.setValue(paramsId['ac']);
    });
    this.setPwdForm.markAsUntouched();
    this.accountService.validatePasswordActivation(this.setPwdForm.get("userId")?.value).subscribe(
      (response: any) => {
      },
      (error: any) => {
        this.errorMessages.push(error.message);
      }
    )
    seterror();
  }


  onSubmit(): void {
    this.errorMessages = [];
    
    this.setPwdForm.markAllAsTouched();
     ;
    if (this.setPwdForm.get("password")?.value === this.setPwdForm.get("confirmPassword")?.value) {
      this.accountService.resetPwd(this.setPwdForm.value).subscribe(
        (response: any) => {
          if (response != null && response.token != null) {
            this.accountService.setToken(response, response.token);
            this.router.navigate(['/dashboard']);

          } else {
              this.errorMessages.push("Activation link might having problem or account already activated.....!!");
          }

        },
        (error: any) => {
          console.log(error);
        }
      )
    } else {
      this.errorMessages.push('Password and Confirm Password are not same !!');
    }

  }


  showPassword(id) {
    id == 'setPassword' ? this.isShow = !this.isShow : this.isValid = !this.isValid;
    CommonFunc.showPassword(id);
  }
}
