import { Injectable } from '@angular/core';
import { CustomGroup } from 'models/custom-loa-group.model';
import { AuthService } from '../services/auth.service';
import { ApiClientService } from './common/api-client.service';
import { CustomGroupAssignment } from 'models/custom-group-assignment.model';
import { ContactAssignment } from 'models/assign-contact.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  public contactList: BehaviorSubject<any> = new BehaviorSubject([]);
  
  constructor(
    private apiClient: ApiClientService,
    private authService: AuthService) { }

    public getContactList(contactList: boolean) { //the component that wants to update something, calls this fn
      this.contactList.next(contactList); //next() will feed the value in Subject
    }

  sendInvitation(requestObject: any) {
    return this.apiClient.post(`api/Contact/sendinvitation`, requestObject, null, true);
  }

  updateRole(requestObject: any) {
    return this.apiClient.post(`api/Contact/changerole`, requestObject, null, true);
  }

  post(requestObject: any) {
    return this.apiClient.post(`api/Contact`, requestObject, null, false);
  }

  put(requestObject: any) {
    return this.apiClient.put(`api/Contact`, requestObject, null, false);
  }

  //delete(id: number) {
  //    return this.apiClient.delete(`api/Contact/${id}`, null, null, false);
  //}
  delete(requestObject: any) {
    return this.apiClient.post(`api/Contact/deletecontact`, requestObject, null, true);
  }

  getAll(value) {
    return this.apiClient.get(`api/Contact`, null, null, false);
  }

  getById(id: number, tenantId: number) {
    return this.apiClient.get(`api/Contact/${id}/${tenantId}`, null, null, false);
  }

  //public listContactByTenant(tenantId: number, pageNum: number, pageSize: number, search: string) {
  public listContactByTenant(search: any) {
    return this.apiClient.post(`api/contact/listcontact`, search, null, true);
  }

  getContactDashboardDetail(request,showLoading = true) {
    return this.apiClient.post(`api/Contact/ContactDashboard`, request, null, showLoading);
  }

  //Unused
  getTenantSubscriptionDetail(tenantId: number) {
    return this.apiClient.get(`api/Payment/tenantsubscriptiondetails/${tenantId}`, null, null, true);
  }

  assignCustomGroups(customGroupAssignment: CustomGroupAssignment) {
    return this.apiClient.post(`api/Contact/assignCustomGroups`, customGroupAssignment, null, true);
  }

  sendNotification(tenantId: number, userLicenses: string) {
    return this.apiClient.post(`api/ExpiringLicenseNotification/userExpiredlicense/${tenantId}/${userLicenses}`, null, null, true);
  }

  getTenantContactCount(tenantId: number) {
    return this.apiClient.get(`api/Contact/getTenantContactCount/${tenantId}`, null, null, true);
  }

  getUserAddresslist(userId: number) {
    return this.apiClient.get(`api/Contact/getUserAddresslist/${userId}`, null, null, true);
  }

  assignContacts(contactAssignment: ContactAssignment) {
    return this.apiClient.post(`api/Contact/assignContacts`, contactAssignment, null, true);
  }

  public getContactLicenseClassList(contactId: number) {
    return this.apiClient.get(`api/Contact/ContactLicenseClassListForFileUpload/${contactId}`, null, null, true);
  }

  createAddressChangeRequest(payload: any) {
    return this.apiClient.post(`api/Contact/createAddressChangeRequest`, payload, null, true);
  }

  getCountyListByStateCode(stateCode: string) {
    return this.apiClient.get(`api/Contact/getCountyListByStateCode/${stateCode}`, null, null, false);
  }

  getResidenceStateOfUser(userId: number, tenantId: number) {
    return this.apiClient.get(`api/Contact/getResidenceStateOfUser/${userId}/${tenantId}`, null, null, true);
  }

  getStatesToSkip(tenantId: number) {
    return this.apiClient.get(`api/Contact/getStatesToSkip/${tenantId}`, null, null, true);
  }

  // Firm Dashboard
  getFirmDashboardDetail(request) {
    return this.apiClient.post(`api/branch/FirmDashboard`, request, null, true);
  }

  archiveContact(requestObject: any) {
    return this.apiClient.post(`api/Contact/archiveContact`, requestObject, null, true);
  }

  public listArchivedContactByTenant(search: any) {
    return this.apiClient.post(`api/contact/listArchivedContactByTenant`, search, null, true);
  }

  unarchiveContact(requestObject: any) {
    return this.apiClient.post(`api/Contact/unarchiveContact`, requestObject, null, true);
  }

  downloadBulkImportTemplate() {
    return this.apiClient.exportGet(`api/Contact/downloadBulkImportTemplate`, null, null, true);
  }

  updateUserAlias(requestObject: any) {
    return this.apiClient.post(`api/Contact/updateUserAlias`, requestObject, null, true);
  }

  updateUserProducerCode(requestObject: any) {
    return this.apiClient.post(`api/Contact/updateUserProducerCode`, requestObject, null, true);
  }

  updateUserProfileInformation(requestObject: any) {
    return this.apiClient.post(`api/Contact/updateUserProfileInformation`, requestObject, null, true);
  }

  getTenantJobTitles(tenantId: number) {
    return this.apiClient.get(`api/Tenant/getJobTitlesByTenant`, null, null, true);
  }

  getTenantDepartment(tenantId: number) {
    return this.apiClient.get(`api/Tenant/getDepartmentsByTenant`, null, null, true);
  }

  getContactCEDetails(search: any) {
    return this.apiClient.post(`api/contact/contactCEDetailsList`, search, null, true);
  }

  updateUserCEDetails(requestObj: any) {
    return this.apiClient.post(`api/Contact/updateUserCEDetails`, requestObj, null, true);
  }

  getStateWiseCETypeList(stateCode: string) {
    return this.apiClient.get(`api/contact/ceTypeList/${stateCode}`, null, null, false);
  }

  addNewContactCEDetails(obj: any) {
    return this.apiClient.post(`api/Contact/insertUserCEDetails`, obj, null, true);
  }

  uploadBulkImportFile(formData: FormData): Observable<any> {
      const header = new HttpHeaders().append(
        "Content-Disposition",
        "multipart/form-data"
    );
    return this.apiClient.uploadFile(`api/contact/uploadBulkImportFile`, formData, header);
  }

  public getUser(userId: number) {
    return this.apiClient.get(`api/user/userdetail/${userId}`, null, null, false);
  }  

  producerLincenseExpireEmailNotificationList(userid :string) {
    return this.apiClient.get(`api/Contact/producerLincenseExpireEmailNotificationList/${userid}`,null,null, true);
  }

  producerLicenseExpireEmailNotificationDetails(emailNotificationLogId : number) {
    return this.apiClient.get(`api/Contact/producerLicenseExpireEmailNotificationDetails/${emailNotificationLogId}`,null, null, true);
  }
}
