export const environment = {
    production: false,
    encryptdecrypt: "1203199320052021",
    baseUrl: "https://agenzee-demo-api.azurewebsites.net/",
    scheduleDemoURL: "https://agenzee.com/schedule-a-demo/",
    calendlyURL: "https://calendly.com/sjoshi-rdglobalinc/onboarding",
    pageSize: "100",
    // baseUrl: "https://license-dev-api.azurewebsites.net/",
    //stripe_publish_key: "pk_test_51LKGniClyRSTXMkncqdft7bvpBWokm3ivm4s261KRsSRWQwM1AACL9gqqcHeV6G0T8He1WEWRHeGCXq1eySEN9V400m8XGnXaG"
    stripe_publish_key: "pk_test_51NEs0WCG6BBG59bwDpah9RdyznPTu6T30jFBX29TCgbJw2bEaHQ3hvCGbwnlgUmXu93uuEas14lIhlTqSjqruHW500VBpTOyFm",
    version: "1.2.262"
};
