<div class="task-list-data ce-upload">
  <div id="ce-upload" data-keyboard="false">
    <div class="heading-dialog">
      <div class="list-content">
        <div class="list-header">
          <h4 class="list-title">Upload Document(s)</h4>
          <button type="button" class="close" aria-label="close" data-dismiss="modal" (click)="close()">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
              <rect width="28" height="28" rx="6" fill="none" />
              <g transform="translate(4 4)">
                <rect width="20" height="20" fill="none" />
                <path d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z"
                      transform="translate(-0.005 0.005)" fill="#757575" />
              </g>
            </svg>
          </button>
        </div>
        <div class="list-body">
          <div class="group-form">
            <div class="common-table">
              <table class="table contact-list-table">
                <thead class="sticky-from-top">
                  <tr>
                    <th scope="col">File Name</th>
                    <th scope="col">File Type</th>
                    <th scope="col">Notes </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let file of newUploadedFile">
                    <td class="max-width-tooltip-450">
                      {{file.fileName}}
                      <p *ngIf="file.errorMessagesFileFormat != null" class="validation-field">{{file.errorMessagesFileFormat}} </p>
                      <p *ngIf="file.errorMessagesFileSize != null" class="validation-field">{{file.errorMessagesFileSize}} </p>
                      <p *ngIf="file.errorMessagesFileType != null" class="validation-field">{{file.errorMessagesFileType}} </p>
                    </td>
                    <td class="custom-type">
                      <div class="select-options">
                        <div class="select-block">
                          <ng-select [searchable]="false" [clearable]="false" placeholder="File Type" class="type min-height" (change)="file.errorMessagesFileType = null" [(ngModel)]="file.fileTypeId">
                            <ng-option *ngFor="let fileType of fileTypeList" [value]="fileType.fileTypeId">
                              {{fileType.fileTypeName}}
                            </ng-option>
                          </ng-select>
                        </div>
                      </div>
                    </td>
                    <td class="max-width-tooltip-450 custom-type">
                      <div class="main-group">
                        <div>
                          <input type="text" class="inline-input inline-edit" autocomplete="off" placeholder="Enter note" name="Enter note" [(ngModel)]="file.description" maxlength="200">
                        </div>
                      </div>
                    </td>
                    <td style="text-align: left; width: 70px;">
                      <button type="button" class="close" aria-label="close" data-dismiss="modal" (click)="removedSelectedFile(file.id)" style="width: 100%; text-align: left;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                          <rect width="28" height="28" rx="6" fill="none" />
                          <g transform="translate(4 4)">
                            <rect width="20" height="20" fill="none" />
                            <path d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z"
                                  transform="translate(-0.005 0.005)" fill="#757575" />
                          </g>
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style="padding-top:8px;">
                <validation-summary [validationErrors]="errorMessages"></validation-summary>
              </div>
              <!--<p class="validation-error-table"><validation-summary [validationErrors]="errorMessagesFileData"></validation-summary></p>
  <p class="validation-error-table" [class.hidden]="!isfileErrorShow"><validation-summary [validationErrors]="errorMessagesFileFormat"></validation-summary></p>-->
            </div>
          </div>
        </div>
        <div class="list-footer">
          <div class="cancel-btn">
            <a type="button" class="cancel-sub" (click)="close()">Cancel</a>
          </div>
          <div class="action-btn">
            <button type="button" class="submit-btn" [disabled]="this.fileUploadValid == false" (click)="uploadFiles()"><span>Save</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
