import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '../../../api-services/account.service';
import { AuthService } from '../../../services/auth.service';
import { ValidatorHelper } from '../../custom-validators/validators.helper';

@Component({
  selector: 'app-forgot-pwd',
  templateUrl: './forgot-pwd.component.html',
  styleUrls: ['./forgot-pwd.component.css']
})
export class ForgotPwdComponent implements OnInit {
  errorMessages: any;
  passwordRequestForm!: FormGroup;
  showToast = false;
  isError: boolean = true;
  constructor(private router: Router, private fb: FormBuilder, private accountService: AccountService, private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.redirect();
    this.passwordRequestForm = this.fb.group({
      email: ['', [Validators.required, ValidatorHelper.emailPattern]],
      activationCode: ['', []]
    });
  }
  
  touchEmail()
  {   
    this.errorMessages = [];
  }
  onSubmit(): void {
    this.passwordRequestForm.markAllAsTouched();
    if (this.passwordRequestForm.valid) {
      
      this.accountService.forgetPwd(this.passwordRequestForm.value).subscribe(
        (response: any) => {
          if (response.userId > 0) {
            this.showToast = true;
            this.errorMessages = [];
            this.isError = false;
            this.errorMessages.push("Activation link sent in mail to reset password");
          }
          else {
            this.errorMessages = [];
            this.isError = true;
            this.errorMessages.push("Something went wrong.");
          }
           // alert('Something went wrong.');
        },
        (error: any) => {
          this.errorMessages = [];
          this.isError = true;
          this.errorMessages.push(error.message);
        }
      )
    }

  }

}
