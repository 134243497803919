<div class="main-layout-grid">
  <main id="main-container">
    <div class="data-content thank-you-page">    
      <div class="content-width full-width">
        <div class="right-side-content">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-12">
                <div class="logo">
                  <img src="assets/images/logo-text.svg" height="72px"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12">
                <div class="thank-you-data">
                  <div class="thank-you-title">
                    <h1 class="title">
                      <!-- Thank You! -->
                      Thank you for your interest in Agenzee!
                    </h1>
                  </div>
                  <div class="thank-you-info">
                    <p class="para">
                      Welcome to the Agenzee family! We are absolutely thrilled to have you on board and can't wait to start working together. We're here to ensure a smooth and seamless experience as you get set up with our platform.
                    </p>
                    <p class="para">
                      Before our upcoming Teams meeting, please have the following information ready to help us get everything in place:
                    </p>
                    <ul>
                      <li>Your Business NPN</li>
                      <li><a href="javascript:void(0)" (click)="downloadExcel()"> Bulk NPN template </a> with your producer information, or at least one producer NPN you'd like to onboard</li>
                      <li>A valid credit card for the account setup</li>
                    </ul>
                    <p class="para">
                    If you have any questions at all, don't hesitate to reach out. We're here to help, and no question is too small!
                    </p>
                    <p class="para">
                      Looking forward to connecting with you soon!  
                    </p>
                  </div>
                  <div class="action-btn">
                    <!-- <a href="javascript:void()" routerLink="/login" class="btn commn-btn">Explore Agenzee</a> -->
                    <a href="https://agenzee.com/blogs/" target="_blank" class="btn commn-btn">Explore Agenzee</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </main>
</div>
