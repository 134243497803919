import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClientService } from 'api-services/common/api-client.service';
import { Filter, FilterList, FilterRequest, FilterResponse } from 'models/filter.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {  

  constructor(private apiClient: ApiClientService) {}

  getFilters(filterCategoryId: number, tenantId: number, userId:number): Observable<FilterList[]> {    
    let params = new HttpParams();
    
    if (filterCategoryId) {
      params = params.append("filterCategoryId", filterCategoryId);
    }

    if (tenantId) {
      params = params.append("tenantId", tenantId);
    }

    if (userId) {
      params = params.append("userId", userId);
    }

    return this.apiClient.get('api/Filter/getFilterListByFilterCategoryTenantUserId', undefined, params, false);
  }

  insertFilter(filters: Filter) {
    return this.apiClient.post('api/Filter/saveFilter', filters, undefined, false);    
  }

  updateFilter(filters: Filter) {
    return this.apiClient.put('api/Filter/updateFilter', filters, null, false);    
  }

  deleteFilter(request: FilterRequest): Observable<FilterResponse>{
    return this.apiClient.post('api/Filter/deleteFilterByFilterId', request, undefined, false);
  }
}
