<div class="task-list-data select-carrier-modal">
    <div id="update-group" data-keyboard="false">
      <div class="heading-dialog">
        <div class="list-content">
          <div class="list-header">
            <h4 class="list-title">
              <span>Select Carrier</span>
            </h4>
            <div class="btn-box d-flex">
                <button class="btn btn-sm secondary-btn mr-4" type="button" data-toggle="modal" data-target="#addNewCarrier" data-backdrop="static" data-keyboard="false">
                    <span >Add Carrier</span>
                    <span class="plus-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <rect data-name="Rectangle 17995" width="20" height="20" fill="none" />
                        <path d="M10,15.625a.676.676,0,0,1-.667-.667V10.667H5.042a.667.667,0,0,1,0-1.334H9.333V5.042a.667.667,0,0,1,1.334,0V9.333h4.291a.667.667,0,0,1,0,1.334H10.667v4.291a.676.676,0,0,1-.667.667Z"
                              transform="translate(0)" fill="#0068D8" />
                      </svg>
                    </span>
                    
                  </button>
              <button type="button" class="close" aria-label="close" data-dismiss="modal"
                      (click)="closePopup(null)">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                  <rect width="28" height="28" rx="6" fill="none" />
                  <g transform="translate(4 4)">
                    <rect width="20" height="20" fill="none" />
                    <path d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z"
                          transform="translate(-0.005 0.005)" fill="#757575" />
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div class="list-body" style="max-height: calc(100vh - 190px); overflow: auto;">
            <div class="row">
            <div class="search-block col-6 pr-0">
              <input id="search" type="text" class="search-input" placeholder="Search by Carrier, Alias, FEIN, Company Code" role="search" name="search"
              (keydown.enter)="$event.preventDefault();getCarrierListByTenant();"  [(ngModel)]="carrierListModel.search">
              <span class="close-icon" (click)="clearSearch()" *ngIf="carrierListModel.search?.length > 0">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><rect _ngcontent-wvn-c189="" width="20" height="20" rx="6" fill="none"></rect><g _ngcontent-wvn-c189="" transform="translate(4 4)"><rect _ngcontent-wvn-c189="" width="16" height="16" fill="none"></rect><path _ngcontent-wvn-c189="" d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z" transform="translate(-0.005 0.005)" fill="#9e9e9e"></path></g></svg>
              </span>
              <button class="search-icon" (click)="getCarrierListByTenant()"> </button>
            </div>
            <div class="pagination-block col-6 text-end">
              <div class="right-part">
                <div class="pagination" style="justify-content: end;">
                  <div class="pagination-number">
                    <span class="num-range">{{range[0]}} - {{range[1]}}</span> of
                    <span class="total-num" *ngIf="carrierList?.length > 0">
                      <span> {{carrierList[0].overallCount}} </span>
                    </span>
                  </div>
                  <div class="pagination-action-btn">
                    <a class="back" (click)="back()" [class.disabled]="range[0] <= 1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="ic-arrow" transform="translate(0)">
                          <rect data-name="Rectangle 17998" width="20" height="20" transform="translate(0)"
                                fill="none" />
                          <path d="M10,12.458a.867.867,0,0,1-.323-.062.925.925,0,0,1-.3-.208L5.6,8.417a.782.782,0,0,1-.229-.625.891.891,0,0,1,.25-.6.823.823,0,0,1,.625-.261.863.863,0,0,1,.6.261L10,10.354l3.167-3.166a.81.81,0,0,1,.593-.25.849.849,0,0,1,.615.27.881.881,0,0,1,0,1.23l-3.75,3.75a.925.925,0,0,1-.3.208A.867.867,0,0,1,10,12.458Z"
                                transform="translate(0.308 20) rotate(-90)" fill="#9E9E9E" />
                        </g>
                      </svg>
                    </a>
                    <a class="next" (click)="next()" [class.disabled]="range[1] == overallCount">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g transform="translate(0)">
                          <rect data-name="Rectangle 17998" width="20" height="20" transform="translate(0)"
                                fill="none" />
                          <path d="M10,12.458a.867.867,0,0,1-.323-.062.925.925,0,0,1-.3-.208L5.6,8.417a.782.782,0,0,1-.229-.625.891.891,0,0,1,.25-.6.823.823,0,0,1,.625-.261.863.863,0,0,1,.6.261L10,10.354l3.167-3.166a.81.81,0,0,1,.593-.25.849.849,0,0,1,.615.27.881.881,0,0,1,0,1.23l-3.75,3.75a.925.925,0,0,1-.3.208A.867.867,0,0,1,10,12.458Z"
                                transform="translate(0.308 20) rotate(-90)" fill="#9E9E9E" />
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div class="common-table pd-0 upload-data mt-3" #scroll>
              <table class="table appointment-list-table">
                <thead class="thead-sticky">
                    <tr>
                      <th scope="col" style="padding-left: 48px;">Carrier name 
                        <img class="sorting-icon-asc" (click)="onSortClick($event, 'carrierName')"
                        src="assets/images/ic_ascending.svg" alt="" /></th>
                      <th scope="col">Alias</th>
                      <th scope="col">LOB <img class="sorting-icon-asc" (click)="onSortClick($event, 'lineofBusiness')"
                        src="assets/images/ic_ascending.svg" alt="" /></th>
                      <th scope="col">FEIN</th>
                      <th scope="col">Company code</th>
                      <th scope="col">Created on <img class="sorting-icon-des" (click)="onSortClick($event, 'createdDate')"
                        src="assets/images/ic_ascending.svg" alt="" /></th>
                    </tr>
                </thead>
                <tbody class="tbody-default">
                  <tr *ngFor="let list of carrierList" [class.select-data]="list.carrierId === selectedCarrierId">
                    <td class="pl-3">
                      <div class="custom-control custom-radio ">
                        <input type="radio" name="carrierName" [(ngModel)]="selectedCarrierId" class="custom-control-input" id="{{list.carrierId}}" [value]="list.carrierId" (change)="selectedCarrierUser(list)">
                        <label class="custom-control-label pl-2" for="{{list.carrierId}}"><span class="max-width-tooltip-250" title="{{list.carrierName}}" appEllipsifyMe>{{list.carrierName}}</span></label>
                      </div>
                    </td>
                    <td><span class="max-width-tooltip-250" appEllipsifyMe>{{list.displayName}}</span></td>
                    <td><span class="max-width-tooltip-250" appEllipsifyMe>{{list.lineofBusiness}}</span></td>
                    <td>{{list.niprfeinCode}}</td>
                    <td>{{list.niprcoCode}}</td>
                    <td>{{list.createdDate | date :'MM/dd/yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="list-footer">
            <div class="d-flex">
            <div class="cancel-btn">
              <a type="button" class="cancel-sub" [ngClass]="{'disabled': selectedCarrierId == null}" (click)="closePopup(null)">Cancel</a>
            </div>
            <div class="action-btn">
              <button type="button" class="submit-btn" [ngClass]="{'disabled': selectedCarrierId == null}" (click)="closePopup(selectedCarrier)">
                <span>Select</span>
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="addNewCarrier" class="common-modal modal fade add-new-carrier" arial-hidden="true" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content admin-modal">
        <div class="modal-header pop-title">
          <h4 class="modal-title">Add New Carrier</h4>
          <button type="button" class="modal-close-btn close" data-dismiss="modal" #closeNewCarrierPopup (click)="resetForm()">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><rect width="28" height="28" rx="6" fill="none" /><g transform="translate(4 4)"><rect width="20" height="20" fill="none" /><path d="M10,11.229,5.979,15.25a.789.789,0,0,1-.6.24.863.863,0,0,1-.6-.261.88.88,0,0,1,0-1.229l4-4L4.75,5.979a.8.8,0,0,1-.24-.614.875.875,0,0,1,.261-.615A.88.88,0,0,1,6,4.75l4,4.021L14.021,4.75A.869.869,0,0,1,15.25,5.979L11.229,10l4.021,4.021a.855.855,0,0,1,0,1.208.88.88,0,0,1-1.229,0Z" transform="translate(-0.005 0.005)" fill="#757575" /></g></svg>
          </button>
        </div>
        <div class="modal-body">
          <div class="group-form">
            <form class="department-form" [formGroup]="addNewCarrierForm">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12">
                    <div class="input-block">
                      <label class="input-label" for="carrierName">Carrier name <span class="validation-field">*</span></label>
                      <input type="text" maxlength="100" class="form-control" formControlName="carrierName" id="carrierName" name="carrierName" placeholder="Carrier name" />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="input-block">
                      <label class="input-label" for="aliasName">Alias <span class="validation-field">*</span></label>
                      <input type="text" maxlength="100" class="form-control" formControlName="aliasName" id="aliasName" name="aliasName" placeholder="Alias" />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="input-block">
                      <label class="input-label" for="lob">LOB <span class="validation-field">*</span></label>
                      <input type="text" maxlength="100" class="form-control" formControlName="lob" id="lob" name="lob" placeholder="LOB" />
                    </div>
                  </div>
                  <div class="col-6 pr-2">
                    <div class="input-block">
                      <label class="input-label" for="fein">FEIN <span class="validation-field">*</span></label>
                      <input type="text" class="form-control" formControlName="fein" id="fein" name="fein" [textMask]="{mask: feinMask}" placeholder="XX-XXXXXXX" />
                    </div>
                  </div>
                  <div class="col-6 pl-2">
                    <div class="input-block">
                      <label class="input-label" for="companyCode">Company code <span class="validation-field">*</span></label>
                      <input type="text" maxlength="10" class="form-control" formControlName="companyCode" id="companyCode" name="companyCode" placeholder="Company code" />
                    </div>
                  </div>
                </div>
              </form>
              <validation-summary [validationErrors]="errorMessages"></validation-summary>
          </div>
          <div class="button-block action-btn text-right">
            <button type="submit" class="btn commn-btn lg-bg" data-dismiss="modal" (click)="resetForm()">Cancel</button>
            <button type="submit" class="btn commn-btn dark-bg" [disabled]="!addNewCarrierForm.valid" (click)="addCarrier(false)">Add</button>
            <button type="submit" class="btn commn-btn dark-bg" [disabled]="!addNewCarrierForm.valid" (click)="addCarrier(true)">Add & Select</button>
          </div>
        </div>
      </div>
    </div>
  </div>


  